import React, { useContext, useState, useEffect, useMemo } from 'react';
import { auth } from './firebase-config';
import PropTypes from 'prop-types';
import NellisAuctionApi from '../../shared-api-adapters/nellis-auction-api';
import { getIdToken } from './auth-helpers';
import AuctionMethodApi from '../../shared-api-adapters/auction-method-api';
import { useHistory } from 'react-router-dom';
import { useAuctionConcoctionApi, useCargoApi } from '../hooks';

export const AuthContext = React.createContext(null);

export const useAuth = () => {
  const history = useHistory();
  const [authUser, setAuthUser] = useState(null);
  const [authState, setAuthState] = useState(null);

  // All memoized instances of our APIs
  const cargoApi = useCargoApi();
  const auctionConcoctionApi = useAuctionConcoctionApi();
  const nellisAuctionApi = useMemo(() => new NellisAuctionApi(), []);
  const auctionMethodApi = useMemo(
    () => {
      if (!authUser) return null;
      return new AuctionMethodApi(
        null,
        process.env.NODE_ENV === 'development'
          ? '32838b54df255fa13b19dfb770ed516cca1f1a97'
          : authUser.apiKey
      );
    },
    [authUser]
  );

  useEffect(() => {
    auth.onAuthStateChanged(authenticated => {
      if (!authenticated) history.push('/login');
      setAuthState(authenticated);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const token = useMemo(
    async () => {
      if (!authState) return null;

      return await getIdToken();
    },
    [authState]
  );

  const loggedInUser = useMemo(
    async () => {
      const t = await token;

      if (!t) return null;

      nellisAuctionApi.setToken(t);

      const response = await nellisAuctionApi.get(nellisAuctionApi.routes.authenticate);

      if (response) return response[0];
      return response;
    },
    [token, nellisAuctionApi]
  );

  useEffect(
    () => {
      const getUser = () => loggedInUser;

      getUser().then(user => {
        if (!authState || !user) setAuthUser(null);
        else setAuthUser({ ...authState, ...user });
      });
    },
    [authState, loggedInUser]
  );

  return {
    authUser,
    nellisAuctionApi,
    auctionMethodApi,
    cargoApi,
    auctionConcoctionApi,
  };
};

export const AuthProvider = ({ children }) => {
  const user = useAuth();

  return <AuthContext.Provider value={user}>{children}</AuthContext.Provider>;
};

AuthProvider.propTypes = {
  children: PropTypes.any.isRequired,
};

export const useAuthContext = () => useContext(AuthContext);
