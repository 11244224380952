import { useState, useEffect } from 'react';
import { firebaseApps } from './firebase-config';
import firebase from 'firebase/app';
import 'firebase/firestore';
// eslint-disable-next-line import/no-unresolved
import { useAsync, useAsyncFn } from 'react-use';
// eslint-disable-next-line import/no-unresolved
import { useCollectionData, useDocumentData } from 'react-firebase-hooks/firestore';

export const firestore = firebaseApps.cargo.firestore();

export const incrementBy1 = firebase.firestore.FieldValue.increment(1);

export const decrementBy1 = firebase.firestore.FieldValue.increment(-1);

export const firebaseIncrement = firebase.firestore.FieldValue.increment;

export const firebaseArrayUnion = firebase.firestore.FieldValue.arrayUnion;

export const firebaseArrayRemove = firebase.firestore.FieldValue.arrayRemove;

export const firebaseDecrement = n => firebase.firestore.FieldValue.increment(-n);

export const deleteField = firebase.firestore.FieldValue.delete;

export const hrFirestore = firebaseApps.nellisHr.firestore();

const {
  firestore: { Timestamp },
} = firebase;

export const pickupQueueCollection = hrFirestore.collection('pickUpQueue');

/**
 * Parking Lot Q
 */
export const getLotStructureDoc = affiliateId =>
  hrFirestore
    .collection('affiliates')
    .doc(affiliateId.toString())
    .collection('lotDetails')
    .doc('structure');

export const getLotQueueCollection = affiliateId =>
  hrFirestore
    .collection('affiliates')
    .doc(affiliateId.toString())
    .collection('lotQueue');

export const useLotStructureData = affiliateId => {
  const lotStructureDoc = getLotStructureDoc(affiliateId);

  return useAsync(async () => {
    const doc = await lotStructureDoc.get();

    return doc.data();
  }, []);
};

/**
 * Firebase data structure helper for firebase collection reference
 * @param setter - some type of state management function [*=fn||useState]
 * @returns {function(...[*]=)}
 */
const buildCollectionSnapshotData = setter => snapshot => {
  const data = snapshot.docs.map(doc => {
    return { docId: doc.id, ...doc.data(), ref: doc.ref, exists: doc.exists };
  });

  setter(data);
};

/**
 * Firebase data structure helper for firebase document references
 * @param setter - some type of state management function [*=fn||useState]
 * @returns {function(...[*]=)}
 */
const buildDocumentSnapshotData = setter => doc => {
  setter({ docId: doc.id, ...doc.data(), ref: doc.ref, exists: doc.exists });
};

/**
 * useEffect structure helper - correctly unsubs a connected stream
 * @param reference - Any firebase reference type [*CollectionReference||*DocumentReference]
 * @returns {function(*=): function(): function(): *}
 */
const snapShotEffect = reference => dataBuilder => () => {
  const unsub = reference.onSnapshot(dataBuilder, err => console.log('NELLIS REPORTING:', err));

  return () => unsub();
};

/**
 * Snapshot initialization helper
 * @param reference
 * @param dataBuilder
 * @param deps
 */
const useSnapshotEffect = (reference, dataBuilder, deps = []) =>
  useEffect(snapShotEffect(reference)(dataBuilder), deps);

/**
 * Creates snapshot stream for given collection
 * @param collection Firebase CollectionReference
 * @returns {*[]}
 */
export const useCollectionSnapshot = collection => {
  const [data, setData] = useState([]);

  useSnapshotEffect(collection, buildCollectionSnapshotData(setData), [collection.id]);

  return data;
};

/**
 * Creates snapshot stream for given document
 * @param documentRef - Firebase DocumentReference
 * @returns {{}}
 */
export const useDocumentSnapshot = documentRef => {
  const [data, setData] = useState({});

  useSnapshotEffect(documentRef, buildDocumentSnapshotData(setData), [documentRef.id]);

  return data;
};

/**
 * @param affiliateId
 * @param statuses - LOT_QUEUE_STATUSES
 * @returns {*[]}
 */
export const useLotQueueSnapshot = (affiliateId, statuses = []) =>
  useCollectionSnapshot(getLotQueueCollection(affiliateId).where('status', 'in', statuses));

/**
 * Looks up customer appointments in firebase
 */
export const useCustomerAppointmentsByAffiliate = (customerId, affiliateId) =>
  useCollectionSnapshot(
    pickupQueueCollection
      .where('affiliateId', '==', affiliateId)
      .where('customer.id', '==', Number(customerId))
      .where('status', '<', 4)
  );

/**
 * Looks up customer appointments in firebase
 */
export const useCustomerAppointments = customerId =>
  useCollectionSnapshot(
    pickupQueueCollection.where('customer.id', '==', Number(customerId)).where('status', '<', 4)
  );

/**
 * Load Out Methods
 */
export const useSingleLoadOut = id => useDocumentData(firestore.collection('loadout').doc(id));

export const useAllLoadOut = () => useCollectionData(firestore.collection('loadout'));

export const useRealtimeLoadOut = () => {
  const [state, setState] = useState([]);

  useEffect(() => {
    firestore.collection('loadout').onSnapshot(
      snapShot => {
        const t = snapShot.docs.map(doc => ({ id: doc.id, ...doc.data() }));

        setState([...t]);
      },
      err => console.log('in ze firebase error', { err })
    );
  }, []);

  return [state, setState];
};

const initialLoadOutState = {
  isPicking: false,
  items: [],
};

export const useLoadOutInsert = (docId, updates) => {
  const data = { ...initialLoadOutState, ...updates };

  const [request, set] = useAsyncFn(
    async () =>
      await firestore
        .collection('loadout')
        .doc(docId.toString())
        .set({
          ...data,
          queueStartTime: firestore.Timestamp.fromDate(new Date()),
        })
  );

  return [request, set];
};

export const insertLoadOut = (docId, updates) => {
  const data = { ...initialLoadOutState, ...updates };

  firestore
    .collection('loadout')
    .doc(docId.toString())
    .set({
      ...data,
      queueStartTime: Timestamp.fromDate(new Date()),
    });
};

export const useLoadOutUpdate = (docId, updates) =>
  useAsyncFn(
    async () =>
      await firestore
        .collection('loadout')
        .doc(docId.toString())
        .update(updates),
    [updates]
  );

export const updateLoadOut = (docId, updates) => {
  firestore
    .collection('loadout')
    .doc(docId.toString())
    .update(updates);
};

export const deleteLoadOut = docId => {
  firestore
    .collection('loadout')
    .doc(docId.toString())
    .delete();
};
