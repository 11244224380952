import React, { Component, useState, useContext } from 'react';
import PropTypes from 'prop-types';
import {
  WEEKDAY_TWO_DIGIT_DATE_TIME,
  PICKUP_QUEUE_STATUSES,
} from '../constants/pickup-queue-constants';
import {
  Header,
  List,
  Button,
  Modal,
  Input,
  Menu,
  Label,
  Dropdown,
  Form,
  Icon,
  Popup,
  Segment,
} from 'semantic-ui-react';
import {
  firebaseArrayRemove,
  firebaseIncrement,
  getLotQueueCollection,
  hrFirestore,
  incrementBy1,
} from '../shared-components/firebase/firestore';
import AuthUserContext from './auth-user-context';
import { NotificationContext } from './notification-context';
import { SUCCESS, FAILED } from '../shared-components/notifications';
import DataFilter from './data-filter';
import API from '../shared-components/api';
import NellisAuctionApi from '../shared-api-adapters/nellis-auction-api';
import FocusInput from './focus-input';
import { MainRefContext } from './layout/main';
import AwaitingCNotification from '../sounds/point-blank.mp3';
import LoadingOutNotification from '../sounds/to-the-point.mp3';
import { useAsyncFn, useAudio } from 'react-use';
import Toggle from '../shared-components/toggle';
import { LostItemFeed, LostItemFeedToggle } from './lost-item-feed';
import { Link } from 'react-router-dom';
import CargoApi from '../shared-api-adapters/cargo-api';
import AuthNetPayment from './auth-net-payment-form';
import { find } from 'lodash/fp';

import {
  sortByArrivalTime,
  sortByApproxPickupTime,
  sortByLastName,
  sortAwaitingConsolidation,
} from '../shared-components/helpers';

import PickUpQueueSearch from './pickup-queue-search';
import EndOfDayButton from './end-of-day-button';
import { itemHistoryBuild } from '../shared-components/helpers-context';

const EditableFirebaseField = ({ disabled, label, field, updateFirebase }) => {
  const [editMode, setEditMode] = useState(false);
  const [inputValue, setInputValue] = useState(field);

  const [{ loading }, update] = useAsyncFn(async value => {
    return updateFirebase(value);
  });

  return editMode ? (
    <Form>
      <FocusInput
        size={'mini'}
        placeholder={'Time to edit..'}
        onChange={(event, { value }) => setInputValue(value)}
        value={inputValue}
        action={
          <Button
            size={'mini'}
            icon={'save'}
            loading={loading}
            onClick={async () => {
              await update(inputValue);
              setEditMode(false);
            }}
          />
        }
      />
    </Form>
  ) : (
    <Button
      basic
      disabled={disabled}
      size={'tiny'}
      onClick={() => {
        setEditMode(true);
      }}
      icon
      labelPosition="right"
    >
      <Button.Content>
        <strong>{label}: </strong>
        {field}
      </Button.Content>
      <Icon name={'pencil'} />
    </Button>
  );
};

EditableFirebaseField.propTypes = {
  label: PropTypes.string,
  field: PropTypes.string,
  updateFirebase: PropTypes.func,
  disabled: PropTypes.bool,
};

class PickupQueue extends Component {
  constructor(props) {
    super(props);

    const {
      authUser: { userId, token },
    } = this.props;

    this.pickUpQueue = hrFirestore.collection('pickUpQueue');
    this.nellisAuctionApi = new NellisAuctionApi(token);
    this.cargoApi = new CargoApi(token);

    this.state = {
      loading: true,
      userId,
      awaitingConsolidation: [],
      inProgress: [],
      complete: [],
      loadingOut: [],
      pickedUp: [],
      scheduled: [],
      summaryModal: false,
      parkingSpaceModal: false,
      paymentModal: false,
      warningModal: {
        open: false,
        header: '',
        action: '',
      },
      warehousePosition: null,
      queueId: 0,
      activeMenuItem: 0,
      currentRecord: {},
      identity: '',
      parkingSpace: '',
      isEmergency: false,
      isEndOfDay: false,
    };
  }

  componentDidMount() {
    const {
      affiliate: { affiliateId },
      mainRef,
    } = this.props;

    hrFirestore
      .collection('emergencyWarning')
      .doc(affiliateId.toString())
      .onSnapshot(doc => {
        const { isEmergency } = doc.data();

        if (isEmergency) mainRef.current.classList.add('emergency-background');
        if (!isEmergency) mainRef.current.classList.remove('emergency-background');

        this.setState({ isEmergency });
      });

    this.fetchDataSet(-1, 'scheduled', sortByApproxPickupTime);
    this.fetchDataSet(0, 'awaitingConsolidation', sortAwaitingConsolidation);
    this.fetchDataSet(1, 'inProgress', sortByLastName);
    this.fetchDataSet(2, 'complete', sortByLastName);
    this.fetchDataSet(3, 'loadingOut', sortByArrivalTime);
    this.fetchDataSet(4, 'pickedUp', sortByLastName);
    this.fetchDataSet(-2, 'needsReview', sortByLastName);
    this.fetchUsers();
  }

  componentDidUpdate(prevProps, prevState) {
    const { awaitingConsolidation, loadingOut } = this.state;
    const { playLoadingOutSound, playAwaitingCSound } = this.props;

    if (
      prevState.awaitingConsolidation.length < awaitingConsolidation.length &&
      prevState.awaitingConsolidation.length !== 0
    ) {
      playAwaitingCSound();
    }
    if (prevState.loadingOut.length < loadingOut.length && prevState.loadingOut.length !== 0) {
      playLoadingOutSound();
    }
  }

  fetchUsers = async () =>
    this.setState({ users: await this.nellisAuctionApi.get(this.nellisAuctionApi.routes.users) });

  fetchDataSet = (status, stateName, sortFunction) => {
    const {
      affiliate: { affiliateId },
    } = this.props;

    this.pickUpQueue
      .where('status', '==', status)
      .where('affiliateId', '==', affiliateId)
      .onSnapshot(snapshot => {
        this.setState({
          [stateName]: sortFunction(snapshot.docs.map(doc => ({ queueId: doc.id, ...doc.data() }))),
        });
      });
  };

  updateDoc = (docId, data, message = 'Successfully updated record') => {
    const { addNotifications } = this.props;

    this.pickUpQueue
      .doc(docId)
      .update(data)
      .then(() => addNotifications([{ ...SUCCESS, content: message, size: 'small' }]))
      .catch(() =>
        addNotifications([
          { ...FAILED, content: 'An error occurred please try again', size: 'small' },
        ])
      );
  };

  remove = queueId => {
    const { addNotifications } = this.props;

    this.pickUpQueue
      .doc(queueId)
      .delete()
      .then(() =>
        addNotifications([{ ...SUCCESS, content: 'Order has been removed', size: 'small' }])
      )
      .catch(() =>
        addNotifications([
          { ...FAILED, content: 'An error occurred please try again', size: 'small' },
        ])
      );
  };

  setCurrentRecord = async queueId => {
    await this.pickUpQueue
      .doc(queueId)
      .get()
      .then(doc => {
        this.setState({ currentRecord: { queueId, ...doc.data(), ref: doc.ref } });
      });
  };

  convertDateFBtoJS = approxPickUpTime => {
    return approxPickUpTime.toDate();
  };

  setActiveMenuItem = menuId => this.setState({ activeMenuItem: menuId });

  statusIcon = status => {
    const { icon, color } = find({ status }, PICKUP_QUEUE_STATUSES);

    return <List.Icon name={icon} size="large" color={color} verticalAlign="middle" />;
  };

  toggleCheckbox = checkboxName =>
    this.setState(prevState => ({ [checkboxName]: !prevState[checkboxName] }));

  toggleModal = modalName => this.setState(prevState => ({ [modalName]: !prevState[modalName] }));

  getUser = userId => {
    const [{ firstName, lastName }] = this.state.users.filter(user => user.id === userId);

    return `${firstName} ${lastName}`;
  };

  updateParkingSpace = (event, inputData) => this.setState({ parkingSpace: inputData.value });

  updateWarehousePosition = (event, inputData) =>
    this.setState({ warehousePosition: inputData.value });

  closeWarning = () => {
    this.setState({ warningModal: { open: false, header: '', action: '' } });
  };

  highlightAppointments = ({ firstTimeBuyer, suspicious }) => {
    if (suspicious) return 'm-3 p-3 fraud-background round';
    if (firstTimeBuyer) return 'm-3 p-3 first-time-buyer-background round';
    return 'm-3 p-3';
  };

  markAsPickedUp = async () => {
    const {
      currentRecord: {
        affiliateId,
        appointmentId,
        arrivalTime,
        consolidatedBy,
        customer: { id: customerId } = {},
        invoiceIds,
        itemIds,
        parkingSpace,
        queueId,
        scannedItems = {},
        totalPickTime = 0,
        ref,
        checkedInBy = null,
        parkingAssignedBy = null,
        preparedInAdvance = null,
      },
    } = this.state;

    itemHistoryBuild(3)(this.props.authUser.userId)(Object.keys(scannedItems));

    getLotQueueCollection(affiliateId)
      .where('lot', '==', parkingSpace)
      .get()
      .then(async doc => {
        if (doc.empty) return;

        const [parkingSpotQueueItem] = doc.docs;
        const { appointments = [] } = parkingSpotQueueItem.data();

        if (appointments.length === 0) return;

        if (appointments.length === 1) {
          await parkingSpotQueueItem.ref.delete();
          return;
        }

        await parkingSpotQueueItem.ref.update({ appointments: firebaseArrayRemove(ref) });
      });

    // Format load out stats
    const itemsByTime = Object.entries(scannedItems).reduce(
      (loadOutStats, [inventoryNumber, { elapsedTime, offsite }]) => {
        if (offsite) return loadOutStats;
        return {
          ...loadOutStats,
          [inventoryNumber]: elapsedTime,
        };
      },
      {}
    );

    // Store load out stats
    const [{ id: loadOutId } = {}] = await this.cargoApi.post(this.cargoApi.routes.loadOut, {
      userId: consolidatedBy,
      customerId,
      locationId: affiliateId,
      elapsedTime: totalPickTime,
      items: itemsByTime,
      itemsString: JSON.stringify(itemsByTime),
    });

    const totalAppointmentTime =
      (new Date().getTime() - this.convertDateFBtoJS(arrivalTime).getTime()) / 1000;

    // Update appointment record with appointment stats
    await this.cargoApi.put(`${this.cargoApi.routes.appointments}/${appointmentId}`, {
      loadOutId,
      arrivalTime: this.convertDateFBtoJS(arrivalTime),
      totalAppointmentTime,
      active: 0,
      checkedInBy,
      preparedInAdvance,
      parkingAssignedBy,
    });

    await this.updateDoc(
      queueId,
      {
        status: 4,
        pickedUpTime: new Date(),
        totalAppointmentTime,
        loadOutId,
      },
      'Order marked as picked up'
    );

    // Update picker stats
    await hrFirestore
      .collection('pickUpQueueDaily')
      .doc(String(consolidatedBy))
      .set(
        {
          affiliateId,
          customers: incrementBy1,
          invoices: firebaseIncrement(invoiceIds.length),
          items: firebaseIncrement(itemIds.length),
          seconds: firebaseIncrement(totalPickTime),
        },
        { merge: true }
      );

    await hrFirestore
      .collection('pickUpQueueDaily')
      .doc('totalCounts')
      .set(
        {
          [String(affiliateId)]: {
            customers: incrementBy1,
            invoices: firebaseIncrement(invoiceIds.length),
            items: firebaseIncrement(itemIds.length),
          },
        },
        { merge: true }
      );
  };

  listItem = (item, modalView = false) => {
    const { isEndOfDay } = this.state;
    const {
      appointmentId,
      approxPickupTime,
      arrivalTime,
      consolidatedBy,
      customer,
      customer: { id, firstName, lastName, phoneNumber } = {},
      createdAt,
      fraud = {},
      isHere,
      itemIds = [],
      newItemsAdded,
      note,
      paid,
      parkingSpace,
      queueId,
      status = 0,
      userId,
      warehousePosition,
    } = item;
    const { suspicious = 0, reasons = [] } = fraud;

    const displayPickerStatuses = [1, 2, 3, 4];

    const isLargeOrder = itemIds.length >= 25;

    return (
      <List.Item key={queueId} className={this.highlightAppointments(fraud)}>
        {this.statusIcon(status)}
        <List.Content>
          <List.Header>
            <Popup
              disabled={modalView}
              hideOnScroll
              on={'click'}
              content={queueId}
              trigger={
                <Label size={'large'}>
                  {lastName}, {firstName} - Items: {itemIds.length}
                </Label>
              }
            />
            {!!suspicious && (
              <Popup
                trigger={
                  <Label size={'large'} className={'ml-2'}>
                    Requires Manager Approval
                  </Label>
                }
              >
                {reasons.length === 0 ? (
                  <Popup.Content>Amount threshold exceeded</Popup.Content>
                ) : (
                  <Popup.Content>
                    <List divided relaxed>
                      {reasons.map((r, k) => {
                        return (
                          <List.Item key={k}>
                            <List.Icon
                              name={'exclamation triangle'}
                              size={'small'}
                              verticalAlign={'middle'}
                            />
                            <List.Content>{r}</List.Content>
                          </List.Item>
                        );
                      })}
                    </List>
                  </Popup.Content>
                )}
              </Popup>
            )}
            {fraud.firstTimeBuyer === 1 && (
              <Label size={'large'} className={'ml-2'}>
                First Time Customer
              </Label>
            )}
            {newItemsAdded === 1 && (
              <Popup
                content={
                  <>
                    {isLargeOrder && <p>***LARGE ORDER***</p>}
                    <p>
                      This customer won new items after scheduling this appointment. These new items
                      were automatically added to this appointment.
                    </p>
                  </>
                }
                trigger={
                  <Label
                    size={'large'}
                    className={'ml-2'}
                    basic={isLargeOrder}
                    color={isLargeOrder ? 'red' : null}
                  >
                    Items Added After Scheduling
                  </Label>
                }
                mouseEnterDelay={500}
              />
            )}
            {approxPickupTime?.seconds - createdAt?.seconds < 1800 && (
              <Popup
                content={
                  <>
                    <p>
                      This customer created their appointment within 30 min of the scheduled
                      appointment time.
                    </p>
                    <p>
                      Notice: {((approxPickupTime?.seconds - createdAt?.seconds) / 60).toFixed(2)}{' '}
                      min
                    </p>
                  </>
                }
                trigger={
                  <Label size={'large'} className={'ml-2'} basic color={'blue'}>
                    Short Notice
                  </Label>
                }
                mouseEnterDelay={500}
              />
            )}
            {isEndOfDay && status === 2 && !modalView && (
              <EndOfDayButton
                items={itemIds}
                customer={customer}
                warehousePosition={warehousePosition}
                deleteAppointment={async () => {
                  this.remove(queueId);
                  await this.cargoApi.post(this.cargoApi.routes.cancellationNotice, {
                    phoneNumber,
                    checkInId: queueId,
                  });
                  await this.cargoApi.put(`${this.cargoApi.routes.appointments}/${appointmentId}`, {
                    active: 0,
                  });
                }}
              />
            )}
            {status === 3 && !suspicious && !modalView && (
              <Button
                content={'Mark as Picked Up'}
                size={'tiny'}
                className={'ml-4'}
                onClick={() => {
                  this.setCurrentRecord(queueId).then(() => {
                    this.setState({
                      warningModal: {
                        open: true,
                        header: 'Mark as picked up?',
                        action: 'PICKED_UP',
                      },
                    });
                  });
                }}
                color={'orange'}
              />
            )}
            {status === -1 && !modalView && (
              <Button
                content={'Move to Awaiting Consolidation'}
                size={'tiny'}
                className={'ml-4'}
                onClick={() => {
                  this.updateDoc(queueId, { status: 0 });
                }}
                color={'yellow'}
              />
            )}
            {status === 4 && !modalView && (
              <Button
                content={'View Summary'}
                size={'tiny'}
                className={'ml-4'}
                onClick={() => {
                  this.setCurrentRecord(queueId).then(() => this.toggleModal('summaryModal'));
                }}
                color={'green'}
              />
            )}
            {paid && isHere && status !== 4 && status !== 3 && (
              <Button icon={'thumbs up'} size={'tiny'} className={'ml-4'} color={'green'} />
            )}
            {!modalView && (
              <Button
                className={'mr-4'}
                color={paid ? 'green' : null}
                content={paid ? 'Paid' : 'Pay'}
                floated={'right'}
                icon={'dollar sign'}
                onClick={() =>
                  this.setCurrentRecord(queueId).then(() => this.toggleModal('paymentModal'))
                }
              />
            )}
          </List.Header>
          <List.Description className={'w-100'}>
            <List relaxed divided horizontal size={'small'}>
              {phoneNumber && (
                <List.Item>
                  <List.Content>PH: #{phoneNumber.slice(-4)}</List.Content>
                </List.Item>
              )}
              <List.Item>
                <List.Content>
                  {modalView ? (
                    `Customer #: ${id}`
                  ) : (
                    <Link to={`/customer/${id}`}>Customer #: {id}</Link>
                  )}
                </List.Content>
              </List.Item>
              {displayPickerStatuses.includes(status) && userId && (
                <List.Item>
                  <List.Content>Picker: {this.getUser(userId)}</List.Content>
                </List.Item>
              )}
              {displayPickerStatuses.includes(status) && consolidatedBy && (
                <List.Item>
                  <List.Content>Picker: {this.getUser(consolidatedBy)}</List.Content>
                </List.Item>
              )}
              {note && note.length > 0 && (
                <List.Item>
                  <List.Content>
                    <EditableFirebaseField
                      label={'Notes'}
                      field={note}
                      updateFirebase={value => this.updateDoc(queueId, { note: value })}
                    />
                  </List.Content>
                </List.Item>
              )}
              {warehousePosition && (
                <List.Item>
                  <List.Content>
                    <EditableFirebaseField
                      label={'Warehouse Position'}
                      field={warehousePosition}
                      updateFirebase={value =>
                        this.updateDoc(queueId, { warehousePosition: value })
                      }
                    />
                  </List.Content>
                </List.Item>
              )}
              {parkingSpace && parkingSpace.length > 0 && (
                <List.Item>
                  <List.Content>
                    <Label size={'big'} color={'black'}>
                      PS: <Label.Detail>{parkingSpace}</Label.Detail>
                    </Label>
                  </List.Content>
                </List.Item>
              )}
              {approxPickupTime && (
                <List.Item>
                  <List.Content>
                    <strong>
                      ETA:{' '}
                      {new Date(this.convertDateFBtoJS(approxPickupTime)).toLocaleDateString(
                        'en-Us',
                        WEEKDAY_TWO_DIGIT_DATE_TIME
                      )}
                    </strong>
                  </List.Content>
                </List.Item>
              )}
              {arrivalTime && (
                <List.Item>
                  <List.Content>
                    <strong>
                      Arrived: {new Date(arrivalTime.seconds * 1000).toLocaleTimeString()}
                    </strong>
                  </List.Content>
                </List.Item>
              )}
            </List>
          </List.Description>
        </List.Content>
      </List.Item>
    );
  };

  renderList = queue => {
    if (queue.length === 0)
      return <div className={'ml-5'}>No customers currently in this queue</div>;

    return (
      <List verticalAlign={'middle'} divided relaxed>
        {queue.map(item => this.listItem(item))}
      </List>
    );
  };

  render() {
    const {
      affiliate: { name, affiliateId },
      authUser: { id },
    } = this.props;
    const {
      currentRecord,
      activeMenuItem,
      parkingSpace,
      parkingSpaceModal,
      scheduled,
      awaitingConsolidation,
      inProgress,
      complete,
      loadingOut,
      pickedUp,
      needsReview,
      isEndOfDay,
      isEmergency,
      warningModal,
      summaryModal,
      paymentModal,
    } = this.state;
    const {
      authorizedPickup,
      customer: { firstName, lastName, id: customerId } = {},
      itemIds = [],
      queueId = '',
      warehousePosition,
      invoiceIds = [],
    } = currentRecord;

    return (
      <>
        <div>
          <DataFilter
            filterBy={'id'}
            multiDataSet={[
              scheduled,
              awaitingConsolidation,
              complete,
              inProgress,
              loadingOut,
              pickedUp,
              needsReview,
            ]}
            conditionalOverride={(queueItem, test) => {
              return (
                test.test(queueItem.customer.firstName) ||
                test.test(queueItem.customer.lastName) ||
                test.test(JSON.stringify(queueItem.invoiceIds)) ||
                test.test(queueItem.customer.phoneNumber) ||
                test.test(queueItem.customer.id)
              );
            }}
          >
            {({ filteredData, handleSearchValueChange, handleSearchValueClear, value }) => {
              const [sch = [], aC = [], c = [], inP = [], lO = [], pU = [], nR = []] = filteredData;

              return (
                <>
                  <div className={'flex jc-between'}>
                    <Header
                      className={'pt-3'}
                      size={'huge'}
                      content={`PICKUP QUEUE ${name ? `-- ${name}` : null} `}
                      icon={'group'}
                    />
                  </div>
                  <Segment className={'flex fd-row flex-wrap-reverse ai-center jc-even'}>
                    <Button
                      color={'red'}
                      icon
                      onClick={() => {
                        hrFirestore
                          .collection('emergencyWarning')
                          .doc(affiliateId.toString())
                          .set({ isEmergency: !isEmergency }, { merge: true });
                      }}
                    >
                      <Icon name={'exclamation triangle'} />
                    </Button>
                    <Button
                      icon
                      onClick={() => this.toggleCheckbox('isEndOfDay')}
                      color={isEndOfDay ? 'green' : null}
                    >
                      <Icon name={'clipboard list'} />
                    </Button>
                    <Toggle>
                      {({ active, toggle }) => {
                        return (
                          <Modal
                            size={'tiny'}
                            open={active}
                            onClose={toggle}
                            trigger={
                              <LostItemFeedToggle onClick={toggle} affiliateId={affiliateId} />
                            }
                          >
                            <Modal.Header>Lost Items</Modal.Header>
                            <Modal.Content>
                              <LostItemFeed
                                formatUser={userId => this.getUser(userId)}
                                affiliateId={affiliateId}
                              />
                            </Modal.Content>
                          </Modal>
                        );
                      }}
                    </Toggle>
                    <PickUpQueueSearch
                      userId={id}
                      changeTab={this.setActiveMenuItem}
                      updateSearch={handleSearchValueChange}
                    />
                    {/*{roleId && access.SUPERVISOR.includes(roleId) && (*/}
                    {/*  <Modal trigger={<Button icon={'wrench'} />} closeIcon centered={false}>*/}
                    {/*    <Modal.Header>*/}
                    {/*      Pickup Queue Edit <Icon name={'wrench'} />*/}
                    {/*    </Modal.Header>*/}
                    {/*    <Modal.Content>*/}
                    {/*      <PickupQueueEdit />*/}
                    {/*    </Modal.Content>*/}
                    {/*  </Modal>*/}
                    {/*)}*/}
                  </Segment>
                  <Menu tabular className={'flex flex-wr'}>
                    <Menu.Item
                      name="Scheduled"
                      active={activeMenuItem === -1}
                      onClick={() => this.setActiveMenuItem(-1)}
                    >
                      <Label className={'mr-2'} color={'grey'}>
                        {sch.length}
                      </Label>
                      Scheduled
                    </Menu.Item>
                    <Menu.Item
                      name="Needs Reviewed"
                      active={activeMenuItem === -2}
                      onClick={() => this.setActiveMenuItem(-2)}
                    >
                      <Label className={'mr-2'} color={'pink'}>
                        {nR.length}
                      </Label>
                      Needs Reviewed
                    </Menu.Item>
                    <Menu.Item
                      name="Awaiting Consolidation"
                      active={activeMenuItem === 0}
                      onClick={() => this.setActiveMenuItem(0)}
                    >
                      <Label className={'mr-2'} color={'red'}>
                        {aC.length}
                      </Label>
                      Awaiting Consolidation
                    </Menu.Item>
                    <Menu.Item
                      name="In Progress"
                      active={activeMenuItem === 1}
                      onClick={() => this.setActiveMenuItem(1)}
                    >
                      <Label className={'mr-2'} color={'blue'}>
                        {inP.length}
                      </Label>
                      In Progress
                    </Menu.Item>

                    <Menu.Item
                      name="Consolidated"
                      active={activeMenuItem === 2}
                      onClick={() => this.setActiveMenuItem(2)}
                    >
                      <Label className={'mr-2'} color={'yellow'}>
                        {c.length}
                      </Label>
                      Consolidated
                    </Menu.Item>
                    <Menu.Item
                      name="Loading Out"
                      active={activeMenuItem === 3}
                      onClick={() => this.setActiveMenuItem(3)}
                    >
                      <Label className={'mr-2'} color={'orange'}>
                        {lO.length}
                      </Label>
                      Loading Out
                    </Menu.Item>
                    <Menu.Item
                      name="Picked Up"
                      active={activeMenuItem === 4}
                      onClick={() => this.setActiveMenuItem(4)}
                    >
                      <Label className={'mr-2'} color={'green'}>
                        {pU.length}
                      </Label>
                      Picked Up
                    </Menu.Item>
                    <Menu.Menu position="right">
                      <Menu.Item>
                        <Input
                          transparent
                          icon={{
                            name: value ? 'close' : 'search',
                            link: true,
                            onClick: () => {
                              handleSearchValueClear();
                            },
                          }}
                          placeholder={'Name, Invoice, Phone'}
                          value={value}
                          onChange={handleSearchValueChange}
                        />
                      </Menu.Item>
                    </Menu.Menu>
                  </Menu>
                  {activeMenuItem === -1 && this.renderList(sch)}
                  {activeMenuItem === -2 && this.renderList(nR)}
                  {activeMenuItem === 0 && this.renderList(aC)}
                  {activeMenuItem === 2 && this.renderList(c)}
                  {activeMenuItem === 1 && this.renderList(inP)}
                  {activeMenuItem === 3 && this.renderList(lO)}
                  {activeMenuItem === 4 && this.renderList(pU)}
                </>
              );
            }}
          </DataFilter>
        </div>

        {/** PAYMENT MODAL */}
        <Modal open={paymentModal} size={'tiny'} onClose={() => this.toggleModal('paymentModal')}>
          <Modal.Header>Payment - {`${firstName} ${lastName}  ${customerId}`}</Modal.Header>
          <Modal.Content>
            <PickUpQueuePayment
              customerId={customerId}
              invoiceIds={invoiceIds}
              queueId={queueId}
              onPaymentComplete={async () => {
                await this.pickUpQueue.doc(queueId).update({ paid: true, isHere: true });
                this.toggleModal('paymentModal');
                this.toggleModal('parkingSpaceModal');
              }}
            />
          </Modal.Content>
        </Modal>

        {/** CONFIRMATION MODAL */}
        <Modal
          open={warningModal.open}
          centered={false}
          dimmer={'blurring'}
          size={'large'}
          onClose={this.closeWarning}
          closeIcon
        >
          <Modal.Header>{warningModal.header}</Modal.Header>
          <List>{this.listItem(currentRecord, true)}</List>
          <Modal.Actions>
            <Button
              content={'YES'}
              icon={'check'}
              size={'large'}
              labelPosition={'left'}
              positive
              onClick={async () => {
                this.closeWarning();
                if (warningModal.action === 'CHECK_IN') {
                  this.toggleModal('parkingSpaceModal');
                } else if (warningModal.action === 'PICKED_UP') {
                  await this.markAsPickedUp();
                } else if (warningModal.action === 'PAY') {
                  this.toggleModal('paymentModal');
                }
              }}
            />
            <Button
              content={'NO'}
              icon={'x'}
              size={'large'}
              labelPosition={'left'}
              negative
              onClick={this.closeWarning}
            />
          </Modal.Actions>
        </Modal>

        {/** CHECK IN MODAL */}
        <Modal
          open={parkingSpaceModal}
          className={'w-50'}
          onClose={() => {
            this.setState({ parkingSpace: '' });
            this.toggleModal('parkingSpaceModal');
          }}
          closeIcon
        >
          <Modal.Header>Check In Complete</Modal.Header>
          <Modal.Content>
            <Modal.Description>
              <Header>Please enter parking space:</Header>
              <Input type={'text'} onChange={this.updateParkingSpace} />
              <Button
                disabled={!parkingSpace}
                content={'Save and Exit'}
                color={'blue'}
                className={'ml-3'}
                onClick={() => {
                  this.pickUpQueue
                    .doc(queueId)
                    .get()
                    .then(doc => {
                      const currentStatus = doc.data().status;

                      this.updateDoc(
                        queueId,
                        {
                          isHere: true,
                          arrivalTime: new Date(),
                          status: currentStatus === 2 ? 3 : currentStatus,
                          parkingSpace,
                        },
                        currentStatus === 0
                          ? 'Customer moved to top of queue'
                          : 'Customer checked in'
                      );
                      this.setState({ parkingSpace: '' });
                      this.toggleModal('parkingSpaceModal');
                    });
                }}
              />
            </Modal.Description>
          </Modal.Content>
        </Modal>

        {/** SUMMARY MODAL */}
        <Modal
          open={summaryModal}
          size={'tiny'}
          closeIcon={true}
          onClose={() => this.toggleModal('summaryModal')}
        >
          <Modal.Header>Summary</Modal.Header>
          <Modal.Content>
            <Modal.Description>
              <div style={{ fontSize: 'x-large' }}>
                Customer: {firstName} {lastName}
              </div>
              {authorizedPickup && (
                <div style={{ fontSize: 'x-large' }}>Authorized Pickup: {authorizedPickup}</div>
              )}
              <div style={{ fontSize: 'x-large' }}>Number of Items: {itemIds.length}</div>
              <div style={{ fontSize: 'x-large' }}>Warehouse Position: {warehousePosition}</div>
            </Modal.Description>
          </Modal.Content>
        </Modal>
      </>
    );
  }
}

PickupQueue.propTypes = {
  affiliate: PropTypes.object.isRequired,
  authUser: PropTypes.object.isRequired,
  addNotifications: PropTypes.func.isRequired,
  mainRef: PropTypes.object.isRequired,
  playLoadingOutSound: PropTypes.func.isRequired,
  playAwaitingCSound: PropTypes.func.isRequired,
};

const PickUpQueueInitialization = props => {
  const mainRef = useContext(MainRefContext);
  const [queueAffiliateId, setQueueAffiliateId] = useState();
  const [awaitingCSoundAudio, , awaitingCSoundControls] = useAudio({
    src: AwaitingCNotification,
    autoPlay: false,
  });
  const [loadingOutSoundAudio, , loadingOutSoundControls] = useAudio({
    src: LoadingOutNotification,
    // src: NSync,
    autoPlay: false,
  });

  return (
    <>
      <div>{awaitingCSoundAudio}</div>
      <div>{loadingOutSoundAudio}</div>
      <AuthUserContext.Consumer>
        {({ authUser, ...restConsumer }) =>
          authUser ? (
            <NotificationContext.Consumer>
              {({ addNotifications }) => {
                return (
                  <API.Query queries={[{ route: 'locations' }]} api={new CargoApi()}>
                    {({ data: [locations] }) => {
                      if (!queueAffiliateId) {
                        return (
                          <Dropdown
                            placeholder={'Select location'}
                            fluid
                            selection
                            onChange={(event, { value }) => setQueueAffiliateId(value)}
                            options={locations
                              .filter(loc => loc.curbsideAvailable)
                              .map((loc, key) => ({
                                key,
                                text: loc.name,
                                value: loc.affiliateId,
                              }))}
                            value={queueAffiliateId}
                          />
                        );
                      }

                      const affiliate = locations.find(loc => loc.affiliateId === queueAffiliateId);

                      return (
                        <>
                          <PickupQueue
                            mainRef={mainRef}
                            affiliate={affiliate}
                            authUser={authUser}
                            {...restConsumer}
                            {...props}
                            addNotifications={addNotifications}
                            playAwaitingCSound={awaitingCSoundControls.play}
                            playLoadingOutSound={loadingOutSoundControls.play}
                          />
                        </>
                      );
                    }}
                  </API.Query>
                );
              }}
            </NotificationContext.Consumer>
          ) : null
        }
      </AuthUserContext.Consumer>
    </>
  );
};

export const PickUpQueuePayment = ({
  customerId,
  onPaymentComplete,
  invoiceIds,
  // queueId,
  buttonContent = 'Mark As Paid',
}) => {
  return (
    <AuthNetPayment
      customerId={customerId}
      invoiceIds={invoiceIds}
      buttonContent={buttonContent}
      updateFirebasePaidStatus={async () => {
        await onPaymentComplete();
      }}
      showButton={true}
    />
  );
};

PickUpQueuePayment.propTypes = {
  customerId: PropTypes.number,
  closeModal: PropTypes.func,
  onPaymentComplete: PropTypes.func,
  invoiceIds: PropTypes.array.isRequired,
  buttonContent: PropTypes.string,
  queueId: PropTypes.string.isRequired,
};

export default PickUpQueueInitialization;
