import React from 'react';
import { Button, Divider, Popup } from 'semantic-ui-react';
import { format } from 'date-fns';
import PropTypes from 'prop-types';
import Paginater from './paginater';
import { convertDateSqlUTCtoJS } from '../helpers/date-format-util';

export const CurbSideExistingAppointments = ({
  existingAppointments,
  existingItems,
  loading,
  cancelAppointment,
  editAppointment,
  editing,
  affiliates,
}) => {
  const isAppointmentLocked = pickupTime =>
    convertDateSqlUTCtoJS(pickupTime).getTime() - new Date().getTime() < 90 * 60000;

  if (loading) return null;

  return (
    existingAppointments.length > 0 && (
      <div>
        <h3>Existing appointments</h3>
        {existingAppointments.map(existingAppointment => {
          const { locationId, appointmentTime, itemIds, id } = existingAppointment;
          const locked = isAppointmentLocked(appointmentTime);
          const items = existingItems.filter(({ item_id: itemId }) =>
            itemIds.includes(itemId.toString())
          );

          const { name = '', affiliateId } = affiliates.find(({ id: ID }) => ID === locationId) || {};

          return (
            <div key={id} className={editing && editing.id === id ? 'selected-border' : null}>
              <div className={editing && editing.id === id ? 'pl-3 my-2 ml-2' : 'my-2'}>
                <Button.Group widths={2}>
                  {locked ? (
                    <Popup
                      on={'hover'}
                      content={
                        'This appointment cannot be changed because it is less than 90 minutes away.'
                      }
                      position={'top center'}
                      trigger={<Button icon={'lock'} />}
                    />
                  ) : (
                    <>
                      <Button
                        className={'w-25'}
                        color={editing && editing.id === id ? 'blue' : null}
                        icon={'cancel'}
                        onClick={() =>
                          cancelAppointment({
                            items,
                            appointmentTime,
                            id,
                          })
                        }
                      />
                      <Button
                        className={'w-25'}
                        color={editing && editing.id === id ? 'blue' : null}
                        icon={'edit'}
                        onClick={() => {
                          if (editing && editing.id === id) {
                            editAppointment(null);
                          } else {
                            editAppointment({ id, affiliateId, ...existingAppointment });
                          }
                        }}
                      />
                    </>
                  )}
                  <Popup
                    flowing
                    hideOnScroll
                    content={
                      <Paginater maxItemsPerPage={10} items={items}>
                        {({ renderPaginater, itemsOnThisPage }) => (
                          <div>
                            Items for pickup:
                            <ul>
                              {itemsOnThisPage.map(
                                ({ item_id: itemId, internal_id: internalId }) => (
                                  <li key={itemId}>{internalId.slice(4)}</li>
                                )
                              )}
                            </ul>
                            {renderPaginater()}
                          </div>
                        )}
                      </Paginater>
                    }
                    position={'top center'}
                    on={'click'}
                    trigger={
                      <Button
                        color={editing && editing.id === id ? 'blue' : null}
                        content={`${format(
                          convertDateSqlUTCtoJS(appointmentTime),
                          'MM/dd/yyyy hh:mm a'
                        )} - ${name}`}
                        icon={'info circle'}
                      />
                    }
                  />
                </Button.Group>
              </div>
            </div>
          );
        })}
        <Divider />
      </div>
    )
  );
};

CurbSideExistingAppointments.propTypes = {
  affiliates: PropTypes.array.isRequired,
  existingAppointments: PropTypes.array.isRequired,
  existingItems: PropTypes.array.isRequired,
  cancelAppointment: PropTypes.func.isRequired,
  editAppointment: PropTypes.func.isRequired,
  editing: PropTypes.object,
  loading: PropTypes.bool.isRequired,
};
