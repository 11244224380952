import React, { Component } from 'react';
import API from './api';
import { Form, Input, Modal, Placeholder, List, Message } from 'semantic-ui-react';
import PropTypes from 'prop-types';

class SearchModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      searchValue: '',
      submitted: false,
    };
  }

  handleInputChange = (event, { value }) => {
    this.setState({ searchValue: value, submitted: false });
  };

  handleSubmission = () => this.setState({ submitted: true });

  render() {
    const { submitted, searchValue } = this.state;
    const {
      route,
      by,
      api,
      placeholder,
      label,
      itemRenderer,
      forceClose = false,
      inputSize,
    } = this.props;

    return (
      <>
        <Form onSubmit={this.handleSubmission}>
          <Input
            size={inputSize}
            fluid
            label={label}
            placeholder={placeholder}
            name={'search'}
            onChange={this.handleInputChange}
          />
        </Form>
        <Modal
          open={submitted && !forceClose}
          onClose={() => this.setState({ submitted: false })}
          size={'mini'}
          centered={false}
        >
          <Modal.Header>Search Results</Modal.Header>
          <Modal.Content>
            <API.Query
              api={api}
              queries={[{ route, filter: { [by]: searchValue } }]}
              control={true}
            >
              {({ data: [results], loading }) => {
                if (loading)
                  return (
                    <Placeholder>
                      <Placeholder.Line />
                      <Placeholder.Line />
                      <Placeholder.Line />
                      <Placeholder.Line />
                      <Placeholder.Line />
                    </Placeholder>
                  );

                if (results.length === 0) return <Message content={'No results found!'} />;

                return <List selection items={results.map(itemRenderer(searchValue))} />;
              }}
            </API.Query>
          </Modal.Content>
        </Modal>
      </>
    );
  }
}

SearchModal.defaultProps = {
  size: 'medium',
};

SearchModal.propTypes = {
  route: PropTypes.string.isRequired,
  by: PropTypes.string.isRequired,
  api: PropTypes.object.isRequired,
  placeholder: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  itemRenderer: PropTypes.func.isRequired,
  forceClose: PropTypes.bool,
  inputSize: PropTypes.string.isRequired,
};

export default SearchModal;
