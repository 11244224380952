const itemPrice = price => invoiceId => item => ({
  route: 'itemPrice',
  method: 'post',
  data: {
    invoice_id: invoiceId,
    internal_id: item.internal_id,
    price,
  },
});

const relistFee = amount => item => ({
  route: 'relistFee',
  method: 'post',
  data: {
    amount,
    internal_id: item.internal_id,
  },
});

const itemStatus = statusId => item => ({
  route: 'itemStatus',
  method: 'post',
  data: {
    InternalID: item.internal_id,
    StatusIs: item.item_status.id,
    ItemStatusID: statusId,
  },
});

// this route refunds the payment
const refund = paymentMode => amount => item => ({
  route: 'refund',
  method: 'post',
  data: {
    invoice_id: item.id,
    amount,
    payment_mode: paymentMode,
  },
});

// this route creates a line item refund to balance the invoice
const refundItem = internalId => amount => invoiceId => description => ({
  route: 'refundItem',
  method: 'post',
  data: [
    {
      internal_id: internalId,
      invoice_id: invoiceId,
      amount: -amount,
      description: `Refund: ${description}`,
    },
  ],
});

// this route adds refund payment and line item refund. Specifically solves an issue
// with discounts remaining in a single penny
const invoiceRefund = paymentMode => internalId => amount => invoiceId => description => ({
  route: 'invoiceRefund',
  method: 'post',
  data: {
    internal_id: internalId,
    invoice_id: invoiceId,
    amount,
    description,
    payment_mode: paymentMode,
  },
});

const relistItem = internalId => ({
  route: 'relistItem',
  method: 'post',
  data: {
    InternalID: internalId,
    AuctionID: '900',
    ItemStatusID: '0',
    RelistingAmount: 0,
    ModifyInvoice: 'Do nothing',
  },
});

const relistItemWithFees = amount => internalId => ({
  route: 'relistItem',
  method: 'post',
  data: {
    InternalID: internalId,
    AuctionID: '900',
    ItemStatusID: '6',
    RelistingAmount: amount,
  },
});

const locateItem = itemId => locationId => ({
  route: 'locationItems',
  method: 'post',
  data: {
    location_id: locationId,
    item_id: itemId,
  },
});

const copyItem = auctionId => internalId => ({
  route: 'copyItem',
  method: 'post',
  data: {
    auction_id: auctionId, // target auction
    internal_id: internalId,
  },
});

export {
  refund,
  itemStatus,
  relistFee,
  itemPrice,
  refundItem,
  invoiceRefund,
  relistItem,
  locateItem,
  relistItemWithFees,
  copyItem,
};
