import { pipe, split, map, slice, filter } from 'lodash/fp';

const PICKUP_BLOCKS = [0, 15, 30, 45];

/**
 * @param hour {string} e.g. 10AM||4PM
 * @returns {number|*}
 */
const resolveHourTo24 = hour => {
  if (hour.includes('AM')) return Number(hour.trim().slice(0, -2));

  if (hour.includes('PM')) return Number(hour.trim().slice(0, -2)) + 12;

  return hour;
};

/**
 * Fills array incremental by one based on start and end values
 *
 * @param start {number}
 * @param end {number}
 * @returns {[]}
 */
const incrementalFill = ([start, end]) => {
  const values = [];

  for (let index = start; index <= end; index++) values.push(index);
  return values;
};

/**
 * @param date
 * @returns {function(Date[]=): FlatArray<Date[][], 1>[]}
 */
const createPickupTimes = date =>
  /**
   * @param hoursOfOperation {number[]}
   * @returns {*}
   */
  (hoursOfOperation = []) => {
    return hoursOfOperation
      .map(hour => {
        const date1 = new Date(new Date(new Date().setDate(date)).setHours(hour));

        return PICKUP_BLOCKS.map(minutes => {
          return new Date(date1.setMinutes(minutes));
        });
      })
      .flat();
  };

/**
 * @param date
 * @returns {Date|number}
 */
const resolveFilterDateStart = date =>
  date === new Date().getDate()
    ? new Date(new Date().setDate(date))
    : new Date(new Date(new Date().setDate(date))).setHours(0, 0, 0, 0);

/**
 * @param byDate
 * @returns {function(*): boolean}
 */
const timeHasPast = byDate =>
  /**
   * @param date
   * @returns {boolean}
   */
  date => {
    return byDate.valueOf() < date.valueOf();
  };

/**
 * @param selectedDate
 */
export const buildPickupTimes = selectedDate =>
  pipe(
    split('-'),
    map(resolveHourTo24),
    incrementalFill,
    slice(0, -1), // don't build times for after close
    createPickupTimes(selectedDate),
    filter(timeHasPast(resolveFilterDateStart(selectedDate)))
  );
