import React, { Component } from 'react';
import HelpMessage from './help-message';
import PropTypes from 'prop-types';

class Home extends Component {
  render() {
    const {
      location: { state },
    } = this.props;

    return (
      <div>
        {state && state.message && state.message.error && (
          <HelpMessage {...state.message} className={'mb-2'} />
        )}
        Welcome to Nellis Auction Headquarters!
      </div>
    );
  }
}

Home.propTypes = {
  location: PropTypes.object.isRequired,
};

export default Home;
