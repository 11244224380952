import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { Button, Checkbox, Divider, Header, Icon, Label } from 'semantic-ui-react';
import API from './api';
import { canRefund } from '../helpers/customer-utils';
import config from '../config';
import AuctionMethodApi from '../shared-api-adapters/auction-method-api';
import * as amm from '../constants/auction-method-mappings';
import { isEmpty } from 'lodash/fp';
import Toggle from '../shared-components/toggle';

class BatchRelist extends Component {
  state = {
    refundableItems: [],
    ids: [],
    results: [],
    storeCreditResults: [],
    overrides: {},
    submitted: false,
    refundToStoreCredit: true,
    submitToCargo: false,
    completedLots: [],
  };

  handleStatusOverride = (item, status) => {
    if (status.id === 8) {
      this.setState(prevState => ({
        overrides: { ...prevState.overrides, [item.id]: amm.MISDESCRIBED_REFUND },
      }));
    }
    if (status.id === 7) {
      this.setState(prevState => ({
        overrides: { ...prevState.overrides, [item.id]: amm.COURTESY_REFUND },
      }));
    }
  };

  resolveStatusType = paid => {
    const { isActionItem } = this.props;

    let type = amm.MISDESCRIBED_REFUND;

    if (isActionItem) {
      type = amm.COURTESY_RELIST;
      if (paid === '1') {
        type = amm.COURTESY_REFUND;
      }
    }

    return type;
  };

  // because during the ui phase I map over invoices I have to store
  // item ids instead of keys to properly map results
  resolveFetchingAttributes = item => {
    const { ids, results, storeCreditResults } = this.state;

    if (ids.includes(item.id) && results.length === 0) {
      return { icon: 'circle notch', labelColor: null, receivedCredit: false, success: null };
    }
    if (results[ids.indexOf(item.id)]) {
      return {
        icon: 'checkmark',
        labelColor: 'green',
        receivedCredit: !!storeCreditResults[ids.indexOf(item.id)],
        success: true,
      };
    }
    if (!results[ids.indexOf(item.id)]) {
      return { icon: 'cancel', labelColor: 'red', receivedCredit: false, success: false };
    }

    return {};
  };

  componentDidMount() {
    const { invoices } = this.props;

    const refundableItems = invoices
      .map(inv => inv.line_items)
      .flat()
      .filter(canRefund);

    const ids = refundableItems.map(item => item.id);

    this.setState({ refundableItems, ids });
  }

  handleCargoSubmit = (event, { inventoryNumber }) =>
    this.setState(prevState => ({ completedLots: [inventoryNumber, ...prevState.completedLots] }));

  /**
   * @TODO should probs map the status onto the items at mount time to clean up some loops
   * @returns {*}
   */
  render() {
    const {
      state: { results, submitted, overrides, refundToStoreCredit, refundableItems, completedLots },
      props: { invoices, isActionItem, customer, user },
      resolveStatusType,
      resolveFetchingAttributes,
      handleStatusOverride,
      handleCargoSubmit,
    } = this;

    let refundTotal = 0;
    let feeTotal = 0;

    const allItemsTotal = refundableItems.reduce((acc, item) => acc + Number(item.item_total), 0);

    return (
      <Toggle>
        {({ active, toggle }) => (
          <>
            {!isEmpty(results) && !isActionItem && (
              <Button
                onClick={toggle}
                content={active ? 'Return to Relist Results' : 'Submit Items To Cargo'}
              />
            )}
            {active &&
              invoices
                .map(inv => inv.line_items)
                .flat()
                .filter(item => {
                  return (
                    canRefund(item) &&
                    resolveFetchingAttributes(item).success &&
                    !completedLots.includes(item.internal_id)
                  );
                })
                .map((item, key) => (
                  <Button
                    key={key}
                    onClick={handleCargoSubmit}
                    inventoryNumber={item.internal_id}
                    content={item.internal_id}
                  />
                ))}

            {!active &&
              invoices.map(inv => (
                <Fragment key={inv.id}>
                  <Header as={'h4'}>Invoice: {inv.id}</Header>
                  {inv.line_items.filter(canRefund).map(item => {
                    const status = Object.keys(overrides).includes(item.id.toString())
                      ? overrides[item.id]
                      : resolveStatusType(inv.paid);
                    const attributes = resolveFetchingAttributes(item);
                    const refund =
                      inv.paid === '1'
                        ? Number(item.item_total) - Number(item.item_total * status.feePercent)
                        : 0;
                    const relistFee = Number(item.item_total * status.feePercent);

                    refundTotal += refund;
                    feeTotal += relistFee;

                    return (
                      <div key={item.id} className={'flex fd-row mb-1 jc-between'}>
                        <div>
                          <Label title={'Lot Number'}>{item.lot_number}</Label>
                          <Label title={'Item Total'}>
                            <Icon name={'dollar'} />
                            {Number(item.item_total).toFixed(2)}
                          </Label>
                          {refund ? (
                            <Label title={'Refund Amount'} color={'green'}>
                              <Icon name={'dollar'} />
                              {refund.toFixed(2)}
                            </Label>
                          ) : (
                            <Label className={'border-dashed bg-none'}>No Refund</Label>
                          )}
                          <Label color={'red'} title={'Relist Fee'}>
                            <Icon name={'dollar'} />
                            {relistFee.toFixed(2)}
                          </Label>
                        </div>
                        {submitted ? (
                          <>
                            {attributes.receivedCredit && (
                              <Label title={'Added Store Credit'}>
                                <Icon name={'dollar'} />
                              </Label>
                            )}
                            <Label color={attributes.labelColor}>
                              {status.description}
                              <Label.Detail>
                                <Icon
                                  loading={attributes.icon === 'circle notch'}
                                  name={attributes.icon}
                                  // className={'lh-1-15'}
                                />
                              </Label.Detail>
                            </Label>
                          </>
                        ) : isActionItem ? (
                          <Label className={'border-dashed bg-none'}>{status.description}</Label>
                        ) : (
                          <Label
                            as={Button}
                            className={'border-dashed bg-none'}
                            onClick={() => handleStatusOverride(item, status)}
                          >
                            {status.description}
                          </Label>
                        )}
                      </div>
                    );
                  })}
                </Fragment>
              ))}

            <Divider />
            <div className={'flex fd-row jc-start mb-3'}>
              <Label size={'huge'} title={'All Items Total'}>
                <Icon name={'dollar'} />
                {allItemsTotal.toFixed(2)}
              </Label>
              <Label size={'huge'} color={'green'} title={'All Items Refund'}>
                <Icon name={'dollar'} />
                {refundTotal.toFixed(2)}
              </Label>
              <Label size={'huge'} color={'red'} title={'All Items Fee Total'}>
                <Icon name={'dollar'} />
                {feeTotal.toFixed(2)}
              </Label>
            </div>
            <API.Crud route={'storeCredit'}>
              {({ post: addToStoreCredit }) => {
                return (
                  <API.Crud route={'relistItem'} api={new AuctionMethodApi()}>
                    {({ post }) => {
                      return (
                        <>
                          <Button
                            className={'mb-1'}
                            color={refundToStoreCredit ? 'blue' : null}
                            size={'large'}
                            fluid
                            content={
                              refundToStoreCredit
                                ? `Refund ${refundTotal.toFixed(2)} to Store Credit and Re-List`
                                : `Refund ${refundTotal.toFixed(2)} to Original Payment and Re-List`
                            }
                            onClick={async () => {
                              this.setState({ submitted: true });

                              const relistingResults = await Promise.all(
                                refundableItems.map(item => {
                                  const invoice = invoices.find(inv => inv.id === item.invoice_id);
                                  const status = Object.keys(overrides).includes(item.id.toString())
                                    ? overrides[item.id]
                                    : resolveStatusType(invoice.paid);
                                  const paymentMode =
                                    status.id === 6
                                      ? {}
                                      : {
                                          PaymentMode: refundToStoreCredit
                                            ? 'Store Credit'
                                            : invoice.payments[0].payment_type,
                                        };

                                  return post({
                                    InternalID: item.internal_id,
                                    AuctionID: config.auctionMethodApi.auctionId,
                                    RelistingAmount: item.item_total * status.feePercent,
                                    ItemStatusID: status.id,
                                    ...paymentMode,
                                  });
                                })
                              );

                              if (refundToStoreCredit) {
                                const scResults = await Promise.all(
                                  refundableItems.map((item, key) => {
                                    const invoice = invoices.find(
                                      inv => inv.id === item.invoice_id
                                    );
                                    const status = Object.keys(overrides).includes(
                                      item.id.toString()
                                    )
                                      ? overrides[item.id]
                                      : resolveStatusType(invoice.paid);

                                    if (relistingResults[key] && status.id !== 6) {
                                      return addToStoreCredit({
                                        customerId: customer.id,
                                        userId: user.id,
                                        amount: refundableItems[key].item_total,
                                      });
                                    }
                                    return false;
                                  })
                                );

                                this.setState({ storeCreditResults: scResults });
                              }

                              // should rename results => relisitngResults
                              this.setState({ results: relistingResults });
                            }}
                          />
                          <Checkbox
                            toggle
                            checked={refundToStoreCredit}
                            onClick={() =>
                              this.setState(prevState => ({
                                refundToStoreCredit: !prevState.refundToStoreCredit,
                              }))
                            }
                          />
                        </>
                      );
                    }}
                  </API.Crud>
                );
              }}
            </API.Crud>
          </>
        )}
      </Toggle>
    );
  }
}

BatchRelist.propTypes = {
  invoices: PropTypes.array.isRequired,
  isActionItem: PropTypes.bool.isRequired,
  customer: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
};

export default BatchRelist;
