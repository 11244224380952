import React, { Component } from 'react';
import { Header, Label } from 'semantic-ui-react';
import PropTypes from 'prop-types';
import config from '../config';

class QuickReturn extends Component {
  state = {
    value: '',
  };

  inputRef = React.createRef();

  handleChange = (event, inventoryNumber) => {
    // @TODO add automated refundski here
    if (event.target.value.length === 10) {
      window.open(
        `${config.cargo.url}/item/${
          event.target.value
        }/form/main?prevInventoryNumber=${inventoryNumber}`
      );
    }

    this.setState({ value: event.target.value });
  };

  componentDidMount() {
    this.inputRef.current.focus();
  }

  render() {
    const {
      state: { value },
      props: { inventoryNumber },
      handleChange,
    } = this;

    return (
      <>
        <Header className={'m-2'}>Re-list and Refund</Header>
        <div className={'m-2'}>Previous Inventory Number:</div>
        <Label size={'large'} className={'m-2'}>
          {inventoryNumber}
        </Label>
        <div className={`ui left icon input m-2`}>
          <input
            type={'text'}
            placeholder={'New inventory number'}
            ref={this.inputRef}
            onChange={event => handleChange(event, inventoryNumber)}
            value={value}
          />
          <i aria-hidden={'true'} className={'barcode icon'} />
        </div>
        </>
    );
  }
}

QuickReturn.propTypes = {
  inventoryNumber: PropTypes.string.isRequired,
};

export default QuickReturn;
