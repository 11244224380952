import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Icon } from 'semantic-ui-react';

class DashContainer extends Component {
  materialColors = {
    default: 'bg-material-dark',
    red: 'bg-material-red',
    blue: 'bg-material-blue',
    purple: 'bg-material-purple',
    green: 'bg-material-green',
    orange: 'bg-material-orange',
  };

  render() {
    const {
      props: { title, children, color, iconHandler },
      materialColors,
    } = this;

    return (
      <div className={'bg-white m-3'}>
        <div className={`${materialColors[color]} c-white p-3 fs-x-large`}>
          {title}
          {iconHandler && (
            <Icon
              className={'float-right'}
              onClick={iconHandler}
              name={'external alternate'}
              link={true}
            />
          )}
        </div>
        <div className={'p-3 border-light'}>{children}</div>
      </div>
    );
  }
}

DashContainer.defaultProps = {
  title: 'Nellis Auction',
  color: 'default',
};

DashContainer.propTypes = {
  children: PropTypes.any.isRequired,
  title: PropTypes.string.isRequired,
  color: PropTypes.string,
  iconHandler: PropTypes.func,
};

export default DashContainer;
