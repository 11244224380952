import React, { Component } from 'react';
import { Grid } from 'semantic-ui-react';
import Refunds from './refunds';
import CustomerSearch from './customer-search';
import PropTypes from 'prop-types';
import DashContainer from './layout/dash-container';
import ClerkDropdown from './clerk-dropdown';
import withAuthorization from './with-authorization';
import PettyCash from './petty-cash';
import { NotificationContext } from './notification-context';
import StoreCreditReport from './store-credit-report';

class Admin extends Component {
  render() {
    return (
      <>
        <Grid columns={2} stackable>
          <Grid.Column width={7}>
            <DashContainer title={'Customers'} color={'blue'}>
              <CustomerSearch />
            </DashContainer>
            <DashContainer title={'Re-listing'} color={'red'}>
              <Refunds />
            </DashContainer>
          </Grid.Column>
          <Grid.Column width={9}>
            <DashContainer title={'Store Credit'} color={'green'}>
              <StoreCreditReport />
            </DashContainer>
            <NotificationContext.Consumer>
              {({ addNotifications }) => (
                <DashContainer title={'Petty Cash'} color={'purple'}>
                  <PettyCash addNotifications={addNotifications} />
                </DashContainer>
              )}
            </NotificationContext.Consumer>
            <DashContainer title={'Daily Batch View'} color={'default'}>
              <div className={'flex fd-column '}>
                <ClerkDropdown />
              </div>
            </DashContainer>
          </Grid.Column>
        </Grid>
      </>
    );
  }
}

Admin.propTypes = {
  authUser: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  location: PropTypes.object,
};

export default withAuthorization(Admin);
