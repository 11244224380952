import React, { Component } from 'react';
import Search from './search';
import { startCase } from 'lodash/fp';
import PropTypes from 'prop-types';
import AuctionMethodApi from '../shared-api-adapters/auction-method-api';
import SearchModal from './search-modal';
import { withRouter } from 'react-router-dom';

class CustomerSearch extends Component {
  renderInvoiceItems = searchValue => item => ({
    key: item.id,
    header: `${startCase(item.firstname)} ${startCase(item.lastname)}`,
    description: item.email,
    onClick: () =>
      this.props.history.push(
        `/customer/${item.id}${this.props.isFilter ? `?search=${searchValue}` : ''}`
      ),
  });

  render() {
    const { options, initialOptionValue, label } = this.props;

    return (
      <Search
        itemRenderer={this.renderInvoiceItems}
        route={'customers'}
        initialOptionValue={initialOptionValue}
        options={options}
        label={label}
      />
    );
  }
}

CustomerSearch.defaultProps = {
  label: '',
  isFilter: false,
};

CustomerSearch.propTypes = {
  history: PropTypes.object.isRequired,
  options: PropTypes.array.isRequired,
  initialOptionValue: PropTypes.string.isRequired,
  label: PropTypes.string,
  isFilter: PropTypes.bool.isRequired,
};

class ModalCustomerSearch extends Component {
  render() {
    const {
      history: { push },
    } = this.props;

    return [
      { by: 'name', label: 'Name' },
      { by: 'phoneNumber', label: 'Phone Number' },
      { by: 'id', label: 'Customer Id' },
      { by: 'invoice_id', label: 'Invoice Id', isFilter: true },
      { by: 'lot_number', label: 'Lot Number', isFilter: true },
    ].map(({ by, label, isFilter }, key) => (
      <div key={key} className={'mb-1'}>
        <SearchModal
          inputSize={'small'}
          api={new AuctionMethodApi()}
          route={'customers'}
          label={label}
          by={by}
          itemRenderer={searchValue => item => ({
            key: item.id,
            header: `${startCase(item.firstname)} ${startCase(item.lastname)}`,
            description: item.email,
            onClick: () => push(`/customer/${item.id}${isFilter ? `?search=${searchValue}` : ''}`),
          })}
          placeholder={label}
        />
      </div>
    ));
  }
}

export default withRouter(ModalCustomerSearch);
