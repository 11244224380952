import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { auth } from '../firebase';
import * as routes from '../constants/routes';
import PropTypes from 'prop-types';
import NellisAuctionApi from '../shared-api-adapters/nellis-auction-api';
import { Button, Form } from 'semantic-ui-react';

const Registration = ({ history }) => (
  <div className={'flex fd-column ai-center'}>
    <h3 className={'ff-jura'}>Complete Registration</h3>
    <SignUpForm history={history} />
  </div>
);

const INITIAL_STATE = {
  email: '',
  passwordOne: '',
  passwordTwo: '',
  error: null,
};

const byPropKey = (propertyName, value) => () => ({
  [propertyName]: value,
});

class SignUpForm extends Component {
  constructor(props) {
    super(props);
    this.api = new NellisAuctionApi();
    this.state = { ...INITIAL_STATE };
  }

  withinPastDay = createdAt =>
    (new Date().getTime() - new Date(createdAt).getTime()) / 3600000 < 24;

  userExists = user => !!user;

  onSubmit = event => {
    const { email, passwordOne } = this.state;

    const { history } = this.props;

    this.api
      .get(`${this.api.routes.userExists}/${email}`)
      .then(([ourUser]) => {
        if (!this.userExists(ourUser) || !this.withinPastDay(ourUser.createdAt)) {
          throw new Error(
            'The provided email is not eligible for registration. Please contact your Administrator'
          );
        }

        auth
          .doCreateUserWithEmailAndPassword(email, passwordOne)
          .then(() => {
            auth.getIdToken().then(token => {
              this.api.setToken(token);
              this.api
                .put(`${this.api.routes.updateStatus}/${email}`, { statusTypeId: 1 })
                .then(() => {
                  this.setState({ ...INITIAL_STATE });
                  history.push(routes.HOME);
                })
                .catch(error => {
                  this.setState(byPropKey('error', error));
                });
            });
          })
          .catch(error => {
            this.setState(byPropKey('error', error));
          });
      })
      .catch(error => {
        this.setState(byPropKey('error', error));
      });

    event.preventDefault();
  };

  render() {
    const { email, passwordOne, passwordTwo, error } = this.state;

    const isInvalid = passwordOne !== passwordTwo || passwordOne === '' || email === '';

    return (
      <Form onSubmit={this.onSubmit} className={'w-60'}>
        <Form.Input
          value={email}
          onChange={event => this.setState(byPropKey('email', event.target.value))}
          type="text"
          placeholder="Email Address"
        />
        <Form.Input
          value={passwordOne}
          onChange={event => this.setState(byPropKey('passwordOne', event.target.value))}
          type="password"
          placeholder="Password"
        />
        <Form.Input
          value={passwordTwo}
          onChange={event => this.setState(byPropKey('passwordTwo', event.target.value))}
          type="password"
          placeholder="Confirm Password"
        />
        <Button type="submit" disabled={isInvalid} style={{ float: 'right' }}>
          Finish
        </Button>

        {error && <p style={{ color: 'red' }}>{error.message}</p>}
      </Form>
    );
  }
}

Registration.propTypes = {
  history: PropTypes.object,
};

SignUpForm.propTypes = {
  history: PropTypes.object,
};

export default withRouter(Registration);

export { SignUpForm };
