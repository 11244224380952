import { isEmpty } from 'lodash/fp';
import moment from 'moment';

const filterByLocation = (selectedLocations = []) => (possibleLocations = []) => (
  invoices = []
) => {
  if (isEmpty(selectedLocations) || isEmpty(possibleLocations) || isEmpty(invoices)) {
    return invoices;
  }

  const allowedAffiliateIds = possibleLocations
    .filter(loca => selectedLocations.includes(loca.name))
    .map(l => l.affiliateId);

  return invoices.filter(inv => allowedAffiliateIds.includes(Number(inv.affiliate_id)));
};

const sumSelectedInvoices = (indexes, invoices) =>
  indexes
    .reduce((acc, index) => {
      return invoices[index].paid === '0' ? acc + invoices[index].balance : acc;
    }, 0)
    .toFixed(2);

const sumInvoices = invoices => invoices.reduce((acc, invoice) => acc + invoice.balance, 0);

// if we are past the 7 day grace period they are no longer allowed to be issued a refund
// If the item status is 'inventory item' || 'picked up'
const canRefund = item =>
  item.picked_up_date
    ? moment().diff(moment(new Date(item.picked_up_date.slice(0, -3))), 'days') < 8 &&
      [0, 1, 3].includes(item.item_status.id)
    : [0, 1, 3].includes(item.item_status.id);

export { sumSelectedInvoices, filterByLocation, sumInvoices, canRefund };
