import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Dropdown, Header, Icon, Input, Table } from 'semantic-ui-react';
import { deleteField, getLotStructureDoc } from '../shared-components/firebase/firestore';
import { useMount } from 'react-use';
import { isEmpty } from 'lodash/fp';
import AuthUserContext from './auth-user-context';

export const semanticColors = ['red', 'orange', 'yellow', 'green', 'blue', 'purple'];

const LotDetailsSettings = ({ affiliateId }) => {
  const [lotUnits, setLotUnit] = useState({});

  const lotStructureDoc = getLotStructureDoc(affiliateId);

  useMount(() => {
    lotStructureDoc.onSnapshot(snapshot => {
      const data = snapshot.data();

      if (isEmpty(data)) {
        lotStructureDoc.set(
          { red: { color: 'red', numOfSpots: 10, position: 1 } },
          { merge: true }
        );
      }

      setLotUnit(prevState => ({ ...prevState, ...data }));
    });
  });

  const sortedByTabPosition = Object.values(lotUnits).sort((a, b) => {
    return a.position - b.position;
  });

  return (
    <>
      <Header>Parking Lot Settings</Header>
      <Table unstackable>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell width={4}>Color</Table.HeaderCell>
            <Table.HeaderCell width={4}>Tab Position</Table.HeaderCell>
            <Table.HeaderCell width={4}>Count</Table.HeaderCell>
            <Table.HeaderCell width={2}>Remove</Table.HeaderCell>
          </Table.Row>
        </Table.Header>

        <Table.Body>
          {sortedByTabPosition.map((obj, key) => {
            const { numOfSpots, position, color } = obj;

            return (
              <Table.Row key={key}>
                <Table.Cell>
                  <Dropdown
                    value={color}
                    placeholder={'Select Color'}
                    onChange={(event, { value }) => {
                      lotStructureDoc.set(
                        { [value]: { color: value, numOfSpots, position } },
                        { merge: true }
                      );
                    }}
                    fluid
                    search
                    selection
                    options={semanticColors.map((color, key) => ({
                      key,
                      text: color.toUpperCase(),
                      value: color,
                    }))}
                  />
                </Table.Cell>
                <Table.Cell>
                  <Dropdown
                    type={'number'}
                    fluid
                    search
                    selection
                    placeholder={'Lot Detail Tab Position'}
                    value={position}
                    options={sortedByTabPosition.map((color, key) => ({
                      key,
                      text: key + 1,
                      value: key + 1,
                    }))}
                    onChange={(event, { value }) =>
                      lotStructureDoc.set(
                        { [color]: { color, numOfSpots, position: Number(value) } },
                        { merge: true }
                      )
                    }
                  />
                </Table.Cell>
                <Table.Cell>
                  <Input
                    type={'number'}
                    fluid
                    placeholder={'Number of spots'}
                    value={numOfSpots}
                    min={0}
                    onChange={(event, { value }) =>
                      lotStructureDoc.set(
                        { [color]: { color, numOfSpots: Number(value), position } },
                        { merge: true }
                      )
                    }
                  />
                </Table.Cell>
                <Table.Cell textAlign={'center'}>
                  <Button
                    color={'red'}
                    icon
                    onClick={async () => {
                      await lotStructureDoc.update({ [color]: deleteField() });
                      // eslint-disable-next-line no-unused-vars
                      const { [color]: deletion, ...rest } = lotUnits;

                      setLotUnit({ ...rest });
                    }}
                  >
                    <Icon name={'close'} />
                  </Button>
                </Table.Cell>
              </Table.Row>
            );
          })}
        </Table.Body>
      </Table>
    </>
  );
};

LotDetailsSettings.propTypes = {
  affiliateId: PropTypes.number.isRequired,
};

const WithAuthUser = () => {
  const { authUser } = useContext(AuthUserContext);

  if (isEmpty(authUser)) return null;

  return <LotDetailsSettings affiliateId={authUser.location.affiliateId} />;
};

export default WithAuthUser;
