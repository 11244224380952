import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';
import AuthUserContext from './auth-user-context';
import { useAsyncFn } from 'react-use';
import AuthorizeNetApi from '../shared-api-adapters/authorize-net-api';
import {
  Accordion,
  Button,
  Divider,
  Dropdown,
  Form,
  Icon,
  Label,
  Message,
  Placeholder,
  Statistic,
  Table,
} from 'semantic-ui-react';
import PropTypes from 'prop-types';
import { useMultiToggle } from '../shared-components/hooks';
import { isEmpty } from 'lodash/fp';

const useInvoicePayment = () => {
  const { auctionMethodApi } = useContext(AuthUserContext);

  return useAsyncFn(
    data => {
      if (!auctionMethodApi) return false;

      return auctionMethodApi.post(auctionMethodApi.routes.invoicePayment, data);
    },
    [auctionMethodApi]
  );
};

const useInvoiceFetchById = (api, invoiceIds) => {
  return useAsyncFn(async () => {
    return api.get(`${api.routes.invoice}?${invoiceIds.map(id => `invoice_id[]=${id}`).join('&')}`);
  }, [api, invoiceIds]);
};

// const useAuthNetCreditCardTransaction = () => {
//   const authorizeNetApi = new AuthorizeNetApi();
//
//   return useAsyncFn(async (amount, track1, userId, customerId) => {
//     return authorizeNetApi.post(
//       '',
//       authorizeNetApi.setTransactionRequestData(amount, track1, userId, customerId)
//     );
//   });
// };

const getPayments = (invoices, paymentAmount) => {
  return invoices.reduce(
    (acc, inv) => {
      if (acc.amountLeftToPay === 0) return acc;

      if (!inv.balance) return acc;

      if (acc.amountLeftToPay >= inv.balance) {
        return {
          amountLeftToPay: acc.amountLeftToPay - inv.balance,
          payments: [...acc.payments, { id: inv.id, amount: inv.balance }],
        };
      }

      if (acc.amountLeftToPay < inv.balance) {
        return {
          amountLeftToPay: 0,
          payments: [...acc.payments, { id: inv.id, amount: acc.amountLeftToPay }],
        };
      }

      return acc;
    },
    { amountLeftToPay: Number(paymentAmount), payments: [] }
  );
};

const useCardOnFile = (api, id) =>
  // eslint-disable-next-line camelcase
  useAsyncFn(async () => api.get(api.routes.cards, null, { user_id: id }), [api]);

const useStoreCredit = (api, customerId) =>
  useAsyncFn(async () => api.get(api.routes.storeCreditByCustomer, customerId), [api]);

const AuthNetPayment = ({
  invoiceIds,
  updateFirebasePaidStatus,
  customerId,
  buttonContent,
  showButton,
}) => {
  const authorizeNetApi = new AuthorizeNetApi();

  const {
    auctionMethodApi,
    api,
    authUser: { userId },
  } = useContext(AuthUserContext);
  const [cash, setCash] = useState(0);
  const [credit, setCredit] = useState(0);
  const [storeCredit, setStoreCredit] = useState(0);
  const [{ value: availableStoreCredit }, fetchStoreCredit] = useStoreCredit(api, customerId);
  const [storeCreditAccumulator, accumulateStoreCredit] = useState(0);
  const [swipeErrorMessages, setSwipeErrorMessages] = useState([]);
  const [swipeSuccessMessages, setSwipeSuccessMessages] = useState([]);
  const [lastSubmittedCash, setLastSubmittedCash] = useState(0);
  const [loadingSwiped, setLoadingSwiped] = useState(false);
  const [loadingCash, setLoadingCash] = useState(false);
  const [loadingStoreCredit, setLoadingStoreCredit] = useState(false);
  const [fbUpdateLoading, setFbUpdateLoading] = useState(false);
  const [storeCreditMessages, setStoreCreditMessages] = useState([]);
  const [cashMessages, setCashMessages] = useState([]);
  const [cardMode, setCardMode] = useState('swipe');
  const [selectedCard, setSelectedCard] = useState({});
  const [{ value: cardsOnFile }, fetchCardsOnFile] = useCardOnFile(auctionMethodApi, customerId);
  const [paymentType, setPaymentType] = useState('single');
  const [previousBalance, setPreviousBalance] = useState(0);
  const [, utils, toggle] = useMultiToggle([]);

  const swipeRef = React.createRef();

  const [, makeCashPayment] = useInvoicePayment();
  const [, makeCreditPayment] = useInvoicePayment();
  const [, makeStoreCreditPayment] = useInvoicePayment();

  const [{ value: invoices = [], loading: fetchingInvoices }, fetchInvoices] = useInvoiceFetchById(
    auctionMethodApi,
    invoiceIds
  );

  const balance = invoices.reduce((acc, inv) => acc + inv.balance, 0);

  const { payments: cashPayments } = useMemo(() => getPayments(invoices, cash), [cash, invoices]);
  const { payments: creditPayments } = useMemo(() => getPayments(invoices, credit), [
    credit,
    invoices,
  ]);
  const { payments: storeCreditPayments } = useMemo(() => getPayments(invoices, storeCredit), [
    storeCredit,
    invoices,
  ]);

  const remainingBalance = balance - cash - credit - storeCredit;

  useEffect(() => {
    if (!fetchInvoices) return;
    fetchInvoices();
  }, [fetchInvoices]);

  useEffect(() => {
    if (!fetchCardsOnFile) return;
    fetchCardsOnFile();
  }, [fetchCardsOnFile]);

  useEffect(() => {
    if (!fetchStoreCredit) return;
    fetchStoreCredit();
  }, [fetchStoreCredit]);

  useEffect(() => {
    if (!availableStoreCredit) return;
    accumulateStoreCredit(
      Number(
        availableStoreCredit
          .reduce((total, { amount = 0 }) => Number(total.toFixed(2)) + amount, 0)
          .toFixed(2)
      )
    );
  }, [availableStoreCredit]);

  const swipedSubmission = async () => {
    const value = swipeRef.current.value;
    const track1 = value.split(';')[0].slice(1, -1);

    setLoadingSwiped(true);
    setSwipeErrorMessages([]);
    setSwipeSuccessMessages([]);

    swipeRef.current.value = '';

    try {
      const { transactionResponse = {} } = await authorizeNetApi.post(
        '',
        authorizeNetApi.setTransactionRequestData(Number(credit), track1, customerId, invoiceIds)
      );

      // @TODO this should be in the api at some point.
      // It's an emergency so I am dropping it here,
      if (!transactionResponse.authCode)
        // eslint-disable-next-line no-throw-literal
        throw {
          transactionResponse: {
            ...transactionResponse,
            errors: [{ errorText: 'Transaction declined! No auth code!' }],
          },
        };

      setSwipeSuccessMessages(transactionResponse.messages || []);

      setTimeout(() => {
        setSwipeSuccessMessages([]);
      }, 10000);

      await Promise.all(
        creditPayments.map(payment =>
          makeCreditPayment({
            InvoiceID: payment.id,
            PaymentMode: 'Swiped Credit Card',
            Amount: payment.amount,
            TransactionID: transactionResponse.transId,
            CCNumber: transactionResponse.accountNumber,
          })
        )
      );

      await fetchInvoices(invoiceIds);
      setCredit(0);
      setLoadingSwiped(false);
    } catch (error) {
      const transactionResponse = error.transactionResponse || {};

      setSwipeErrorMessages(
        transactionResponse.errors || [{ errorText: 'Failed! Reason unknown.' }]
      );

      setTimeout(() => {
        setSwipeErrorMessages([]);
      }, 10000);
      setLoadingSwiped(false);
    }
  };

  const cardOnFileSubmission = async () => {
    try {
      setLoadingSwiped(true);
      setSwipeErrorMessages([]);
      setSwipeSuccessMessages([]);

      await Promise.all(
        creditPayments.map(payment =>
          makeCreditPayment({
            InvoiceID: payment.id,
            PaymentMode: 'Credit Card',
            Amount: payment.amount,
            CCNumber: selectedCard.cc_number,
            PaymentProfileID: selectedCard.payment_profile_id,
          })
        )
      );

      await fetchInvoices(invoiceIds);
      setCredit(0);
      setSwipeSuccessMessages([{ description: 'Successfully applied card payment!' }]);
      setLoadingSwiped(false);
      setTimeout(() => {
        setSwipeSuccessMessages([]);
      }, 10000);
    } catch (error) {
      setSwipeErrorMessages([{ description: 'Failed to apply card payment!' }]);
      setTimeout(() => {
        setSwipeErrorMessages([]);
      }, 10000);
    }
  };

  const changeDue = lastSubmittedCash - previousBalance || Number(cash) - balance;

  return (
    <>
      {balance <= 0 && invoices.length > 0 && fetchingInvoices === false && showButton && (
        <>
          <Button
            fluid
            content={buttonContent}
            color={'blue'}
            onClick={async () => {
              setFbUpdateLoading(true);
              await updateFirebasePaidStatus();
            }}
            loading={fbUpdateLoading}
            disabled={fbUpdateLoading}
          />
          <Divider />
        </>
      )}

      <div className={'flex fd-column mb-3'}>
        <div className={'flex fd-column jc-end'}>
          <Button.Group size={'mini'}>
            <Button onClick={() => setPaymentType('single')} active={paymentType === 'single'}>
              Single Payment
            </Button>
            <Button onClick={() => setPaymentType('partial')} active={paymentType === 'partial'}>
              Partial Payments
            </Button>
          </Button.Group>
        </div>
        <Statistic color={'red'} size={'tiny'} className={'flex ai-flex-end mb-1'}>
          <Statistic.Label>Total Balance</Statistic.Label>
          <Statistic.Value>
            <Icon name={'dollar'} />
            {balance.toFixed(2)}
          </Statistic.Value>
        </Statistic>
      </div>
      <Form
        className={'border-dashed round p-3 mb-3'}
        onSubmit={async () => {
          setLoadingCash(true);
          setCashMessages([]);
          try {
            await Promise.all(
              cashPayments.map(payment =>
                makeCashPayment({
                  InvoiceID: payment.id,
                  PaymentMode: 'Cash',
                  Amount: payment.amount,
                })
              )
            );

            setPreviousBalance(balance);
            setCashMessages([{ status: 'success', description: 'Successfully applied cash' }]);
            setLastSubmittedCash(cash);
            setLoadingCash(false);
            setCash(0);
            setTimeout(() => setCashMessages([]), 10000);
            await fetchInvoices(invoiceIds);
          } catch (error) {
            setCashMessages([{ status: 'error', description: 'Failed to submit cash' }]);
          }
        }}
      >
        <strong>{paymentType === 'partial' ? 'Apply Cash' : 'Tendered Cash'}</strong>
        <Form.Input
          action
          className={'flex jc-between'}
          fluid
          onChange={(e, { value }) => setCash(value)}
          type={'number'}
          value={Number(cash)}
          min={'0.00'}
          step={'0.01'}
        >
          <input type={'number'} min={'0.00'} step={'0.01'} />
          <Button
            icon
            type={'button'}
            onClick={() => {
              setCash(remainingBalance.toFixed(2));
            }}
          >
            <Icon name={'dollar'} />
          </Button>
          <Button
            type={'submit'}
            color={'teal'}
            loading={loadingCash}
            disabled={Number(cash) === 0}
            onClick={() => {
              swipeRef.current.focus();
            }}
            icon
          >
            <Icon name={'send'} />
          </Button>
        </Form.Input>
        {paymentType === 'partial' && (
          <>
            <strong>Tendered Cash</strong>
            <TenderedCash appliedCash={lastSubmittedCash} />
          </>
        )}
        {paymentType === 'single' && (
          <Label>
            Change Due: <Label.Detail>{changeDue < 0 ? 0 : changeDue.toFixed(2)}</Label.Detail>
          </Label>
        )}
        {cashMessages.map((cashMessage, key) => {
          return (
            <Message key={key} color={cashMessage.status === 'success' ? 'green' : 'red'}>
              {cashMessage.description}
            </Message>
          );
        })}
      </Form>
      <Form
        className={'border-dashed round p-3 mb-3'}
        onSubmit={cardMode === 'swipe' ? swipedSubmission : cardOnFileSubmission}
      >
        <strong>Apply Credit</strong>
        <Form.Input
          action
          className={'flex jc-between'}
          fluid
          onChange={(e, { value }) => setCredit(value)}
          type={'number'}
          value={Number(credit)}
          min={'0.00'}
          step={'0.01'}
        >
          <input type={'number'} min={'0.00'} step={'0.01'} />
          <Button
            type={'button'}
            onClick={() => {
              setCredit(remainingBalance.toFixed(2));
            }}
            icon
          >
            <Icon name={'dollar'} />
          </Button>
          <Dropdown
            selection
            compact
            value={cardMode}
            onChange={(event, { value }) => setCardMode(value)}
            options={[
              { key: 'swipe', value: 'swipe', text: 'Swipe' },
              { key: 'onFile', value: 'onFile', text: 'On File' },
            ]}
          />
          <Button
            icon
            onClick={() => swipeRef.current.focus()}
            color={'teal'}
            type={'button'}
            disabled={cardMode === 'onFile'}
          >
            <Icon name={'payment'} />
          </Button>
        </Form.Input>
        {cardMode === 'swipe' && (
          <Form.Input label={'Swipe Card'} type={'text'} action disabled={credit === 0}>
            <input ref={swipeRef} />
            <Button
              type={'submit'}
              loading={loadingSwiped}
              disabled={loadingSwiped}
              icon
              color={'teal'}
            >
              <Icon name={'send'} />
            </Button>
          </Form.Input>
        )}
        {cardMode === 'onFile' && cardsOnFile && (
          <Button.Group fluid size={'mini'} compact>
            {cardsOnFile.cards.map((card, key) => (
              <Button
                key={key}
                type={'button'}
                onClick={() => setSelectedCard(card)}
                active={selectedCard.cc_number === card.cc_number}
              >
                <Button.Content>{card.name}</Button.Content>
                <Button.Content>{card.cc_number}</Button.Content>
              </Button>
            ))}
            <Button
              type={'submit'}
              disabled={(isEmpty(selectedCard) && Number(balance) === 0) || loadingSwiped}
              loading={loadingSwiped}
            >
              Submit
            </Button>
          </Button.Group>
        )}
        {cardMode === 'onFile' && !cardsOnFile && (
          <Message color={'red'}>
            <Message.Header>NO CARDS FOUND!</Message.Header>
            <Message.Content>Please contact management! This should never happen!</Message.Content>
          </Message>
        )}
        {swipeErrorMessages.map((e, key) => {
          return (
            <Message color={'red'} key={key}>
              {e.errorText}
            </Message>
          );
        })}
        {swipeSuccessMessages.map((m, key) => {
          return (
            <Message color={'green'} key={key}>
              {m.description}
            </Message>
          );
        })}
      </Form>
      <Form
        className={'border-dashed round p-3 mb-3'}
        onSubmit={async () => {
          setLoadingStoreCredit(true);
          setStoreCreditMessages([]);
          try {
            await Promise.all(
              storeCreditPayments.map(payment =>
                makeStoreCreditPayment({
                  InvoiceID: payment.id,
                  PaymentMode: 'Store Credit',
                  Amount: payment.amount,
                })
              )
            );
            await api.post(api.routes.storeCredit, {
              customerId,
              amount: -Number(storeCredit),
              userId,
              notes: 'Invoice payment',
            });
            await fetchInvoices(invoiceIds);
            await fetchStoreCredit();
            setStoreCredit(0);
            setLoadingStoreCredit(false);
            setStoreCreditMessages([
              { status: 'success', description: 'Successfully applied store credit' },
            ]);
            setTimeout(() => setStoreCreditMessages([]), 10000);
          } catch (error) {
            setStoreCreditMessages([
              { status: 'error', description: 'Failed to submit store credit' },
            ]);
          }
        }}
      >
        <strong>Store Credit</strong>
        <Form.Input
          action
          className={'flex jc-between'}
          fluid
          onChange={(e, { value }) => {
            if (
              Number(value) > balance ||
              Number(value) < 0 ||
              Number(value) > storeCreditAccumulator ||
              (value.includes('.') && value.split('.')[1].length > 2)
            )
              return;
            setStoreCredit(value);
          }}
          type={'number'}
          value={storeCredit}
          min={'0.00'}
          step={'0.01'}
        >
          <input type={'number'} min={'0.00'} step={'0.01'} />
          <Button
            type={'button'}
            onClick={() => {
              if (Number(storeCredit) > 0) setStoreCredit(0);
              else {
                setStoreCredit(
                  remainingBalance > storeCreditAccumulator
                    ? storeCreditAccumulator.toFixed(2)
                    : remainingBalance.toFixed(2)
                );
              }
            }}
            icon
          >
            <Icon name={'dollar'} />
          </Button>
          <Button
            type={'submit'}
            color={'teal'}
            loading={loadingStoreCredit}
            disabled={Number(storeCredit) === 0}
            icon
          >
            <Icon name={'send'} />
          </Button>
        </Form.Input>
        <Label>
          Available Store Credit:{' '}
          <Label.Detail>
            {Number(storeCredit) > 0
              ? (storeCreditAccumulator - Number(storeCredit)).toFixed(2)
              : storeCreditAccumulator.toFixed(2)}
          </Label.Detail>
        </Label>
        {storeCreditMessages.map((storeCreditMessage, key) => {
          return (
            <Message key={key} color={storeCreditMessage.status === 'success' ? 'green' : 'red'}>
              {storeCreditMessage.description}
            </Message>
          );
        })}
      </Form>
      <div className={'flex fd-row jc-between mb-2'}>
        {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
        <label>Total Payment</label>
        <Label>
          <Icon name={'dollar'} />
          {(Number(cash) + Number(credit) + Number(storeCredit)).toFixed(2)}
        </Label>
      </div>
      <div className={'flex fd-row jc-between'}>
        {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
        <label>Remaining Due</label>
        <Label color={'red'}>
          <Icon name={'dollar'} />
          {remainingBalance < 0 ? 0 : remainingBalance.toFixed(2)}
        </Label>
      </div>
      <Divider />

      <Accordion>
        <Accordion.Title active={utils.has(0)} index={0} onClick={() => toggle(0)}>
          <Icon name="dropdown" />
          Invoices
        </Accordion.Title>
        <Accordion.Content active={utils.has(0)}>
          <Table basic={'very'} celled textAlign={'right'} compact>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell textAlign={'left'}>Invoice Id</Table.HeaderCell>
                <Table.HeaderCell>Balance</Table.HeaderCell>
                <Table.HeaderCell>Total Amount</Table.HeaderCell>
              </Table.Row>
            </Table.Header>

            <Table.Body>
              {fetchingInvoices &&
                invoiceIds.map((id, key) => (
                  <Table.Row key={key}>
                    <Table.Cell>
                      <Placeholder>
                        <Placeholder.Line length="full" />
                      </Placeholder>
                    </Table.Cell>
                    <Table.Cell>
                      <Placeholder>
                        <Placeholder.Line length="full" />
                      </Placeholder>
                    </Table.Cell>
                    <Table.Cell>
                      <Placeholder>
                        <Placeholder.Line length="full" />
                      </Placeholder>
                    </Table.Cell>
                  </Table.Row>
                ))}
              {invoices.map((inv, key) => (
                <Table.Row key={key}>
                  <Table.Cell textAlign={'left'}>{inv.id}</Table.Cell>
                  <Table.Cell positive={inv.balance === 0} negative={inv.balance > 0}>
                    ${inv.balance}
                  </Table.Cell>
                  <Table.Cell>${inv.invoice_total}</Table.Cell>
                </Table.Row>
              ))}
            </Table.Body>
          </Table>
        </Accordion.Content>
        <Accordion.Title active={utils.has(1)} index={1} onClick={() => toggle(1)}>
          <Icon name="dropdown" />
          Payments
        </Accordion.Title>
        <Accordion.Content active={utils.has(1)}>
          <Table basic={'very'} striped>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>Invoice Id</Table.HeaderCell>
                <Table.HeaderCell>Payment Type</Table.HeaderCell>
                <Table.HeaderCell>Amount</Table.HeaderCell>
              </Table.Row>
            </Table.Header>

            <Table.Body>
              {invoices.map(inv => {
                return inv.payments.map((payment, key) => (
                  <Table.Row key={key}>
                    <Table.Cell>{inv.id}</Table.Cell>
                    <Table.Cell>{payment.payment_type}</Table.Cell>
                    <Table.Cell>{payment.amount}</Table.Cell>
                  </Table.Row>
                ));
              })}
            </Table.Body>
          </Table>
        </Accordion.Content>
      </Accordion>
    </>
  );
};

AuthNetPayment.defaultProps = {
  updateFirebasePaidStatus: () => {},
  buttonContent: '',
  showButton: false,
};

AuthNetPayment.propTypes = {
  invoiceIds: PropTypes.array.isRequired,
  updateFirebasePaidStatus: PropTypes.func.isRequired,
  customerId: PropTypes.number.isRequired,
  buttonContent: PropTypes.string.isRequired,
  showButton: PropTypes.bool,
};

const TenderedCash = ({ appliedCash }) => {
  const [showCustomInput, toggleCustomInput] = useState(false);
  const [tenderedValue, setTenderedValue] = useState(0);
  const inputRef = useRef();

  useEffect(() => {
    if (!showCustomInput) return;
    inputRef.current.focus();
  }, [showCustomInput]);

  return (
    <div>
      {showCustomInput ? (
        <div className="ui mini fluid action left icon input mb-1">
          <input
            disabled={Number(appliedCash) === 0}
            step=".01"
            value={tenderedValue}
            type="number"
            ref={inputRef}
            onChange={() => {
              setTenderedValue(inputRef.current.value);
            }}
          />
          <i className="dollar icon" />
          <button
            type={'button'}
            className="ui mini icon button"
            onClick={() => {
              toggleCustomInput(b => !b);
            }}
          >
            <i className="close icon" />
          </button>
        </div>
      ) : (
        <Button.Group fluid size={'mini'} className={'mb-1'}>
          {[10, 20, 40, 50, 100].map((val, key) => {
            return (
              <Button
                key={key}
                disabled={Number(appliedCash) === 0}
                type={'button'}
                icon
                onClick={() => {
                  // onTenderedSelect(val);
                  setTenderedValue(val);
                }}
              >
                <Icon name={'dollar'} />
                {val}
              </Button>
            );
          })}
          <Button
            icon
            color={tenderedValue ? 'blue' : null}
            disabled={Number(appliedCash) === 0}
            onClick={() => {
              toggleCustomInput(s => !s);
            }}
          >
            <Icon name={'pencil'} />
          </Button>
        </Button.Group>
      )}
      <Label color={'blue'}>
        Change Due:{' '}
        <Label.Detail>{(Number(tenderedValue) - Number(appliedCash)).toFixed(2)}</Label.Detail>
      </Label>
    </div>
  );
};

TenderedCash.defaultProps = {
  appliedCash: 0,
};

TenderedCash.propTypes = {
  appliedCash: PropTypes.number.isRequired,
};

export default React.memo(
  AuthNetPayment,
  (prevProps, nextProps) =>
    JSON.stringify(prevProps.invoiceIds) === JSON.stringify(nextProps.invoiceIds)
);
