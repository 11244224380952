import React from 'react';
import { Label } from 'semantic-ui-react';
import PropTypes from 'prop-types';

export const CurbSideLabels = ({
  affiliate,
  authorizedPickup,
  hidden,
  note,
  pickupDate,
  pickupTime,
  selectedItems,
  totalAmount,
}) => {
  const displayLabel = (content, detail) => {
    return (
      <Label color={'blue'} className={'mb-2 mt-2'}>
        {content}: <Label.Detail>{detail}</Label.Detail>
      </Label>
    );
  };

  return (
    !hidden && (
      <>
        {selectedItems.length > 0 && displayLabel('Items selected', selectedItems.length)}

        {totalAmount !== 0.0 && displayLabel('Total amount due', `$${totalAmount.toFixed(2)}`)}

        {affiliate && displayLabel('Location', affiliate.name)}

        {pickupDate && displayLabel('Pick-up day', pickupDate.toDateString())}

        {pickupTime && displayLabel('Pick-up time', pickupTime)}

        {authorizedPickup && displayLabel('Authorized pick-up', authorizedPickup)}

        {note && displayLabel('Vehicle', note)}
      </>
    )
  );
};

CurbSideLabels.propTypes = {
  affiliate: PropTypes.object,
  authorizedPickup: PropTypes.string,
  hidden: PropTypes.bool.isRequired,
  note: PropTypes.string,
  pickupDate: PropTypes.object,
  pickupTime: PropTypes.string,
  selectedItems: PropTypes.array.isRequired,
  totalAmount: PropTypes.number.isRequired,
};
