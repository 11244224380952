import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import LogoutButton from './logout';
import AuthUserContext from './auth-user-context';
import * as routes from '../constants/routes';
import PropTypes from 'prop-types';
import { Button, Divider, Dropdown, Icon, Label } from 'semantic-ui-react';
import * as access from '../constants/access-levels';
import Logo from '../images/NellisAuction-black-trans.png';

const NavButton = props => (
  <Fragment>
    <Button basic fluid as={Link} {...props} />
    <Divider className={'w-100'} />
  </Fragment>
);

const Navigation = ({ onNavClick }) => (
  <AuthUserContext.Consumer>
    {({ authUser }) =>
      authUser ? (
        <NavigationAuth authUser={authUser} onNavClick={onNavClick} roleId={authUser.roleId} />
      ) : (
        <NavigationNonAuth onNavClick={onNavClick} />
      )
    }
  </AuthUserContext.Consumer>
);

const NavigationAuth = ({ authUser, roleId, onNavClick }) => (
  <div className={'flex fd-column jc-center ai-center p-3'}>
    <div className={'flex ai-center'}>
      <img className={'m-4'} src={Logo} alt="Nellis Auction" width={'90'} />
    </div>
    <Label className={'mb-4'}>
      <Icon name={'user circle'} />
      {`${authUser.firstName} ${authUser.lastName}`}
    </Label>
    {roleId && access.SUPERVISOR.includes(roleId) && (
      <Fragment>
        <NavButton content={'Users'} icon={'users'} onClick={onNavClick} to={routes.USERS} />
        <NavButton
          content={'Add User'}
          icon={'user'}
          onClick={onNavClick}
          to={routes.USERS_CREATE}
        />
      </Fragment>
    )}
    {roleId && [...access.SUPERVISOR, 3].includes(roleId) && (
      <Fragment>
        <NavButton content={'Admin'} icon={'chess pawn'} onClick={onNavClick} to={routes.ADMIN} />
      </Fragment>
    )}
    <NavButton
      content={'Pick Up Queue'}
      icon={'boxes'}
      onClick={onNavClick}
      to={routes.PICKUP_QUEUE}
    />
    {roleId && [...access.SUPERVISOR, 3].includes(roleId) && (
      <>
        <Dropdown item text={'Parking Lot'} as={Button} basic fluid className={'t-center'}>
          <Dropdown.Menu className={'left'}>
            <Dropdown.Item as={Link} to={routes.LOT_DETAILS} onClick={onNavClick}>
              <Icon name={'car'} />
              Lot Details
            </Dropdown.Item>
            <Dropdown.Item as={Link} to={routes.LOT_QUEUE} onClick={onNavClick}>
              <Icon name={'list'} />
              Lot Queue
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
        <Divider className={'w-100'} />
      </>
    )}
    <NavButton
      content={'Stats Dashboard'}
      icon={'line graph'}
      onClick={onNavClick}
      to={routes.STATS_DASHBOARD}
    />
    {roleId && access.SUPERVISOR.includes(roleId) && (
      <Fragment>
        <Button
          basic
          fluid
          content={'Email Service Desk'}
          icon={'envelope'}
          onClick={() =>
            window.open(
              'mailto:incoming+nellisauctionsoftware-hr-8425822-issue-@incoming.gitlab.com'
            )
          }
        />
        <Divider className={'w-100'} />
      </Fragment>
    )}
    <NavButton
      content={'Employee Password Reset'}
      icon={'asterisk'}
      onClick={onNavClick}
      to={routes.EMPLOYEE_RESET_PASSWORD}
    />
    <LogoutButton basic fluid as={Link} to={routes.LOGIN} onClick={onNavClick}>
      <Icon name={'log out'} flipped={'horizontally'} />
      Logout
    </LogoutButton>
  </div>
);

const NavigationNonAuth = () => (
  <div className={'flex fd-column jc-center ai-center p-3'}>
    <img className={'m-4'} src={Logo} alt="Nellis Auction" width={'90'} height={'50'} />
    <Button className={'mb-3'} basic fluid as={Link} to={routes.LOGIN}>
      <Icon name={'log out'} />
      Login
    </Button>
    <Button basic fluid as={Link} to={routes.EMPLOYEE_RESET_PASSWORD}>
      <Icon name={'asterisk'} />
      Employee Password Reset
    </Button>
  </div>
);

Navigation.propTypes = {
  authUser: PropTypes.object,
  onNavClick: PropTypes.func.isRequired,
};

NavigationAuth.propTypes = {
  authUser: PropTypes.object,
  roleId: PropTypes.number,
  onNavClick: PropTypes.func,
};

export default Navigation;
