import React, { Component } from 'react';
import { Button } from 'semantic-ui-react';
import withAuthorization from '../with-authorization';
import { withRouter } from 'react-router-dom';

class GenerateInvoices extends Component {
  state = {
    loading: false,
  };

  fetchInvoices = async () => {
    const {
      auctionMethodApi,
      refreshPage,
      match: {
        params: { id },
      },
    } = this.props;

    this.setState({ loading: true });

    await auctionMethodApi.get('/checkoutapi/generatetestinvoices', null, {
      auction_id: 231,
      user_id: id,
    });

    this.setState({ loading: false });

    refreshPage();
  };

  render() {
    const {
      state: { loading },
      fetchInvoices,
    } = this;

    if (process.env.NODE_ENV === 'production') return null;

    return (
      <Button
        onClick={fetchInvoices}
        content={'Generate Invoices'}
        loading={loading}
        className={'fixed r-1r mt-3 border-dashed zi-1'}
      />
    );
  }
}

export default withAuthorization(withRouter(GenerateInvoices));
