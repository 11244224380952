import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { PasswordForgetLink } from './password-forget';
import { auth } from '../firebase';
import * as routes from '../constants/routes';
import { Button, Form, Segment } from 'semantic-ui-react';
import HelpMessage from './help-message';
// import Login from '../shared-components/firebase/login';

const LoginPage = ({ history, location: { state } }) => (
  <div className={'flex ai-center jc-center'}>
    <Segment className={'flex fd-column'}>
      <h3 className={'ff-jura t-center'}>Login</h3>
      {state && state.message && state.message.error && <HelpMessage {...state.message} />}
      <SignInForm history={history} />
    </Segment>
  </div>
);

LoginPage.propTypes = {
  location: PropTypes.object.isRequired,
};

const byPropKey = (propertyName, value) => () => ({
  [propertyName]: value,
});

class SignInForm extends Component {
  constructor(props) {
    super(props);
    this._isMounted = true;

    this.initialState = {
      email: '',
      password: '',
      error: null,
    };

    this.state = { ...this.initialState };
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  onSubmit = event => {
    const { email, password } = this.state;

    const { history } = this.props;

    auth
      .doSignInWithEmailAndPassword(email, password)
      .then(() => {
        if (this._isMounted) {
          this.setState({ ...this.initialState });
        }
        history.push(routes.HOME);
      })
      .catch(error => {
        this.setState(byPropKey('error', error));
      });

    event.preventDefault();
  };

  render() {
    const { email, password, error } = this.state;

    const isInvalid = password === '' || email === '';

    return (
      <Form onSubmit={this.onSubmit}>
        <Form.Input
          value={email}
          onChange={event => this.setState(byPropKey('email', event.target.value))}
          type="text"
          placeholder="Email Address"
          autoComplete="username"
          fluid
        />
        <Form.Input
          value={password}
          onChange={event => this.setState(byPropKey('password', event.target.value))}
          type="password"
          placeholder="Password"
          autoComplete="current-password"
        />
        <div className={'flex fd-column'}>
          <Button disabled={isInvalid} type="submit" style={{ float: 'right' }}>
            Login
          </Button>
          <div className={'mt-2'}>
            <PasswordForgetLink />
          </div>
        </div>
        {error && <p style={{ color: 'red' }}>{error.message}</p>}
      </Form>
    );
  }
}

LoginPage.propTypes = {
  history: PropTypes.object,
};

SignInForm.propTypes = {
  history: PropTypes.object,
};

export default withRouter(LoginPage);

export { SignInForm };
