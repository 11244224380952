import React from 'react';
import { withRouter } from 'react-router-dom';
import AuthUserContext from './auth-user-context';
import PropTypes from 'prop-types';

const withAuthorization = Component => {
  class WithAuthorization extends React.Component {
    render() {
      return (
        <AuthUserContext.Consumer>
          {({ authUser, ...restConsumer }) =>
            authUser ? <Component authUser={authUser} {...restConsumer} {...this.props} /> : null
          }
        </AuthUserContext.Consumer>
      );
    }
  }

  WithAuthorization.propTypes = {
    history: PropTypes.object,
  };

  return withRouter(WithAuthorization);
};

export default withAuthorization;
