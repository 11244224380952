import React, { Component } from 'react';
import { Form, Header } from 'semantic-ui-react';
import withAuthorization from './with-authorization';
import PropTypes from 'prop-types';
import API from './api';
import { NotificationContext } from './notification-context';
import { SUCCESS, FAILED } from '../shared-components/notifications';

const emptyForm = {
  customers: '',
  amount: '',
  notes: '',
};

class BatchStoreCredit extends Component {
  state = { storeCreditForm: emptyForm };

  handleInputChange = (event, { name, value }) => {
    this.setState(prevState => ({
      storeCreditForm: { ...prevState.storeCreditForm, [name]: value },
    }));
  };

  render() {
    const {
      state: {
        storeCreditForm: { customers, amount, notes },
      },
      props: {
        api,
        authUser: { userId },
      },
      handleInputChange,
    } = this;

    return (
      <>
        {[4, 1, 2, 223, 382].includes(userId) && (
          <NotificationContext.Consumer>
            {({ addNotifications }) => (
              <API.Crud route={'storeCreditBatch'} api={api}>
                {({ post }) => (
                  <>
                    <Header content={'Batch Store Credit'} size={'large'} />
                    <Form
                      onSubmit={async () => {
                        const customerId = customers.split(/,\s*/);
                        const data = customerId
                          .filter(customer => customer !== '')
                          .map(customer => ({
                            customerId: Number(customer),
                            amount: Number(amount),
                            userId,
                            notes,
                          }));

                        const result = await post({ data });

                        addNotifications([result ? SUCCESS : FAILED]);

                        if (result) this.setState({ storeCreditForm: emptyForm });
                      }}
                    >
                      <Form.TextArea
                        type={'string'}
                        placeholder={'Comma-separated list of customer IDs (e.g. 1,2,3)'}
                        name={'customers'}
                        value={customers}
                        onChange={handleInputChange}
                        label={'Customer IDs'}
                      />
                      <Form.Input
                        type={'number'}
                        min={0}
                        name={'amount'}
                        value={amount}
                        onChange={handleInputChange}
                        label={'Amount'}
                        placeholder={'Amount to give customer(s) in USD'}
                      />
                      <Form.TextArea
                        type={'string'}
                        label={'Notes'}
                        name={'notes'}
                        value={notes}
                        onChange={handleInputChange}
                        placeholder={'Reason for store credit'}
                      />
                      <Form.Button
                        content={'SUBMIT'}
                        color={'green'}
                        disabled={!customers || !amount}
                      />
                    </Form>
                  </>
                )}
              </API.Crud>
            )}
          </NotificationContext.Consumer>
        )}
      </>
    );
  }
}

BatchStoreCredit.propTypes = {
  authUser: PropTypes.object,
  api: PropTypes.object,
};

export default withAuthorization(BatchStoreCredit);
