import React, { Component } from 'react';
import { Button } from 'semantic-ui-react';
import PropTypes from 'prop-types';

class UserFilters extends Component {
  createButtons = (active, filters) => {
    let iteration = 0;

    const buttons = [];

    filters.map(filter => {
      buttons.push(
        <Button
          active={active === filter.toLowerCase()}
          color={active === filter.toLowerCase() ? 'blue' : null}
          filter={filter.toLowerCase()}
          key={filter}
          onClick={this.props.onClick}
        >
          {filter}
        </Button>
      );

      if (iteration !== filters.length - 1) {
        buttons.push(<Button.Or key={iteration} />);
        iteration += 1;
      }

      return null;
    });

    return buttons;
  };

  render() {
    const { active, filters } = this.props;

    return <Button.Group size={'small'}>{this.createButtons(active, filters)}</Button.Group>;
  }
}

UserFilters.propTypes = {
  active: PropTypes.string,
  filters: PropTypes.array.isRequired,
  onClick: PropTypes.func.isRequired,
};

export default UserFilters;
