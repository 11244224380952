const convertDateSqltoJS = date => {
  let replace = date.replace(/-/g, '/').replace(/T/g, ' ');

  // eslint-disable-next-line no-restricted-properties
  replace = replace.substr(0, replace.indexOf('.'));
  return new Date(replace);
};

const convertDateSqlUTCtoJS = date => {
  let replace = date.replace(/-/g, '/').replace(/T/g, ' ');

  // eslint-disable-next-line no-restricted-properties
  replace = replace.substr(0, replace.indexOf('.'));

  replace = replace.split(' ');
  const splitDate = replace[0].split('/');
  const splitTime = replace[1].split(':');

  return new Date(
    Date.UTC(
      Number(splitDate[0]),
      Number(splitDate[1]) - 1,
      Number(splitDate[2]),
      Number(splitTime[0]),
      Number(splitTime[1]),
      Number(splitTime[2])
    )
  );
};

const convertDateFBtoJS = date => {
  return date.toDate();
};

const setDateOptions = settings => {
  return settings;
};

const convertDateObj = (date, setting) => {
  return new Date(convertDateFBtoJS(date).toLocaleDateString('en-Us', setDateOptions(setting)));
};

const convertEpochTime = epochSeconds => {
  return new Date(epochSeconds * 1000)
    .toLocaleString('en-US', {
      month: '2-digit',
      day: '2-digit',
      year: '2-digit',
      hour: '2-digit',
      minute: '2-digit',
    })
    .replace(/,/, ' ');
};

export {
  setDateOptions,
  convertDateFBtoJS,
  convertDateObj,
  convertDateSqltoJS,
  convertEpochTime,
  convertDateSqlUTCtoJS,
};
