import React, { Component } from 'react';
import { Form } from 'semantic-ui-react';

class InputAuthorization extends Component {
  state = {
    id: '',
  };

  handleChange = (event, { name, value }) => this.setState({ [name]: value });

  render() {
    return (
      <div>
        {this.state.id === this.props.password ? (
          this.props.children
        ) : (
          <Form>
            <Form.Input
              type={'password'}
              label={'Manager Scan'}
              onChange={this.handleChange}
              name={'id'}
            />
          </Form>
        )}
      </div>
    );
  }
}

export default InputAuthorization;
