const PICKUP_QUEUE_STATUSES = [
  { status: -1, color: 'grey', icon: 'calendar', statusName: 'Scheduled' },
  { status: -2, color: 'pink', icon: 'clipboard', statusName: 'Needs Reviewed' },
  { status: 0, color: 'red', icon: 'clock outline', statusName: 'Awaiting Consolidation' },
  { status: 1, color: 'blue', icon: 'hourglass half', statusName: 'In Progress' },
  { status: 2, color: 'yellow', icon: 'box', statusName: 'Consolidated' },
  { status: 3, color: 'orange', icon: 'dolly flatbed', statusName: 'Loading Out' },
  { status: 4, color: 'green', icon: 'checkmark', statusName: 'Picked Up' },
];

export const getQueueColor = appointmentStatus =>
  PICKUP_QUEUE_STATUSES.find(({ status }) => appointmentStatus === status)?.color || null;

const WEEKDAY_TWO_DIGIT_DATE_TIME = {
  weekday: 'short',
  year: 'numeric',
  month: 'numeric',
  day: 'numeric',
  hour: 'numeric',
  minute: 'numeric',
  hour12: true,
};

export { PICKUP_QUEUE_STATUSES, WEEKDAY_TWO_DIGIT_DATE_TIME };
