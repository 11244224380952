import React, { useCallback, useMemo, useReducer } from 'react';
import PropTypes from 'prop-types';
import { reducer, useAsyncReducerEffect, useCargoApi } from './hooks';
import CargoApi from '../shared-api-adapters/cargo-api';
import { useAuthContext } from './firebase/authentication';

export const HelpersContext = React.createContext();

/**
 * Item History Create Builder
 *
 * @param actionId
 * @returns {function(...[*]=)}
 */
export const itemHistoryBuild = actionId => userId => async inventoryNumbers => {
  const api = new CargoApi();

  const cargoItems = await Promise.all(
    inventoryNumbers.map(invNum => api.get(api.routes.itemByInventoryNumber, invNum))
  );

  const cargoIds = cargoItems.flat().map(item => item.id);

  await Promise.all(
    cargoIds.map(id =>
      api.post(api.routes.itemsHistories, {
        itemId: id,
        userId,
        actionId,
        createdAt: new Date(),
      })
    )
  );
};

const useGetActions = () => {
  const api = useCargoApi();

  return useCallback(
    () => {
      return api.get(api.routes.actions);
    },
    [api]
  );
};

const useGetUsers = () => {
  const { nellisAuctionApi } = useAuthContext();

  return useCallback(
    () => {
      return nellisAuctionApi.get(nellisAuctionApi.routes.users);
    },
    [nellisAuctionApi]
  );
};

const reducerActions = {
  setActions: (state, action) => ({ ...state, itemHistoryActions: action.results }),
  setUsers: (state, action) => ({ ...state, users: action.results }),
  loading: (state, action) => ({ ...state, loading: action.loading }),
};

const HelpersContextProvider = ({ children }) => {
  const { authUser } = useAuthContext();
  const [{ itemHistoryActions, users }, dispatch] = useReducer(reducer(reducerActions), {
    itemHistoryActions: [],
    users: [],
  });

  useAsyncReducerEffect(useGetActions(), 'setActions', dispatch);
  useAsyncReducerEffect(useGetUsers(), 'setUsers', dispatch);

  const itemHistoryFns = useMemo(
    () =>
      itemHistoryActions.reduce(
        (acc, val) => {
          return {
            ...acc,
            [val.id]: itemHistoryBuild(val.id)(authUser !== null ? authUser.userId : authUser),
          };
        },
        { actions: itemHistoryActions, users }
      ),
    [authUser, itemHistoryActions, users]
  );

  return <HelpersContext.Provider value={itemHistoryFns}>{children}</HelpersContext.Provider>;
};

HelpersContextProvider.propTypes = {
  children: PropTypes.any.isRequired,
};

export default HelpersContextProvider;
