import React, { Component } from 'react';
import API from './api';
import { Form } from 'semantic-ui-react';
import PropTypes from 'prop-types';
import { NotificationContext } from './notification-context';
import { startCase } from 'lodash/fp';

class RecordUpdater extends Component {
  constructor(props) {
    super(props);

    this.state = { ...props.initialValues };
  }

  render() {
    const { route, fields, additionalSubmissionValues, onSuccessfulSubmission } = this.props;

    return (
      <NotificationContext.Consumer>
        {({ addNotifications }) => (
          <API.Crud route={route}>
            {({ post, loading }) => {
              return (
                <Form
                  onSubmit={async () => {
                    const result = await post({ ...this.state, ...additionalSubmissionValues });

                    if (result) {
                      addNotifications([
                        {
                          header: 'Success!',
                          content: 'Successfully updated record',
                          color: 'green',
                        },
                      ]);

                      onSuccessfulSubmission();

                      return;
                    }

                    addNotifications([
                      { header: 'Failed!', content: 'Failed to add record', color: 'red' },
                    ]);
                  }}
                >
                  {fields.map((field, key) => (
                    <Form.Input
                      key={key}
                      label={startCase(field)}
                      name={field}
                      value={this.state[field] || ''}
                      onChange={(e, { name, value }) => this.setState({ [name]: value })}
                    />
                  ))}
                  <Form.Button content={'Submit'} loading={loading} />
                </Form>
              );
            }}
          </API.Crud>
        )}
      </NotificationContext.Consumer>
    );
  }
}

RecordUpdater.defaultProps = {
  initialValues: {},
  additionalSubmissionValues: {},
};

RecordUpdater.propTypes = {
  route: PropTypes.string.isRequired,
  initialValues: PropTypes.object.isRequired,
  fields: PropTypes.array.isRequired,
  additionalSubmissionValues: PropTypes.object.isRequired,
  onSuccessfulSubmission: PropTypes.func.isRequired,
};

export default RecordUpdater;
