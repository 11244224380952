const urlSearchParams = ({ search, pathname }, push) => {
  // eslint-disable-next-line
  const u = new URLSearchParams(search);

  const setUrl = (param, value) => {
    u.set(param, value);

    push({
      pathname,
      search: u.toString(),
    });
  };

  const appendUrl = (param, value) => {
    u.append(param, value);

    push({
      pathname,
      search: u.toString(),
    });
  };

  const deleteUrl = param => {
    u.delete(param);

    push({
      pathname,
      search: u.toString(),
    });
  };

  const toggleUrl = (param, value) => {
    const allValues = u.getAll(param);

    const updatedValues = allValues.includes(value)
      ? allValues.filter(v => v !== value)
      : [...allValues, value];

    deleteUrl(param);

    updatedValues.forEach(val => appendUrl(param, val));
  };

  return {
    setUrl,
    appendUrl,
    toggleUrl,
    deleteUrl,
    urlParams: u,
  };
};

export { urlSearchParams };
