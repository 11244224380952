import firebase from 'firebase/app';
import 'firebase/auth';

const config = {
  apiKey: 'AIzaSyAvGXLVqNxBgGsVV-Lso0P1Yw9O1dZiguE',
  authDomain: 'nellishr-cbba0.firebaseapp.com',
  databaseURL: 'https://nellishr-cbba0.firebaseio.com',
  projectId: 'nellishr-cbba0',
  storageBucket: 'nellishr-cbba0.appspot.com',
  messagingSenderId: '1079556129226',
};

if (firebase.apps.length === 0) firebase.initializeApp(config);

const auth = firebase.auth();

export { auth };
