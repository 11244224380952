const {
  REACT_APP_AUTHORIZE_NET_HOSTNAME_DEV,
  REACT_APP_AUTHORIZE_NET_MERCHANT_NAME_DEV,
  REACT_APP_AUTHORIZE_NET_TRANSACTION_KEY_DEV,
  REACT_APP_AUCTION_CONCOCTION_HOSTNAME_DEV,
  REACT_APP_NELLIS_HR_HOSTNAME_DEV,
  REACT_APP_AUCTION_METHOD_API_KEY_DEV,
  REACT_APP_AUCTION_METHOD_API_BASE_DEV,
  REACT_APP_AUCTION_METHOD_API_HOSTNAME_DEV,
  REACT_APP_AUCTION_METHOD_API_AUCTION_ID_DEV,
  REACT_APP_CARGO_URL_DEV,
  REACT_APP_CAROGAPI_HOSTNAME_DEV,
  REACT_APP_AUTHORIZE_NET_HOSTNAME,
  REACT_APP_AUTHORIZE_NET_MERCHANT_NAME,
  REACT_APP_AUTHORIZE_NET_TRANSACTION_KEY,
  REACT_APP_AUCTION_CONCOCTION_HOSTNAME,
  REACT_APP_NELLIS_HR_HOSTNAME,
  REACT_APP_AUCTION_METHOD_API_KEY,
  REACT_APP_AUCTION_METHOD_API_BASE,
  REACT_APP_AUCTION_METHOD_API_HOSTNAME,
  REACT_APP_AUCTION_METHOD_API_AUCTION_ID,
  REACT_APP_CARGO_URL,
  REACT_APP_CAROGAPI_HOSTNAME,
  REACT_APP_VERSION,
} = process.env;

const variables = {
  development: {
    authorizeNet: {
      hostname: REACT_APP_AUTHORIZE_NET_HOSTNAME_DEV,
      merchantName: REACT_APP_AUTHORIZE_NET_MERCHANT_NAME_DEV,
      transactionKey: REACT_APP_AUTHORIZE_NET_TRANSACTION_KEY_DEV,
    },
    auctionConcoction: {
      hostname: REACT_APP_AUCTION_CONCOCTION_HOSTNAME_DEV,
    },
    nellisAuctionApi: {
      hostname: REACT_APP_NELLIS_HR_HOSTNAME_DEV,
    },
    auctionMethodApi: {
      xApiKey: REACT_APP_AUCTION_METHOD_API_KEY_DEV,
      base: REACT_APP_AUCTION_METHOD_API_BASE_DEV,
      hostname: REACT_APP_AUCTION_METHOD_API_HOSTNAME_DEV,
      auctionId: REACT_APP_AUCTION_METHOD_API_AUCTION_ID_DEV,
    },
    cargo: {
      url: REACT_APP_CARGO_URL_DEV,
    },
    cargoApi: {
      hostname: REACT_APP_CAROGAPI_HOSTNAME_DEV,
    },
  },
  production: {
    authorizeNet: {
      hostname: REACT_APP_AUTHORIZE_NET_HOSTNAME,
      merchantName: REACT_APP_AUTHORIZE_NET_MERCHANT_NAME,
      transactionKey: REACT_APP_AUTHORIZE_NET_TRANSACTION_KEY,
    },
    nellisAuctionApi: {
      hostname: REACT_APP_NELLIS_HR_HOSTNAME,
    },
    auctionConcoction: {
      hostname: REACT_APP_AUCTION_CONCOCTION_HOSTNAME,
    },
    auctionMethodApi: {
      xApiKey: REACT_APP_AUCTION_METHOD_API_KEY,
      base: REACT_APP_AUCTION_METHOD_API_BASE,
      hostname: REACT_APP_AUCTION_METHOD_API_HOSTNAME,
      auctionId: REACT_APP_AUCTION_METHOD_API_AUCTION_ID,
    },
    cargo: {
      url: REACT_APP_CARGO_URL,
    },
    cargoApi: {
      // @TODO dont forget to change back
      hostname: REACT_APP_CAROGAPI_HOSTNAME,
    },
  },
};

export { REACT_APP_VERSION };

export default variables[process.env.NODE_ENV];
