import React, { Component } from 'react';
import {
  Button,
  Divider,
  Form,
  Header,
  Segment,
  TextArea,
  Comment,
  Label,
  Icon,
  Popup,
  Placeholder,
} from 'semantic-ui-react';
import { isEmpty } from 'lodash/fp';
import PropTypes from 'prop-types';
import API from './api';
import RecordUpdater from './record-updater';
import InputAuthorization from './input-authorization';
import TransactionCheckButton from './transaction-check-button';
import CargoApi from '../shared-api-adapters/cargo-api';
import AuctionConcoction from '../shared-api-adapters/auction-concoction';

class CustomerCard extends Component {
  constructor(props) {
    super(props);

    this.cargoApi = new CargoApi();
    this.auctionConcoctionApi = new AuctionConcoction();

    this.state = {
      notes: props.customer.notes || [],
      note: '',
      customerTransactionDetail: {},
      customerProfileId: null,
    };

    this.notesEnd = React.createRef();
  }

  getAuctionMethodInfo = async () => {
    const { customer } = this.props;
    const res = await this.cargoApi.get(
      this.cargoApi.routes.accountsForReviewByCustomerId,
      Number(customer.id)
    );

    const [{ auth_net_id: authNetId } = {}] = await this.auctionConcoctionApi.get(
      this.auctionConcoctionApi.routes.customerProfile,
      Number(customer.id)
    );

    const data = res[0] || {};

    this.setState({ customerTransactionDetail: data, customerProfileId: authNetId });
  };

  renderInformationLabel = (label, description) => (
    <div>
      <strong>{label}:</strong> {description}
    </div>
  );

  handleNoteAdd = () => {
    const { tags } = this.props;

    this.setState(prevState => ({
      notes: [
        ...prevState.notes,
        { note: prevState.note, date: new Date().toLocaleString(), author: this.props.user, tags },
      ],
      note: '',
    }));
  };

  handleChange = (event, { name, value }) => {
    this.setState({ [name]: value });
  };

  scrollToBottomOfNotes = () => {
    this.notesEnd.current.scrollIntoView();
  };

  componentDidUpdate(prevProps, prevState) {
    if (prevState.notes.length !== this.state.notes.length) {
      this.getAuctionMethodInfo();
      this.scrollToBottomOfNotes();
    }
  }

  componentDidMount() {
    this.getAuctionMethodInfo();
    this.scrollToBottomOfNotes();
  }

  handleKeyEnter = event => {
    if (event.key !== 'Enter') return;

    const {
      props: {
        amCrud: { put },
        customer,
        user,
        tags,
      },
      state: { note },
      handleNoteAdd,
    } = this;

    event.preventDefault();

    // @TODO handle errors
    put({
      id: customer.id,
      note,
      date: new Date().toLocaleString(),
      author: user.id,
      tags,
    });

    handleNoteAdd();
  };

  handleNoteAddClick = () => {
    const {
      props: {
        customer,
        user,
        amCrud: { put },
        tags,
      },
      state: { note },
      handleNoteAdd,
    } = this;

    // @TODO handle errors
    put({
      id: customer.id,
      note,
      date: new Date().toLocaleString(),
      author: user.id,
      tags,
    });

    handleNoteAdd();
  };

  render() {
    const {
      props: {
        refreshPage,
        user,
        customer: {
          address,
          approved_to_bid: approvedToBid,
          banned,
          city,
          created,
          id,
          firstname,
          lastname,
          phone,
          zip,
          company_name: companyName,
        },
        customer,
        tags,
        onTagClick,
        clearTags,
      },
      state: { note, notes, customerTransactionDetail, customerProfileId },
      handleChange,
      renderInformationLabel,
      handleKeyEnter,
      handleNoteAddClick,
    } = this;

    return (
      <Segment>
        <Header as={'h3'}>
          <Icon name={'user'} />
          <Header.Content>
            {firstname} {lastname}
            <Header.Subheader>{id}</Header.Subheader>
          </Header.Content>
        </Header>
        <div className={'lh-1-75'}>
          <>
            {renderInformationLabel('Phone Number', phone)}
            {renderInformationLabel(
              'Address',
              `${address}, ${city}, ${customer['state/province']}, ${zip}`
            )}
            {renderInformationLabel('Member since', created.slice(0, 10))}
            {renderInformationLabel('Approved to Bid', Number(approvedToBid) ? 'Yes' : 'No')}
            {renderInformationLabel('Banned', Number(banned) ? 'Yes' : 'No')}
            {renderInformationLabel('Company Name', companyName)}
            <API.Query queries={[{ route: 'storeCreditByCustomer', id }]}>
              {({ data: [s] }) => {
                const storeCredit = s
                  .reduce((acc, value) => {
                    return acc + value.amount;
                  }, 0)
                  .toFixed(2);

                return [1, 2].includes(user.roleId) ? (
                  <Popup
                    content={
                      <InputAuthorization password={'10'}>
                        <RecordUpdater
                          fields={['amount', 'notes']}
                          additionalSubmissionValues={{
                            customerId: customer.id,
                            userId: user.id,
                          }}
                          route={'storeCredit'}
                          onSuccessfulSubmission={refreshPage}
                        />
                      </InputAuthorization>
                    }
                    trigger={renderInformationLabel('Store Credit', `$${storeCredit}`)}
                    on={'click'}
                  />
                ) : (
                  renderInformationLabel('Store Credit', `$${storeCredit}`)
                );
              }}
            </API.Query>
            <TransactionCheckButton
              customerId={customerTransactionDetail.customerId || Number(id)}
              customerProfileId={customerTransactionDetail.authNetId || customerProfileId}
              accountForReviewId={customerTransactionDetail.id}
              refreshPage={refreshPage}
            />
          </>

          <Divider />
          <strong>Notes: </strong>
          <div className={'mh-30r ofy-auto'}>
            <Comment.Group className={'p-2'}>
              {notes.map((n, key) => (
                <Comment key={key}>
                  <Comment.Author className={'light-blue'}>
                    {`${n.author.firstName} ${n.author.lastName}`}
                  </Comment.Author>
                  <Comment.Metadata>{n.date}</Comment.Metadata>
                  <Comment.Text className={'flex fd-column'}>
                    {!isEmpty(n.tags) && (
                      <div className={'flex fd-row '}>
                        <Label
                          as={Button}
                          size={'mini'}
                          onClick={event => onTagClick(event, { value: n.tags.invoiceId })}
                        >
                          Inv:
                          <Label.Detail>{n.tags.invoiceId}</Label.Detail>
                        </Label>
                        <Label
                          as={Button}
                          size={'mini'}
                          onClick={event => onTagClick(event, { value: n.tags.lotNumber })}
                        >
                          Lot:
                          <Label.Detail>{n.tags.lotNumber}</Label.Detail>
                        </Label>
                      </div>
                    )}
                    {n.note}
                  </Comment.Text>
                </Comment>
              ))}
            </Comment.Group>
            <div ref={this.notesEnd} />
          </div>
          <div className={'flex fd-row jc-between border-dashed round my-1 p-1'}>
            <strong>Tags:</strong>
            {!isEmpty(tags) && (
              <div>
                <Label
                  as={Button}
                  size={'tiny'}
                  className={'shadow'}
                  onClick={event => onTagClick(event, { value: tags.invoiceId })}
                >
                  INV:
                  <Label.Detail>{tags.invoiceId}</Label.Detail>
                </Label>
                <Label size={'tiny'} className={'shadow'}>
                  LOT:
                  <Label.Detail>{tags.lotNumber}</Label.Detail>
                </Label>
              </div>
            )}
            <Icon name={'close'} link onClick={clearTags} />
          </div>
          <Form>
            <TextArea
              placeholder={'Customer notes...'}
              name={'note'}
              onChange={handleChange}
              value={note}
              onKeyPress={handleKeyEnter}
            />
            <Button content={'Add Note'} className={'mt-2'} onClick={handleNoteAddClick} />
          </Form>
        </div>
      </Segment>
    );
  }
}

CustomerCard.propTypes = {
  customer: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
  amCrud: PropTypes.object.isRequired,
  onTagClick: PropTypes.func.isRequired,
  clearTags: PropTypes.func.isRequired,
  tags: PropTypes.object,
  refreshPage: PropTypes.func.isRequired,
};

// eslint-disable-next-line react/display-name
CustomerCard.Placeholder = () => (
  <Segment>
    <Placeholder>
      <Placeholder.Header>
        <Placeholder.Line />
      </Placeholder.Header>
      <Placeholder.Paragraph>
        <Placeholder.Line />
        <Placeholder.Line />
        <Placeholder.Line />
      </Placeholder.Paragraph>
      <Placeholder.Paragraph>
        <Placeholder.Line />
        <Placeholder.Line />
        <Placeholder.Line />
      </Placeholder.Paragraph>
    </Placeholder>
  </Segment>
);

export default CustomerCard;
