import React, { Fragment } from 'react';
import { Button, Sidebar } from 'semantic-ui-react';
import Header from './layout/header';
import Main from './layout/main';
import Toggle from '../shared-components/toggle';
import { Link, Route, Switch } from 'react-router-dom';
import UsersTable from './users-table';
import UserCreate from './user-create';
import Registration from './registration';
import LoginPage from './login';
import LoginStatus from './login-status';
import PasswordForgetPage from './password-forget';
import AccountPage from './account';
import Navigation from './navigation';
import withAuthentication from './with-authentication';
import * as routes from '../constants/routes';
import API from './api';
import Firewall from './firewall';
import Home from './home';
import * as access from '../constants/access-levels';
import logo from '../images/NellisAuction-white-trans.png';
import Admin from './admin';
import CustomerPage from './customer-page';
import DailySnapshot from './daily-snapshot';
import QuickCirc from './quick-circ';
import BatchMove from './batch-move';
import BatchStoreCredit from './batch-store-credit';
import PickupQueue from './pickup-queue';
import { CurbSideAuth } from './curbside-city-auth';
import LotDetails from './lot-details';
import LotDetailsSettings from './lot-details-settings';
import LotQueue from './lot-queue';
import FraudDashboard from './fraud-dashboard';
import { REACT_APP_VERSION } from '../config';
import EmployeePasswordReset from './employee-reset-password';

const MainApp = () => {
  return (
    <Fragment>
      <Sidebar.Pushable className={'l-0 b-0 r-0 fixed'}>
        <Toggle>
          {({ active, toggle, setInactive }) => (
            <Fragment>
              <Sidebar
                animation="overlay"
                icon="labeled"
                onHide={setInactive}
                visible={active}
                className={'bg-clear'}
              >
                <Navigation onNavClick={setInactive} />
              </Sidebar>
              <Sidebar.Pusher>
                <Header>
                  <div className={'flex ai-center'}>
                    <Button icon={'bars'} className={'mx-3'} onClick={toggle} />
                    <Link to={'/'}>
                      <img src={logo} alt="Nellis Auction" width={'90'} height={'50'} />
                    </Link>
                    <small className={'c-white fs-1-25 ml-2'}>v{REACT_APP_VERSION}</small>
                  </div>
                  <div className={'flex-wr inline-flex pl-2'}>
                    <LoginStatus />
                  </div>
                </Header>
                <Main>
                  <Route exact path={routes.REGISTRATION} component={Registration} />
                  <Route path={routes.LOGIN} component={LoginPage} />
                  <Route
                    exact
                    path={routes.EMPLOYEE_RESET_PASSWORD}
                    component={EmployeePasswordReset}
                  />
                  <Route exact path={routes.PASSWORD_FORGET} component={PasswordForgetPage} />
                  <Route exact path={routes.ACCOUNT} component={AccountPage} />
                  <Route exact path={routes.HOME} component={Home} />
                  <Route exact path={routes.ADMIN} component={Admin} />
                  <Route exact path={routes.QUICK_CIRC} component={QuickCirc} />
                  <Route
                    exact
                    path={routes.BATCH_STORE_CREDIT}
                    render={props => (
                      <Firewall
                        history={props.history}
                        accessLevel={[...access.SUPERVISOR, ...access.AUTHENTICATED_USER]}
                        render={({ authUser }) => {
                          return <BatchStoreCredit authUser={authUser} />;
                        }}
                      />
                    )}
                  />
                  <Route
                    exact
                    path={routes.STATS_DASHBOARD}
                    render={props => (
                      <Firewall
                        history={props.history}
                        accessLevel={[...access.SUPERVISOR, ...access.AUTHENTICATED_USER]}
                        render={() => <DailySnapshot />}
                      />
                    )}
                  />
                  <Route
                    exact
                    path={routes.FRAUD_DASHBOARD}
                    render={props => (
                      <Firewall
                        history={props.history}
                        accessLevel={[...access.SUPERVISOR]}
                        render={() => <FraudDashboard />}
                      />
                    )}
                  />
                  <Route
                    exact
                    path={routes.LOT_DETAILS}
                    render={props => (
                      <Firewall
                        checkApiKey={true}
                        history={props.history}
                        accessLevel={[...access.SUPERVISOR, ...access.AUTHENTICATED_USER]}
                        render={() => {
                          return <LotDetails />;
                        }}
                      />
                    )}
                  />
                  <Route
                    exact
                    path={routes.LOT_DETAILS_SETTINGS}
                    component={LotDetailsSettings}
                    render={props => (
                      <Firewall
                        history={props.history}
                        accessLevel={[...access.SUPERVISOR, ...access.AUTHENTICATED_USER]}
                        render={() => {
                          return <LotDetailsSettings />;
                        }}
                      />
                    )}
                  />
                  <Route
                    exact
                    path={routes.LOT_QUEUE}
                    render={props => (
                      <Firewall
                        checkApiKey={true}
                        history={props.history}
                        accessLevel={[...access.SUPERVISOR, ...access.AUTHENTICATED_USER]}
                        render={() => {
                          return <LotQueue />;
                        }}
                      />
                    )}
                  />
                  <Route
                    exact
                    path={routes.BATCH_MOVE}
                    render={props => (
                      <Firewall
                        history={props.history}
                        accessLevel={access.SUPERVISOR}
                        render={({ authUser }) => {
                          return <BatchMove user={authUser} match={props.match} />;
                        }}
                      />
                    )}
                  />
                  <Route
                    exact
                    path={routes.PICKUP_QUEUE}
                    render={props => (
                      <Firewall
                        checkApiKey={true}
                        history={props.history}
                        accessLevel={[...access.SUPERVISOR, ...access.AUTHENTICATED_USER]}
                        render={() => <PickupQueue />}
                      />
                    )}
                  />
                  <Route
                    exact
                    path={`${routes.CUSTOMER}/:id`}
                    render={props => (
                      <Firewall
                        checkApiKey={true}
                        history={props.history}
                        accessLevel={[...access.SUPERVISOR, ...access.AUTHENTICATED_USER]}
                        render={({ authUser }) => {
                          return <CustomerPage user={authUser} match={props.match} />;
                        }}
                      />
                    )}
                  />
                  <Route
                    path={`${routes.TEAM}/:id`}
                    exact
                    render={props => (
                      <Firewall
                        history={props.history}
                        accessLevel={access.SUPERVISOR}
                        render={({ api, history }) => (
                          <API.Query queries={[{ route: 'users' }, { route: 'locations' }]}>
                            {({ withLocations, data: [users, locations] }) => {
                              const team = users.filter(
                                user =>
                                  user.supervisorId === Number(props.match.params.id) &&
                                  user.statusTypeId === 1
                              );

                              const supervisor =
                                users.length > 0
                                  ? users.find(user => user.id === Number(props.match.params.id))
                                  : {};

                              const supervisorName = `${supervisor.firstName} ${supervisor.lastName}`;

                              if (team.length > 0) {
                                return (
                                  <UsersTable
                                    api={api}
                                    defaultFilter={'all'}
                                    history={history}
                                    filters={false}
                                    supervisorIds={[]}
                                    users={team.map(withLocations(locations))}
                                    title={`${supervisorName}'s team`}
                                  />
                                );
                              }
                              return (
                                <div>
                                  There are no people assigned to {`${supervisorName}'s`} team
                                </div>
                              );
                            }}
                          </API.Query>
                        )}
                      />
                    )}
                  />
                  <Route
                    path={routes.USERS}
                    exact
                    render={props => (
                      <Firewall
                        history={props.history}
                        accessLevel={access.SUPERVISOR}
                        render={({ api, history }) => (
                          <API.Query
                            queries={[
                              { route: 'users' },
                              { route: 'locations' },
                              { route: 'supervisors' },
                              { route: 'inventorySupervisors' },
                            ]}
                          >
                            {({
                              withLocations,
                              data: [users, locations, supervisors, inventorySupervisors],
                            }) => (
                              <UsersTable
                                supervisorIds={[...supervisors, ...inventorySupervisors].map(
                                  supe => supe.id
                                )}
                                api={api}
                                history={history}
                                users={users.map(withLocations(locations))}
                              />
                            )}
                          </API.Query>
                        )}
                      />
                    )}
                  />
                  <Switch>
                    <Route
                      path={`/users/create`}
                      render={props => (
                        <Firewall
                          history={props.history}
                          accessLevel={access.SUPERVISOR}
                          render={() => (
                            <API.Query queries={[{ route: 'users' }]}>
                              {({ data: [users] }) => (
                                <API.Crud route={'users'}>
                                  {crud => (
                                    <UserCreate
                                      {...props}
                                      emails={users.map(user => user.email)}
                                      onSubmit={crud.post}
                                      successfulSubmit={crud.success}
                                      messageComponent={crud.renderMessage}
                                      readOnly={false}
                                      showPayRate={true}
                                    />
                                  )}
                                </API.Crud>
                              )}
                            </API.Query>
                          )}
                        />
                      )}
                    />
                    <Route
                      path={`/users/:id`}
                      render={props => (
                        <Firewall
                          accessLevel={access.SUPERVISOR}
                          render={({ authUser }) => (
                            <API.Query
                              queries={[
                                { route: 'users' },
                                { route: 'locations' },
                                { route: 'usersToApplications' },
                                { route: 'usersToRoles' },
                              ]}
                              id={props.match.params.id}
                            >
                              {({
                                data: [users, locations, applications, role],
                                withApplications,
                                withRole,
                                withLocations,
                              }) => (
                                <API.Crud route={'users'}>
                                  {crud => (
                                    <UserCreate
                                      {...props}
                                      messageComponent={crud.renderMessage}
                                      onSubmit={crud.put}
                                      successfulSubmit={crud.success}
                                      emails={users.map(user => user.email)}
                                      user={withApplications(applications)(
                                        withRole(role)(
                                          withLocations(locations)(
                                            users.find(
                                              user => user.id === Number(props.match.params.id)
                                            )
                                          )
                                        )
                                      )}
                                      readOnly={authUser && authUser.roleId === 3}
                                      showPayRate={authUser && authUser.roleId === 1}
                                    />
                                  )}
                                </API.Crud>
                              )}
                            </API.Query>
                          )}
                          history={props.history}
                        />
                      )}
                    />
                  </Switch>
                </Main>
              </Sidebar.Pusher>
            </Fragment>
          )}
        </Toggle>
      </Sidebar.Pushable>
    </Fragment>
  );
};

const App = () => {
  return (
    <Switch>
      <Route exact path={'/pickup'} component={CurbSideAuth} />
      <Route path={'/'} component={MainApp} />
    </Switch>
  );
};

export default withAuthentication(App);
