import React from 'react';
import PropTypes from 'prop-types';

class MultiToggle extends React.Component {
  state = {
    indexes: this.props.initialIndexes || [],
    domain: this.props.domain,
  };

  toggle = index =>
    this.setState(prevState =>
      prevState.indexes.includes(index)
        ? { indexes: prevState.indexes.filter(ix => ix !== index) }
        : { indexes: [...prevState.indexes, index] }
    );

  addAll = indexes => {
    this.setState({ indexes });
  };

  removeAll = () => {
    this.setState({ indexes: [] });
  };

  static getDerivedStateFromProps(nextProps, prevState) {
    if (JSON.stringify(nextProps.domain) !== JSON.stringify(prevState.domain)) {
      return {
        domain: nextProps.domain,
        indexes: [],
      };
    }
    return null;
  }

  render() {
    const {
      toggle,
      addAll,
      removeAll,
      state: { indexes },
      props: { className, children },
    } = this;

    return (
      <div className={className || ''}>{children({ indexes, toggle, addAll, removeAll })}</div>
    );
  }
}

MultiToggle.propTypes = {
  children: PropTypes.func,
  className: PropTypes.string,
  domain: PropTypes.any,
  initialIndexes: PropTypes.array,
};

export default MultiToggle;
