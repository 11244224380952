import React, { Component } from 'react';
import { Form, Message } from 'semantic-ui-react';
import AuctionMethodApi from '../shared-api-adapters/auction-method-api';
import withAuthorization from './with-authorization';
import PropTypes from 'prop-types';

class AuctionMethodAuth extends Component {
  constructor(props) {
    super(props);

    this.state = {
      username: '',
      password: '',
      authError: false,
      saveError: false,
      inputError: false,
      success: false,
      loading: false,
    };

    this.amApi = new AuctionMethodApi();
  }

  handleAuthRequest = async () => {
    const {
      props: { authUser, api, newUserId },
      state: { username, password },
    } = this;

    if (!username || !password) {
      this.setState({
        inputError: true,
      });
      return setTimeout(() => {
        this.setState({
          inputError: false,
        });
      }, 5000);
    }

    this.setState({
      authError: false,
      saveError: false,
      success: false,
      loading: true,
    });

    let auth;
    let result;

    try {
      auth = await this.amApi.auth(username, password);

      const {
        data: {
          data: { api_key: apiKey },
        },
      } = auth;

      result = await api.put(`${api.routes.users}/${newUserId}`, {
        apiKey,
        roleId: authUser.roleId,
        statusTypeId: authUser.statusTypeId,
        applicationIds: authUser.applicationIds,
      });
    } catch (error) {
      if (!auth) {
        return this.setState({
          authError: true,
          loading: false,
        });
      }
      if (!result) {
        return this.setState({
          saveError: true,
          loading: false,
        });
      }
    }

    setTimeout(() => {
      // eslint-disable-next-line no-restricted-globals
      location.reload();
    }, 5000);

    return this.setState({
      success: true,
      loading: false,
    });
  };

  render() {
    const { username, password, authError, saveError, success, loading, inputError } = this.state;

    return (
      <>
        <Message>
          <Message.Header>Please verify your Auction Method credentials!</Message.Header>
          <Message.Content>
            This is the main web apps credentials, not our internal apps credentials.
          </Message.Content>
        </Message>
        <Form>
          <Form.Input
            label={'Username'}
            name={'username'}
            value={username}
            error={!username && inputError}
            onChange={(event, { name, value }) => this.setState({ [name]: value })}
          />
          <Form.Input
            label={'Password'}
            type={'password'}
            name={'password'}
            value={password}
            error={!password && inputError}
            onChange={(event, { name, value }) => this.setState({ [name]: value })}
          />
          <Form.Button
            type={'button'}
            content={'Submit'}
            loading={loading}
            onClick={this.handleAuthRequest}
          />
          {authError && (
            <Message
              header={'You are not authorized!'}
              content={'Retry or contact Mike Pavese for help!'}
              color={'red'}
            />
          )}
          {saveError && (
            <Message
              header={'YOUR AUTHORIZATION KEY WAS NOT SAVED!'}
              content={'Retry or contact Mike Pavese for help!'}
              color={'red'}
            />
          )}
          {success && (
            <Message
              header={'YOUR AUTHORIZATION KEY WAS SAVED!'}
              content={'The page will refresh shortly.'}
              color={'green'}
            />
          )}
        </Form>
      </>
    );
  }
}

AuctionMethodAuth.propTypes = {
  api: PropTypes.any.isRequired,
  authUser: PropTypes.object.isRequired,
  override: PropTypes.bool,
  newUserId: PropTypes.any.isRequired,
};

export default withAuthorization(AuctionMethodAuth);
