import React, { Component } from 'react';
import {
  Button,
  Form,
  Grid,
  Icon,
  Image,
  Input,
  Label,
  Message,
  Modal,
  Responsive,
  Divider,
} from 'semantic-ui-react';
import moment from 'moment';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import API from './api';
import config from '../config';
import Toggle from '../shared-components/toggle';
import ItemReport from './item-report';
import withAuthorization from './with-authorization';
import { SUCCESS, FAILED } from '../constants/notifications';
import { NotificationContext } from './notification-context';
import * as requests from '../helpers/batch-relist';
import { canRefund } from '../helpers/customer-utils';
import CargoApi from '../shared-api-adapters/cargo-api';
import CrudButton from './crud-button';
import InputAuthorization from './input-authorization';

const PopupLabel = ({ title, iconName, output, labelColor = null }) => (
  <div className={'flex fd-row jc-between ai-center mb-1'}>
    <div className={'mr-3'}>{title}:</div>
    <Label color={labelColor}>
      <Icon name={iconName} />
      {output}
    </Label>
  </div>
);

PopupLabel.propTypes = {
  title: PropTypes.string.isRequired,
  iconName: PropTypes.string.isRequired,
  output: PropTypes.any.isRequired,
  labelColor: PropTypes.string,
};

class LineItem extends Component {
  state = {
    returnPopup: false,
    component: '',
    relistModalOpen: false,
  };

  resolveItemStatusOutput = () => {
    const { item } = this.props;

    switch (item.item_status.id) {
      case 0:
        return (
          <>
            <span>Pick up by: </span>
            <span>
              {moment()
                .add(item.timeRemaining, 'h')
                .format('MM/DD/YY')}
            </span>
          </>
        );
      case 1:
        return (
          <>
            <span>Picked Up: </span>
            <span>{item.picked_up_date.slice(0, -3) || 'Date Not Known'}</span>
          </>
        );
      default:
        return item.item_status.description;
    }
  };

  handleChange = (event, { name, value }) => this.setState({ [name]: value });

  render() {
    const {
      props: {
        item,
        onClick,
        onTagAdditionsClick,
        showItemTimerButton,
        auctionMethodApi,
        customer,
        user,
        refreshPage,
        addNotifications,
      },
      resolveItemStatusOutput,
    } = this;

    return (
      <>
        <Responsive minWidth={'992'}>
          <Grid className={'px-3 my-1 ml-1'} divided>
            <Grid.Row>
              <Grid.Column width={2}>
                <Image src={item.thumb_url} size={'tiny'} />
              </Grid.Column>
              <Grid.Column width={8}>
                <Modal
                  trigger={
                    <Button basic>
                      <Icon name={'barcode'} />
                      {item.lot_number || item.internal_id}
                    </Button>
                  }
                  content={
                    <ItemReport
                      inventoryNumber={item.internal_id}
                      naItemUrl={`${config.auctionMethodApi.base}/auction/${item.auction_id}/item/${item.item_id}`}
                    />
                  }
                  size={'small'}
                />
                <div className={'mt-2'}>{item.description}</div>
                <div className={'mt-2 mb-1'}>
                  <Label color={'blue'}>
                    Final Bid: <Label.Detail>${item.final_bid}</Label.Detail>
                  </Label>
                  <Label color={'teal'}>
                    Item Total: <Label.Detail>${item.item_total}</Label.Detail>
                  </Label>
                </div>
                <div>
                  <Label color={'black'}>
                    <Icon name={'server'} />
                    Rack Location:<Label.Detail>{item.location_id || 'Unknown'}</Label.Detail>
                  </Label>
                </div>
              </Grid.Column>
              <Grid.Column width={6} verticalAlign={'middle'}>
                <div className={'my-1'}>
                  <Message color={'brown'} className={'t-center'}>
                    {resolveItemStatusOutput()}
                  </Message>
                </div>
                <Button.Group fluid>
                  {showItemTimerButton && (
                    <Button compact size={'mini'} onClick={onClick} icon title={'Timer extension'}>
                      <Icon name={'plus'} />
                    </Button>
                  )}
                  {canRefund(item) && (
                    <Modal
                      size={'mini'}
                      trigger={
                        <Button compact size={'mini'} icon title={'Discount'}>
                          <Icon name={'dollar'} />
                        </Button>
                      }
                      on={'click'}
                    >
                      <Modal.Header>Discount - {item.internal_id}</Modal.Header>
                      <Modal.Content>
                        <InputAuthorization password={'10'}>
                          <SimpleForm
                            api={auctionMethodApi}
                            initialValues={{ amount: 0, payment_mode: 'Store Credit' }}
                          >
                            {({
                              batch,
                              addNotifications,
                              state: formState,
                              handleChange,
                              loading,
                            }) => {
                              const paymentModes = Object.keys(
                                item.payments.reduce(
                                  (acc, payment) => ({
                                    ...acc,
                                    [payment.payment_type]: 0,
                                  }),
                                  { 'Store Credit': 0 }
                                )
                              );

                              return (
                                <API.Crud route={'storeCredit'}>
                                  {({ post: addStoreCredit, loading: scLoading }) => {
                                    return (
                                      <Toggle active={'amount'}>
                                        {({ active, set }) => {
                                          return (
                                            <Form
                                              onSubmit={async () => {
                                                const amount =
                                                  active === 'percent'
                                                    ? item.final_bid *
                                                      (Number(formState.amount) / 100)
                                                    : formState.amount;

                                                const results = await batch([
                                                  requests.invoiceRefund(formState.payment_mode)(
                                                    item.internal_id
                                                  )(amount)(item.invoice_id)('Discount'),
                                                ]);

                                                if (!results) {
                                                  addNotifications([
                                                    {
                                                      ...FAILED,
                                                      content: `Failed to add discount for item ${item.internal_id}`,
                                                    },
                                                  ]);

                                                  return;
                                                }

                                                const [result] = results;

                                                addNotifications([
                                                  {
                                                    ...SUCCESS,
                                                    content: `Successfully added refund of ${result.amount} for item ${item.internal_id}`,
                                                  },
                                                ]);

                                                if (formState.payment_mode === 'Cash') {
                                                  addNotifications([
                                                    {
                                                      header: 'Change Due!',
                                                      content: `You owe the customer $${amount} in change!`,
                                                    },
                                                  ]);
                                                }

                                                if (formState.payment_mode !== 'Store Credit') {
                                                  refreshPage();
                                                  return;
                                                }

                                                const scResult = await addStoreCredit({
                                                  customerId: customer.id,
                                                  userId: user.id,
                                                  // @todo probs gonna change
                                                  notes: `Discount on invoice ${item.invoice_id}`,
                                                  amount,
                                                });

                                                if (!scResult) {
                                                  addNotifications([
                                                    {
                                                      ...FAILED,
                                                      content: `Failed to add ${result.amount} to store credit, please contact your manager!`,
                                                    },
                                                  ]);

                                                  return;
                                                }

                                                addNotifications([
                                                  {
                                                    ...SUCCESS,
                                                    content: `Successfully added ${result.amount} to store credit!`,
                                                  },
                                                ]);

                                                refreshPage();
                                              }}
                                            >
                                              <div className={'flex fd-column'}>
                                                <div className={'mb-1'}>
                                                  <Label
                                                    as={Button}
                                                    active={active === 'amount'}
                                                    type={'button'}
                                                    content={'Amount'}
                                                    size={'tiny'}
                                                    onClick={() => set('amount')}
                                                  />
                                                  <Label
                                                    as={Button}
                                                    active={active === 'percent'}
                                                    type={'button'}
                                                    content={'Percent'}
                                                    size={'tiny'}
                                                    onClick={() => set('percent')}
                                                  />
                                                </div>
                                                <Input
                                                  name={'amount'}
                                                  value={formState.amount}
                                                  className={'mb-2'}
                                                  onChange={handleChange}
                                                  icon={active === 'percent' ? 'percent' : 'dollar'}
                                                  iconPosition={'left'}
                                                />
                                                <Button.Group vertical className={'mb-2'}>
                                                  {paymentModes.map((paymentMode, key) => (
                                                    <Button
                                                      key={key}
                                                      color={'green'}
                                                      type={'button'}
                                                      active={
                                                        formState.payment_mode === paymentMode
                                                      }
                                                      content={paymentMode}
                                                      style={{
                                                        ...(formState.payment_mode === paymentMode
                                                          ? {
                                                              boxShadow: '0 0 3px 3px green',
                                                            }
                                                          : {}),
                                                        ...{
                                                          opacity: [1, 0.75, 0.65, 0.5][key],
                                                        },
                                                      }}
                                                      onClick={e =>
                                                        handleChange(e, {
                                                          name: 'payment_mode',
                                                          // @TODO calculate proper percent
                                                          value: paymentMode,
                                                        })
                                                      }
                                                    />
                                                  ))}
                                                </Button.Group>
                                                <Button
                                                  content={'Submit'}
                                                  loading={loading || scLoading}
                                                />
                                              </div>
                                            </Form>
                                          );
                                        }}
                                      </Toggle>
                                    );
                                  }}
                                </API.Crud>
                              );
                            }}
                          </SimpleForm>
                        </InputAuthorization>
                      </Modal.Content>
                    </Modal>
                  )}
                  <Button
                    compact
                    size={'mini'}
                    icon
                    onClick={onTagAdditionsClick}
                    title={'Notes for item'}
                  >
                    <Icon name={'comment'} />
                  </Button>
                  {canRefund(item) && (
                    <Toggle active={false}>
                      {({ active, toggle }) => {
                        return (
                          <Modal
                            open={active}
                            onClose={toggle}
                            size={'mini'}
                            trigger={
                              <Button
                                compact
                                icon
                                size={'mini'}
                                title={'Lost item'}
                                onClick={toggle}
                              >
                                <Icon name={'blind'} />
                              </Button>
                            }
                          >
                            <Modal.Header>Wait!</Modal.Header>
                            <API.Query
                              api={new CargoApi()}
                              queries={[{ route: 'itemByInventoryNumber', id: item.internal_id }]}
                              control={true}
                            >
                              {({ data: [[i]], loading }) => {
                                if (!i)
                                  return (
                                    <Modal.Content>
                                      <Message
                                        color={'red'}
                                        content={
                                          'This item does not exist in Cargo! Please contact Mike Pavese!'
                                        }
                                      />
                                    </Modal.Content>
                                  );
                                return (
                                  <>
                                    <Modal.Content>
                                      Are you sure you want to list this item as lost?
                                    </Modal.Content>
                                    <Modal.Actions>
                                      <Button content={'Cancel'} onClick={toggle} />
                                      <CrudButton
                                        secondary
                                        api={new CargoApi()}
                                        className={'mb-1'}
                                        route={'lost'}
                                        submissionData={{ itemId: i.id }}
                                        content={'Yes, this item is lost!'}
                                        onSuccessfulSubmit={async () => {
                                          addNotifications([
                                            {
                                              ...SUCCESS,
                                              content: `Successfully added item to lost`,
                                            },
                                          ]);

                                          const results = await requests.itemStatus(3)(item);

                                          if (results) {
                                            addNotifications([
                                              {
                                                ...SUCCESS,
                                                content: `Successfully updated item status of ${item.internal_id} to lost!`,
                                              },
                                            ]);
                                          }

                                          if (!results) {
                                            addNotifications([
                                              {
                                                ...FAILED,
                                                content: `Failed to update the item status of ${item.internal_id} to lost!`,
                                              },
                                            ]);
                                          }

                                          refreshPage();
                                        }}
                                        onUnsuccessfulSubmit={() => {
                                          addNotifications([
                                            { ...FAILED, content: `Failed to add item to lost` },
                                          ]);
                                          toggle();
                                        }}
                                      />
                                    </Modal.Actions>
                                  </>
                                );
                              }}
                            </API.Query>
                          </Modal>
                        );
                      }}
                    </Toggle>
                  )}
                  {canRefund(item) && (
                    <Toggle active={false}>
                      {({ active, toggle }) => {
                        return (
                          <Modal
                            open={active}
                            onClose={toggle}
                            size={'mini'}
                            trigger={
                              <Button
                                compact
                                icon
                                size={'mini'}
                                title={'Trash item'}
                                onClick={toggle}
                              >
                                <Icon name={'trash'} />
                              </Button>
                            }
                          >
                            <Modal.Header>Wait!</Modal.Header>
                            <API.Query
                              api={new CargoApi()}
                              queries={[{ route: 'itemByInventoryNumber', id: item.internal_id }]}
                              control={true}
                            >
                              {({ data: [[i]] }) => {
                                if (!i)
                                  return (
                                    <Modal.Content>
                                      <Message
                                        color={'red'}
                                        content={
                                          'This item does not exist in Cargo! Please contact Mike Pavese!'
                                        }
                                      />
                                    </Modal.Content>
                                  );
                                return (
                                  <>
                                    <Modal.Content>
                                      Are you sure you want to list this item as trashed?
                                    </Modal.Content>
                                    <Modal.Actions>
                                      <Button content={'Cancel'} onClick={toggle} />
                                      <CrudButton
                                        secondary
                                        api={new CargoApi()}
                                        className={'mb-1'}
                                        route={'trash'}
                                        submissionData={{ itemId: i.id }}
                                        content={'Yes, this item is trash!'}
                                        onSuccessfulSubmit={async () => {
                                          addNotifications([
                                            {
                                              ...SUCCESS,
                                              content: `Successfully added item to trash`,
                                            },
                                          ]);

                                          const results = await requests.itemStatus(3)(item);

                                          if (results) {
                                            addNotifications([
                                              {
                                                ...SUCCESS,
                                                content: `Successfully updated item status of ${item.internal_id} to trash!`,
                                              },
                                            ]);
                                          }

                                          if (!results) {
                                            addNotifications([
                                              {
                                                ...FAILED,
                                                content: `Failed to update the item status of ${item.internal_id} to trash!`,
                                              },
                                            ]);
                                          }

                                          refreshPage();
                                        }}
                                        onUnsuccessfulSubmit={() => {
                                          addNotifications([
                                            { ...FAILED, content: `Failed to add item to trash` },
                                          ]);
                                          toggle();
                                        }}
                                      />
                                    </Modal.Actions>
                                  </>
                                );
                              }}
                            </API.Query>
                          </Modal>
                        );
                      }}
                    </Toggle>
                  )}
                </Button.Group>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Responsive>
        <Responsive maxWidth={'991'}>
          <Grid relaxed stackable>
            <Grid.Row className={'ml-3'}>
              <Grid.Column width={2}>
                <Image src={item.thumb_url} size={'tiny'} />
              </Grid.Column>
              <Grid.Column width={8}>
                <Modal
                  trigger={
                    <Button basic>
                      <Icon name={'barcode'} />
                      {item.lot_number || item.internal_id}
                    </Button>
                  }
                  content={
                    <ItemReport
                      inventoryNumber={item.internal_id}
                      naItemUrl={`${config.auctionMethodApi.base}/auction/${item.auction_id}/item/${item.item_id}`}
                    />
                  }
                  size={'small'}
                />
                <div className={'mt-2'}>{item.description}</div>
                <div className={'mt-2 mb-1'}>
                  <Label color={'blue'}>
                    Final Bid: <Label.Detail>${item.final_bid}</Label.Detail>
                  </Label>
                  <Label color={'teal'}>
                    Item Total: <Label.Detail>${item.item_total}</Label.Detail>
                  </Label>
                </div>
                <div>
                  <Label color={'black'}>
                    <Icon name={'server'} />
                    Rack Location:<Label.Detail>{item.location_id || 'Unknown'}</Label.Detail>
                  </Label>
                </div>
              </Grid.Column>
              <Grid.Column width={6} verticalAlign={'middle'}>
                <div className={'my-1'}>
                  <Message color={'brown'} className={'t-center'}>
                    {resolveItemStatusOutput()}
                  </Message>
                </div>
                <Button.Group fluid>
                  {showItemTimerButton && (
                    <Button compact size={'mini'} onClick={onClick} icon title={'Timer extension'}>
                      <Icon name={'plus'} />
                    </Button>
                  )}
                  {canRefund(item) && (
                    <Modal
                      size={'mini'}
                      trigger={
                        <Button compact size={'mini'} icon title={'Discount'}>
                          <Icon name={'dollar'} />
                        </Button>
                      }
                      on={'click'}
                    >
                      <Modal.Header>Discount - {item.internal_id}</Modal.Header>
                      <Modal.Content>
                        <InputAuthorization password={'10'}>
                          <SimpleForm
                            api={auctionMethodApi}
                            initialValues={{ amount: 0, payment_mode: 'Store Credit' }}
                          >
                            {({
                              batch,
                              addNotifications,
                              state: formState,
                              handleChange,
                              loading,
                            }) => {
                              const paymentModes = Object.keys(
                                item.payments.reduce(
                                  (acc, payment) => ({
                                    ...acc,
                                    [payment.payment_type]: 0,
                                  }),
                                  { 'Store Credit': 0 }
                                )
                              );

                              return (
                                <API.Crud route={'storeCredit'}>
                                  {({ post: addStoreCredit, loading: scLoading }) => {
                                    return (
                                      <Toggle active={'amount'}>
                                        {({ active, set }) => {
                                          return (
                                            <Form
                                              onSubmit={async () => {
                                                const amount =
                                                  active === 'percent'
                                                    ? item.final_bid *
                                                      (Number(formState.amount) / 100)
                                                    : formState.amount;

                                                const results = await batch([
                                                  requests.invoiceRefund(formState.payment_mode)(
                                                    item.internal_id
                                                  )(amount)(item.invoice_id)('Discount'),
                                                ]);

                                                if (!results) {
                                                  addNotifications([
                                                    {
                                                      ...FAILED,
                                                      content: `Failed to add discount for item ${item.internal_id}`,
                                                    },
                                                  ]);

                                                  return;
                                                }

                                                const [result] = results;

                                                addNotifications([
                                                  {
                                                    ...SUCCESS,
                                                    content: `Successfully added refund of ${result.amount} for item ${item.internal_id}`,
                                                  },
                                                ]);

                                                if (formState.payment_mode === 'Cash') {
                                                  addNotifications([
                                                    {
                                                      header: 'Change Due!',
                                                      content: `You owe the customer $${amount} in change!`,
                                                    },
                                                  ]);
                                                }

                                                if (formState.payment_mode !== 'Store Credit') {
                                                  refreshPage();
                                                  return;
                                                }

                                                const scResult = await addStoreCredit({
                                                  customerId: customer.id,
                                                  userId: user.id,
                                                  // @todo probs gonna change
                                                  notes: `Discount on invoice ${item.invoice_id}`,
                                                  amount,
                                                });

                                                if (!scResult) {
                                                  addNotifications([
                                                    {
                                                      ...FAILED,
                                                      content: `Failed to add ${result.amount} to store credit, please contact your manager!`,
                                                    },
                                                  ]);

                                                  return;
                                                }

                                                addNotifications([
                                                  {
                                                    ...SUCCESS,
                                                    content: `Successfully added ${result.amount} to store credit!`,
                                                  },
                                                ]);

                                                refreshPage();
                                              }}
                                            >
                                              <div className={'flex fd-column'}>
                                                <div className={'mb-1'}>
                                                  <Label
                                                    as={Button}
                                                    active={active === 'amount'}
                                                    type={'button'}
                                                    content={'Amount'}
                                                    size={'tiny'}
                                                    onClick={() => set('amount')}
                                                  />
                                                  <Label
                                                    as={Button}
                                                    active={active === 'percent'}
                                                    type={'button'}
                                                    content={'Percent'}
                                                    size={'tiny'}
                                                    onClick={() => set('percent')}
                                                  />
                                                </div>
                                                <Input
                                                  name={'amount'}
                                                  value={formState.amount}
                                                  className={'mb-2'}
                                                  onChange={handleChange}
                                                  icon={active === 'percent' ? 'percent' : 'dollar'}
                                                  iconPosition={'left'}
                                                />
                                                <Button.Group vertical className={'mb-2'}>
                                                  {paymentModes.map((paymentMode, key) => (
                                                    <Button
                                                      key={key}
                                                      color={'green'}
                                                      type={'button'}
                                                      active={
                                                        formState.payment_mode === paymentMode
                                                      }
                                                      content={paymentMode}
                                                      style={{
                                                        ...(formState.payment_mode === paymentMode
                                                          ? {
                                                              boxShadow: '0 0 3px 3px green',
                                                            }
                                                          : {}),
                                                        ...{
                                                          opacity: [1, 0.75, 0.65, 0.5][key],
                                                        },
                                                      }}
                                                      onClick={e =>
                                                        handleChange(e, {
                                                          name: 'payment_mode',
                                                          // @TODO calculate proper percent
                                                          value: paymentMode,
                                                        })
                                                      }
                                                    />
                                                  ))}
                                                </Button.Group>
                                                <Button
                                                  content={'Submit'}
                                                  loading={loading || scLoading}
                                                />
                                              </div>
                                            </Form>
                                          );
                                        }}
                                      </Toggle>
                                    );
                                  }}
                                </API.Crud>
                              );
                            }}
                          </SimpleForm>
                        </InputAuthorization>
                      </Modal.Content>
                    </Modal>
                  )}
                  <Button
                    compact
                    size={'mini'}
                    icon
                    onClick={onTagAdditionsClick}
                    title={'Notes for item'}
                  >
                    <Icon name={'comment'} />
                  </Button>
                  {canRefund(item) && (
                    <Toggle active={false}>
                      {({ active, toggle }) => {
                        return (
                          <Modal
                            open={active}
                            onClose={toggle}
                            size={'mini'}
                            trigger={
                              <Button
                                compact
                                icon
                                size={'mini'}
                                title={'Lost item'}
                                onClick={toggle}
                              >
                                <Icon name={'blind'} />
                              </Button>
                            }
                          >
                            <Modal.Header>Wait!</Modal.Header>
                            <API.Query
                              api={new CargoApi()}
                              queries={[{ route: 'itemByInventoryNumber', id: item.internal_id }]}
                              control={true}
                            >
                              {({ data: [[i]], loading }) => {
                                if (!i)
                                  return (
                                    <Modal.Content>
                                      <Message
                                        color={'red'}
                                        content={
                                          'This item does not exist in Cargo! Please contact Mike Pavese!'
                                        }
                                      />
                                    </Modal.Content>
                                  );
                                return (
                                  <>
                                    <Modal.Content>
                                      Are you sure you want to list this item as lost?
                                    </Modal.Content>
                                    <Modal.Actions>
                                      <Button content={'Cancel'} onClick={toggle} />
                                      <CrudButton
                                        secondary
                                        api={new CargoApi()}
                                        className={'mb-1'}
                                        route={'lost'}
                                        submissionData={{ itemId: i.id }}
                                        content={'Yes, this item is lost!'}
                                        onSuccessfulSubmit={async () => {
                                          addNotifications([
                                            {
                                              ...SUCCESS,
                                              content: `Successfully added item to lost`,
                                            },
                                          ]);

                                          const results = await requests.itemStatus(3)(item);

                                          if (results) {
                                            addNotifications([
                                              {
                                                ...SUCCESS,
                                                content: `Successfully updated item status of ${item.internal_id} to lost!`,
                                              },
                                            ]);
                                          }

                                          if (!results) {
                                            addNotifications([
                                              {
                                                ...FAILED,
                                                content: `Failed to update the item status of ${item.internal_id} to lost!`,
                                              },
                                            ]);
                                          }

                                          refreshPage();
                                        }}
                                        onUnsuccessfulSubmit={() => {
                                          addNotifications([
                                            { ...FAILED, content: `Failed to add item to lost` },
                                          ]);
                                          toggle();
                                        }}
                                      />
                                    </Modal.Actions>
                                  </>
                                );
                              }}
                            </API.Query>
                          </Modal>
                        );
                      }}
                    </Toggle>
                  )}
                  {canRefund(item) && (
                    <Toggle active={false}>
                      {({ active, toggle }) => {
                        return (
                          <Modal
                            open={active}
                            onClose={toggle}
                            size={'mini'}
                            trigger={
                              <Button
                                compact
                                icon
                                size={'mini'}
                                title={'Trash item'}
                                onClick={toggle}
                              >
                                <Icon name={'trash'} />
                              </Button>
                            }
                          >
                            <Modal.Header>Wait!</Modal.Header>
                            <API.Query
                              api={new CargoApi()}
                              queries={[{ route: 'itemByInventoryNumber', id: item.internal_id }]}
                              control={true}
                            >
                              {({ data: [[i]] }) => {
                                if (!i)
                                  return (
                                    <Modal.Content>
                                      <Message
                                        color={'red'}
                                        content={
                                          'This item does not exist in Cargo! Please contact Mike Pavese!'
                                        }
                                      />
                                    </Modal.Content>
                                  );
                                return (
                                  <>
                                    <Modal.Content>
                                      Are you sure you want to list this item as trashed?
                                    </Modal.Content>
                                    <Modal.Actions>
                                      <Button content={'Cancel'} onClick={toggle} />
                                      <CrudButton
                                        secondary
                                        api={new CargoApi()}
                                        className={'mb-1'}
                                        route={'trash'}
                                        submissionData={{ itemId: i.id }}
                                        content={'Yes, this item is trash!'}
                                        onSuccessfulSubmit={async () => {
                                          addNotifications([
                                            {
                                              ...SUCCESS,
                                              content: `Successfully added item to trash`,
                                            },
                                          ]);

                                          const results = await requests.itemStatus(3)(item);

                                          if (results) {
                                            addNotifications([
                                              {
                                                ...SUCCESS,
                                                content: `Successfully updated item status of ${item.internal_id} to trash!`,
                                              },
                                            ]);
                                          }

                                          if (!results) {
                                            addNotifications([
                                              {
                                                ...FAILED,
                                                content: `Failed to update the item status of ${item.internal_id} to trash!`,
                                              },
                                            ]);
                                          }

                                          refreshPage();
                                        }}
                                        onUnsuccessfulSubmit={() => {
                                          addNotifications([
                                            { ...FAILED, content: `Failed to add item to trash` },
                                          ]);
                                          toggle();
                                        }}
                                      />
                                    </Modal.Actions>
                                  </>
                                );
                              }}
                            </API.Query>
                          </Modal>
                        );
                      }}
                    </Toggle>
                  )}
                </Button.Group>
              </Grid.Column>
            </Grid.Row>
            <Divider section />
          </Grid>
        </Responsive>
      </>
    );
  }
}

LineItem.defaultProps = {
  showItemTimerButton: true,
};

LineItem.propTypes = {
  item: PropTypes.object.isRequired,
  onClick: PropTypes.func.isRequired,
  onTagAdditionsClick: PropTypes.func.isRequired,
  showItemTimerButton: PropTypes.bool.isRequired,
  history: PropTypes.object.isRequired,
  isActionItem: PropTypes.bool.isRequired,
  addNotifications: PropTypes.func.isRequired,
  customer: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
  refreshPage: PropTypes.func.isRequired,
  auctionMethodApi: PropTypes.object.isRequired,
};

class SimpleForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      ...props.initialValues,
    };
  }

  handleChange = (e, { name, value }) => this.setState({ [name]: value });

  render() {
    const {
      props: { route, api, children },
      state,
      handleChange,
    } = this;

    return (
      <NotificationContext.Consumer>
        {({ addNotifications }) => {
          return (
            <API.Crud route={route} api={api}>
              {({ ...crud }) => {
                return children({ ...crud, addNotifications, state, handleChange });
              }}
            </API.Crud>
          );
        }}
      </NotificationContext.Consumer>
    );
  }
}

SimpleForm.propTypes = {
  api: PropTypes.object.isRequired,
  children: PropTypes.func.isRequired,
};

export default withAuthorization(withRouter(LineItem));

export { PopupLabel };
