import React, { Component } from 'react';
import { Button, Form, Label, Loader, Message, Table } from 'semantic-ui-react';
import CargoApi from '../shared-api-adapters/cargo-api';
import AuctionMethodApi from '../shared-api-adapters/auction-method-api';
import API from './api';
import MultiToggle from '../shared-components/multi-toggle';
import MultiToggleButton from './multi-toggle-button';

class BatchMove extends Component {
  constructor(props) {
    super(props);

    this.state = { loadItems: [], selectedItems: [] };
  }

  loadIdRef = React.createRef();
  destinationAuctionIdRef = React.createRef();

  handleLoadSearch = () => {
    const { items } = this.props;

    const loadItems = items
      .filter(item => item.loadId === Number(this.loadIdRef.current.value))
      .map(item => ({ ...item, submitted: 'Not Submitted' }));

    this.setState({ loadItems });
  };

  handleSubmit = async selectedItems => {
    const { loadItems } = this.state;
    const { submit } = this.props;

    // we need unique ID's of selected items so that we can map the promise.all against it
    const mapping = loadItems
      .filter((item, key) => selectedItems.includes(key))
      .map(item => item.internalId);

    const results = await Promise.all(
      mapping.map(internalId => {
        return submit({
          // eslint-disable-next-line camelcase
          auction_id: this.destinationAuctionIdRef.current.value, // target auction
          // eslint-disable-next-line camelcase
          internal_id: internalId,
        });
      })
    );

    // use the mapping to see which ID's have been submitted,
    // and grab it's mapped index to see the result of its
    // submission
    const updatedItems = loadItems.map(item =>
      mapping.includes(item.internalId)
        ? { ...item, submitted: results[mapping.indexOf(item.internalId)] ? 'Success' : 'Failure' }
        : item
    );

    this.setState({ results, loadItems: updatedItems });
  };

  render() {
    const { loadItems } = this.state;
    const { loading } = this.props;

    return (
      <MultiToggle>
        {({ removeAll, addAll, indexes, toggle }) => {
          return (
            <>
              <Loader active={loading} size={'huge'} />
              <Form onSubmit={this.handleLoadSearch}>
                <div className="field">
                  {/* eslint-disable-next-line jsx-a11y/label-has-associated-control,jsx-a11y/label-has-for */}
                  <label>Load Id / Co Id / Seller Id</label>
                  <div className="ui input">
                    <input
                      placeholder="Enter Id..."
                      name="loadId"
                      type="text"
                      ref={this.loadIdRef}
                    />
                  </div>
                  <Button content={'Search'} className={'my-1'} />
                </div>
              </Form>
              {loadItems.length > 0 ? (
                <Form onSubmit={() => this.handleSubmit(indexes)}>
                  {/* <SelectionTable items={loadItems} />*/}
                  {/* move shit to top so submission knows whats selected*/}
                  <div>
                    <MultiToggleButton
                      onClick={
                        indexes.length > 0
                          ? removeAll
                          : () => addAll(loadItems.map((item, key) => key))
                      }
                      options={loadItems}
                      selected={indexes}
                    />
                    <SelectionTable items={loadItems} selected={indexes} toggle={toggle} />
                  </div>
                  <div className="ui input mt-2">
                    <input
                      placeholder="Destination Auction Id"
                      name="loadId"
                      type="text"
                      ref={this.destinationAuctionIdRef}
                    />
                  </div>
                  <div className={'mt-1'}>
                    <Button content={'Send'} />
                  </div>
                </Form>
              ) : (
                <Message content={'No items'} />
              )}
            </>
          );
        }}
      </MultiToggle>
    );
  }
}

class SelectionTable extends Component {
  render() {
    const { items, selected, toggle } = this.props;

    return (
      <div className={'mt-2'}>
        <Label>
          Selected: <Label.Detail>{selected.length}</Label.Detail>
        </Label>
        <Table compact>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>Load Id</Table.HeaderCell>
              <Table.HeaderCell>Inventory Number</Table.HeaderCell>
              <Table.HeaderCell>Submission</Table.HeaderCell>
            </Table.Row>
          </Table.Header>

          <Table.Body>
            {items.map((item, key) => (
              <Table.Row
                key={key}
                active={selected.includes(key)}
                onClick={() => toggle(key)}
                className={'pointer'}
              >
                <Table.Cell>{item.loadId}</Table.Cell>
                <Table.Cell>{item.internalId}</Table.Cell>
                <Table.Cell>{item.submitted}</Table.Cell>
              </Table.Row>
            ))}
          </Table.Body>
        </Table>
      </div>
    );
  }
}

class BatchMovePage extends Component {
  render() {
    return (
      <API.Query
        api={new CargoApi()}
        queries={[{ route: 'auctionMethodAuction900' }]}
        control={true}
      >
        {({ data: [items], loading }) => {
          if (loading)
            return (
              <Loader active size={'massive'}>
                Fetching Items From Auction 900
              </Loader>
            );

          return (
            <>
              <API.Crud api={new AuctionMethodApi()} route={'copyItem'}>
                {({ post, loading }) => {
                  return <BatchMove items={items} submit={post} loading={loading} />;
                }}
              </API.Crud>
            </>
          );
        }}
      </API.Query>
    );
  }
}

export default BatchMovePage;
