import React, { useMemo, useState, useEffect, useCallback } from 'react';
import curbSideLogo from '../images/curbside_trans_reduced.png';
import {
  Button,
  Checkbox,
  Container,
  Divider,
  Item,
  Form,
  Icon,
  Image,
  Label,
  List,
  Loader,
  Message,
  Modal,
} from 'semantic-ui-react';
import { useAsyncFn } from 'react-use';
import { hrFirestore } from '../shared-components/firebase/firestore';
import AuctionMethodApi from '../shared-api-adapters/auction-method-api';
import AuctionConcoction from '../shared-api-adapters/auction-concoction';
import { setHours, setMinutes, addDays, format } from 'date-fns';
import { PICKUP_LOCATIONS } from '../constants';
import { snackbarPortal } from '../shared-components/notification-center';
import { CurbSideExistingAppointments } from './curbside-city-existing-appointments';
import { CurbSideLabels } from './curbside-city-labels';
import PropTypes from 'prop-types';
import { filterCustomerItems } from '../shared-components/customer-helpers';
import { CurbSidePickupLocation } from './curbside-city-pick-up-location';
import CargoApi from '../shared-api-adapters/cargo-api';
import { convertDateSqlUTCtoJS } from '../helpers/date-format-util';
import { buildPickupTimes } from '../helpers/curbside-utils';

const auctionMethodApi = new AuctionMethodApi(
  null,
  process.env.NODE_ENV === 'development'
    ? '32838b54df255fa13b19dfb770ed516cca1f1a97'
    : '9a7ad014613cb0896260feeea5785bc46f23e185'
);

// const auctionMethodApi = new AuctionMethodApi(
//   'https://dev-api.cargo.helex.io:8080/https://www.nellisauction.com',
//   '9a7ad014613cb0896260feeea5785bc46f23e185'
// );

const auctionConcoction = new AuctionConcoction();
const cargoApi = new CargoApi();
const FRAUD_THRESHOLD = 500.0;

const statusTypes = {
  SUCCESS: 'SUCCESS',
  ERROR: 'ERROR',
  ATTENTION: 'ATTENTION',
  IDLE: 'IDLE',
  CREATED: 'CREATED',
  UPDATED: 'UPDATED',
  CANCELED: 'CANCELED',
};

const CurbSideHeader = () => {
  return (
    <div className={'w-100 primary'}>
      <div className={'pt-3 flex fd-row ai-center'}>
        <Image src={curbSideLogo} alt="Nellis Auction" size={'medium'} />
      </div>
    </div>
  );
};

export const CurbSide = ({ customerId, userId }) => {
  const [existingAppointments, setExistingAppointments] = useState([]);
  const [existingItemIds, setExistingItemIds] = useState([]);
  const [invoices, setInvoices] = useState([]);
  const [items, setItems] = useState([]);
  const [selectAll, setSelectAll] = useState(true);
  const [selectedItems, setSelectedItems] = useState([]);
  const [affiliates, setAffiliates] = useState([]);
  const [affiliateId, setAffiliateId] = useState();
  const [affiliateIds, setAffiliateIds] = useState([]);
  const [totalAmount, setTotalAmount] = useState(0.0);
  const [finalAmount, setFinalAmount] = useState(0.0);
  const [loading, setLoading] = useState(true);
  const [note, setNote] = useState('');
  const [authorizedPickup, setAuthorizedPickup] = useState('');
  const [message, setMessage] = useState({ text: '', color: 'yellow', header: 'Attention!' });
  const [page, setPage] = useState(0);
  const [appointmentInfo, setAppointmentInfo] = useState({
    id: null,
    approxPickupTime: null,
    items: [],
  });
  const [appointmentId, setAppointmentId] = useState(0);
  const [pickupTime, setPickupTime] = useState(null);
  const [pickupDate, setPickupDate] = useState(new Date());
  const [, setPreviousData] = useState({
    previousPickupTime: null,
    previousPickupDate: null,
    previousItemCount: 0,
  });
  const [showCancelAppointmentModal, setShowCancelAppointmentModal] = useState(false);
  const [applicationState, setApplicationState] = useState(statusTypes.IDLE);
  const [appointmentStatus, setAppointmentStatus] = useState(statusTypes.IDLE);
  const [firstTimeBuyer, setFirstTimeBuyer] = useState(true);
  const [editing, edit] = useState(null);

  const [customerInfo, setCustomerInfo] = useState({});
  const [customerAppointments, setCustomerAppointments] = useState([]);
  const [includeNewItems, setIncludeNewItems] = useState(true);

  // const [appointmentCounts, setAppointmentCounts] = useState({});

  const displayMessage = (color, header, contents, showNumber = false) => {
    return (
      <Message className={'mt-4 mb-4'} color={color}>
        <Message.Header>{header}</Message.Header>
        {contents.map((content, key) => (
          <Message.Content key={key} className={'mt-2'}>
            {content}
          </Message.Content>
        ))}
        {showNumber && (
          <Message.Content className={'mt-2'}>
            If you need assistance, please call us at <strong>702-531-1300 OPTION 0</strong>
          </Message.Content>
        )}
      </Message>
    );
  };

  const formatName = name => name.toLowerCase()[0].toUpperCase() + name.toLowerCase().slice(1);

  const getAffiliateInfo = () => affiliates.find(({ affiliate }) => affiliate === affiliateId);

  const getInvoiceIds = () => [
    ...new Set(
      items
        .filter(({ item_id: itemId }) => selectedItems.includes(itemId))
        .map(({ invoice_id: invoiceId }) => invoiceId)
    ),
  ];

  // const isAppointmentPast = time => time.toDate().getTime() - new Date().getTime() < 0;

  const toggleSelectedItem = itemId => {
    const updatedItems = selectedItems.includes(itemId)
      ? selectedItems.filter(item => item !== itemId)
      : [...selectedItems, itemId];

    setSelectedItems(updatedItems);
  };

  const toggleSelectedInvoice = invoiceId => {
    const itemIdsOnInvoice = items
      .filter(item => item.invoice_id === invoiceId)
      .map(invoiceItem => invoiceItem.item_id);

    const selectedItemsNotOnInvoice = selectedItems.filter(
      itemId => !itemIdsOnInvoice.includes(itemId)
    );

    const selectedItemsOnInvoice = selectedItems.filter(itemId =>
      itemIdsOnInvoice.includes(itemId)
    );

    const updatedItems =
      itemIdsOnInvoice.length === selectedItemsOnInvoice.length
        ? selectedItemsNotOnInvoice
        : [...itemIdsOnInvoice, ...selectedItemsNotOnInvoice];

    setSelectedItems(updatedItems);
  };

  const pickUpDates = [
    new Date(),
    ...new Array(3).fill(1).map((inc, count) => addDays(new Date(), inc + count)),
  ];

  const getDocId = async () => {
    return await hrFirestore
      .collection('pickUpQueue')
      .where('appointmentId', '==', appointmentId)
      .get()
      .then(({ docs }) => {
        const [fetchDocId = {}] = docs.map(doc => {
          return { docId: doc.id };
        });

        return fetchDocId.docId;
      })
      .catch(error => console.log({ error }));
  };

  const updateAppointment = async () => {
    // Update SQL Appointment: Inside then() delete firebase appointment

    const invoiceIds = getInvoiceIds();

    const appointment = {
      includeNewItems,
      itemIds: selectedItems,
      authorizedPickup,
      note,
      appointmentTime: pickupDate,
      invoiceIds,
      invoiceIdsString: invoiceIds.toString(),
    };

    return await cargoApi
      .put(`${cargoApi.routes.appointments}/${appointmentId}`, appointment)
      .then(async () => {
        const docId = await getDocId();

        if (!docId) {
          setMessage({
            text: 'Your appointment has been updated',
            color: 'green',
            header: 'SUCCESS!',
          });
          setPage(page + 1);
          setAppointmentStatus(statusTypes.CREATED);
          setApplicationState(statusTypes.SUCCESS);
          edit(null);
        } else {
          await hrFirestore
            .collection('pickUpQueue')
            .doc(docId)
            .delete()
            .then(() => {
              setMessage({
                text: 'Your appointment has been updated',
                color: 'green',
                header: 'SUCCESS!',
              });
              setPage(page + 1);
              setAppointmentStatus(statusTypes.CREATED);
              setApplicationState(statusTypes.SUCCESS);
              edit(null);
            });
        }
      })
      .catch(() => {
        setMessage({
          text: 'An error has occurred. Please try again.',
          color: 'red',
          header: 'ERROR!',
        });
        setApplicationState(statusTypes.ERROR);
      })
      .finally(() => setLoading(false));
  };

  const saveAppointment = async () => {
    if (selectedItems.length === 0) {
      return setMessage({
        text: 'You must select at least one item to pick-up.',
        color: 'red',
        header: 'ERROR!',
      });
    }

    setLoading(true);
    setFinalAmount(totalAmount);

    const affiliateInfo = getAffiliateInfo();

    const { id } = affiliateInfo;

    const fraud = {
      firstTimeBuyer: firstTimeBuyer ? 1 : 0,
      suspicious: totalAmount >= FRAUD_THRESHOLD ? 1 : 0,
      reasons: ['Amount threshold exceeded'],
    };

    const submittedBy = userId
      ? { id: userId, type: 'user' }
      : { id: customerId, type: 'customer' };

    const invoiceIds = getInvoiceIds();

    const appointment = {
      customerId,
      appointmentTime: pickupDate,
      itemIds: selectedItems,
      invoiceIds,
      submittedBy,
      itemIdsString: selectedItems.toString(),
      invoiceIdsString: invoiceIds.toString(),
      fraud,
      fraudString: Object.entries(fraud).toString(),
      submittedByString: Object.entries(submittedBy).toString(),
      authorizedPickup,
      note,
      locationId: id,
      customer: customerInfo,
      includeNewItems,
      active: 1,
    };

    return await cargoApi
      .post(cargoApi.routes.appointments, appointment)
      .then(() => {
        setMessage({
          text: 'Your appointment is scheduled',
          color: 'green',
          header: 'SUCCESS!',
        });
        setPage(page + 1);
        setAppointmentStatus(statusTypes.CREATED);
        setApplicationState(statusTypes.SUCCESS);
        setLoading(false);
      })
      .catch(() => {
        setMessage({
          text: 'An error has occurred. Please try again.',
          color: 'red',
          header: 'ERROR!',
        });
        setApplicationState(statusTypes.ERROR);
        setLoading(false);
      });
  };

  useEffect(() => {
    cargoApi
      .get(cargoApi.routes.locations)
      .then(locations => {
        setAffiliates(
          locations
            .filter(({ curbsideAvailable }) => curbsideAvailable === 1)
            .map(location => {
              return { ...location, affiliate: location.affiliateId };
            })
        );
      })
      .catch(() => {
        setMessage({
          text: 'Unable to retrieve data. Please try again.',
          color: 'red',
          header: 'ERROR!',
        });
      });
  }, []);

  useEffect(() => {
    auctionMethodApi
      .get(auctionMethodApi.routes.customers, null, { id: customerId })
      .then(async ([{ email, firstname: firstName, lastname: lastName, phone: phoneNumber }]) => {
        const customer = {
          email,
          firstName: formatName(firstName),
          lastName: formatName(lastName),
          id: Number(customerId),
          phoneNumber,
        };

        setCustomerInfo(customer);
      })
      .catch(() => {
        setMessage({
          text: 'Call Customer Support (702) 531-1300',
          color: 'red',
          header: 'ERROR!',
        });
      });
  }, [customerId]);

  useEffect(() => {
    cargoApi
      .get(`${cargoApi.routes.appointments}/customerId/${customerId}`)
      .then(appointments => {
        const activeAppointments = appointments.filter(app => app.active === 1);

        setCustomerAppointments(activeAppointments);
      })
      .catch(() => {
        setMessage({
          text: 'Unable to retrieve data. Please try again.',
          color: 'red',
          header: 'ERROR!',
        });
      });
  }, [customerId, applicationState, appointmentStatus]);

  useEffect(() => {
    auctionConcoction
      .get(auctionConcoction.routes.firstTimeBuyer, customerId)
      .then(([result]) => setFirstTimeBuyer(result))
      .catch(() => {
        setMessage({
          text: 'Unable to retrieve customer information.',
          color: 'red',
          header: 'ERROR!',
        });
      });
  }, [customerId]);

  useEffect(() => {
    setExistingAppointments(customerAppointments);
  }, [customerAppointments]);

  useMemo(() => {
    setExistingItemIds(existingAppointments.map(({ itemIds }) => itemIds).flat());
  }, [existingAppointments]);

  useEffect(() => {
    setLoading(true);

    auctionMethodApi
      .get('/checkoutapi/invoice2', null, {
        // eslint-disable-next-line camelcase
        user_id: customerId,
        // eslint-disable-next-line camelcase
        days_back: 10,
      })
      .then(response =>
        setInvoices(
          response.filter(({ line_items: lineItems }) =>
            lineItems.some(({ picked_up: pickedUp }) => pickedUp === '0')
          )
        )
      )
      .catch(() => {
        setMessage({
          text: 'Failed to load customer invoices. Please try again',
          color: 'red',
          header: 'ERROR!',
        });
      })
      .finally(() => setLoading(false));
  }, [customerId]);

  useEffect(() => {
    setAffiliateIds([
      ...new Set(
        invoices
          .map(invoice =>
            filterCustomerItems(invoice.line_items)
              .filter(lineItem => !existingItemIds.includes(lineItem.item_id.toString()))
              .map(() => Number(invoice.affiliate_id))
          )
          .flat()
      ),
    ]);
  }, [invoices, affiliates, existingItemIds]);

  const checkAffiliate = useCallback(
    invoiceAffiliate => {
      return !affiliateId
        ? affiliateIds.includes(invoiceAffiliate)
        : invoiceAffiliate === affiliateId;
    },
    [affiliateId, affiliateIds]
  );

  const filterExistingItems = useCallback(
    existing => {
      return [
        ...new Set(
          invoices
            .map(invoice => {
              return filterCustomerItems(invoice.line_items)
                .filter(
                  lineItem =>
                    !existing.includes(lineItem.item_id.toString()) &&
                    checkAffiliate(Number(invoice.affiliate_id))
                )
                .map(lineItem => ({
                  ...lineItem,
                  // eslint-disable-next-line camelcase
                  item_id: lineItem.item_id.toString(),
                  affiliateId: Number(invoice.affiliate_id),
                }));
            })
            .flat()
        ),
      ];
    },
    [invoices, checkAffiliate]
  );

  useEffect(() => {
    if (editing) return;

    setItems(filterExistingItems(existingItemIds));
  }, [filterExistingItems, existingItemIds, editing]);

  useEffect(() => {
    if (editing) return;

    setSelectedItems([
      ...new Set(
        items
          .filter(item => item.affiliateId === affiliateId && item.item_status.id === 0)
          .map(item => item.item_id.toString())
          .flat()
      ),
    ]);
  }, [items, affiliateId, editing]);

  useEffect(() => {
    if (appointmentStatus === statusTypes.CREATED || applicationState === statusTypes.SUCCESS)
      return;

    if (items.length === 0) {
      setApplicationState(statusTypes.ATTENTION);
    } else {
      setApplicationState(statusTypes.IDLE);
    }
  }, [items, appointmentStatus, applicationState]);

  useEffect(() => {
    setTotalAmount(
      items.reduce(
        (acc, { item_id: itemId, item_total: itemTotal }) =>
          !selectedItems.includes(itemId) ? acc : acc + Number(itemTotal),
        0.0
      )
    );
    setSelectAll(items.length === selectedItems.length);
  }, [items, selectedItems]);

  useEffect(() => {
    if (message.text === '') return;

    snackbarPortal(message.text, message.header, message.color);
  }, [message]);

  useEffect(() => {
    if (!appointmentInfo.id) return;

    setPreviousData({
      previousPickupDate: convertDateSqlUTCtoJS(appointmentInfo.appointmentTime),
      previousPickupTime: format(convertDateSqlUTCtoJS(appointmentInfo.appointmentTime), 'hh:mm a'),
      previousItemCount: appointmentInfo.items.length,
    });
  }, [appointmentInfo]);

  const pickUpTimes = buildPickupTimes(pickupDate.getDate())(
    affiliates.find(({ affiliateId: id }) => id === affiliateId)?.weeklyHours || '10AM - 5PM'
  );

  const [, cancelAppointmentConfirmation] = useAsyncFn(async appointment => {
    const { id } = appointment;

    setAppointmentId(id);
    setAppointmentInfo(appointment);
    setShowCancelAppointmentModal(!showCancelAppointmentModal);
  });

  const deleteAppointment = async appInfo => {
    setLoading(true);

    const { id } = appInfo;

    const docId = await getDocId();

    if (!docId) {
      await cargoApi.put(`${cargoApi.routes.appointments}/${id}`, { active: 0 });
      setMessage({
        text: 'You have cancelled your appointment',
        color: 'green',
        header: 'SUCCESS!',
      });
      setAppointmentStatus(statusTypes.CANCELED);
      setLoading(false);
    } else {
      await hrFirestore
        .collection('pickUpQueue')
        .doc(docId)
        .delete()
        .then(async () => {
          await cargoApi.put(`${cargoApi.routes.appointments}/${id}`, { active: 0 });
          setMessage({
            text: 'You have cancelled your appointment',
            color: 'green',
            header: 'SUCCESS!',
          });
          setAppointmentStatus(statusTypes.CANCELED);
        })
        .catch(() =>
          setMessage({
            text: 'An error has occurred. Please try again.',
            color: 'red',
            header: 'ERROR!',
          })
        )
        .finally(() => setLoading(false));
    }
  };

  const resetCurbside = () => {
    setPickupDate(new Date());
    setPickupTime(null);
    setAuthorizedPickup('');
    setNote('');
    setPage(0);
    edit(null);
    setApplicationState(statusTypes.IDLE);
    setAppointmentStatus(statusTypes.IDLE);
  };

  const editAppointment = useCallback(
    appointment => {
      if (!appointment) {
        resetCurbside();
        return;
      }
      setAffiliateId(appointment?.affiliateId);
      setApplicationState(statusTypes.IDLE);
      setAppointmentStatus(statusTypes.IDLE);
      edit(appointment);

      // Step 1: set affiliate id state to existing appointment
      setAppointmentId(appointment.id);

      // Step 2: set items to non-scheduled items and include items in current appointment
      const otherExistingItemIds = existingItemIds.filter(
        itemId => !appointment.itemIds.includes(itemId)
      );
      const editableItems = filterExistingItems(otherExistingItemIds);

      setItems(editableItems);

      // Step 3: set selected items to items in appointment (toggle select all accordingly)
      setSelectedItems(appointment.itemIds);
      setSelectAll(appointment.itemIds.length === editableItems.length);

      // Step 4: set previously populated fields accordingly
      setPickupDate(convertDateSqlUTCtoJS(appointment.appointmentTime));
      setPickupTime(format(convertDateSqlUTCtoJS(appointment.appointmentTime), 'hh:mm a'));

      // Track previous date/time and item count to decrement from scheduledCounts
      setPreviousData({
        previousPickupDate: convertDateSqlUTCtoJS(appointment.appointmentTime),
        previousPickupTime: format(convertDateSqlUTCtoJS(appointment.appointmentTime), 'hh:mm a'),
        previousItemCount: appointment.itemIds.length,
      });

      setIncludeNewItems(appointment.includeNewItems);
      setAuthorizedPickup(appointment.authorizedPickup);
      setNote(appointment.note);

      // Step 5: move to items page
      setPage(1);
    },
    [existingItemIds, filterExistingItems]
  );

  const renderBanner = status => {
    if (status === statusTypes.ERROR) {
      return displayMessage('red', 'Something went wrong!', ['A system error has occurred.'], true);
    }

    if (status === statusTypes.IDLE) {
      let contents = ['Schedule a new appointment below. We hope to see you soon!'];

      if (existingAppointments.length > 0) {
        contents = [
          'You can edit or cancel an existing appointment, or schedule a new one below. We hope to see you soon!',
        ];
      }
      return displayMessage('blue', 'Congratulations on your wins!', contents);
    }

    if (status === statusTypes.SUCCESS) {
      return displayMessage(
        'green',
        'Success!',
        [
          `You're now scheduled for pickup at ${getAffiliateInfo()?.name}`,
          <List bulleted key={`new_appointment_${affiliateId}`}>
            <List.Item>Balance Due at Check-In: ${finalAmount.toFixed(2)}</List.Item>
            <List.Item>Please follow the map below to check-in properly</List.Item>
            <List.Item>
              Your confirmation code is: <Label>{customerId}</Label>
            </List.Item>
          </List>,
        ],
        true
      );
    }

    if (status === statusTypes.ATTENTION) {
      let contents = ["We don't show any items available for pick-up."];

      if (existingAppointments.length > 0) {
        contents = ['All of your items are scheduled for pickup'];
      }

      return displayMessage('yellow', 'Attention!', contents, true);
    }

    return null;
  };

  if (loading)
    return (
      <div>
        <CurbSideHeader />
        <Loader active size={'big'} content={'Loading Information!'} />
      </div>
    );

  return customerId ? (
    <div>
      <CurbSideHeader />
      <Container>
        {renderBanner(applicationState)}

        <CurbSideExistingAppointments
          existingAppointments={existingAppointments}
          existingItems={invoices
            .map(({ line_items: lineItems }) => lineItems)
            .flat()
            .filter(({ item_id: itemId }) => existingItemIds.includes(itemId.toString()))}
          cancelAppointment={cancelAppointmentConfirmation}
          editAppointment={editAppointment}
          editing={editing}
          loading={loading}
          affiliates={affiliates}
        />

        <CurbSideLabels
          affiliate={getAffiliateInfo()}
          pickupTime={pickupTime}
          pickupDate={pickupDate}
          authorizedPickup={authorizedPickup}
          note={note}
          selectedItems={selectedItems}
          totalAmount={totalAmount}
          hidden={[0, 5].includes(page)}
        />

        <CurbSidePickupLocation
          affiliateIds={affiliateIds}
          loading={loading}
          hidden={page !== 0 || items.length === 0}
          affiliates={affiliates}
          onClick={pickupLocationId => {
            setAffiliateId(pickupLocationId);
            setPage(page + 1);
          }}
        />

        {page === 1 && items.length > 0 && (
          <>
            <h3>Items available for pick-up at {getAffiliateInfo().name}</h3>
            <Message className={'mt-2'} color={'yellow'}>
              <Message.Header>Attention!</Message.Header>
              {selectAll && (
                <Message.Content className={'mt-2'}>
                  We have selected all your items requiring pick-up. If you wish to pick-up certain
                  items, please select only the items you wish to pick-up.
                </Message.Content>
              )}
              <Message.Content className={'mt-2'}>
                <strong>Note:</strong> We require full payment prior to releasing partial pick-ups.
              </Message.Content>
            </Message>
            <Message color={'blue'}>
              <Message.Header>FYI</Message.Header>
              <Message.Content className={'mt-2'}>
                Clicking the blue invoice id selects all items on that invoice
              </Message.Content>
            </Message>
            <div className={'mb-4'}>
              <Button
                content={
                  <Checkbox
                    checked={selectAll}
                    label={selectAll ? 'Deselect all' : 'Select all'}
                    onClick={() => {
                      setSelectedItems(
                        !selectAll ? items.map(({ item_id: itemId }) => itemId) : []
                      );
                      setSelectAll(!selectAll);
                    }}
                  />
                }
              />
            </div>
            <hr />
            <Item.Group divided>
              {items.map(
                ({
                  description,
                  item_id: itemId,
                  invoice_id: invoiceId,
                  item_total: itemTotal,
                  lot_number: lotNumber,
                }) => {
                  return (
                    <Item key={lotNumber}>
                      <Item.Content>
                        <Item.Header>
                          <Checkbox
                            checked={selectedItems.includes(itemId.toString())}
                            onClick={() => toggleSelectedItem(itemId)}
                            className={'pointer'}
                            label={`Lot #${lotNumber}`}
                          />
                        </Item.Header>
                        <Item.Meta>
                          <span className="cinema">{`${description.slice(0, 50)}${
                            description.length > 50 ? '...' : ''
                          }`}</span>
                        </Item.Meta>
                        <Item.Extra>
                          <Label
                            icon={'file alternate'}
                            content={`#${invoiceId}`}
                            className={'float-right ml-1 mr-1 pointer'}
                            color={'blue'}
                            onClick={() => toggleSelectedInvoice(invoiceId)}
                          />
                          <Label
                            icon={'dollar'}
                            content={`${itemTotal}`}
                            className={'float-right ml-1 mr-1'}
                            color={'green'}
                          />
                        </Item.Extra>
                      </Item.Content>
                    </Item>
                  );
                }
              )}
            </Item.Group>
            <hr />
          </>
        )}
        {page === 2 && (
          <>
            <h3>Pick-up dates</h3>
            {pickUpDates.map((date, key) => (
              <div key={key}>
                <Button
                  content={date.toDateString()}
                  disabled={
                    (pickUpTimes.length === 0 && date.getDate() === new Date().getDate()) ||
                    ([0, 1].includes(date.getDay()) && affiliateId === 5)
                  }
                  fluid
                  className={'mb-1'}
                  onClick={() => {
                    setPickupDate(date);
                    setPage(page + 1);
                  }}
                />
              </div>
            ))}
          </>
        )}
        {page === 3 && (
          <>
            <h3>Pick-up times</h3>
            {pickUpTimes.map((date, key) => {
              return (
                <div key={key}>
                  <Button as="div" labelPosition="right" fluid className={'mb-1'}>
                    <Button
                      icon
                      fluid
                      onClick={async () => {
                        setPickupDate(
                          setHours(setMinutes(pickupDate, date.getMinutes()), date.getHours())
                        );
                        setPickupTime(format(date, 'hh:mm a'));
                        setPage(page + 1);
                      }}
                    >
                      <Icon name="clock" />
                      {format(date, 'hh:mm a')}
                    </Button>
                  </Button>
                </div>
              );
            })}
          </>
        )}
        {page === 4 && (
          <>
            <h3>Additional notes</h3>
            <Message
              content={
                <Checkbox
                  checked={includeNewItems}
                  label={`By checking this box we will automatically add any additional items you've won between now and your scheduled pick-up time to your appointment.`}
                />
              }
              fluid
              className={'pointer mb-4 t-left'}
              color={'yellow'}
              onClick={() => setIncludeNewItems(!includeNewItems)}
            />
            <Form className={'mb-2'}>
              <Form.Input
                label={'Is anyone else picking up for you?'}
                placeholder={'Name'}
                value={authorizedPickup}
                onChange={(event, { value }) => setAuthorizedPickup(value)}
              />
              <Form.Input
                label={'Please describe your vehicle.'}
                placeholder={'Red Toyota Tacoma...'}
                value={note}
                onChange={(event, { value }) => setNote(value)}
              />
            </Form>
          </>
        )}
        {page === 5 && (
          <>
            <h3>{getAffiliateInfo().name} map</h3>
            <Message color={'red'}>
              <Message.Header>Notice!</Message.Header>
              <Message.Content>
                Please bring a valid form of <strong>ID</strong>, as well as the
                <strong> credit card you paid with, or plan to pay with, </strong>
                to your pick-up appointment. Thank you!
              </Message.Content>
            </Message>
            {[5, 33, 14].includes(affiliateId) && (
              <Image src={PICKUP_LOCATIONS[affiliateId]} size={'large'} className={'m-auto'} />
            )}
            <Divider />
          </>
        )}
        <div className={'flex fd-row jc-between mt-4'}>
          {page !== 5 && page !== 0 && (
            <Button
              size={'small'}
              className={'mb-2'}
              onClick={() => {
                if (page === 1 && !!editing) resetCurbside();
                else setPage(page - 1);
              }}
            >
              <Icon name={'left arrow'} />
              Previous
            </Button>
          )}
          {page === 1 && (
            <Button
              size={'small'}
              className={'mb-2'}
              onClick={() => setPage(page + 1)}
              disabled={selectedItems.length < 1}
            >
              Next
              <Icon name={'right arrow'} />
            </Button>
          )}
          {page === 4 && (
            <>
              {editing ? (
                <Button size={'small'} color={'blue'} onClick={updateAppointment}>
                  <Icon name={'thumbs up'} />
                  Update
                </Button>
              ) : (
                <Button size={'small'} color={'green'} onClick={saveAppointment}>
                  <Icon name={'thumbs up'} />
                  Confirm
                </Button>
              )}
            </>
          )}
        </div>
        <Modal open={showCancelAppointmentModal} size={'mini'}>
          <Modal.Header>
            Are you sure you would like to cancel the following appointment?
          </Modal.Header>
          <Modal.Content>
            <div className={'mb-1'}>
              <strong>Pickup Date:</strong>{' '}
              {appointmentInfo.appointmentTime &&
                format(
                  convertDateSqlUTCtoJS(appointmentInfo.appointmentTime),
                  'MM/dd/yyyy hh:mm a'
                )}
            </div>
            <div className={'mt-1 mb-1'}>
              <strong>Items:</strong>{' '}
              {appointmentInfo.items
                .map(({ internal_id: internalId }) => internalId.slice(4))
                .join(', ')}
            </div>
          </Modal.Content>
          <Modal.Content>
            <Modal.Actions>
              <Button
                color="red"
                onClick={() => setShowCancelAppointmentModal(!showCancelAppointmentModal)}
              >
                No
              </Button>
              <Button
                positive
                icon="checkmark"
                labelPosition="right"
                content="Yes"
                onClick={() => {
                  deleteAppointment(appointmentInfo).then(() => {
                    if (
                      applicationState === statusTypes.SUCCESS ||
                      (editing && editing.id === appointmentInfo.id)
                    ) {
                      resetCurbside();
                    }
                    setShowCancelAppointmentModal(!showCancelAppointmentModal);
                  });
                }}
              />
            </Modal.Actions>
          </Modal.Content>
        </Modal>
      </Container>
    </div>
  ) : null;
};

CurbSide.propTypes = {
  customerId: PropTypes.number.isRequired,
  userId: PropTypes.number,
};
