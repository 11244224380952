import axios from 'axios';

class ApiService {
  constructor(token = null) {
    this.axios = axios;
    this.filters = {};
    this.hostname = null;
    this.message = null;
    this.response = null;
    this.token = token;
  }

  destroy = async (route, data) => this._request('DELETE', route, data);

  get = async (route, id = null, filters = {}) =>
    this._request('GET', this._applyFilters(id ? `${route}/${id}` : route, filters));

  getMessage = () => this.message;

  post = async (route, data) => this._request('POST', route, data);

  put = async (route, data) => this._request('PUT', route, data);

  patch = async (route, data) => this._request('PATCH', route, data);

  getNew = async (route, data) => this._request('GET', this.buildRoute(route, data));

  buildRoute = (route, data) =>
    Object.keys(data).reduce((acc, value) => acc.replace(`{${value}}`, data[value]), route);

  setToken = token => (this.token = token);

  /**
   * Apply all filters to provided route
   *
   * @param route
   * @param filters
   * @return {string}
   * @private
   */
  _applyFilters(route, filters) {
    this.filters = filters;
    const filterString = Object.keys(this.filters)
      .reduce((acc, val) => {
        if (this.filters[val] === 'object') {
          this.filters[val] = JSON.stringify(this.filters[val]);
        }

        return this.filters[val] ? [...acc, `${val}=${this.filters[val]}`] : acc;
      }, [])
      .join('&');

    return Object.keys(this.filters).length > 0 ? `${route}?${filterString}` : route;
  }

  /**
   * Wrapper for axios
   *
   * @param method
   * @param route
   * @param data
   * @return {Promise<*|boolean>}
   * @private
   */
  _request = async (method, route, data = {}) => {
    this.response = await this.axios({
      method,
      url: `${this.hostname}${route}`,
      data: { ...data },
      headers: { authorization: this.token },
    }).catch(error => error.response);

    return this._validateStatus();
  };

  /**
   * Validate response from our API
   *
   * @return {boolean|*}
   * @protected
   */
  _validateStatus() {
    const {
      data: { data, message, error },
      status,
    } = this.response;

    // don't report database errors
    this.message = error.name === 'SequelizeDatabaseError' ? '' : message;

    return [200, 201, 204].includes(status) ? data : false;
  }
}

export default ApiService;
