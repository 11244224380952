import React, { useCallback, useEffect, useLayoutEffect, useMemo, useRef, useState } from 'react';
import * as am4core from '@amcharts/amcharts4/core';
import * as am4charts from '@amcharts/amcharts4/charts';
import am4ThemesAnimated from '@amcharts/amcharts4/themes/animated';
import am4ThemesMaterial from '@amcharts/amcharts4/themes/material';

import { pickupQueueCollection } from '../shared-components/firebase/firestore';
import {
  Header,
  Segment,
  Statistic,
  Dropdown,
  Button,
  Modal,
  Table,
  Grid,
  Label,
} from 'semantic-ui-react';
import PropTypes from 'prop-types';
import { convertDateFBtoJS, setDateOptions } from '../helpers/date-format-util';
import AuthUserContext from './auth-user-context';
import API from '../shared-components/api';
import CargoApi from '../shared-api-adapters/cargo-api';
import outliers from 'outliers';
import { Link } from 'react-router-dom';
import * as routes from '../constants/routes';
import { sortByArrivalTime } from '../shared-components/helpers';

import { isEmpty, sortBy } from 'lodash/fp';
import { useInterval } from 'react-use';

const PollingPickupQueueData = ({ children, affiliateId }) => {
  const [pickupQueueData, setPickupQueueData] = useState([]);

  const getFBData = useCallback(async () => {
    const t = await pickupQueueCollection.where('affiliateId', '==', affiliateId).get();

    const data = t.docs.map(doc => {
      return { docId: doc.id, ...doc.data(), ref: doc.ref, exists: doc.exists };
    });

    setPickupQueueData(data);
  }, [affiliateId]);

  useInterval(() => {
    getFBData();
  }, 60000);

  useEffect(() => {
    getFBData();
  }, [getFBData]);

  return children(pickupQueueData);
};

const EmployeeCountsTable = ({ tableData }) => {
  return (
    <Table basic="very">
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell>Name</Table.HeaderCell>
          <Table.HeaderCell>Order Count</Table.HeaderCell>
          <Table.HeaderCell>Item Count</Table.HeaderCell>
          <Table.HeaderCell>Missing</Table.HeaderCell>
          <Table.HeaderCell>% Missing</Table.HeaderCell>
        </Table.Row>
      </Table.Header>

      <Table.Body>
        {sortBy(['itemCount'], tableData)
          .slice()
          .reverse()
          .map(({ name, orderCount, itemCount, missing }, key) => (
            <Table.Row key={key}>
              <Table.Cell>{name}</Table.Cell>
              <Table.Cell>{orderCount}</Table.Cell>
              <Table.Cell>{itemCount}</Table.Cell>
              <Table.Cell>{missing}</Table.Cell>
              <Table.Cell>{((missing * 100) / (missing + itemCount)).toFixed(2)}</Table.Cell>
            </Table.Row>
          ))}
      </Table.Body>
    </Table>
  );
};

EmployeeCountsTable.propTypes = {
  tableData: PropTypes.array.isRequired,
};

const AppointmentCountsChart = ({ pickupQueueData = [] }) => {
  const chart = useRef(null);

  const pickupQueueDataWithTime = useMemo(
    () =>
      pickupQueueData.map(item => ({
        ...item,
        time: item.approxPickupTime
          .toDate()
          .toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' }),
      })),
    [pickupQueueData]
  );

  // accumulate appointment and item counts
  const z = useMemo(
    () =>
      pickupQueueDataWithTime.reduce(
        (acc, val) => {
          return {
            ...acc,
            [val.time]: {
              itemCount: acc[val.time].itemCount + val.itemIds.length,
              time: val.time,
              appointmentCount: acc[val.time].appointmentCount + 1,
            },
          };
        },
        // set defaults
        pickupQueueDataWithTime.reduce(
          (acc, val) => ({
            ...acc,
            [val.time]: {
              itemCount: 0,
              appointmentCount: 0,
            },
          }),
          {}
        )
      ),
    [pickupQueueDataWithTime]
  );

  useLayoutEffect(() => {
    const x = am4core.create('chartDiv', am4charts.XYChart);

    am4core.useTheme(am4ThemesMaterial);
    am4core.useTheme(am4ThemesAnimated);

    x.paddingRight = 20;
    x.colors.list = [am4core.color('#DB2828'), am4core.color('#026CDE')];

    // Axis properties
    const dateAxis = x.xAxes.push(new am4charts.CategoryAxis());

    dateAxis.dataFields.category = 'time';
    dateAxis.title.text = 'Hours of Operation';
    dateAxis.renderer.grid.template.location = 0;
    dateAxis.renderer.minGridDistance = 80;

    const valueAxis = x.yAxes.push(new am4charts.ValueAxis());

    valueAxis.tooltip.disabled = true;
    valueAxis.renderer.minWidth = 35;
    valueAxis.title.text = 'Amount (Per Time Slot)';

    // Appointment count properties
    const series = x.series.push(new am4charts.ColumnSeries());

    series.dataFields.categoryX = 'time';
    series.dataFields.valueY = 'appointmentCount';
    series.name = 'Appointments';
    series.columns.template.tooltipText = '{categoryX}: [bold]';
    series.columns.template.fillOpacity = 0.8;
    series.tooltipText = '{valueY.value}';

    // Item count properties
    const series1 = x.series.push(new am4charts.LineSeries());

    series1.dataFields.categoryX = 'time';
    series1.dataFields.valueY = 'itemCount';
    series1.name = 'Items';
    series1.strokeWidth = 3;
    series1.tensionX = 0.8;
    series1.bullets.push(new am4charts.CircleBullet());
    series1.tooltipText = '{valueY.value}';

    // Add legend
    x.legend = new am4charts.Legend();
    x.cursor = new am4charts.XYCursor();

    chart.current = x;

    return () => {
      x.dispose();
    };
  }, []);

  useLayoutEffect(() => {
    if (isEmpty(z)) return;
    chart.current.data = Object.values(z).sort((a, b) => {
      return new Date(`1970/01/01 ${a.time}`) - new Date(`1970/01/01 ${b.time}`);
    });
  }, [z]);

  return (
    <div
      id={'chartDiv'}
      style={{
        width: '100%',
        height: '500px',
      }}
    />
  );
};

AppointmentCountsChart.propTypes = {
  pickupQueueData: PropTypes.array.isRequired,
};

const DailySnapshot = ({ affiliate: { name, affiliateId } }) => {
  const [timeAhead, setTimeAhead] = useState(30);
  const [scheduled, setScheduled] = useState([]);
  const [awaitingConsolidation, setAwaitingConsolidation] = useState([]);
  const [consolidated, setConsolidated] = useState([]);
  const [loadingOut, setLoadingOut] = useState([]);
  const [pickedUp, setPickedUp] = useState([]);

  useEffect(() => {
    const fetchDataSet = (status, setState, fn = data => data) => {
      pickupQueueCollection
        .where('status', '==', status)
        .where('affiliateId', '==', affiliateId)
        .onSnapshot(snapshot => {
          setState(
            fn(
              snapshot.docs.map(doc => ({
                queueId: doc.id,
                ...doc.data(),
              }))
            )
          );
        });
    };

    fetchDataSet(0, setAwaitingConsolidation);
    fetchDataSet(-1, setScheduled);
    fetchDataSet(2, setConsolidated);
    fetchDataSet(3, setLoadingOut);
    fetchDataSet(4, setPickedUp);
  }, [affiliateId]);

  const formatSeconds = inputSeconds => {
    const minutes = Math.floor(inputSeconds / 60);
    const seconds = Math.round(inputSeconds - minutes * 60);

    if (minutes > 0) {
      return `${minutes} min ${seconds} sec`;
    }
    if (!inputSeconds) {
      return '0';
    }
    return `${seconds} sec`;
  };

  const calculateMean = values => {
    const l = values.length;
    const sum = values.reduce((a, b) => {
      return a + b;
    }, 0);

    return sum / l;
  };

  const averageApptTime = useMemo(() => {
    const sorted = pickedUp
      .filter(({ totalAppointmentTime }) => totalAppointmentTime)
      .map(({ totalAppointmentTime }) => {
        return Math.round(totalAppointmentTime);
      })
      .sort((a, b) => {
        return a - b;
      });

    const filterOutliers = sorted.filter(outliers());

    return calculateMean(filterOutliers);
  }, [pickedUp]);

  const averagePickTime = useMemo(() => {
    const picked = [...consolidated, ...loadingOut, ...pickedUp]
      .filter(({ totalPickTime }) => totalPickTime)
      .map(({ totalPickTime, scannedItems }) => {
        const numOfItems = Object.keys(scannedItems).length;

        return totalPickTime / numOfItems;
      })
      .sort((a, b) => {
        return a - b;
      });

    const filterOutliers = picked.filter(outliers());

    return calculateMean(filterOutliers);
  }, [consolidated, loadingOut, pickedUp]);

  const upcomingAppointments = useCallback(
    minutes => {
      const projectedTime = new Date().setMinutes(new Date().getMinutes() + minutes);

      return [...scheduled, ...awaitingConsolidation].filter(
        ({ approxPickupTime }) => approxPickupTime.toDate() <= projectedTime
      );
    },
    [scheduled, awaitingConsolidation]
  );

  const appointmentsBehind = useMemo(() => {
    const appointments = upcomingAppointments(30);

    return appointments.length;
  }, [upcomingAppointments]);

  const totalItems = useMemo(() => {
    const appointments = upcomingAppointments(timeAhead);

    return appointments.reduce((total, { itemIds }) => {
      return total + itemIds.length;
    }, 0);
  }, [timeAhead, upcomingAppointments]);

  const completed = pickedUp.filter(
    ({ pickedUpTime, scannedItems = {}, approxPickupTime, createdAt }) =>
      // appointments actually completed and scheduled ahead of time
      pickedUpTime && !isEmpty(scannedItems) && approxPickupTime?.seconds - createdAt?.seconds > 900
  );

  const percentOver2Min =
    (completed.filter(p => p.totalAppointmentTime > 120).length * 100) / completed.length;

  return (
    <>
      <Header size={'large'}>{name} -- Daily Snapshot</Header>
      <Grid stackable centered>
        <Grid.Row columns={3}>
          <Grid.Column>
            <Segment textAlign={'center'} color={'red'} padded={'very'}>
              <Statistic className={'mt-4 mb-4'}>
                <Statistic.Value>{appointmentsBehind}</Statistic.Value>
                <Statistic.Label>Behind Preparedness</Statistic.Label>
              </Statistic>
            </Segment>
          </Grid.Column>
          <Grid.Column>
            <Segment textAlign={'center'} color={'orange'} padded={'very'}>
              <Statistic className={'mt-4 mb-4'}>
                <Statistic.Value>{formatSeconds(averagePickTime)}</Statistic.Value>
                <Statistic.Label>Per Item</Statistic.Label>
              </Statistic>
            </Segment>
          </Grid.Column>
          <Grid.Column>
            <Segment color={'blue'} textAlign={'center'} padded={'very'}>
              <Statistic className={'mt-4 mb-3'}>
                <Statistic.Value>{totalItems}</Statistic.Value>
                <Statistic.Label>Items</Statistic.Label>
                <Button.Group className={'mt-2'} compact vertical>
                  <Button
                    color={timeAhead === 30 ? 'blue' : null}
                    content={'Next 30 min'}
                    onClick={() => setTimeAhead(30)}
                  />
                  <Button
                    color={timeAhead === 60 ? 'blue' : null}
                    content={'Next 60 min'}
                    onClick={() => setTimeAhead(60)}
                  />
                </Button.Group>
              </Statistic>
            </Segment>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row columns={'1'}>
          <Grid.Column>
            <Segment textAlign={'center'} color={'green'} padded={'very'}>
              <div>
                <Statistic className={'mt-4 mb-3 mr-3'}>
                  <Statistic.Value>{formatSeconds(averageApptTime)}</Statistic.Value>
                  <Statistic.Label>Avg Customer Wait Time</Statistic.Label>
                </Statistic>
                <Statistic className={'mt-4 mb-3'}>
                  <Statistic.Value>
                    {isNaN(percentOver2Min) ? 0 : percentOver2Min.toFixed(2)}%
                  </Statistic.Value>
                  <Statistic.Label>Over 2 minutes</Statistic.Label>
                </Statistic>
              </div>
              <AverageApptTimeTable completed={completed} />
            </Segment>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </>
  );
};

DailySnapshot.propTypes = {
  affiliate: PropTypes.object.isRequired,
};

const AverageApptTimeTable = ({ completed }) => {
  const [apptTableModal, setApptTableModal] = useState(false);

  const sorted = sortByArrivalTime(completed);

  return (
    <>
      <Modal
        size={'large'}
        closeIcon
        closeOnDimmerClick={false}
        onClose={() => setApptTableModal(false)}
        open={apptTableModal}
        trigger={
          <Button className={'mt-2'} onClick={() => setApptTableModal(true)} color={'green'}>
            View Appointments
          </Button>
        }
      >
        <Modal.Header>{`Appointments || ${new Date().toDateString()}`}</Modal.Header>
        {!sorted || (sorted.length <= 0 && <Modal.Content>No Appointments Logged</Modal.Content>)}
        <Modal.Content scrolling>
          <Table celled unstackable>
            <Table.Header>
              <Table.HeaderCell>Name</Table.HeaderCell>
              <Table.HeaderCell>Customer Id</Table.HeaderCell>
              <Table.HeaderCell>Created At</Table.HeaderCell>
              <Table.HeaderCell>Scheduled Pick Up Time</Table.HeaderCell>
              <Table.HeaderCell>Item Count</Table.HeaderCell>
              <Table.HeaderCell>Pick Start Time</Table.HeaderCell>
              <Table.HeaderCell>Arrival Time</Table.HeaderCell>
              <Table.HeaderCell>Appointment End Time</Table.HeaderCell>
            </Table.Header>
            <Table.Body>
              {sorted.map((appointment, key) => {
                const over2min = appointment.totalAppointmentTime > 120;

                return (
                  <Table.Row key={key} error={over2min}>
                    <Table.Cell>
                      {`${appointment.customer.firstName} ${appointment.customer.lastName}`}
                    </Table.Cell>
                    <Table.Cell>
                      <Link to={`${routes.CUSTOMER}/${appointment.customer.id}`}>
                        {appointment.customer.id}
                      </Link>
                    </Table.Cell>
                    <Table.Cell>
                      {new Date(convertDateFBtoJS(appointment.approxPickupTime)).toLocaleTimeString(
                        'en-US',
                        setDateOptions({ hour: '2-digit', minute: '2-digit' })
                      )}
                    </Table.Cell>
                    <Table.Cell>
                      {new Date(convertDateFBtoJS(appointment.createdAt)).toLocaleTimeString(
                        'en-US',
                        setDateOptions({ hour: '2-digit', minute: '2-digit' })
                      )}
                    </Table.Cell>
                    <Table.Cell>{Object.keys(appointment.scannedItems).length}</Table.Cell>
                    <Table.Cell>
                      {new Date(convertDateFBtoJS(appointment.pickStartTime)).toLocaleTimeString(
                        'en-US',
                        setDateOptions({ hour: '2-digit', minute: '2-digit' })
                      )}
                    </Table.Cell>
                    <Table.Cell>
                      {new Date(convertDateFBtoJS(appointment.arrivalTime)).toLocaleTimeString(
                        'en-US',
                        setDateOptions({ hour: '2-digit', minute: '2-digit' })
                      )}
                    </Table.Cell>
                    <Table.Cell>
                      {new Date(convertDateFBtoJS(appointment.pickedUpTime)).toLocaleTimeString(
                        'en-US',
                        setDateOptions({ hour: '2-digit', minute: '2-digit' })
                      )}
                    </Table.Cell>
                  </Table.Row>
                );
              })}
            </Table.Body>
          </Table>
        </Modal.Content>
      </Modal>
    </>
  );
};

AverageApptTimeTable.propTypes = {
  completed: PropTypes.array.isRequired,
};

const DailySnapshotInitialization = () => {
  const [queueAffiliateId, setQueueAffiliateId] = useState(null);

  return (
    <AuthUserContext.Consumer>
      {({ authUser, ...restConsumer }) =>
        authUser ? (
          <API.Query queries={[{ route: 'users' }]} api={restConsumer.api}>
            {({ data: [users] }) => {
              const u = users.reduce((acc, val) => ({ ...acc, [val.id]: val }), {});

              return (
                <API.Query queries={[{ route: 'locations' }]} api={new CargoApi()}>
                  {({ data: [locations] }) => {
                    if (!queueAffiliateId) {
                      return (
                        <>
                          <Header content={'Stats Dashboard'} size={'large'} />
                          <Dropdown
                            placeholder={'Select location'}
                            fluid
                            selection
                            onChange={(event, { value }) => setQueueAffiliateId(value)}
                            options={locations
                              .filter(loc => loc.curbsideAvailable)
                              .map((loc, key) => ({
                                key,
                                text: loc.name,
                                value: loc.affiliateId,
                              }))}
                            value={queueAffiliateId}
                          />
                        </>
                      );
                    }

                    const affiliate = locations.find(loc => loc.affiliateId === queueAffiliateId);

                    return (
                      <>
                        <PollingPickupQueueData affiliateId={affiliate.affiliateId}>
                          {pickupQueueData => {
                            // eslint-disable-next-line no-unused-vars
                            const { undefined: useLess, ...z } = pickupQueueData.reduce(
                              (acc, value) => {
                                const prev = acc[value.consolidatedBy];

                                const itemCount = Object.values(value?.scannedItems || {}).filter(
                                  item => item.scanned
                                ).length;

                                const missingCount = Object.values(
                                  value?.scannedItems || {}
                                ).filter(item => item.lost).length;

                                return {
                                  ...acc,
                                  totalItemCount: acc.totalItemCount + itemCount,
                                  totalMissingCount: acc.totalMissingCount + missingCount,
                                  [value.consolidatedBy]: {
                                    ...prev,
                                    orderCount: prev.orderCount + 1,
                                    itemCount: prev.itemCount + itemCount,
                                    missing: prev.missing + missingCount,
                                  },
                                };
                              },
                              pickupQueueData.reduce((acc, value) => {
                                return {
                                  ...acc,
                                  totalItemCount: 0,
                                  totalMissingCount: 0,
                                  [value.consolidatedBy]: {
                                    name: `${u[value.consolidatedBy]?.firstName} ${
                                      u[value.consolidatedBy]?.lastName
                                    }`,
                                    orderCount: 0,
                                    itemCount: 0,
                                    missing: 0,
                                  },
                                };
                              }, {})
                            );

                            const { totalItemCount, totalMissingCount, ...countsByEmployee } = z;

                            return (
                              <Grid columns={2} stackable>
                                <Grid.Row>
                                  <Grid.Column width={11}>
                                    <DailySnapshot affiliate={affiliate} />
                                  </Grid.Column>
                                  <Grid.Column width={5}>
                                    {!isEmpty(z) && (
                                      <>
                                        <Label color={'green'}>
                                          Total Items:
                                          <Label.Detail>{totalItemCount}</Label.Detail>
                                        </Label>
                                        <Label color={'red'}>
                                          Total Missing:
                                          <Label.Detail>
                                            {totalMissingCount} |{' '}
                                            {(
                                              (totalMissingCount * 100) /
                                              (totalMissingCount + totalItemCount)
                                            ).toFixed(2)}
                                            %
                                          </Label.Detail>
                                        </Label>
                                        <EmployeeCountsTable
                                          tableData={Object.values(countsByEmployee)}
                                        />
                                      </>
                                    )}
                                  </Grid.Column>
                                </Grid.Row>
                                <Grid.Row>
                                  <AppointmentCountsChart
                                    affiliateId={affiliate.affiliateId}
                                    pickupQueueData={pickupQueueData}
                                  />
                                </Grid.Row>
                              </Grid>
                            );
                          }}
                        </PollingPickupQueueData>
                      </>
                    );
                  }}
                </API.Query>
              );
            }}
          </API.Query>
        ) : null
      }
    </AuthUserContext.Consumer>
  );
};

export default DailySnapshotInitialization;
