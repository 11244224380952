import React, { useReducer, useEffect } from 'react';
import { Label, Message } from 'semantic-ui-react';
import { useLocation } from 'react-router-dom';
import CargoApi from '../shared-api-adapters/cargo-api';
import { CurbSide } from './curbside-city';

const cargoApi = new CargoApi(null);

const statusTypes = {
  PENDING: 'PENDING',
  SUCCESS: 'SUCCESS',
  ERROR: 'ERROR',
};

const types = {
  FETCH: 'FETCH',
  FETCH_COMPLETE: 'FETCH_COMPLETE',
  SET_CUSTOMER_INFO: 'SET_CUSTOMER_INFO',
  SET_USER: 'SET_USER',
  TOKEN_ERROR: 'TOKEN_ERROR',
};

const curbSideReducer = (state, action) => {
  switch (action.type) {
    case types.FETCH:
      return {
        ...state,
        status: statusTypes.PENDING,
      };
    case types.FETCH_COMPLETE:
      return {
        ...state,
        status: action.status,
      };
    case types.SET_USER:
      return {
        ...state,
        customerId: action.customerId,
      };
    case types.TOKEN_ERROR:
      return {
        ...state,
        status: statusTypes.ERROR,
        errorMessage:
          'It is likely your token has expired and you need to login to nellisauction.com again.',
      };
    default:
      return state;
  }
};

export const CurbSideAuth = () => {
  const location = useLocation();
  const [state, dispatch] = useReducer(curbSideReducer, {
    status: 'PENDING',
    page: -2,
    customerId: null,
  });

  // eslint-disable-next-line
  const params = new URLSearchParams(location.search);
  const token = params.get('token');

  const getUserId = async () => {
    if (!token) return window.open('https://www.nellisauction.com/login/', '_self');

    dispatch({ type: types.FETCH });

    try {
      const [{ userData }] = await cargoApi.get(cargoApi.routes.verifyUser, token);

      dispatch({ type: types.SET_USER, customerId: Number(userData.id) });
      dispatch({ type: types.FETCH_COMPLETE });
    } catch (error) {
      dispatch({ type: types.TOKEN_ERROR });
    }
  };

  useEffect(getUserId, [token]);

  if (state.status === statusTypes.ERROR)
    return (
      <Message className={'mt-2'} color={'red'}>
        <Message.Header>Something went wrong!</Message.Header>
        <Message.Content className={'mt-2'}>{state.errorMessage}</Message.Content>
        <Message.Content className={'mt-2'}>
          Please call <Label>702-531-1300 OPTION 0</Label>
        </Message.Content>
      </Message>
    );

  return state.customerId ? (
    <>
      <CurbSide customerId={state.customerId} />
    </>
  ) : null;
};
