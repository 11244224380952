import React, { Component } from 'react';
import API from './api';
import { Button } from 'semantic-ui-react';
import PropTypes from 'prop-types';

class CrudButton extends Component {
  resolveButtonColor = status => {
    switch (status) {
      case null:
        return null;
      case true:
        return 'green';
      case false:
        return 'red';
      default:
        return null;
    }
  };

  render() {
    const {
      api,
      route,
      submissionData,
      onSuccessfulSubmit,
      onUnsuccessfulSubmit,
      ...rest
    } = this.props;

    return (
      <API.Crud route={route} api={api}>
        {({ post, loading, success }) => {
          return (
            <>
              <Button
                {...rest}
                color={this.resolveButtonColor(success)}
                loading={loading}
                onClick={async () => {
                  const result = await post(submissionData);

                  if (result) onSuccessfulSubmit();
                  if (!result) onUnsuccessfulSubmit();
                }}
              />
            </>
          );
        }}
      </API.Crud>
    );
  }
}

CrudButton.defaultProps = {
  onSuccessfulSubmit: () => {},
  onUnsuccessfulSubmit: () => {},
};

CrudButton.propTypes = {
  route: PropTypes.string.isRequired,
  submissionData: PropTypes.object.isRequired,
  api: PropTypes.any.isRequired,
  onSuccessfulSubmit: PropTypes.func.isRequired,
  onUnsuccessfulSubmit: PropTypes.func.isRequired,
};

export default CrudButton;
