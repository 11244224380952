import React, { Component, Fragment } from 'react';
import { Button, Header, Form as SemForm } from 'semantic-ui-react';
import { validators } from '../helpers/react-final-form';
import { Form, Field } from 'react-final-form';
import config from '../config';

const { required, digitLength, composeValidators } = validators;

class Refunds extends Component {
  state = {
    prevNum: null,
    newNum: null,
  };

  prevRef = React.createRef();
  newRef = React.createRef();

  basePath = config.cargo.url;

  onSubmit = ({ prevNum, newNum }) => {
    if (!prevNum || !newNum) return null;

    window.open(`${this.basePath}/item/${newNum}/form/main?prevInventoryNumber=${prevNum}`);

    return null;
  };

  componentDidMount() {
    this.prevRef.current.focus();
  }

  render() {
    return (
      <Form
        onSubmit={this.onSubmit}
        render={({ handleSubmit, submitting, pristine, values, form }) => (
          <SemForm onSubmit={handleSubmit} className={'flex fd-column jc-center ai-center'}>
            <Header>Previous Inventory Number</Header>
            <Field name={'prevNum'} validate={composeValidators(digitLength(10), required)}>
              {({ input, meta }) => {
                if (input.value.length === 10) this.newRef.current.focus();
                return (
                  <Fragment>
                    <div className={`ui large left icon input`}>
                      <input type={'number'} ref={this.prevRef} {...input} />
                      <i aria-hidden={'true'} className={'barcode icon'} />
                    </div>
                    {meta.touched && meta.error && <span className={'c-red'}>{meta.error}</span>}
                  </Fragment>
                );
              }}
            </Field>

            <Header>New Inventory Number</Header>
            <Field name={'newNum'} validate={composeValidators(digitLength(10), required)}>
              {({ input, meta }) => {
                if (input.value.length === 10 && form.getState().valid) {
                  handleSubmit();
                  form.reset();
                  this.prevRef.current.focus();
                }
                return (
                  <Fragment>
                    <div className={'ui large left icon input'}>
                      <input type={'number'} ref={this.newRef} {...input} />
                      <i aria-hidden={'true'} className={'barcode icon'} />
                    </div>
                    {meta.touched && meta.error && <span className={'c-red'}>{meta.error}</span>}
                  </Fragment>
                );
              }}
            </Field>

            <div className={'mt-3'}>
              <Button type="submit" disabled={submitting} size={'large'}>
                Submit
              </Button>
              <Button
                type="button"
                onClick={() => {
                  form.reset();
                  this.prevRef.current.focus();
                }}
                disabled={submitting || pristine}
                size={'large'}
              >
                Reset
              </Button>
            </div>
          </SemForm>
        )}
      />
    );
  }
}

export default Refunds;
