import React from 'react';
import { Button, Divider } from 'semantic-ui-react';
import PropTypes from 'prop-types';

export const CurbSidePickupLocation = ({ hidden, loading, affiliateIds, affiliates, onClick }) => {
  if (loading || hidden) return null;

  return (
    <>
      <h3>Choose a location to schedule an appointment</h3>
      <div>
        {affiliates
          .filter(({ affiliate }) => affiliateIds.includes(affiliate))
          .map(({ affiliate, name }) => {
            return (
              <Button
                key={`affiliate_${affiliate}`}
                content={name}
                fluid
                className={'mb-1'}
                onClick={() => onClick(affiliate)}
              />
            );
          })}
      </div>
      <Divider />
    </>
  );
};

CurbSidePickupLocation.propTypes = {
  hidden: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  affiliates: PropTypes.array.isRequired,
  affiliateIds: PropTypes.array.isRequired,
};
