import React, { Component, Fragment } from 'react';
import { Button, Icon, Table, Search } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import * as routes from '../constants/routes';
import UserFilters from './user-filters';
import Paginater from './paginater';

class UsersTable extends Component {
  constructor(props) {
    super(props);

    this.state = {
      filter: props.defaultFilter,
      results: [],
      searchLoading: false,
      value: '',
    };
  }

  formatResult = results => {
    const { supervisorIds } = this.props;

    return results.map(result => ({
      key: result.id,
      title: `${result.firstName} ${result.lastName}`,
      data: { userId: result.id, isSupervisor: supervisorIds.includes(result.id) },
    }));
  };

  handleSearchChange = (e, { value }) => {
    this.setState({ searchLoading: true, value });

    if (value.length <= 0) return this.setState({ searchLoading: false });

    setTimeout(() => {
      this.props.api.get(this.props.api.routes.users, null, { search: value }).then(results =>
        this.setState({
          searchLoading: false,
          results: results ? this.formatResult(results) : [],
        })
      );
    }, 300);

    return null;
  };

  resultRenderer = ({ title, data }) => (
    <div>
      {data.isSupervisor && (
        <Link to={`${routes.TEAM}/${data.userId}`}>
          <Icon name={'id badge outline'} color={'green'} />
        </Link>
      )}
      <Link to={`${routes.USERS}/${data.userId}`}>{`${title}`}</Link>
    </div>
  );

  setFilter = (event, { filter }) => this.setState({ filter, activePage: 1 });

  render() {
    const {
      props: { users, supervisorIds, filters, title },
      state: { filter, results, searchLoading, value },
    } = this;

    const filteredUsers = users.filter(user => {
      switch (filter) {
        case 'supervisors':
          return supervisorIds.includes(user.id) && user.statusTypeId === 1;
        case 'active':
          return user.statusTypeId === 1;
        case 'inactive':
          return user.statusTypeId === 2;
        case 'pending':
          return user.statusTypeId === 3;
        case 'unassigned':
          return user.supervisorId === 0 && user.statusTypeId === 1;
        default:
          return user;
      }
    });

    return (
      <Fragment>
        {title && <h3 className={'ff-jura'}>{title}</h3>}
        {filters && (
          <div className={'mb-3 flex fd-row ai-center jc-between'}>
            <UserFilters
              active={filter}
              filters={['All', 'Active', 'Inactive', 'Pending', 'Unassigned', 'Supervisors']}
              onClick={this.setFilter}
            />
            <Search
              loading={searchLoading}
              onSearchChange={this.handleSearchChange}
              resultRenderer={this.resultRenderer}
              results={results}
              value={value}
              size={'small'}
            />
            <Button
              as={Link}
              to={`${routes.USERS}/create`}
              icon
              labelPosition="left"
              color={'blue'}
              size="small"
            >
              <Icon name="user" /> Add User
            </Button>
          </div>
        )}
        <Paginater items={filteredUsers} maxItemsPerPage={10} domain={filter}>
          {({ itemsOnThisPage, renderPaginater }) => (
            <Table celled compact definition striped unstackable>
              <Table.Header fullWidth>
                <Table.Row>
                  <Table.HeaderCell className={'w-5 t-center'}>ID</Table.HeaderCell>
                  <Table.HeaderCell>Name</Table.HeaderCell>
                  <Table.HeaderCell>Location</Table.HeaderCell>
                  <Table.HeaderCell>Email</Table.HeaderCell>
                  <Table.HeaderCell>Phone</Table.HeaderCell>
                </Table.Row>
              </Table.Header>

              <Table.Body className={'tr-hover'}>
                {itemsOnThisPage.map((user, key) => (
                  <Table.Row key={key}>
                    <Table.Cell className={'w-5 t-center'}>
                      <Link to={`${routes.USERS}/${user.id}`}>{user.id}</Link>
                    </Table.Cell>
                    <Table.Cell>
                      {user.id && supervisorIds.includes(user.id) && (
                        <Link to={`${routes.TEAM}/${user.id}`}>
                          <Icon name={'id badge outline'} color={'green'} />
                        </Link>
                      )}
                      <Link to={`${routes.USERS}/${user.id}`}>
                        {`${user.firstName} ${user.lastName}`}
                      </Link>
                    </Table.Cell>
                    <Table.Cell>
                      {user.location && `${user.location.name} - ${user.location.city}`}
                    </Table.Cell>
                    <Table.Cell>{user.email}</Table.Cell>
                    <Table.Cell>{user.phoneNumber}</Table.Cell>
                  </Table.Row>
                ))}
              </Table.Body>

              <Table.Footer fullWidth>
                <Table.Row className={'h-3'}>
                  <Table.HeaderCell />
                  <Table.HeaderCell colSpan="4">{renderPaginater()}</Table.HeaderCell>
                </Table.Row>
              </Table.Footer>
            </Table>
          )}
        </Paginater>
      </Fragment>
    );
  }
}

UsersTable.defaultProps = {
  defaultFilter: 'active',
  filters: true,
};

UsersTable.propTypes = {
  api: PropTypes.object,
  defaultFilter: PropTypes.string,
  filters: PropTypes.bool,
  supervisorIds: PropTypes.array,
  title: PropTypes.string,
  users: PropTypes.array.isRequired,
};

export default UsersTable;
