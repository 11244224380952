import React from 'react';
import { escapeRegExp } from 'lodash/fp';
import PropTypes from 'prop-types';

class DataFilter extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      value: props.initialValue || '',
      filteredData: [],
    };
  }

  resetComponent = () => {
    this.setState({ filteredData: [], value: '' });
  };

  handleSearchValueChange = (event, { value }) => {
    const { data, filterBy, conditionalOverride, multiDataSet = [] } = this.props;

    this.setState({ isLoading: true, value });

    setTimeout(() => {
      if (this.state.value.length === 0) return this.resetComponent();

      const re = new RegExp(escapeRegExp(value), 'i');

      if (multiDataSet.length > 0) {
        return this.setState({
          isLoading: false,
          filteredData: multiDataSet.map(mData =>
            mData.filter(d => re.test(d[filterBy]) || conditionalOverride(d, re, value))
          ),
        });
      }

      return this.setState({
        isLoading: false,
        filteredData: data.filter(d => re.test(d[filterBy]) || conditionalOverride(d, re, value)),
      });
    }, 300);
  };

  componentDidUpdate(prevProps) {
    if (JSON.stringify(prevProps.data) !== JSON.stringify(this.props.data)) {
      this.handleSearchValueChange({}, { value: this.state.value });
    }
    if (JSON.stringify(prevProps.multiDataSet) !== JSON.stringify(this.props.multiDataSet)) {
      this.handleSearchValueChange({}, { value: this.state.value });
    }
  }

  handleSearchValueClear = () => this.resetComponent();

  render() {
    const {
      handleSearchValueChange,
      handleSearchValueClear,
      props: { children, data, multiDataSet },
      state: { filteredData, value },
    } = this;

    return children({
      handleSearchValueChange,
      handleSearchValueClear,
      value,
      // if filter results in empty dataset, return original data
      filteredData: value ? filteredData : data || multiDataSet,
    });
  }
}

DataFilter.defaultProps = {
  conditionalOverride: () => {},
};

DataFilter.propTypes = {
  data: PropTypes.array,
  multiDataSet: PropTypes.array,
  children: PropTypes.func.isRequired,
  filterBy: PropTypes.string.isRequired,
  conditionalOverride: PropTypes.func.isRequired,
  initialValue: PropTypes.string,
};

export default DataFilter;
