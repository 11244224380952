import ApiService from './api-service';
import { auctionConcoction } from './routes';
import config from '../config';

class AuctionConcoction extends ApiService {
  constructor(token = null, hostnameOverride = null) {
    super(token);
    this.hostname = hostnameOverride || config.auctionConcoction.hostname;
    this.routes = auctionConcoction;
  }

  uploadCsv = async formData => {
    this.response = await this.axios
      .post(`${this.hostname}${this.routes.manifestUpload}`, formData)
      .catch(error => error.response);

    return this._validateStatus();
  };
}

export default AuctionConcoction;
