import React, { useContext, useEffect, useState } from 'react';
import AuthUserContext from './auth-user-context';
import { Label, Header, Icon, Form, Message } from 'semantic-ui-react';
import AuctionConcoction from '../shared-api-adapters/auction-concoction';
import { useTableCreator } from '../shared-components/table-hooks';
import { format } from 'date-fns';

const StoreCreditReport = () => {
  const auctionConcoctionApi = new AuctionConcoction();
  const { api } = useContext(AuthUserContext);
  const [nellisAuctionApi] = useState(api);
  const [customerId, updateCustomerId] = useState('');
  const [{ id, firstname: firstName, lastname: lastName } = {}, setCustomer] = useState({});
  const [storeCredit, setStoreCredit] = useState([]);
  const [storeCreditAccumulator, accumulateStoreCredit] = useState(0);
  const [submitted, submit] = useState(false);

  const table = useTableCreator({
    data: storeCredit
      .sort(({ createdAt: a }, { createdAt: b }) => new Date(b) - new Date(a))
      .map(({ amount, userId, invoiceId, notes, createdAt }) => ({
        amount: amount.toFixed(2),
        userId,
        invoiceId,
        notes,
        createdAt: format(new Date(createdAt), 'MM/dd/yyyy'),
        positive: amount > 0,
        negative: amount < 0,
      })),
    maxItemsPerPage: 5,
    basic: 'very',
    textAlign: 'center',
  });

  useEffect(() => {
    if (!storeCredit) return;
    accumulateStoreCredit(
      Number(
        storeCredit
          .reduce((total, { amount = 0 }) => Number(total.toFixed(2)) + amount, 0)
          .toFixed(2)
      )
    );
  }, [storeCredit]);

  useEffect(() => {
    const fetchStoreCredit = async () => {
      return await nellisAuctionApi.get(nellisAuctionApi.routes.storeCreditByCustomer, id);
    };

    if (!id) {
      setStoreCredit([]);
    } else {
      fetchStoreCredit().then(results => {
        setStoreCredit(results);
      });
    }
  }, [id, nellisAuctionApi]);

  return (
    <div>
      <Form
        onSubmit={async () => {
          if (!customerId) return;

          const [{ customer_profile: customerProfile = {} } = {}] = await auctionConcoctionApi.get(
            `/customers/${customerId}`
          );

          setCustomer(customerProfile || {});
          submit(true);
        }}
      >
        <Form.Input
          placeholder={'Customer ID'}
          type={'number'}
          value={customerId}
          onChange={(event, { value }) => updateCustomerId(value)}
          icon={{
            name: customerId ? 'close' : 'search',
            link: true,
            onClick: () => {
              submit(false);
              updateCustomerId('');
              setCustomer([]);
              setStoreCredit([]);
            },
          }}
        />
      </Form>
      {submitted && !id && <Message content={'Customer not found!'} />}
      {id && storeCredit.length === 0 && (
        <Message content={`No store credit found for ${firstName} ${lastName}!`} />
      )}
      {table && (
        <>
          <Header
            content={
              <div className={'flex jc-between ai-center'}>
                <div className={'flex'}>
                  <Icon name={'user'} size={'large'} />
                  <div>
                    {firstName} {lastName}
                  </div>
                </div>
                <div>
                  <Label size={'large'} content={`$${storeCreditAccumulator.toFixed(2)}`} />
                </div>
              </div>
            }
          />
          {table}
        </>
      )}
    </div>
  );
};

export default StoreCreditReport;
