import React from 'react';
import { List, Segment } from 'semantic-ui-react';
import PropTypes from 'prop-types';
import Closeable from './closeable';

class ClosableList extends React.Component {
  render() {
    const { items } = this.props;

    return (
      <Closeable>
        <Segment className={'mh-30r ofy-auto zi-1'}>
          {items.length > 0 ? <List selection items={items} /> : 'No results'}
        </Segment>
      </Closeable>
    );
  }
}

ClosableList.propTypes = {
  items: PropTypes.array.isRequired,
  onClick: PropTypes.func,
};

export default ClosableList;
