import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { Divider, Button, Icon, Label, Message, Grid, Card } from 'semantic-ui-react';
import { useWindowSize } from 'react-use';
import AuthUserContext from './auth-user-context';
import config from '../config';

/**
 * Takes an array of data and returns:
 *  - 1) an array of arrays (i.e. list of columns) of passed in length (i.e. column count)
 *  - 2) number of columns filled (for 'columns' property in Grid)
 *  - Used to align Semantic UI Grid Columns in a natural order when displaying sequential data
 *  - [ [Column 1], [Column 2], ... , [Column n] ]
 *
 * @param data
 * @param numberOfColumns
 * @returns {any[]}
 */
const useAlignColumns = (data, numberOfColumns) => {
  const { columnAcc: columns } = data.reduce(
    ({ currentColumn, columnAcc }, item) => {
      return {
        currentColumn: currentColumn === numberOfColumns - 1 ? 0 : currentColumn + 1,
        columnAcc: { ...columnAcc, [currentColumn]: [...columnAcc[currentColumn], item] },
      };
    },
    {
      currentColumn: 0,
      columnAcc: Object.assign({}, new Array(numberOfColumns).fill([])),
    }
  );

  return [
    Object.values(columns),
    Object.values(columns).filter(column => column.length > 0).length || 1,
  ];
};

const InvoiceCirculation = ({ invoices, onSuccessfulSubmission }) => {
  const { width } = useWindowSize();
  const mobile = useMemo(() => width < 768, [width]);
  const { auctionMethodApi } = useContext(AuthUserContext);

  const [checkboxes, updateCheckboxes] = useState({});
  const [loading, load] = useState(false);
  const [error, setError] = useState(false);

  const items = useMemo(
    () =>
      invoices
        .map(({ line_items: lineItems }) => lineItems)
        .flat()
        // Whitelisted items
        .filter(({ item_status: { id: itemStatus } = {} }) => [0, 2].includes(itemStatus)),
    [invoices]
  );

  const [columns, columnCount] = useAlignColumns(
    Object.entries(checkboxes).map(([inventoryNumber, info]) => ({ ...info, inventoryNumber })),
    4
  );

  useEffect(() => {
    updateCheckboxes(
      items.reduce(
        (asCheckboxes, { internal_id: inventoryNumber, location_id: location }) => ({
          ...asCheckboxes,
          [inventoryNumber]: {
            selected: true,
            relisted: false,
            location,
          },
        }),
        {}
      )
    );
  }, [items]);

  // At least one item was re-listed
  const someItemRelisted = useMemo(
    () => Object.values(checkboxes).some(({ relisted }) => relisted),
    [checkboxes]
  );

  // At least one item is selected
  const someItemSelected = useMemo(
    () => Object.values(checkboxes).some(({ selected }) => selected),
    [checkboxes]
  );

  // Not every item is selected
  const notAllItemsSelected = useMemo(
    () => Object.values(checkboxes).some(({ selected, relisted }) => !selected && !relisted),
    [checkboxes]
  );

  // All currently selected items
  const selectedItems = useMemo(
    () =>
      Object.entries(checkboxes)
        .filter(([, { selected }]) => selected)
        .map(([inventoryNumber]) => inventoryNumber),
    [checkboxes]
  );

  const submitToAm = useCallback(() => {
    load(true);

    let successful = true;

    Promise.all(
      selectedItems.map(internalId =>
        auctionMethodApi
          .post(auctionMethodApi.routes.relistItem, {
            InternalID: internalId,
            AuctionID: config.auctionMethodApi.auctionId,
            ItemStatusID: '4',
            RelistingAmount: 0,
            ModifyInvoice: 'Do nothing',
          })
          .then(response => {
            const { status = 200 } = response;

            if (status !== 200 || response === false) {
              successful = false;
            } else {
              updateCheckboxes(prevState => ({
                ...prevState,
                [internalId]: { ...prevState[internalId], selected: false, relisted: true },
              }));
            }
          })
          .catch(() => {
            successful = false;
          })
      )
    )
      .then(() => {
        if (successful) {
          onSuccessfulSubmission();
        } else {
          setError(true);
        }
      })
      .finally(() => load(false));
  }, [selectedItems, onSuccessfulSubmission, auctionMethodApi]);

  useEffect(() => {
    if (loading) setError(false);
  }, [loading]);

  const updateCheckbox = useCallback(
    inventoryNumber => {
      if (loading) return;

      updateCheckboxes(currCheckboxes => ({
        ...currCheckboxes,
        [inventoryNumber]: {
          ...currCheckboxes[inventoryNumber],
          selected:
            !currCheckboxes[inventoryNumber].selected && !currCheckboxes[inventoryNumber].relisted,
        },
      }));
    },
    [loading]
  );

  if (invoices.length === 0 || items.length === 0) return null;

  return (
    <>
      <Grid columns={2} stackable reversed={'mobile'}>
        <Grid.Column textAlign={mobile ? null : 'left'} verticalAlign={'middle'}>
          <Button
            disabled={loading}
            color={'grey'}
            content={notAllItemsSelected ? 'Select all' : 'Deselect all'}
            onClick={() =>
              updateCheckboxes(currCheckboxes =>
                Object.entries(currCheckboxes).reduce(
                  (asCheckboxes, [inventoryNumber, { relisted }]) => {
                    return {
                      ...asCheckboxes,
                      [inventoryNumber]: {
                        ...currCheckboxes[inventoryNumber],
                        selected: !relisted && notAllItemsSelected,
                      },
                    };
                  },
                  {}
                )
              )
            }
          />
        </Grid.Column>
        <Grid.Column textAlign={mobile ? null : 'right'} verticalAlign={'middle'}>
          <Button
            loading={loading}
            disabled={!someItemSelected}
            color={'teal'}
            icon={'undo'}
            content={'Re-list'}
            onClick={submitToAm}
          />
        </Grid.Column>
      </Grid>
      {error && (
        <Message negative>
          <Icon name={'exclamation circle'} />
          An error occurred. The remaining selected items could not be re-listed. Please try again.
        </Message>
      )}
      <Divider />
      {someItemRelisted && (
        <Message>
          <Icon color={'green'} name={'check'} />
          Items successfully re-listed
        </Message>
      )}
      <div className={'flex fd-column ai-start'}>
        <Label
          color={'blue'}
          className={'mb-3'}
          icon={'clipboard list'}
          content={`Selected Items: ${selectedItems.length}`}
        />

        <Grid columns={columnCount} stackable>
          {columns.map((column, i) => {
            if (column.length === 0) return null;

            return (
              <Grid.Column key={i}>
                {column.map(({ inventoryNumber, selected, relisted, location }, j) => {
                  const getIcon = () => {
                    if (relisted) return { name: 'check', color: 'green' };
                    if (selected) return { name: 'check square outline' };
                    return { name: 'square outline' };
                  };

                  return (
                    <Card
                      key={j}
                      as={'div'}
                      className={'pointer'}
                      onClick={() => updateCheckbox(inventoryNumber)}
                    >
                      <Card.Content className={'flex ai-center'}>
                        <Icon size={'large'} {...getIcon()} />
                        <div>
                          {inventoryNumber?.slice(4)}
                          <Card.Meta className={'ellipsis mw-7r'}>{location || 'None'}</Card.Meta>
                        </div>
                      </Card.Content>
                    </Card>
                  );
                })}
              </Grid.Column>
            );
          })}
        </Grid>
      </div>
    </>
  );
};

InvoiceCirculation.propTypes = {
  invoices: PropTypes.array.isRequired,
  onSuccessfulSubmission: PropTypes.func.isRequired,
};

export default InvoiceCirculation;
