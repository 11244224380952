/**
 * Takes an array of line item objects from Auction Method
 * and filters customer items to pick up
 *
 * @param lineItems
 * @returns {array}
 */
const filterCustomerItems = lineItems => {
  const inventoryNumbers = lineItems.map(({ internal_id: inventoryNumber }) => inventoryNumber);

  // Filter out:
  // - non-inventory items, 0 in Auction Method
  // - duplicates, if there is a duplicate, that item was re-listed
  return lineItems.filter(
    ({ item_status: { id: itemStatus }, internal_id: inventoryNumber }) =>
      itemStatus === 0 &&
      inventoryNumbers.reduce((total, currentNumber) => {
        if (currentNumber === inventoryNumber) return total + 1;
        return total;
      }, 0) === 1
  );
};

export { filterCustomerItems };
