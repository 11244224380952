import React, { useContext, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { useAsyncFn, useInterval, useUnmount } from 'react-use';
import {
  Button,
  Popup,
  Checkbox,
  Divider,
  Header,
  Icon,
  Label,
  Loader,
  Menu,
  Modal,
  Segment,
} from 'semantic-ui-react';
import {
  getLotQueueCollection,
  useDocumentSnapshot,
  useLotQueueSnapshot,
  useLotStructureData,
} from '../shared-components/firebase/firestore';
import { LOT_QUEUE_STATUSES, resolveStatus } from './lot-queue';
import { isEmpty, find } from 'lodash/fp';
import { differenceInMinutes } from 'date-fns';
import AuthUserContext from './auth-user-context';
import { useHistory, useLocation } from 'react-router-dom';
import * as routes from '../constants/routes';
import { urlSearchParams } from '../helpers/url-utils';

const LotDetailAppointmentReport = ({ appointment }) => {
  const { push } = useHistory();

  const appointmentData = useDocumentSnapshot(appointment);

  if (isEmpty(appointmentData)) return null;

  if (!appointmentData.exists) return <Label color={'black'}>APPOINTMENT DELETED</Label>;

  const shortNoticeBg =
    appointmentData?.approxPickupTime?.seconds - appointmentData?.createdAt?.seconds < 1800
      ? 'short-notice-background'
      : '';

  return (
    <div className={'flex fd-row'}>
      <Label {...resolveStatus(appointmentData.status)} />

      <Label
        className={`mw-7r ellipsis ${
          appointmentData?.fraud?.suspicious ? 'fraud-background' : shortNoticeBg
        }`}
        onClick={() => push(`/customer/${appointmentData.customer.id}`)}
      >
        {`${appointmentData.customer.firstName} ${appointmentData.customer.lastName}`}
      </Label>
    </div>
  );
};

LotDetailAppointmentReport.propTypes = {
  appointment: PropTypes.object.isRequired,
};

const LotDetailArrivalTimeCounter = ({ arrivalTime }) => {
  const [count, setCount] = useState(0);
  // every minute
  const [delay, setDelay] = useState(60000);

  useInterval(() => {
    // this only serves the purpose of re-rendering the component
    setCount(count + 1);
  }, delay);

  useUnmount(() => setDelay(null));

  const waitTime = differenceInMinutes(new Date(), arrivalTime);

  return `${waitTime} m`;
};

const ParkingSpotUserAssignment = ({ lot, color, onQueueAdd, lotQueueRoute }) => {
  const {
    authUser: { location, userId },
  } = useContext(AuthUserContext);
  const { push } = useHistory();

  const lotQueueCollection = getLotQueueCollection(location.affiliateId);

  const [, addParkingSpotToLotQueue] = useAsyncFn(
    async status =>
      await lotQueueCollection.add({
        userId: status === LOT_QUEUE_STATUSES.PROCESSING ? userId : null,
        arrivalTime: new Date(),
        color,
        status,
        lot,
        parkingAssignedBy: userId || null,
      }),
    [userId, color, lot, lotQueueCollection]
  );

  return (
    <>
      <Header className={'flex jc-end'}>
        <Label color={color} size={'big'}>
          <Icon name={'car'} />
          {lot}
        </Label>
      </Header>
      <Button
        fluid
        content={'Assist customer now!'}
        className={'mb-1'}
        onClick={() => {
          addParkingSpotToLotQueue(LOT_QUEUE_STATUSES.PROCESSING);

          push(lotQueueRoute);
        }}
      />
      <Button
        fluid
        content={'Add To Queue'}
        onClick={() => {
          addParkingSpotToLotQueue(LOT_QUEUE_STATUSES.IN_QUEUE);

          onQueueAdd();
        }}
      />
    </>
  );
};

ParkingSpotUserAssignment.propTypes = {
  lot: PropTypes.string.isRequired,
  color: PropTypes.string.isRequired,
  onQueueAdd: PropTypes.func.isRequired,
  lotQueueRoute: PropTypes.string.isRequired,
};

const ParkingSpotUserAssignmentPure = React.memo(
  ParkingSpotUserAssignment,
  (prevProps, nextProps) => {
    return prevProps.lot === nextProps.lot;
  }
);

const LotDetails = ({ affiliateId }) => {
  const {
    authUser: { userId },
  } = useContext(AuthUserContext);
  const { push } = useHistory();
  const routerLocation = useLocation();

  const uP = urlSearchParams(routerLocation, push);

  const [active, setActive] = useState(uP.urlParams.get('tab') || 'blue');
  const [moveMode, toggleMoveMode] = useState(false);
  const [deleteMode, toggleDeleteMode] = useState(false);
  const [lotToMove, setLotToMove] = useState();
  const [selectedLot, setSelectedLot] = useState();

  const lotQueue = useLotQueueSnapshot(affiliateId, Object.values(LOT_QUEUE_STATUSES));

  const { value: lotStructure = {} } = useLotStructureData(affiliateId);

  const [, editParkingSpot] = useAsyncFn(async fn => {
    return await fn();
  });

  useEffect(() => {
    setLotToMove(null);
    setSelectedLot(null);
  }, [moveMode, deleteMode]);

  const parkingSpots = useMemo(
    () =>
      Object.values(lotStructure).reduce((acc, val) => {
        return {
          ...acc,
          [val.color]: new Array(val.numOfSpots).fill(1).map((v, key) => {
            return `${val.color.slice(0, 1).toUpperCase()}${key + 1}`;
          }),
        };
      }, {}),
    [lotStructure]
  );

  const sortedByTabPosition = Object.values(lotStructure).sort((a, b) => {
    return a.position - b.position;
  });

  const lotQueueRoute = `${routes.LOT_QUEUE}?from=${routes.LOT_DETAILS}&tab=${active}`;

  if (sortedByTabPosition.length === 0) return <Loader active />;

  return (
    <>
      <Segment className={'flex jc-end'}>
        <div className={'flex fd-row ai-center border-dashed round p-1 mr-1'}>
          <div className={'mr-2'}>Delete</div>
          <Checkbox
            checked={deleteMode}
            toggle
            onChange={() => {
              toggleDeleteMode(prevState => !prevState);
              toggleMoveMode(false);
            }}
          />
        </div>
        <div className={'flex fd-row ai-center border-dashed round p-1'}>
          <div className={'mr-2'}>Move</div>
          <Checkbox
            checked={moveMode}
            toggle
            onChange={() => {
              toggleMoveMode(prevState => !prevState);
              toggleDeleteMode(false);
              setLotToMove(null);
            }}
          />
        </div>
      </Segment>
      <Menu tabular className={'flex flex-wr'} size={'mini'}>
        {sortedByTabPosition.map(({ color }, key) => {
          return (
            <Menu.Item
              key={key}
              active={active === color}
              onClick={() => {
                setActive(color);
                uP.setUrl('tab', color);
              }}
              className={'t-center'}
            >
              <Button color={color} content={color} />
            </Menu.Item>
          );
        })}
      </Menu>
      {parkingSpots[active].map((lot, key) => {
        const queueItem = find({ lot }, lotQueue) || {};

        return (
          <Button
            key={key}
            as={Label}
            fluid
            className={'mb-1 border-width-0'}
            basic={isEmpty(queueItem)}
            color={active}
          >
            <Button.Content className={'flex fd-row jc-between ai-center '}>
              <div className={'flex fd-row ai-center'}>
                <div>
                  {queueItem.appointments &&
                    queueItem.appointments.map((appointment, key1) => {
                      return <LotDetailAppointmentReport key={key1} appointment={appointment} />;
                    })}
                </div>
                {queueItem.arrivalTime && (
                  <Popup
                    on={'click'}
                    trigger={
                      <Label>
                        <LotDetailArrivalTimeCounter arrivalTime={queueItem.arrivalTime.toDate()} />
                      </Label>
                    }
                  >
                    <Popup.Content>{queueItem.docId}</Popup.Content>
                  </Popup>
                )}
              </div>
              <div className={'flex fd-row'}>
                {!isEmpty(queueItem) && (
                  <>
                    <Button
                      size={'mini'}
                      icon
                      disabled={
                        queueItem.status === LOT_QUEUE_STATUSES.PROCESSING && !!queueItem.userId
                      }
                      loading={
                        queueItem.status === LOT_QUEUE_STATUSES.PROCESSING && !!queueItem.userId
                      }
                      onClick={() => {
                        editParkingSpot(() =>
                          queueItem.ref.set(
                            { userId, status: LOT_QUEUE_STATUSES.PROCESSING },
                            { merge: true }
                          )
                        );

                        push(lotQueueRoute);
                      }}
                    >
                      <Icon name={'hand paper outline'} />
                    </Button>
                    <Button
                      size={'mini'}
                      icon
                      disabled={!moveMode && !deleteMode}
                      onClick={async () => {
                        if (moveMode) setLotToMove(lot);
                        if (deleteMode) {
                          editParkingSpot(() => queueItem.ref.delete());
                          toggleDeleteMode(false);
                        }
                      }}
                    >
                      {lot}
                    </Button>
                  </>
                )}
                {isEmpty(queueItem) && (
                  <Button
                    icon
                    size={'mini'}
                    className={'mb-1'}
                    color={active}
                    disabled={moveMode && !lotToMove}
                    content={lot}
                    onClick={() => {
                      setSelectedLot(lot);
                    }}
                  />
                )}
              </div>
            </Button.Content>
          </Button>
        );
      })}
      <Modal open={!!selectedLot} onClose={() => setSelectedLot(null)}>
        <Modal.Content>
          {!moveMode && (
            <ParkingSpotUserAssignmentPure
              lot={selectedLot}
              color={active}
              onQueueAdd={() => {
                setSelectedLot(null);
              }}
              lotQueueRoute={lotQueueRoute}
            />
          )}
          {moveMode && (
            <>
              Are you sure you would like to move {lotToMove} to {selectedLot}
              ?
              <Divider />
              <div className={'flex jc-end'}>
                <Button
                  content={'Yes'}
                  onClick={async () => {
                    const { ref, appointments = [] } = find({ lot: lotToMove }, lotQueue);

                    editParkingSpot(() => ref.update({ lot: selectedLot }));

                    // update PS for all attached appointments
                    editParkingSpot(
                      async () =>
                        await Promise.all(
                          appointments.map(appointment =>
                            appointment.update({ parkingSpace: selectedLot })
                          )
                        )
                    );

                    setLotToMove(null);
                    setSelectedLot(null);
                  }}
                />
              </div>
            </>
          )}
        </Modal.Content>
      </Modal>
    </>
  );
};

LotDetails.propTypes = {
  affiliateId: PropTypes.number.isRequired,
};

const WithAuthUser = () => {
  const { authUser } = useContext(AuthUserContext);

  if (isEmpty(authUser)) return null;

  return <LotDetails affiliateId={authUser.location.affiliateId} />;
};

export default WithAuthUser;
