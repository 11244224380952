import ApiService from './api-service';
import config from '../config';

class AuthorizeNetApi extends ApiService {
  constructor(token = null, hostname, merchantName, transactionKey) {
    super(token);
    this.hostname = hostname || config.authorizeNet.hostname;
    this.merchantName = merchantName || config.authorizeNet.merchantName;
    this.transactionKey = transactionKey || config.authorizeNet.transactionKey;
  }

  TRANSACTION_STATUSES = {
    CAPTURED_PENDING_SETTLEMENT: 'capturedPendingSettlement',
  };

  setTransactionRequestData = (amount, track1, customerId = '', invoiceIds = []) => ({
    createTransactionRequest: {
      merchantAuthentication: {
        name: this.merchantName,
        transactionKey: this.transactionKey,
      },
      transactionRequest: {
        transactionType: 'authCaptureTransaction',
        amount,
        payment: {
          trackData: {
            track1,
          },
        },
        order: {
          invoiceNumber: invoiceIds[0] || '00000',
          description: `Invoice IDs: ${JSON.stringify(invoiceIds)}`,
        },
        customer: {
          id: customerId,
        },
        retail: {
          marketType: 2,
          deviceType: 7,
        },
      },
    },
  });

  setTransactionFetchData = customerProfileId => ({
    getTransactionListForCustomerRequest: {
      merchantAuthentication: {
        name: this.merchantName,
        transactionKey: this.transactionKey,
      },
      customerProfileId,
      sorting: {
        orderBy: 'submitTimeUTC',
        orderDescending: true,
      },
    },
  });

  setTransactionDetailsFetchData = transId => ({
    getTransactionDetailsRequest: {
      merchantAuthentication: {
        name: this.merchantName,
        transactionKey: this.transactionKey,
      },
      transId,
    },
  });

  setCustomerPaymentProfileFetchData = (customerProfileId, customerPaymentProfileId) => ({
    getCustomerPaymentProfileRequest: {
      merchantAuthentication: {
        name: this.merchantName,
        transactionKey: this.transactionKey,
      },
      customerProfileId,
      customerPaymentProfileId,
      includeIssuerInfo: 'true',
    },
  });

  /**
   * Wrapper for axios
   *
   * @param method
   * @param route
   * @param data
   * @return {Promise<*|boolean>}
   * @private
   */
  _request = async (method, route, data = {}) => {
    this.response = await this.axios({
      method,
      url: `${this.hostname}${route}`,
      data: { ...data },
    }).catch(error => error.response);

    return this._validateStatus();
  };

  /**
   * Validate response from our API
   *
   * @return {boolean|*}
   * @protected
   */
  _validateStatus() {
    const { data } = this.response;

    switch (data.messages.resultCode) {
      case 'Ok':
        return data;
      default:
        throw data;
    }
  }
}

export default AuthorizeNetApi;
