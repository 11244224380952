import './shared-css/main.css';
import 'semantic-ui-css/semantic.min.css';

import React from 'react';
import ReactDOM from 'react-dom';
import App from './components/app';
import { BrowserRouter } from 'react-router-dom';
// have to keep both providers until we make all contexts pull from shared
import NotificationsProvider from './components/notification-context';
import { NotificationsProvider as NPShared } from './shared-components/notification-context';

ReactDOM.render(
  <BrowserRouter>
    <NPShared>
      <NotificationsProvider>
        <App />
      </NotificationsProvider>
    </NPShared>
  </BrowserRouter>,
  document.querySelector('#root')
);
