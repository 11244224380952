import React, { useCallback, useMemo, useState, useEffect } from 'react';
import {
  Grid,
  Button,
  Modal,
  Table,
  Placeholder,
  Divider,
  Label,
  Statistic,
  Icon,
  Header,
  Loader,
  Popup,
} from 'semantic-ui-react';
import { useAsyncFn } from 'react-use';
import Paginater from './paginater';
import DataFilter from './data-filter';
import { startCase } from 'lodash/fp';
import PropTypes from 'prop-types';
import AuthorizeNetApi from '../shared-api-adapters/authorize-net-api';
import AuctionConcoction from '../shared-api-adapters/auction-concoction';
import AuctionMethodApi from '../shared-api-adapters/auction-method-api';
import { convertEpochTime } from '../helpers/date-format-util';

const sortTypes = {
  NO_SORT: {
    typeName: 'NO_SORT',
    fn: arr => {
      return arr;
    },
  },
  ASC: {
    typeName: 'ASC',
    fn: (arr, by) => {
      return arr.sort((a, b) => {
        if (a[by] > b[by]) {
          return 1;
        }
        if (a[by] < b[by]) {
          return -1;
        }
        return 0;
      });
    },
  },
  DESC: {
    typeName: 'DESC',
    fn: (arr, by) => {
      return arr.sort((a, b) => {
        if (b[by] > a[by]) {
          return 1;
        }
        if (b[by] < a[by]) {
          return -1;
        }
        return 0;
      });
    },
  },
};

const resolveIconName = type => {
  switch (type) {
    case sortTypes.DESC.typeName:
      return 'arrow down';
    case sortTypes.ASC.typeName:
      return 'arrow up';
    default:
      return '';
  }
};

const TransactionCheckButton = ({
  customerId,
  customerProfileId,
  // accountForReviewId,
  initialSort = 'submitTimeLocal',
  // refreshPage,
}) => {
  const auctionMethodApi = new AuctionMethodApi();
  const auctionConcoctionApi = new AuctionConcoction();
  // const {
  //   authUser: { roleId, id },
  // } = useContext(AuthUserContext);

  const authorizeNetApi = new AuthorizeNetApi();

  const [tableSort, setTableSort] = useState(sortTypes.DESC.typeName);
  const [sortBy, setSortBy] = useState(initialSort);
  const [profileSortDirection, setProfileSortDirection] = useState(false);
  const [creditCardTable, setCreditCardTable] = useState({});
  const [showModal, setShowModal] = useState(false);
  // const appointments = useCustomerAppointments(customerId);
  // const [ipAddress, setIpAddress] = useState('');

  const resolveSortType = useCallback(() => {
    if (tableSort === sortTypes.NO_SORT.typeName) setTableSort(sortTypes.ASC.typeName);
    if (tableSort === sortTypes.ASC.typeName) setTableSort(sortTypes.DESC.typeName);
    if (tableSort === sortTypes.DESC.typeName) setTableSort(sortTypes.NO_SORT.typeName);
  }, [tableSort, setTableSort]);

  const [{ loading: fetchingCustomerInvoices }, fetchCustomerInvoices] = useAsyncFn(async () => {
    return await auctionMethodApi.get(
      `${auctionMethodApi.routes.invoice}?user_id=${customerId}&days_back=8`
    );
  }, [customerId]);

  // const customerItems = customerInvoices.map(({ line_items: lineItems = [] }) => lineItems).flat();

  const [
    // eslint-disable-next-line no-unused-vars
    { loading: fetchingProfileLogs, value: profileLogs = [] },
    fetchProfileLog,
  ] = useAsyncFn(async () => {
    return auctionConcoctionApi.get(`${auctionConcoctionApi.routes.customerProfile}/${customerId}`);
  }, [customerId]);

  const profileTable = profileLogs
    .map(({ fa_user_profile_logs: logs }) => logs)
    .flat()
    .reverse();

  const [
    { loading: fetchingTransactions, value: { transactions = [] } = {} },
    fetchTransactions,
  ] = useAsyncFn(async () => {
    return authorizeNetApi.post('', authorizeNetApi.setTransactionFetchData(customerProfileId));
  }, [customerProfileId]);

  const [
    { loading: fetchingCustomerPaymentProfile, value: customerPaymentProfile = {} },
    fetchCustomerPaymentProfile,
  ] = useAsyncFn(
    async customerPaymentProfileId => {
      return authorizeNetApi.post(
        '',
        authorizeNetApi.setCustomerPaymentProfileFetchData(
          customerProfileId,
          customerPaymentProfileId
        )
      );
    },
    [customerProfileId]
  );

  const [
    { loading: fetchingCustomerTransactionDetails, value: customerTransactionDetails = {} },
    fetchCustomerTransactionDetails,
  ] = useAsyncFn(async transId => {
    return authorizeNetApi.post('', authorizeNetApi.setTransactionDetailsFetchData(transId));
  }, []);

  const [
    {
      loading: fetchingCustomer,
      value: [{ firstname: firstName, lastname: lastName, created: customerCreated } = {}] = [],
    },
    fetchCustomer,
  ] = useAsyncFn(async () => {
    return await auctionMethodApi.get(`${auctionMethodApi.routes.customers}?id=${customerId}`);
  }, [customerId]);

  const [
    {
      loading: fetchingInvoices,
      value: [{ total: totalInvoices = 0, count: invoicesSum = 0 } = {}] = [],
    },
    fetchInvoices,
  ] = useAsyncFn(async () => {
    return await auctionConcoctionApi.get(`/invoices/customer/${customerId}`);
  }, [customerId]);

  const loading = useMemo(() => {
    return fetchingTransactions || fetchingCustomer || fetchingInvoices || fetchingCustomerInvoices;
  }, [fetchingTransactions, fetchingCustomer, fetchingInvoices, fetchingCustomerInvoices]);

  const statuses = useMemo(
    () =>
      transactions.reduce((acc, val) => {
        return {
          ...acc,
          [val.transactionStatus]: [
            ...(acc[val.transactionStatus] || []),
            { ...val, ...val.profile },
          ],
        };
      }, {}),
    [transactions]
  );

  // useEffect(() => {
  //   const fetchIpAddressOfMostRecentTrans = async () => {
  //     if (transactions.length === 0) {
  //       return 'No Transactions';
  //     }
  //
  //     const { transId } = transactions[0];
  //
  //     const res = await fetchCustomerTransactionDetails(transId);
  //
  //     return res;
  //   };
  //
  //   fetchIpAddressOfMostRecentTrans().then(data => {
  //     const { transaction: { customerIP = 'No IP Address Found' } = {} } = data;
  //
  //     setIpAddress(customerIP);
  //   });
  // }, [transactions, fetchCustomerTransactionDetails]);

  useEffect(() => {
    const creditCardCall = async () => {
      return await Promise.all(
        transactions.map(async i => {
          const {
            profile: { customerPaymentProfileId },
          } = i;
          const {
            paymentProfile: {
              billTo: { state = 'Not Found', zip = 'Not Found', address = 'Not Found' } = {},
            } = {},
          } = await fetchCustomerPaymentProfile(customerPaymentProfileId);

          return { ...i, state, zip, address };
        })
      ).then(results => {
        return results.reduce((acc, val) => {
          if (acc[val.accountNumber]) {
            return {
              ...acc,
              [val.accountNumber]: {
                ...acc[val.accountNumber],
                accountNumber: val.accountNumber,
                cardType: val.accountType,
                firstName: val.firstName,
                lastName: val.lastName,
                state: val.state,
                zip: val.zip,
                address: val.address,
                [val.transactionStatus]: acc[val.accountNumber][val.transactionStatus]
                  ? acc[val.accountNumber][val.transactionStatus] + 1
                  : 1,
              },
            };
          }
          return {
            ...acc,
            [val.accountNumber]: {
              ...acc[val.accountNumber],
              [val.transactionStatus]: 1,
            },
          };
        }, {});
      });
    };

    if (transactions.length === 0) return;
    creditCardCall().then(data => {
      setCreditCardTable(data);
    });
  }, [transactions, fetchCustomerPaymentProfile]);

  const creditCardStatuses = ['Voided', 'Settled', 'Declined'];

  const sortProfileTable = useCallback(
    value => {
      if (value === true) {
        profileTable.reverse();
        setProfileSortDirection(true);
      } else {
        profileTable.reverse();
        setProfileSortDirection(false);
      }
    },
    [profileTable]
  );

  const sortedTransactions = sortTypes[tableSort].fn(Object.values(statuses).flat(), sortBy);

  const { transaction: { billTo = {} } = {} } = customerTransactionDetails;
  const { paymentProfile: { billTo: profileBillTo = {} } = {} } = customerPaymentProfile;

  // const creditCards = Object.values(creditCardTable).map(card => {
  //   const { zip = 'Not Found', accountNumber = 'Not Found', address = 'Not Found' } = card;
  //   let houseNumber;
  //
  //   if (address.match(/^\d+\s/g) === null) {
  //     houseNumber = address;
  //   } else {
  //     houseNumber = address
  //       .match(/^\d+\s/g)
  //       .join('')
  //       .replace(' ', '');
  //   }
  //
  //   return { zip, cardNumber: accountNumber, houseNumber };
  // });

  return (
    <Modal
      closeIcon
      size={'large'}
      onMount={async () => {
        await fetchTransactions();
        await fetchCustomer();
        await fetchInvoices();
        await fetchCustomerInvoices();
        await fetchProfileLog();
      }}
      trigger={
        <Button
          fluid
          color={'red'}
          className={'mt-2'}
          content={'Review Transaction Details'}
          onClick={() => setShowModal(!showModal)}
        />
      }
      onClose={() => setShowModal(false)}
      open={showModal}
      className={'mh-100'}
    >
      <Modal.Content>
        {loading && (
          <Placeholder fluid>
            <Placeholder.Line />
            <Divider />
            <Placeholder.Line />
            <Placeholder.Line />
            <Placeholder.Line />
            <Placeholder.Line />
          </Placeholder>
        )}
        <Grid className={'p-4'}>
          <Grid.Row>
            {!loading && (
              <div>
                <Header className={'ml-1'} size={'large'}>
                  <Icon name={'user'} />
                  {firstName} {lastName}
                </Header>
                <Label
                  size={'large'}
                  content={`Customer Created: ${new Date(customerCreated).toLocaleDateString()}`}
                />
                <Label size={'large'} content={`Total Invoices: ${invoicesSum}`} />
                <Label size={'large'} content={`Invoices Sum: $${totalInvoices.toFixed(2)}`} />
              </div>
            )}
          </Grid.Row>
          <Grid.Row>
            {!loading && (
              <DataFilter
                filterBy={'id'}
                data={sortedTransactions}
                conditionalOverride={(transaction, test) => {
                  return test.test(transaction.transactionStatus);
                }}
              >
                {({ filteredData, handleSearchValueChange, handleSearchValueClear, value }) => {
                  const sum = filteredData.reduce((acc, val) => {
                    return acc + Number(val.settleAmount);
                  }, 0);

                  return (
                    <>
                      {Object.entries(statuses).map(([status, arr], key) => {
                        return (
                          <Label
                            key={key}
                            basic
                            as={Button}
                            active={value === status}
                            size={'large'}
                            className={'pointer'}
                            onClick={() => handleSearchValueChange({}, { value: status })}
                          >
                            {startCase(status)}
                            <Label.Detail>{arr.length}</Label.Detail>
                          </Label>
                        );
                      })}
                      <Label
                        as={Button}
                        onClick={handleSearchValueClear}
                        className={'pointer'}
                        size={'large'}
                      >
                        All
                      </Label>
                      <Paginater
                        items={filteredData}
                        domain={filteredData.length}
                        maxItemsPerPage={10}
                      >
                        {({ itemsOnThisPage, renderPaginater }) => {
                          return (
                            <Table basic={'very'} striped>
                              <Table.Header>
                                <Table.Row textAlign={'right'}>
                                  {[
                                    { label: 'Submit Time', key: 'submitTimeLocal' },
                                    { label: 'Invoice Number', key: 'invoiceNumber' },
                                    { label: 'Account Number', key: 'accountNumber' },
                                    { label: 'Transaction ID', key: 'transId' },
                                    {
                                      label: 'Customer Payment Profile Id',
                                      key: 'customerPaymentProfileId',
                                    },
                                    { label: 'Settle Amount', key: 'settleAmount' },
                                    { label: 'Transaction Status', key: 'transactionStatus' },
                                  ].map((val, key) => {
                                    return (
                                      <Table.HeaderCell
                                        key={key}
                                        className={'pointer'}
                                        onClick={() => {
                                          setSortBy(val.key);
                                          resolveSortType();
                                        }}
                                      >
                                        <Icon
                                          color={'blue'}
                                          name={
                                            sortBy === val.key ? resolveIconName(tableSort) : null
                                          }
                                        />
                                        {val.label}
                                      </Table.HeaderCell>
                                    );
                                  })}
                                </Table.Row>
                              </Table.Header>

                              <Table.Body>
                                {itemsOnThisPage.map((item, key) => (
                                  <Table.Row
                                    key={key}
                                    error={item.transactionStatus === 'declined'}
                                    textAlign={'right'}
                                  >
                                    <Table.Cell>
                                      {new Date(item.submitTimeLocal).toLocaleDateString()}
                                    </Table.Cell>
                                    <Table.Cell>{item.invoiceNumber}</Table.Cell>
                                    <Table.Cell>{item.accountNumber}</Table.Cell>
                                    <Table.Cell>
                                      <Popup
                                        wide
                                        className={'mh-30r ofy-auto'}
                                        pinned={true}
                                        on={'click'}
                                        onMount={async () =>
                                          await fetchCustomerTransactionDetails(item.transId)
                                        }
                                        trigger={<div>{item.transId}</div>}
                                      >
                                        <Popup.Content>
                                          <div className={'p-3'}>
                                            <Loader active={fetchingCustomerTransactionDetails} />
                                            <Label.Group className={'flex fd-column'}>
                                              <Label
                                                content={'First Name:'}
                                                detail={billTo.firstName}
                                              />
                                              <Label
                                                content={'Last Name:'}
                                                detail={billTo.lastName}
                                              />
                                              <Label
                                                content={'Phone Number:'}
                                                detail={billTo.phoneNumber}
                                              />
                                              <Label content={'Address:'} detail={billTo.address} />
                                              <Label content={'City:'} detail={billTo.city} />
                                              <Label content={'State:'} detail={billTo.state} />
                                              <Label content={'ZIP:'} detail={billTo.zip} />
                                              <Label content={'Country:'} detail={billTo.country} />
                                            </Label.Group>
                                          </div>
                                        </Popup.Content>
                                      </Popup>
                                    </Table.Cell>
                                    <Table.Cell>
                                      <Popup
                                        wide
                                        pinned={true}
                                        on={'click'}
                                        onMount={async () =>
                                          await fetchCustomerPaymentProfile(
                                            item.profile.customerPaymentProfileId
                                          )
                                        }
                                        trigger={<div>{item.profile.customerPaymentProfileId}</div>}
                                      >
                                        <Popup.Content>
                                          <div className={'p-3'}>
                                            <Loader active={fetchingCustomerPaymentProfile} />
                                            <Label.Group className={'flex fd-column'}>
                                              <Label
                                                content={'First Name:'}
                                                detail={profileBillTo.firstName}
                                              />
                                              <Label
                                                content={'Last Name:'}
                                                detail={profileBillTo.lastName}
                                              />
                                              <Label
                                                content={'Phone Number:'}
                                                detail={profileBillTo.phoneNumber}
                                              />
                                              <Label
                                                content={'Address:'}
                                                detail={profileBillTo.address}
                                              />
                                              <Label
                                                content={'City:'}
                                                detail={profileBillTo.city}
                                              />
                                              <Label
                                                content={'State:'}
                                                detail={profileBillTo.state}
                                              />
                                              <Label content={'ZIP:'} detail={profileBillTo.zip} />
                                              <Label
                                                content={'Country:'}
                                                detail={profileBillTo.country}
                                              />
                                            </Label.Group>
                                          </div>
                                        </Popup.Content>
                                      </Popup>
                                    </Table.Cell>
                                    <Table.Cell>{item.settleAmount}</Table.Cell>
                                    <Table.Cell>{item.transactionStatus}</Table.Cell>
                                  </Table.Row>
                                ))}
                              </Table.Body>

                              <Table.Footer>
                                <Table.Row>
                                  <Table.HeaderCell colSpan="3">
                                    {renderPaginater()}
                                  </Table.HeaderCell>
                                  <Table.HeaderCell colSpan="2" />
                                  <Table.HeaderCell colSpan="3">
                                    <Statistic>
                                      <Statistic.Value>${sum.toFixed(2)}</Statistic.Value>
                                      <Statistic.Label>Total Amount</Statistic.Label>
                                    </Statistic>
                                  </Table.HeaderCell>
                                </Table.Row>
                              </Table.Footer>
                            </Table>
                          );
                        }}
                      </Paginater>
                    </>
                  );
                }}
              </DataFilter>
            )}
          </Grid.Row>
          <Divider />
          {!loading && (
            <Grid.Row>
              <Header>Credit Card Table</Header>
              <Table textAlign={'center'} celled striped>
                <Table.Header>
                  <Table.Row>
                    <Table.HeaderCell>Credit Card</Table.HeaderCell>
                    <Table.HeaderCell>Card Type</Table.HeaderCell>
                    <Table.HeaderCell>Name</Table.HeaderCell>
                    <Table.HeaderCell>State</Table.HeaderCell>
                    <Table.HeaderCell>ZIP Code</Table.HeaderCell>
                    {creditCardStatuses.map((status, key) => {
                      return <Table.HeaderCell key={key}>{status}</Table.HeaderCell>;
                    })}
                  </Table.Row>
                </Table.Header>
                <Table.Body>
                  {Object.values(creditCardTable).map((c, key) => {
                    return (
                      <Table.Row key={key}>
                        <Table.Cell>{c.accountNumber}</Table.Cell>
                        <Table.Cell>{c.cardType}</Table.Cell>
                        <Table.Cell>{`${c.firstName} ${c.lastName}`}</Table.Cell>
                        <Table.Cell>{c.state}</Table.Cell>
                        <Table.Cell>{c.zip}</Table.Cell>
                        <Table.Cell>{!c.voided ? '0' : c.voided}</Table.Cell>
                        <Table.Cell>
                          {!c.settledSuccessfully ? '0' : c.settledSuccessfully}
                        </Table.Cell>
                        <Table.Cell>{!c.declined ? '0' : c.declined}</Table.Cell>
                      </Table.Row>
                    );
                  })}
                </Table.Body>
              </Table>
            </Grid.Row>
          )}
          <Divider />
          {!loading && (
            <Grid.Row>
              <Header>Profile Change Log</Header>
              <Paginater maxItemsPerPage={10} items={profileTable} domain={profileTable.length}>
                {({ itemsOnThisPage, renderPaginater }) => {
                  return (
                    <Table sortable textAlign={'center'} celled striped>
                      <Table.Header>
                        <Table.Row>
                          <Table.HeaderCell
                            onClick={() => sortProfileTable(!profileSortDirection)}
                            sorted={profileSortDirection ? 'ascending' : 'descending'}
                          >
                            Modified Date
                          </Table.HeaderCell>
                          <Table.HeaderCell>Action</Table.HeaderCell>
                          <Table.HeaderCell>Comment</Table.HeaderCell>
                        </Table.Row>
                      </Table.Header>
                      <Table.Body>
                        {itemsOnThisPage.map((p, key) => {
                          return (
                            <Table.Row key={key}>
                              <Table.Cell>{convertEpochTime(p.modified_date)}</Table.Cell>
                              <Table.Cell>{p.action}</Table.Cell>
                              <Table.Cell>{p.comment}</Table.Cell>
                            </Table.Row>
                          );
                        })}
                      </Table.Body>
                      <Table.Footer>
                        <Table.Row>
                          <Table.HeaderCell colSpan="3">{renderPaginater()}</Table.HeaderCell>
                        </Table.Row>
                      </Table.Footer>
                    </Table>
                  );
                }}
              </Paginater>
            </Grid.Row>
          )}
          {/*{SUPERVISOR.includes(roleId) && !loading ? (*/}
          {/*  <Grid.Row>*/}
          {/*    <Grid.Column>*/}
          {/*      <Header content={'Appointments'} />*/}
          {/*      {appointments.length > 0 ? (*/}
          {/*        <EditAppointmentStatus appointments={appointments} authUserId={id} />*/}
          {/*      ) : (*/}
          {/*        <Message content={`No appointments to update for customer ${customerId}`} />*/}
          {/*      )}*/}
          {/*      <Divider />*/}
          {/*      <TransactionReviewButtons*/}
          {/*        customerIp={ipAddress}*/}
          {/*        creditCards={creditCards}*/}
          {/*        customerId={customerId}*/}
          {/*        fetchCustomerTransactionDetails={fetchCustomerTransactionDetails}*/}
          {/*        loading={loading}*/}
          {/*        accountForReviewId={accountForReviewId}*/}
          {/*        customerItems={customerItems}*/}
          {/*        modalHandler={() => {*/}
          {/*          setShowModal(false);*/}
          {/*          refreshPage();*/}
          {/*        }}*/}
          {/*      />*/}
          {/*    </Grid.Column>*/}
          {/*  </Grid.Row>*/}
          {/*) : null}*/}
        </Grid>
      </Modal.Content>
    </Modal>
  );
};

TransactionCheckButton.propTypes = {
  customerId: PropTypes.any.isRequired,
  customerProfileId: PropTypes.number,
  initialSort: PropTypes.string,
  accountForReviewId: PropTypes.number,
  refreshPage: PropTypes.func,
};

export default TransactionCheckButton;
