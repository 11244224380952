import { Table } from 'semantic-ui-react';
import React, { useEffect, useReducer } from 'react';
import { startCase } from 'lodash/fp';
import PropTypes from 'prop-types';
import Paginater from './paginater';

const useTableCreator = ({ data, maxItemsPerPage = 0, ...rest }) => {
  if (data.length === 0) return null;

  if (maxItemsPerPage) {
    return (
      <Paginater maxItemsPerPage={maxItemsPerPage} items={data} domain={data.length}>
        {({ itemsOnThisPage, renderPaginater }) => (
          <Table {...rest}>
            <Table.Header>
              <Table.Row>
                {Object.keys(itemsOnThisPage[0])
                  .filter(header => !['positive', 'negative', 'warning'].includes(header))
                  .map((header, key) => (
                    <Table.HeaderCell key={key}>{startCase(header)}</Table.HeaderCell>
                  ))}
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {itemsOnThisPage.map(
                ({ positive = false, negative = false, warning = false, ...row }, i) => (
                  <Table.Row key={i} positive={positive} negative={negative} warning={warning}>
                    {Object.values(row).map((cell, j) => (
                      <Table.Cell key={j}>{cell}</Table.Cell>
                    ))}
                  </Table.Row>
                )
              )}
            </Table.Body>
            <Table.Footer fullWidth>
              <Table.Row className={'h-3'}>
                <Table.HeaderCell colSpan={Object.keys(data[0]).length}>
                  {renderPaginater()}
                </Table.HeaderCell>
              </Table.Row>
            </Table.Footer>
          </Table>
        )}
      </Paginater>
    );
  }

  return (
    <Table {...rest}>
      <Table.Header>
        <Table.Row>
          {Object.keys(data[0]).map((header, key) => (
            <Table.HeaderCell key={key}>{startCase(header)}</Table.HeaderCell>
          ))}
        </Table.Row>
      </Table.Header>
      <Table.Body>
        {data.map(({ positive = false, negative = false, warning = false, ...row }, i) => (
          <Table.Row key={i} positive={positive} negative={negative} warning={warning}>
            {Object.values(row).map((cell, j) => (
              <Table.Cell key={j}>{cell}</Table.Cell>
            ))}
          </Table.Row>
        ))}
      </Table.Body>
    </Table>
  );
};

useTableCreator.propTypes = {
  data: PropTypes.arrayOf(Object).isRequired,
  maxItemsPerPage: PropTypes.number,
};

const initialTable = () => ({
  data: [],
  currentRecord: {},
  editing: false,
  creating: false,
  removing: false,
  matches: [],
  filters: {},
  error: false,
  refresh: 0,
});

const tableReducer = (state, [type, data]) => {
  switch (type) {
    case 'SET_DATA':
      return {
        ...state,
        data,
      };
    case 'RESET':
      return {
        ...state,
        currentRecord: {},
        editing: false,
        creating: false,
        removing: false,
      };
    case 'EDIT':
      return {
        ...state,
        currentRecord: data,
        editing: true,
        creating: false,
        removing: false,
      };
    case 'CREATE':
      return {
        ...state,
        currentRecord: data,
        creating: true,
        editing: false,
        removing: false,
      };
    case 'REMOVE':
      return {
        ...state,
        currentRecord: data,
        creating: false,
        editing: false,
        removing: true,
      };
    case 'INPUT_CHANGE':
      return {
        ...state,
        currentRecord: { ...state.currentRecord, [data.name]: data.value },
      };
    case 'TOGGLE':
      return {
        ...state,
        currentRecord: { ...state.currentRecord, [data.name]: !data.value },
      };
    case 'FILTER_DATA': {
      const filters = { ...state.filters, [data.name]: { value: data.value, option: data.option } };

      if (data.value === '') delete filters[data.name];

      return {
        ...state,
        filters,
      };
    }
    case 'FILTER': {
      return {
        ...state,
        matches: state.data.filter(record => {
          const matches = Object.keys(state.filters).reduce((matched, filter) => {
            const { value, option } = state.filters[filter];

            if (option === 'includes' && String(record[filter]).includes(String(value))) {
              return matched + 1;
            }

            if (option === 'startsWith' && String(record[filter]).startsWith(String(value))) {
              return matched + 1;
            }

            if (String(record[filter]) === String(value)) return matched + 1;

            return matched;
          }, 0);

          return matches === Object.keys(state.filters).length;
        }),
      };
    }
    case 'SET_ERROR':
      return { ...state, error: true };
    case 'UNSET_ERROR':
      return { ...state, error: false };
    case 'REFRESH':
      return { ...state, refresh: state.refresh + 1 };
    default:
      return state;
  }
};

const useTableEditor = ({ tableData }) => {
  const [
    { data, currentRecord, editing, creating, removing, matches, filters, error, refresh },
    dispatchTable,
  ] = useReducer(tableReducer, initialTable(), initialTable);

  useEffect(
    () => {
      if (error) {
        setTimeout(() => dispatchTable(['UNSET_ERROR']), 5000);
      }
    },
    [error]
  );

  useEffect(
    () => {
      dispatchTable(['SET_DATA', tableData]);

      if (Object.keys(filters).length > 0) {
        dispatchTable(['FILTER']);
      }
    },
    [tableData, filters]
  );

  return {
    dispatchTable,
    data: Object.keys(filters).length > 0 ? matches : data,
    currentRecord,
    editing,
    creating,
    removing,
    error,
    refresh,
  };
};

export { useTableCreator, useTableEditor };
