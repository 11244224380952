import React, { useState } from 'react';
import { Button, Modal, Icon } from 'semantic-ui-react';
import SearchModal from './search-modal';
import AuctionMethodApi from '../shared-api-adapters/auction-method-api';
import { CurbSide } from './curbside-city';
import { startCase } from 'lodash/fp';
import PropTypes from 'prop-types';

export const QuickCustomerLookup = ({ searching, itemRenderer, inputSize }) => {
  return [
    { by: 'name', label: 'Name' },
    { by: 'phoneNumber', label: 'Phone Number' },
    { by: 'id', label: 'Customer ID' },
  ].map(({ by, label }, key) => (
    <div key={key} className={'mb-1'}>
      <SearchModal
        forceClose={!searching}
        api={new AuctionMethodApi()}
        route={'customers'}
        label={label}
        by={by}
        itemRenderer={itemRenderer}
        placeholder={label}
        inputSize={inputSize}
      />
    </div>
  ));
};

const PickUpQueueSearch = ({ userId, changeTab, updateSearch }) => {
  const [searching, toggleSearch] = useState(false);
  const [curbside, toggleCurbside] = useState(false);
  const [customerId, setCustomer] = useState(null);

  return (
    <>
      <Button icon onClick={() => toggleSearch(true)}>
        <Icon name={'search'} className={'m-0'} />
      </Button>
      <Modal open={searching} size={'mini'} centered={false} onClose={() => toggleSearch(false)}>
        <div className={'bg-white'}>
          <div className={'bg-material-blue c-white p-3 fs-x-large'}>Customers</div>
          <div className={'p-3 border-light'}>
            <QuickCustomerLookup
              searching={searching}
              itemRenderer={() => ({ id, firstname, lastname, email }) => ({
                key: id,
                header: `${startCase(firstname)} ${startCase(lastname)}`,
                description: email,
                onClick: () => {
                  updateSearch(null, { value: id });
                  changeTab(-1);
                  setCustomer(Number(id));
                  toggleCurbside(true);
                  toggleSearch(false);
                },
              })}
            />
          </div>
        </div>
      </Modal>
      <Modal open={curbside} centered={false} onClose={() => toggleCurbside(false)}>
        <Modal.Content>
          {customerId !== null && <CurbSide customerId={customerId} userId={userId} />}
        </Modal.Content>
      </Modal>
    </>
  );
};

PickUpQueueSearch.propTypes = {
  userId: PropTypes.number,
  changeTab: PropTypes.func,
  updateSearch: PropTypes.func,
};

export default PickUpQueueSearch;
