import React, { Component } from 'react';
import Access from './access';
import AuthUserContext from './auth-user-context';
import PropTypes from 'prop-types';

class Firewall extends Component {
  render() {
    return (
      <AuthUserContext.Consumer>
        {({ api, authUser, complete }) => {
          if (!complete) return null;

          return <Access {...this.props} authUser={authUser} api={api} />;
        }}
      </AuthUserContext.Consumer>
    );
  }
}

Firewall.propTypes = {
  accessLevel: PropTypes.array.isRequired,
  render: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
};

export default Firewall;
