import React, { useCallback, useState } from 'react';
import { Header, Icon, Card, Button, Grid, Image, Modal, Divider } from 'semantic-ui-react';
import PropTypes from 'prop-types';
import Paginater from './paginater';
import AuctionConcoction from '../shared-api-adapters/auction-concoction';

const EndOfDayButton = ({
  items: customerItems = [],
  customer: { id, firstName, lastName },
  warehousePosition = 'None',
  deleteAppointment,
}) => {
  const [loading, load] = useState(false);
  const [itemsToPutAway, setItemsToPutAway] = useState([]);
  const [auctionConcoction] = useState(new AuctionConcoction());

  const fetchItems = useCallback(() => {
    load(true);
    return Promise.all(
      customerItems.map(async itemId => await auctionConcoction.get(`/items/${itemId}`))
    )
      .then(items =>
        // Flatten since Auction Concoction returns array
        items.flat().filter(({ item_status: itemStatus } = {}) => itemStatus === 'Picked up')
      )
      .finally(() => load(false));
  }, [customerItems, auctionConcoction]);

  const CheckItems = props => (
    <Button
      {...props}
      loading={loading}
      onClick={async () => {
        const items = await fetchItems();

        if (items.length === 0) deleteAppointment();
        else setItemsToPutAway(items);
      }}
    />
  );

  return (
    <>
      <CheckItems className={'ml-2'} size={'tiny'} content={'Mark as no show'} color={'red'} />
      <Modal
        size={'small'}
        open={itemsToPutAway.length > 0}
        closeIcon
        onClose={() => setItemsToPutAway([])}
      >
        <Modal.Header>
          <Icon name={'exclamation circle'} />
          The following items were not put away:
        </Modal.Header>
        <Modal.Content>
          <Grid>
            <Grid.Row className={'flex jc-center'}>
              <Header>
                <Icon name={'user'} />
                <Header.Content>
                  {firstName} {lastName}
                </Header.Content>
                <Header.Subheader>
                  <div>
                    <strong>Customer ID:</strong> {id}
                  </div>
                  <div>
                    <strong>Warehouse Position:</strong> {warehousePosition}
                  </div>
                  <div>
                    <strong>Items To Put Away:</strong> {itemsToPutAway.length}/
                    {customerItems.length}
                  </div>
                </Header.Subheader>
              </Header>
            </Grid.Row>
            <Divider />
            <Grid.Row className={'flex jc-center'}>
              <Paginater maxItemsPerPage={1} items={itemsToPutAway}>
                {({ renderPaginater, itemsOnThisPage }) => {
                  return (
                    <div>
                      {itemsOnThisPage.map(
                        (
                          { lot_number: lotNumber, thumb_url: image, title: description } = {},
                          key
                        ) => (
                          <Card key={key}>
                            <div className={'flex jc-center'}>
                              <Image size={'small'} alt={'Image Not Found'} src={image} />
                            </div>
                            <Card.Content>
                              <Card.Header>{lotNumber}</Card.Header>
                              <Card.Description>{description}</Card.Description>
                            </Card.Content>
                          </Card>
                        )
                      )}
                      <div className={'flex jc-center'}>{renderPaginater()}</div>
                    </div>
                  );
                }}
              </Paginater>
            </Grid.Row>
          </Grid>
        </Modal.Content>
        <Modal.Actions>
          <CheckItems size={'large'} color={'blue'} icon={'redo'} content={'Try Again'} />
        </Modal.Actions>
      </Modal>
    </>
  );
};

EndOfDayButton.propTypes = {
  items: PropTypes.array.isRequired,
  customer: PropTypes.object.isRequired,
  warehousePosition: PropTypes.string,
  deleteAppointment: PropTypes.func.isRequired,
};

export default EndOfDayButton;
