import React, { Component } from 'react';
import { Button, Divider, Header, Icon, Label, Popup, List, Responsive } from 'semantic-ui-react';
import PropTypes from 'prop-types';
import { PopupLabel } from './line-item';
import AuctionMethodApi from '../shared-api-adapters/auction-method-api';
import API from './api';
import { Refund } from './relist-refund';
import { getQueueColor, WEEKDAY_TWO_DIGIT_DATE_TIME } from '../constants/pickup-queue-constants';
import { setDateOptions, convertDateSqltoJS } from '../helpers/date-format-util';

const PaymentBreakdown = ({ invoice }) => {
  return (
    <>
      <PopupLabel title={'Sub-Total'} output={invoice.sub_total} iconName={'dollar'} />
      <PopupLabel title={'Buyers Premium'} output={invoice.premium_amount} iconName={'dollar'} />
      <PopupLabel title={'Fees'} output={invoice.fee_amount} iconName={'dollar'} />
      <PopupLabel title={'Tax'} output={invoice.tax_amount} iconName={'dollar'} />
      <PopupLabel title={'Invoice Total'} output={invoice.invoice_total} iconName={'dollar'} />
      <PopupLabel
        title={'Balance Due'}
        output={invoice.balance}
        iconName={'dollar'}
        labelColor={invoice.balance > 0 ? 'red' : null}
      />
      <Divider />
      {invoice.transactions.length > 0 && (
        <>
          <Header as={'h5'} content={'Transactions: '} />
          {invoice.transactions.map((trans, key) => (
            <PopupLabel
              key={key}
              // due to the way AM sends these descriptions we parse out html here
              title={trans.description}
              output={trans.amount}
              iconName={'dollar'}
            />
          ))}
        </>
      )}
    </>
  );
};

PaymentBreakdown.propTypes = {
  invoice: PropTypes.object.isRequired,
};

const PickBreakDown = ({ completed, users }) => {
  const getUser = (userId, pickers) => {
    const [{ firstName, lastName }] = pickers.filter(user => user.id === userId);

    return `${firstName} ${lastName}`;
  };

  if (completed.length === 0) {
    return <Header content={'No Pickup Queue Stats Available...'} />;
  }
  return (
    <>
      {completed.map((pick, key) => {
        const appointment = convertDateSqltoJS(pick.appointmentTime).toLocaleDateString(
          'en-US',

          setDateOptions(WEEKDAY_TWO_DIGIT_DATE_TIME)
        );
        // const arrival = convertDateFBtoJS(pick.arrivalTime).toLocaleDateString(
        //   'en-US',
        //   setDateOptions(weekdayOpt)
        // );
        //
        // const pickStart = convertDateFBtoJS(pick.pickStartTime).toLocaleDateString(
        //   'en-US',
        //   setDateOptions(weekdayOpt)
        // );

        const picker = getUser(pick.consolidatedBy, users);
        const invoiceId = pick.invoiceIds.toString().replace(/,/g, ' || ');
        const items = pick.itemIds;

        return (
          <div key={key}>
            <Divider />
            <Header>Invoices {invoiceId}</Header>
            <PopupLabel title={'Consolidated By '} output={picker} iconName={'user'} />
            <PopupLabel
              title={'Appointment Time '}
              output={appointment}
              iconName={'clock'}
              labelColor={'blue'}
            />
            <Header size={'medium'}>Items:</Header>
            <List items={items} />
            <Divider />
          </div>
        );
      })}
    </>
  );
};

PickBreakDown.propTypes = {
  completed: PropTypes.array.isRequired,
  users: PropTypes.array.isRequired,
};

class InvoiceListItem extends Component {
  renderItemLabel = (iconName, text) => {
    return (
      <Label className={'m-1'}>
        <Icon name={iconName} />
        {text}
      </Label>
    );
  };

  render() {
    const {
      props: {
        active,
        users,
        onClick,
        invoice,
        location,
        refreshPage,
        customer,
        appointment,
        completed,
        isActionView,
      },
      renderItemLabel,
    } = this;

    const paidStatus =
      invoice.paid === '1' ? { color: 'green', text: 'Paid' } : { color: 'red', text: 'Unpaid' };

    return (
      <>
        <Responsive minWidth={'992'}>
          <div className={active ? 'selected-border' : 'focus-border'}>
            <div className={'px-3 my-2 ml-2 flex fd-row jc-between ai-center'}>
              <div onClick={onClick} className={'pointer'}>
                {renderItemLabel('hashtag', invoice.id)}
                {renderItemLabel('clock', invoice.created_time)}
                {renderItemLabel('building outline', location.name)}
                <Popup
                  trigger={renderItemLabel('dollar', invoice.balance)}
                  content={<PaymentBreakdown invoice={invoice} />}
                  flowing
                  pinned
                  position={'top left'}
                  style={{ overflowY: 'auto', maxHeight: '20rem' }}
                  hoverable={true}
                  basic
                />
                <Popup
                  trigger={
                    <Label color={getQueueColor(appointment.status)}>
                      <Icon name={'boxes'} />
                      {invoice.line_items.length}
                    </Label>
                  }
                  content={<PickBreakDown completed={completed} users={users} />}
                  flowing
                  on={'click'}
                  pinned
                  position={'top left'}
                  style={{ overflowY: 'auto', maxHeight: '20rem' }}
                  basic
                />
              </div>
              <div>
                {isActionView && invoice.paid === '0' && (
                  <Refund.Unpaid invoice={invoice} refreshPage={refreshPage} customer={customer} />
                )}
                {isActionView && invoice.paid === '1' && (
                  <Refund.Quarantine
                    invoice={invoice}
                    refreshPage={refreshPage}
                    customer={customer}
                  />
                )}
                {!isActionView && (
                  <Refund.Relot invoice={invoice} refreshPage={refreshPage} customer={customer} />
                )}
                <API.Crud route={'emailInvoice'} api={new AuctionMethodApi()}>
                  {({ post, success, loading }) => {
                    return (
                      <Label
                        color={success ? 'green' : null}
                        loading={loading}
                        as={Button}
                        onClick={() => {
                          post({
                            invoice_id: invoice.id,
                            message: 'Your invoice has been updated! Please review recent changes.',
                          });
                        }}
                      >
                        <Icon name={success ? 'check' : 'mail'} />
                        Email Invoice
                      </Label>
                    );
                  }}
                </API.Crud>
                <Label color={paidStatus.color} className={'fw-100'} content={paidStatus.text} />
              </div>
            </div>
          </div>
        </Responsive>
        <Responsive maxWidth={'991'}>
          <div className={active ? 'selected-border p-3' : 'focus-border p-3'}>
            <div className={'px-3 my-2 ml-2 flex fd-row jc-even ai-center'}>
              <div onClick={onClick} className={'pointer'}>
                {renderItemLabel('hashtag', invoice.id)}
                {renderItemLabel('clock', invoice.created_time)}
                {renderItemLabel('building outline', location.name)}
                <Popup
                  trigger={renderItemLabel('dollar', invoice.balance)}
                  content={<PaymentBreakdown invoice={invoice} />}
                  flowing
                  pinned
                  position={'top left'}
                  style={{ overflowY: 'auto', maxHeight: '20rem' }}
                  hoverable={true}
                  basic
                />
                <Popup
                  trigger={
                    <Label color={getQueueColor(appointment.status)}>
                      <Icon name={'boxes'} />
                      {invoice.line_items.length}
                    </Label>
                  }
                  content={<PickBreakDown completed={completed} users={users} />}
                  flowing
                  on={'click'}
                  pinned
                  position={'top left'}
                  style={{ overflowY: 'auto', maxHeight: '20rem' }}
                  basic
                />
              </div>
              <div className={'inline-flex flex-wr ai-center jc-between'}>
                {isActionView && invoice.paid === '0' && (
                  <Refund.Unpaid invoice={invoice} refreshPage={refreshPage} customer={customer} />
                )}
                {isActionView && invoice.paid === '1' && (
                  <Refund.Quarantine
                    invoice={invoice}
                    refreshPage={refreshPage}
                    customer={customer}
                  />
                )}
                {!isActionView && (
                  <Refund.Relot invoice={invoice} refreshPage={refreshPage} customer={customer} />
                )}
                <API.Crud route={'emailInvoice'} api={new AuctionMethodApi()}>
                  {({ post, success, loading }) => {
                    return (
                      <Label
                        className={'flex ai-center m-1'}
                        color={success ? 'green' : null}
                        loading={loading}
                        as={Button}
                        onClick={() => {
                          post({
                            // eslint-disable-next-line camelcase
                            invoice_id: invoice.id,
                            message: 'Your invoice has been updated! Please review recent changes.',
                          });
                        }}
                      >
                        <Icon name={success ? 'check' : 'mail'} />
                        Email Invoice
                      </Label>
                    );
                  }}
                </API.Crud>
                <Label
                  color={paidStatus.color}
                  className={'fw-100 m-1'}
                  content={paidStatus.text}
                />
              </div>
            </div>
          </div>
          <Divider section />
        </Responsive>
      </>
    );
  }
}

InvoiceListItem.defaultProps = {
  location: { name: 'Unavailable' },
  appointment: { status: null },
};

InvoiceListItem.propTypes = {
  active: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
  invoice: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  refreshPage: PropTypes.func.isRequired,
  customer: PropTypes.object.isRequired,
  appointment: PropTypes.object,
  completed: PropTypes.array,
  user: PropTypes.object,
  users: PropTypes.array,
};

export default InvoiceListItem;
