import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Dropdown,
  Grid,
  Header,
  Icon,
  Input,
  Label,
  Menu,
  Message,
  Modal,
  Segment,
  Sticky,
} from 'semantic-ui-react';
import { isEmpty } from 'lodash/fp';
import MultiToggle from './multi-toggle';
import MultiToggleButton from './multi-toggle-button';
import Toggle from '../shared-components/toggle';
import CustomerCard from './customer-card';
import ItemTimers from './item-timers';
import LineItem from './line-item';
import InvoiceListItem from './invoice-list-item';
import API from './api';
import CargoApi from '../shared-api-adapters/cargo-api';
import DataFilter from './data-filter';
import { DateInput } from 'semantic-ui-calendar-react';
import moment from 'moment';
import { DAYS_BACK } from '../constants';
import { filterByLocation, sumSelectedInvoices } from '../helpers/customer-utils';
import BatchRelist from './batch-relist';
import withAuthorization from './with-authorization';
import { MainRefContext } from './layout/main';
import { urlSearchParams } from '../helpers/url-utils';
import { hrFirestore } from '../shared-components/firebase/firestore';
import { SUCCESS } from '../constants/notifications';
import AuthNetPayment from './auth-net-payment-form';
import { CurbSide } from './curbside-city';
import InvoiceCirculation from './invoice-circulation';

class Customer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      display: 'Invoices',
      openModal: false,
      noteTags: {},
      modal: {
        open: false,
        type: null,
        data: null,
      },
      pickUpQueueData: [],
      firebaseLoading: false,
      invoiceCompleted: [],
    };
  }

  handleChange = (event, { name, value }) => {
    this.setState({ [name]: value });
  };

  handleModalToggle = changes => {
    this.setState(prevState => ({ modal: { ...prevState.modal, ...changes } }));
  };

  handleNoteTagAdditions = noteTags => {
    this.setState({ noteTags });
  };

  setIsHere = () => {
    this.setState({ firebaseLoading: true });
    hrFirestore
      .collection('pickUpQueue')
      .where('customer.id', '==', this.props.id)
      .get()
      .then(snapShot => {
        snapShot.docs.forEach(doc => doc.ref.update({ isHere: true }));
      });
    this.setState({ firebaseLoading: false });
    this.props.addNotifications([{ ...SUCCESS, content: 'Marked customer as arrived!' }]);
  };

  componentDidMount() {
    // Get stats from completed pickups
    this.cargoApi.get(this.cargoApi.routes.completedPickups, Number(this.props.id)).then(data => {
      this.setState({ invoiceCompleted: data });
    });

    // Get pickup queue data currently in firebase
    hrFirestore
      .collection('pickUpQueue')
      .where('customer.id', '==', Number(this.props.id))
      .onSnapshot(snapshot => {
        const data = snapshot.docs.map(doc => ({ queueId: doc.id, ...doc.data() }));

        this.setState({ pickUpQueueData: data });
      });
  }

  render() {
    const {
      props: {
        invoices,
        customer,
        locations,
        users,
        user,
        fetchInvoices,
        refreshPage,
        auctionMethodApi,
        addNotifications,
        location,
        history: { push },
      },
      state: { display, modal, noteTags, pickUpQueueData, invoiceCompleted },
      // handleChange,
      handleModalToggle,
      handleNoteTagAdditions,
    } = this;

    this.cargoApi = new CargoApi(user.token);

    const { invoicesNeedingAction, completedInvoices } = invoices;

    const { setUrl, deleteUrl, appendUrl, toggleUrl, urlParams } = urlSearchParams(location, push);

    return (
      <>
        <MultiToggle initialIndexes={urlParams.getAll('location')}>
          {({ indexes, toggle, addAll }) => {
            return (
              <Toggle
                active={
                  urlParams.get('isActionView') === 'true' || urlParams.get('isActionView') === null
                }
              >
                {({ active, toggle: invoiceViewToggle }) => {
                  const d = filterByLocation(indexes)(locations)(
                    active ? invoicesNeedingAction : completedInvoices
                  );

                  return (
                    <DataFilter
                      initialValue={urlParams.get('search') || ''}
                      filterBy={'id'}
                      data={d}
                      conditionalOverride={(inv, test, values) => {
                        const bulkSearchValues = values.split(',').map(val => val.trim());

                        return inv.line_items.find(
                          item =>
                            bulkSearchValues.includes(item.lot_number) ||
                            test.test(item.lot_number) ||
                            test.test(item.description)
                        );
                      }}
                    >
                      {({
                        filteredData: filteredInvoices,
                        handleSearchValueChange,
                        handleSearchValueClear,
                        value: searchValue,
                      }) => {
                        return (
                          <Grid columns={2} stackable>
                            <Grid.Column width={5}>
                              <API.Crud api={auctionMethodApi} route={'customers'} ignoreId={true}>
                                {({ put }) => {
                                  return (
                                    <MainRefContext.Consumer>
                                      {mainRef => {
                                        return (
                                          <>
                                            {isEmpty(customer) ? (
                                              <CustomerCard.Placeholder />
                                            ) : (
                                              <CustomerCard
                                                amCrud={{ put }}
                                                customer={customer}
                                                user={user}
                                                tags={noteTags}
                                                onTagClick={(event, { value }) => {
                                                  setUrl('search', value);
                                                  handleSearchValueChange(event, { value });
                                                }}
                                                clearTags={() => handleNoteTagAdditions({})}
                                                refreshPage={refreshPage}
                                              />
                                            )}
                                          </>
                                        );
                                      }}
                                    </MainRefContext.Consumer>
                                  );
                                }}
                              </API.Crud>
                            </Grid.Column>
                            <Grid.Column width={11}>
                              <Menu secondary>
                                <Dropdown item text={'Location'} className={'border-bottom-red'}>
                                  <Dropdown.Menu>
                                    <Dropdown.Item
                                      onClick={() => {
                                        const names = locations.map(l => l.name);

                                        names.forEach(name => appendUrl('location', name));
                                        addAll(names);
                                      }}
                                    >
                                      All
                                    </Dropdown.Item>
                                    {locations.map((loc, key) => (
                                      <Dropdown.Item
                                        key={key}
                                        active={indexes.includes(loc.name)}
                                        name={loc.name}
                                        onClick={() => {
                                          toggleUrl('location', loc.name);
                                          toggle(loc.name);
                                        }}
                                      >
                                        {loc.name}
                                      </Dropdown.Item>
                                    ))}
                                  </Dropdown.Menu>
                                </Dropdown>
                                {/* <Dropdown*/}
                                {/*  item*/}
                                {/*  text={'Display Type'}*/}
                                {/*  className={'border-bottom-blue'}*/}
                                {/* >*/}
                                {/*  <Dropdown.Menu>*/}
                                {/*    {['Items', 'Invoices'].map((view, key) => (*/}
                                {/*      <Dropdown.Item*/}
                                {/*        key={key}*/}
                                {/*        name={'display'}*/}
                                {/*        value={view}*/}
                                {/*        onClick={(event, inputData) => {*/}
                                {/*          handleChange(event, inputData);*/}
                                {/*          // save to url*/}
                                {/*        }}*/}
                                {/*      >*/}
                                {/*        {view}*/}
                                {/*      </Dropdown.Item>*/}
                                {/*    ))}*/}
                                {/*  </Dropdown.Menu>*/}
                                {/* </Dropdown>*/}
                              </Menu>
                              {indexes.map((loc, key) => (
                                <Label color={'red'} key={key} className={'shadow'}>
                                  {loc}
                                  <Icon
                                    name={'delete'}
                                    onClick={() => {
                                      toggleUrl('location', loc);
                                      toggle(loc);
                                    }}
                                  />
                                </Label>
                              ))}
                              <Label color={'blue'} className={'shadow'}>
                                {display}
                              </Label>

                              <MultiToggle domain={[active, indexes, filteredInvoices]}>
                                {({
                                  indexes: selectedItems,
                                  toggle: toggleItems,
                                  addAll: addAllItems,
                                  removeAll: removeAllItems,
                                }) => {
                                  return (
                                    <>
                                      <div
                                        className={
                                          'flex flex-wr jc-start ai-center ac-between mt-3'
                                        }
                                      >
                                        <Button
                                          compact
                                          toggle
                                          content={active ? ' Non-Action Items' : 'Action items'}
                                          active={active}
                                          onClick={() => {
                                            setUrl('isActionView', !active);
                                            invoiceViewToggle();
                                          }}
                                        />
                                        <div className={'inline-flex ai-center'}>
                                          <DateInput
                                            closable
                                            dateFormat={'MM-DD-YYYY'}
                                            iconPosition="left"
                                            onChange={(event, { value }) =>
                                              this.setState({ date: value })
                                            }
                                            value={this.state.date || ''}
                                            className={'m-2'}
                                            placeholder={'Enter start date...'}
                                            maxDate={moment().subtract(DAYS_BACK, 'days')}
                                            initialDate={moment().subtract(DAYS_BACK, 'days')}
                                          />
                                          <Button
                                            compact
                                            content={'Get Invoices'}
                                            color={'teal'}
                                            disabled={!this.state.date}
                                            onClick={() => {
                                              const daysBack = moment(new Date()).diff(
                                                moment(new Date(this.state.date)),
                                                'days'
                                              );

                                              fetchInvoices(daysBack);
                                            }}
                                          />
                                        </div>
                                        <Input
                                          className={'m-2 border-bottom-lightgrey'}
                                          transparent
                                          icon={{
                                            name: searchValue ? 'close' : 'search',
                                            link: true,
                                            onClick: () => {
                                              deleteUrl('search');
                                              handleSearchValueClear();
                                            },
                                          }}
                                          placeholder={'Search invoices...'}
                                          value={searchValue}
                                          onChange={handleSearchValueChange}
                                        />
                                      </div>
                                      <MainRefContext.Consumer>
                                        {mainRef => {
                                          return (
                                            <Sticky
                                              context={mainRef}
                                              offset={85}
                                              className={'mt-3 mb-4'}
                                            >
                                              <Segment
                                                className={'flex flex-wr ac-between ai-center'}
                                              >
                                                <div className={'m-2'}>
                                                  <MultiToggleButton
                                                    onClick={
                                                      selectedItems.length > 0
                                                        ? removeAllItems
                                                        : () =>
                                                            addAllItems(
                                                              filteredInvoices.map((v, key) => key)
                                                            )
                                                    }
                                                    options={filteredInvoices}
                                                    selected={selectedItems}
                                                  />
                                                </div>
                                                <div className={' m-2 px-1'}>
                                                  <Modal
                                                    trigger={<Button content={'Curbside City'} />}
                                                  >
                                                    <Modal.Content>
                                                      <CurbSide
                                                        customerId={Number(customer.id)}
                                                        userId={user.id}
                                                      />
                                                    </Modal.Content>
                                                  </Modal>
                                                </div>

                                                <Modal
                                                  size={'tiny'}
                                                  onClose={() => refreshPage()}
                                                  trigger={
                                                    <Button
                                                      className={'flex fd-row jc-even'}
                                                      disabled={
                                                        !selectedItems.some(
                                                          i => filteredInvoices[i].paid === '0'
                                                        )
                                                      }
                                                    >
                                                      <div className={'mr-1'}>Payment:</div>
                                                      <div>
                                                        {
                                                          selectedItems.filter(
                                                            key =>
                                                              filteredInvoices[key].paid === '0'
                                                          ).length
                                                        }
                                                        <Icon name={'at'} />
                                                        <Icon name={'dollar'} />
                                                        {sumSelectedInvoices(
                                                          selectedItems,
                                                          filteredInvoices
                                                        )}
                                                      </div>
                                                    </Button>
                                                  }
                                                >
                                                  <Modal.Content>
                                                    <AuthNetPayment
                                                      invoiceIds={selectedItems.map(
                                                        i => filteredInvoices[i].id
                                                      )}
                                                      customerId={Number(customer.id)}
                                                    />
                                                  </Modal.Content>
                                                </Modal>
                                                <Modal
                                                  trigger={
                                                    <Button
                                                      disabled={
                                                        ![1, 2].includes(user.roleId) ||
                                                        selectedItems.length === 0
                                                      }
                                                      content={'Invoice Recirculate'}
                                                    />
                                                  }
                                                >
                                                  <Modal.Header as={Header}>
                                                    <Icon name={'refresh'} />
                                                    <Header.Content>
                                                      Invoice Recirculation
                                                      <Header.Subheader>
                                                        [
                                                        {selectedItems
                                                          .map(index => filteredInvoices[index].id)
                                                          .join(', ')}
                                                        ]
                                                      </Header.Subheader>
                                                    </Header.Content>
                                                  </Modal.Header>
                                                  <Modal.Content>
                                                    <InvoiceCirculation
                                                      onSuccessfulSubmission={() => {
                                                        refreshPage();
                                                      }}
                                                      invoices={selectedItems.map(
                                                        index => filteredInvoices[index]
                                                      )}
                                                    />
                                                  </Modal.Content>
                                                </Modal>
                                              </Segment>
                                            </Sticky>
                                          );
                                        }}
                                      </MainRefContext.Consumer>

                                      {isEmpty(filteredInvoices) && (
                                        <Message warning className={'mt-3'}>
                                          No Invoices for selected filters
                                        </Message>
                                      )}
                                      {display === 'Invoices'
                                        ? filteredInvoices.map((inv, key) => {
                                            return (
                                              <Fragment key={key}>
                                                <InvoiceListItem
                                                  key={key}
                                                  user={user}
                                                  users={users}
                                                  completed={invoiceCompleted.filter(
                                                    ({ invoiceIds }) =>
                                                      invoiceIds
                                                        .map(i => Number(i))
                                                        .includes(Number(inv.id))
                                                  )}
                                                  customer={customer}
                                                  isActionView={active}
                                                  onClick={() => toggleItems(key)}
                                                  active={selectedItems.includes(key)}
                                                  invoice={inv}
                                                  location={locations.find(
                                                    loc =>
                                                      loc.affiliateId === Number(inv.affiliate_id)
                                                  )}
                                                  refreshPage={refreshPage}
                                                  appointment={pickUpQueueData.find(
                                                    ({ invoiceIds = [] }) =>
                                                      invoiceIds
                                                        .map(invId => Number(invId))
                                                        .includes(Number(inv.id))
                                                  )}
                                                />
                                                {selectedItems.includes(key) &&
                                                  inv.line_items.map((item, tmp) => (
                                                    <Fragment key={tmp}>
                                                      <LineItem
                                                        user={user}
                                                        customer={customer}
                                                        isActionItem={active}
                                                        item={{
                                                          ...item,
                                                          ...inv,
                                                        }}
                                                        onClick={() =>
                                                          handleModalToggle({
                                                            open: true,
                                                            type: 'singleTimer',
                                                            data: {
                                                              ...inv,
                                                              line_items: [item],
                                                            },
                                                          })
                                                        }
                                                        showItemTimerButton={active}
                                                        onTagAdditionsClick={() =>
                                                          handleNoteTagAdditions({
                                                            invoiceId: inv.id,
                                                            lotNumber: item.lot_number,
                                                          })
                                                        }
                                                        refreshPage={refreshPage}
                                                        addNotifications={addNotifications}
                                                      />
                                                    </Fragment>
                                                  ))}
                                              </Fragment>
                                            );
                                          })
                                        : filteredInvoices.map((inv, key) => (
                                            <Fragment key={key}>
                                              <InvoiceListItem
                                                key={key}
                                                onClick={() => toggleItems(key)}
                                                user={user}
                                                users={users}
                                                completed={invoiceCompleted.filter(
                                                  ({ invoiceIds }) =>
                                                    invoiceIds
                                                      .map(i => Number(i))
                                                      .includes(Number(inv.id))
                                                )}
                                                customer={customer}
                                                isActionView={active}
                                                active={selectedItems.includes(key)}
                                                invoice={inv}
                                                location={locations.find(
                                                  loc =>
                                                    loc.affiliateId === Number(inv.affiliate_id)
                                                )}
                                                refreshPage={refreshPage}
                                              />
                                              {inv.line_items.map((item, tmp) => (
                                                <Fragment key={tmp}>
                                                  <LineItem
                                                    user={user}
                                                    customer={customer}
                                                    isActionItem={active}
                                                    item={{
                                                      ...item,
                                                      ...inv,
                                                    }}
                                                    onClick={() =>
                                                      handleModalToggle({
                                                        open: true,
                                                        type: 'singleTimer',
                                                        data: {
                                                          ...inv,
                                                          line_items: [item],
                                                        },
                                                      })
                                                    }
                                                    showItemTimerButton={active}
                                                    onTagAdditionsClick={() =>
                                                      handleNoteTagAdditions({
                                                        invoiceId: inv.id,
                                                        lotNumber: item.lot_number,
                                                      })
                                                    }
                                                    refreshPage={refreshPage}
                                                    addNotifications={addNotifications}
                                                  />
                                                </Fragment>
                                              ))}
                                            </Fragment>
                                          ))}
                                      <Modal
                                        open={modal.open}
                                        onClose={() => {
                                          handleModalToggle({ open: false });
                                          if (modal.type === 'relist') refreshPage();
                                        }}
                                      >
                                        {modal.type === 'timers' && (
                                          <>
                                            <Modal.Header>Increase Timers</Modal.Header>
                                            <Modal.Content>
                                              <API.Crud
                                                api={new CargoApi()}
                                                route={'itemTimersExtend'}
                                              >
                                                {({ put }) => {
                                                  return (
                                                    <ItemTimers
                                                      cargoCrud={{ put }}
                                                      invoices={selectedItems.map(
                                                        index => filteredInvoices[index]
                                                      )}
                                                      timerUpdates={refreshPage}
                                                      closeModal={() =>
                                                        handleModalToggle({ open: false })
                                                      }
                                                    />
                                                  );
                                                }}
                                              </API.Crud>
                                            </Modal.Content>
                                          </>
                                        )}
                                        {modal.type === 'singleTimer' && (
                                          <>
                                            <Modal.Header>Increase Timers</Modal.Header>
                                            <Modal.Content>
                                              <API.Crud
                                                api={new CargoApi()}
                                                route={'itemTimersExtend'}
                                              >
                                                {({ put }) => {
                                                  return (
                                                    <ItemTimers
                                                      cargoCrud={{ put }}
                                                      invoices={[modal.data]}
                                                      timerUpdates={refreshPage}
                                                      closeModal={() =>
                                                        handleModalToggle({ open: false })
                                                      }
                                                      extraItems={filteredInvoices
                                                        .filter(inv => inv.id === modal.data.id)
                                                        .reduce(
                                                          (acc, inv) => [
                                                            ...acc,
                                                            ...inv.line_items.filter(
                                                              item =>
                                                                item.id !==
                                                                modal.data.line_items[0].id
                                                            ),
                                                          ],
                                                          []
                                                        )}
                                                    />
                                                  );
                                                }}
                                              </API.Crud>
                                            </Modal.Content>
                                          </>
                                        )}
                                        {modal.type === 'relist' && (
                                          <>
                                            <Modal.Header>Relist and Refund</Modal.Header>
                                            <Modal.Content>
                                              <API.Crud api={auctionMethodApi} route={'relistitem'}>
                                                {({ post }) => {
                                                  return (
                                                    <BatchRelist
                                                      customer={customer}
                                                      user={user}
                                                      submit={post}
                                                      invoices={selectedItems
                                                        .map(index => filteredInvoices[index])
                                                        .filter(inv =>
                                                          inv.line_items.some(
                                                            item =>
                                                              [0, 1].includes(item.item_status.id)
                                                            // [1, 2].includes(
                                                            //   item.cargoItem.itemStatusId
                                                            // )
                                                          )
                                                        )}
                                                      isActionItem={active}
                                                    />
                                                  );
                                                }}
                                              </API.Crud>
                                            </Modal.Content>
                                          </>
                                        )}
                                      </Modal>
                                    </>
                                  );
                                }}
                              </MultiToggle>
                            </Grid.Column>
                          </Grid>
                        );
                      }}
                    </DataFilter>
                  );
                }}
              </Toggle>
            );
          }}
        </MultiToggle>
        <Button
          icon={'redo'}
          onClick={refreshPage}
          size={'huge'}
          // color={'black'}
          className={'fixed r-1r b-5r shadow-pop'}
          circular
        />
      </>
    );
  }
}

Customer.propTypes = {
  user: PropTypes.object.isRequired,
  customer: PropTypes.object.isRequired,
  locations: PropTypes.array.isRequired,
  invoices: PropTypes.object.isRequired,
  refreshPage: PropTypes.func.isRequired,
  fetchInvoices: PropTypes.func.isRequired,
  auctionMethodApi: PropTypes.object.isRequired,
  addNotifications: PropTypes.func.isRequired,
  location: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  users: PropTypes.array.isRequired,
};

export default withAuthorization(Customer);
