import React from 'react';
import { Message } from 'semantic-ui-react';
import Toggle from '../shared-components/toggle';

const HelpMessage = props => (
  <Toggle>
    {({ setActive, active }) => <Message {...props} hidden={active} onDismiss={setActive} />}
  </Toggle>
);

export default HelpMessage;
