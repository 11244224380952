import firebase from 'firebase/app';
import 'firebase/auth';

const nellisHrConfig = {
  apiKey: 'AIzaSyAvGXLVqNxBgGsVV-Lso0P1Yw9O1dZiguE',
  authDomain: 'nellishr-cbba0.firebaseapp.com',
  databaseURL: 'https://nellishr-cbba0.firebaseio.com',
  projectId: 'nellishr-cbba0',
  storageBucket: 'nellishr-cbba0.appspot.com',
  messagingSenderId: '1079556129226',
};

const nellisHrDevConfig = {
  apiKey: 'AIzaSyBtsRRXKLdExqK4w6iVFepil-NP_HXXOOc',
  authDomain: 'nellishr-dev-0.firebaseapp.com',
  databaseURL: 'https://nellishr-dev-0.firebaseio.com',
  projectId: 'nellishr-dev-0',
  storageBucket: 'nellishr-dev-0.appspot.com',
  messagingSenderId: '933547853436',
  appId: '1:933547853436:web:5a343bd1e6ec5925762bf9',
  measurementId: 'G-FN8CYF39PF',
};

const cargoDevConfig = {
  apiKey: 'AIzaSyCLYRxaA4YzCy5NV5jdQoGufnIHbBr4QHY',
  authDomain: 'cargo-dev-ed306.firebaseapp.com',
  databaseURL: 'https://cargo-dev-ed306.firebaseio.com',
  projectId: 'cargo-dev-ed306',
  storageBucket: 'cargo-dev-ed306.appspot.com',
  messagingSenderId: '210378522370',
};

const cargoConfig = {
  apiKey: 'AIzaSyAkZiqliUKs8aZo5PUi-37KHSg4ydZkOCk',
  authDomain: 'inventory-manager-5097d.firebaseapp.com',
  databaseURL: 'https://inventory-manager-5097d.firebaseio.com',
  projectId: 'inventory-manager-5097d',
  storageBucket: 'inventory-manager-5097d.appspot.com',
  messagingSenderId: '389694388031',
};

const cargo =
  process.env.NODE_ENV === 'production'
    ? firebase.initializeApp(cargoConfig, 'cargo')
    : firebase.initializeApp(cargoDevConfig, 'cargo');

const firebaseApps = {
  cargo,
  nellisHr:
    process.env.NODE_ENV === 'production'
      ? firebase.initializeApp(nellisHrConfig, 'nellisHr')
      : firebase.initializeApp(nellisHrDevConfig, 'nellisHr'),
};

// @TODO probs need to change the backend route /nellisAuctionAuthenticate to allow pulling users from dev firebase
const auth =
  process.env.NODE_ENV === 'development'
    ? firebase.initializeApp(nellisHrConfig, 'nellisHrProdAuth').auth()
    : firebaseApps.nellisHr.auth();

export { auth, firebaseApps, firebase };
