import React, { Fragment } from 'react';
import { Form, Field } from 'react-final-form';
import {
  Form as SemForm,
  Button,
  Segment,
  Label,
  Divider,
  Message,
  Modal,
  Popup,
} from 'semantic-ui-react';
import { startCase } from 'lodash/fp';
import API from './api';
import createDecorator from 'final-form-focus';
import PropTypes from 'prop-types';
import { STATES } from '../constants';
import Snackbar from '../shared-components/snackbar';
import { validators, adapters } from '../helpers/react-final-form';
import AuctionMethodAuth from './auction-method-auth';

const {
  required,
  composeValidators,
  dateFormat,
  digitLength,
  emailMustBeUnique,
  mustBeNumber,
} = validators;
const { CalendarAdapter, BasicField, DropdownAdapter, RadioAdapter, ToggleAdapter } = adapters;

const focusOnErrors = createDecorator();

class UserCreate extends React.Component {
  constructor(props) {
    super(props);

    this.authenticated = this.props.user && this.props.user.apiKey;

    this.authButton = {
      color: this.authenticated ? 'green' : 'yellow',
      icon: this.authenticated ? 'check circle' : 'warning circle',
      content: this.authenticated
        ? 'Auction Method Credentials Authenticated'
        : 'Auction Method Credentials Unauthenticated',
      size: 'small',
      type: 'button',
      compact: true,
    };

    this.state = {
      values: this.props.user ? this.getFormData(this.props.user) : { applicationIds: [] },
    };
  }

  getFormData = ({
    id,
    address,
    city,
    dateOfBirth,
    dateOfHire,
    email,
    emergencyContact,
    emergencyContactPhoneNumber,
    emergencyContactExtras,
    firstName,
    lastName,
    locationId,
    payRate,
    payRateType,
    phoneNumber,
    supervisorId,
    state,
    title,
    zipCode,
    roleId,
    statusTypeId,
    applicationIds = [],
  }) => ({
    id,
    address,
    city,
    dateOfBirth,
    dateOfHire,
    email,
    emergencyContact,
    emergencyContactPhoneNumber,
    emergencyContactExtras,
    firstName,
    lastName,
    locationId,
    payRate,
    payRateType,
    phoneNumber,
    supervisorId,
    state,
    title,
    zipCode,
    roleId,
    statusTypeId,
    applicationIds,
  });

  setValues = values => this.setState({ values });

  render() {
    const {
      onSubmit,
      user,
      readOnly,
      emails,
      successfulSubmit,
      showPayRate,
      match: {
        params: { id },
      },
    } = this.props;

    return (
      <Segment>
        <Label as="a" color="blue" ribbon>
          User Information
        </Label>
        <Snackbar
          visible={successfulSubmit === true}
          color={'green'}
          message={'Successfully updated user!'}
        />
        <Snackbar
          visible={successfulSubmit === false}
          color={'red'}
          message={'Failed to update user!'}
        />
        <Form
          onSubmit={async values => {
            // because user updating is directly connected to the DB, the changes
            // need to be held in local state so that after submission, the changes can
            // be seen
            if (user) this.setValues(values);
            document.querySelector('#main').scrollTop = 0;
            return await onSubmit(values);
          }}
          decorators={[focusOnErrors]}
          initialValues={this.state.values}
          render={obj => (
            <SemForm
              onSubmit={event => {
                const submission = obj.handleSubmit(event);

                if (submission instanceof Promise) {
                  submission.then(() => obj.form.reset());
                }
              }}
              className={'mt-3'}
              error={obj.submitFailed}
              loading={obj.submitting}
            >
              <Message
                error
                header={'Form incomplete!'}
                content={'Please complete the noted fields.'}
              />

              <div className={'mb-3 flex jc-between'}>
                {(this.authenticated || !user) && (
                  <Popup
                    on={user ? 'click' : 'hover'}
                    flowing
                    position={'right center'}
                    content={
                      user ? (
                        <>
                          <strong>API Key: </strong> {this.props.user.apiKey}
                        </>
                      ) : (
                        'Please finish creating your account here before entering your Auction Method credentials'
                      )
                    }
                    trigger={<Button {...this.authButton} />}
                  />
                )}
                {!this.authenticated && user && (
                  <Modal closeIcon trigger={<Button {...this.authButton} />}>
                    <Modal.Content>
                      <AuctionMethodAuth newUserId={id} />
                    </Modal.Content>
                  </Modal>
                )}
                {user && user.statusTypeId < 3 && (
                  <Field label={'Status'} component={ToggleAdapter} name={'statusTypeId'} />
                )}
                {user && !(user.statusTypeId < 3) && (
                  <API.Crud route={'userResendEmail'}>
                    {({ put, success }) => (
                      <Fragment>
                        <Button
                          animated
                          color={'yellow'}
                          compact
                          onClick={() => put({ id: user.email })}
                        >
                          <Button.Content visible>Status Pending</Button.Content>
                          <Button.Content hidden>Resend Email</Button.Content>
                        </Button>
                        <Snackbar visible={success} message={'Email Resent'} />
                      </Fragment>
                    )}
                  </API.Crud>
                )}
              </div>

              <SemForm.Group widths="equal">
                <BasicField readOnly={readOnly} name={'firstName'} validate={required} />
                <BasicField readOnly={readOnly} name={'lastName'} validate={required} />
                <BasicField
                  readOnly={readOnly || (user && user.statusTypeId !== 3)}
                  name={'email'}
                  validate={id ? () => {} : composeValidators(required, emailMustBeUnique(emails))}
                />
              </SemForm.Group>
              <SemForm.Group grouped>
                <label htmlFor="dateOfBirth">Date Of Birth</label>
                <Field
                  disabled={readOnly}
                  name={'dateOfBirth'}
                  validate={dateFormat}
                  component={CalendarAdapter}
                  placeholder={'YYYY-MM-DD'}
                />
              </SemForm.Group>
              <BasicField name={'address'} readOnly={readOnly} />
              <SemForm.Group widths={'equal'}>
                <BasicField name={'city'} readOnly={readOnly} />
                <Field
                  disabled={readOnly}
                  label={'State'}
                  search
                  selection
                  component={DropdownAdapter}
                  options={STATES.map(state => ({ text: state, value: state }))}
                  name={'state'}
                />
                <BasicField
                  readOnly={readOnly}
                  name={'zipCode'}
                  type={'number'}
                  validate={composeValidators(mustBeNumber, digitLength(5))}
                />
              </SemForm.Group>
              <SemForm.Group widths={'equal'}>
                <BasicField readOnly={readOnly} name={'phoneNumber'} validate={digitLength(10)} />
                <BasicField readOnly={readOnly} name={'title'} />
                {showPayRate && (
                  <BasicField
                    name={'payRate'}
                    icon={'dollar sign'}
                    iconPosition={'left'}
                    validate={composeValidators(mustBeNumber, required)}
                    type={'number'}
                  />
                )}
              </SemForm.Group>
              {showPayRate && (
                <SemForm.Group inline>
                  <label htmlFor="payRateType">Pay Rate Type: </label>
                  <Field
                    readOnly={readOnly}
                    name="payRateType"
                    component={RadioAdapter}
                    label={'hourly'}
                    validate={required}
                  />
                  <Field
                    readOnly={readOnly}
                    name="payRateType"
                    component={RadioAdapter}
                    label={'salary'}
                    validate={required}
                  />
                </SemForm.Group>
              )}
              <BasicField
                name={'pin'}
                type={'number'}
                readOnly={readOnly}
                validate={digitLength(4)}
              />
              <API.Query
                queries={[
                  { route: 'locations' },
                  { route: 'supervisorsActive' },
                  { route: 'inventorySupervisorsActive' },
                  { route: 'roles' },
                  { route: 'applications' },
                ]}
                control={true}
              >
                {({ data, loading }) => {
                  const [locations, supervisors, inventorySupervisors, roles, applications] = data;

                  if (loading)
                    return [...new Array(4)].map((val, key) => <SemForm.Input key={key} loading />);
                  if (data.length === 0) return 'Unable to retrieve data';
                  return (
                    <Fragment>
                      <Field
                        disabled={readOnly}
                        name={'locationId'}
                        component={DropdownAdapter}
                        label={'Location'}
                        options={locations.map(location => ({
                          text: location.name,
                          value: location.id,
                        }))}
                      />
                      <Field
                        disabled={readOnly}
                        name={'supervisorId'}
                        component={DropdownAdapter}
                        label={'Supervisor'}
                        options={[
                          ...[...supervisors, ...inventorySupervisors].map(supervisor => ({
                            text: `${supervisor.firstName} ${supervisor.lastName}`,
                            value: supervisor.id,
                          })),
                          { text: 'Unassigned', value: 0 },
                        ]}
                      />
                      <Field
                        name={'roleId'}
                        disabled={(user && user.roleId === 1) || readOnly}
                        component={DropdownAdapter}
                        label={'Role'}
                        placeholder={'Please select user role'}
                        validate={required}
                        options={[
                          ...roles.map(role => ({
                            text: role.name,
                            value: role.id,
                          })),
                        ]}
                      />
                      <Field
                        name={'applicationIds'}
                        multiple
                        selection
                        disabled={readOnly}
                        component={DropdownAdapter}
                        label={'Applications'}
                        placeholder={'Please select the applications that this user has access to'}
                        options={[
                          ...applications.map(app => ({
                            text: app.name,
                            value: app.id,
                          })),
                        ]}
                      />
                    </Fragment>
                  );
                }}
              </API.Query>
              <SemForm.Group grouped>
                <label htmlFor="dateOfHire">Date Of Hire</label>
                <Field
                  disabled={readOnly}
                  name={'dateOfHire'}
                  component={CalendarAdapter}
                  validate={dateFormat}
                  placeholder={'YYYY-MM-DD'}
                />
              </SemForm.Group>
              <SemForm.Group grouped>
                <label htmlFor="dateOfBenefitsEligibility">Date Of Benefits Eligibility</label>
                <Field
                  disabled={readOnly}
                  name={'dateOfBenefitsEligibility'}
                  component={CalendarAdapter}
                  validate={dateFormat}
                  placeholder={'YYYY-MM-DD'}
                />
              </SemForm.Group>
              <SemForm.Group grouped>
                <label htmlFor="dateOfSeparation">Date Of Separation</label>
                <Field
                  disabled={readOnly}
                  name={'dateOfSeparation'}
                  component={CalendarAdapter}
                  validate={dateFormat}
                  placeholder={'YYYY-MM-DD'}
                />
              </SemForm.Group>
              <Divider />
              <Label as="a" color="blue" ribbon>
                Emergency Contact
              </Label>
              <SemForm.Group grouped>
                <BasicField readOnly={readOnly} name={'emergencyContact'} label={'Full Name'} />
                <BasicField
                  readOnly={readOnly}
                  name={'emergencyContactPhoneNumber'}
                  type={'number'}
                  label={'Phone Number'}
                />
                <BasicField
                  readOnly={readOnly}
                  name={'emergencyContactExtraInfo'}
                  label={'Special Instructions or Medical Needs'}
                />
              </SemForm.Group>
              <Divider />
              <Label as="a" color="blue" ribbon>
                Miscellaneous
              </Label>
              <SemForm.Group grouped>
                <Field name={'notes'}>
                  {({ input, meta }) => (
                    <SemForm.TextArea
                      readOnly={readOnly}
                      {...input}
                      label={'Notes'}
                      placeholder={meta.error && meta.touched ? meta.error : startCase('Notes')}
                      error={meta.error && meta.touched}
                    />
                  )}
                </Field>
              </SemForm.Group>
              <Divider />
              <div>
                <Button type="submit" disabled={obj.submitting}>
                  Submit
                </Button>
                <Button
                  type="button"
                  onClick={obj.form.reset}
                  disabled={obj.submitting || obj.pristine}
                >
                  Reset
                </Button>
              </div>
            </SemForm>
          )}
        />
      </Segment>
    );
  }
}

UserCreate.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  user: PropTypes.object,
  messageComponent: PropTypes.func,
  readOnly: PropTypes.bool.isRequired,
  emails: PropTypes.array,
  match: PropTypes.object,
  successfulSubmit: PropTypes.bool,
  showPayRate: PropTypes.bool.isRequired,
};

export default UserCreate;
