import React, { Component, Fragment } from 'react';
import {
  Button,
  Divider,
  Form,
  Grid,
  Header,
  Icon,
  Image,
  Label,
  Message,
  Modal,
  Statistic,
  Table,
  TextArea,
  Segment,
  Responsive,
} from 'semantic-ui-react';
import { Form as FinalForm, Field as FinalField } from 'react-final-form';
import PropTypes from 'prop-types';
import QuickReturn from './quick-return';
import { canRefund } from '../helpers/customer-utils';
import API from './api';
import * as requests from '../helpers/batch-relist';
import withAuthorization from './with-authorization';
import { NotificationContext } from './notification-context';
import { FAILED, SUCCESS } from '../constants/notifications';
import CargoApi from '../shared-api-adapters/cargo-api';
import ItemReport from './item-report';
import config from '../config';
import { isEmpty } from 'lodash/fp';

class RefundForm extends Component {
  render() {
    const {
      moneyTable,
      feeTotal,
      refundTotal,
      payments,
      reqs,
      customer,
      authUser,
      refreshPage,
      invoice,
      auctionMethodApi,
      inventoryNumbersProcessed,
      onSuccessfulSubmission,
      onRemoveItem,
    } = this.props;

    return (
      <NotificationContext.Consumer>
        {({ addNotifications }) => (
          <>
            <Table celled padded compact>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell>Lot Number</Table.HeaderCell>
                  <Table.HeaderCell>Item Total</Table.HeaderCell>
                  <Table.HeaderCell>Relist Fee</Table.HeaderCell>
                  <Table.HeaderCell>Refund</Table.HeaderCell>
                  <Table.HeaderCell>Remove</Table.HeaderCell>
                </Table.Row>
              </Table.Header>
              <Table.Body>
                {moneyTable.map((item, key) => {
                  return (
                    <Table.Row key={key}>
                      <Table.Cell>{item.lotNumber}</Table.Cell>
                      <Table.Cell>{item.itemTotal}</Table.Cell>
                      <Table.Cell>{item.relistFee}</Table.Cell>
                      <Table.Cell>{item.refund}</Table.Cell>
                      <Table.Cell collapsing={true} textAlign={'center'}>
                        <Button
                          color={'red'}
                          size={'mini'}
                          icon={'close'}
                          onClick={() => onRemoveItem(item.itemId)}
                        />
                      </Table.Cell>
                    </Table.Row>
                  );
                })}
              </Table.Body>
              <Table.Footer>
                <Table.Row>
                  <Table.HeaderCell colSpan={'5'}>
                    <Statistic className={'p-3'} floated={'right'} color="red" size={'small'}>
                      <Statistic.Value>
                        <Icon name={'dollar'} />
                        {feeTotal}
                      </Statistic.Value>
                      <Statistic.Label>Fees to Customer</Statistic.Label>
                    </Statistic>
                    <Statistic className={'p-3'} floated={'right'} color="green" size={'small'}>
                      <Statistic.Value>
                        <Icon name={'dollar'} />
                        {refundTotal}
                      </Statistic.Value>
                      <Statistic.Label>Refund to Customer</Statistic.Label>
                    </Statistic>
                  </Table.HeaderCell>
                </Table.Row>
              </Table.Footer>
            </Table>
            <Divider className={'mt-4'} />
            <FinalForm
              onSubmit={() => {}}
              validate={() => {}}
              render={({ values }) => {
                const paymentTotal = Object.values(values).reduce(
                  (acc, val) => acc + Number(val),
                  0
                );

                return (
                  <API.Crud api={auctionMethodApi}>
                    {({ batch, loading }) => (
                      <API.Crud route={'storeCredit'}>
                        {({ post: addStoreCredit, loading: scLoading }) => (
                          <Form
                            onSubmit={async () => {
                              const refundTypes = Object.entries(values)
                                .filter(([, amount]) => amount > 0)
                                .map(([paymentMode, amount]) =>
                                  requests.refund(paymentMode)(amount)({
                                    id: invoice.id,
                                  })
                                );

                              const results = await batch([...refundTypes, ...reqs]);

                              if (!results) {
                                addNotifications([
                                  {
                                    content: `Did not apply refunds, fees, or item status updates for items - ${JSON.stringify(
                                      inventoryNumbersProcessed
                                    )}  Please contact a manager to correct the invoice!`,
                                    header: 'FAILED!',
                                    color: 'red',
                                    timestamp: new Date().toLocaleTimeString(),
                                  },
                                ]);

                                return;
                              }

                              addNotifications([
                                {
                                  content: `Successfully applied refunds for items - ${JSON.stringify(
                                    inventoryNumbersProcessed
                                  )}`,
                                  header: 'SUCCESS!',
                                  color: 'green',
                                  timestamp: new Date().toLocaleTimeString(),
                                },
                              ]);

                              if (values['Store Credit']) {
                                const scResult = await addStoreCredit({
                                  customerId: customer.id,
                                  userId: authUser.userId,
                                  amount: values['Store Credit'],
                                  notes: `Refund for invoice ${invoice.id}`,
                                });

                                if (!scResult) {
                                  addNotifications([
                                    {
                                      ...FAILED,
                                      content: `Failed to add ${values['Store Credit']} to store credit, please contact your manager!`,
                                    },
                                  ]);
                                }

                                addNotifications([
                                  {
                                    ...SUCCESS,
                                    content: `Successfully added ${values['Store Credit']} to store credit!`,
                                  },
                                ]);
                              }

                              if (values.Cash) {
                                addNotifications([
                                  {
                                    content: `You owe $${values.Cash} in cash for a refund on Invoice - ${invoice.id}`,
                                    header: 'Cash Due!',
                                    timestamp: new Date().toLocaleTimeString(),
                                  },
                                ]);
                              }

                              onSuccessfulSubmission();
                              refreshPage();
                            }}
                          >
                            {Object.entries(payments).map(([paymentType, amount], key) => {
                              return (
                                <FinalField key={key} name={paymentType} initialValue={0}>
                                  {({ input }) => {
                                    return (
                                      <Form.Input
                                        label={
                                          <Label>
                                            {paymentType}
                                            <Label.Detail>${amount.toFixed(2)}</Label.Detail>
                                          </Label>
                                        }
                                        inline
                                        size={'mini'}
                                        {...input}
                                        className={'flex fd-row jc-between ai-center'}
                                        icon={'dollar'}
                                        iconPosition={'left'}
                                        type={'number'}
                                        min={'0.00'}
                                        step={'0.01'}
                                        action={
                                          <Button
                                            type={'button'}
                                            content={'Bal'}
                                            onClick={() => {
                                              input.onChange(
                                                (refundTotal - paymentTotal).toFixed(2)
                                              );
                                            }}
                                          />
                                        }
                                      />
                                    );
                                  }}
                                </FinalField>
                              );
                            })}
                            <Button
                              type={'submit'}
                              content={'Submit'}
                              loading={loading || scLoading}
                              disabled={
                                Number(refundTotal).toFixed(2) !== Number(paymentTotal).toFixed(2)
                              }
                            />
                          </Form>
                        )}
                      </API.Crud>
                    )}
                  </API.Crud>
                );
              }}
            />
          </>
        )}
      </NotificationContext.Consumer>
    );
  }
}

RefundForm.defaultProps = {
  onSuccessfulSubmission: () => {},
};

RefundForm.propTypes = {
  reportRefundToCargo: PropTypes.bool,
  moneyTable: PropTypes.array.isRequired,
  feeTotal: PropTypes.string.isRequired,
  refundTotal: PropTypes.string.isRequired,
  payments: PropTypes.object.isRequired,
  reqs: PropTypes.array.isRequired,
  customer: PropTypes.object.isRequired,
  authUser: PropTypes.object.isRequired,
  refreshPage: PropTypes.func.isRequired,
  invoice: PropTypes.object.isRequired,
  auctionMethodApi: PropTypes.object.isRequired,
  inventoryNumbersProcessed: PropTypes.array.isRequired,
  onSuccessfulSubmission: PropTypes.func,
  onRemoveItem: PropTypes.func,
};

class ProcessQueue extends Component {
  render() {
    const {
      items,
      onSelect,
      onStatusChange,
      statuses,
      selected,
      onDescriptionChange,
      descriptions,
      auctionId,
    } = this.props;

    return (
      <div>
        {items.length === 0 && <Message>No items available to process</Message>}
        {items.map((item, key) => {
          const {
            lot_number: lotNumber,
            thumb_url: thumbUrl,
            description,
            id,
            internal_id: internalId,
          } = item;

          return (
            <Fragment key={key}>
              <Responsive maxWidth={'767'}>
                <Grid
                  centered
                  relaxed
                  columns={'equal'}
                  stackable
                  padded
                  verticalAlign={'middle'}
                  key={key}
                >
                  <Segment className={'m-2'} padded>
                    <Grid.Column className={'m-3'} width={6}>
                      <Image
                        src={
                          thumbUrl ||
                          'https://d3d4g4mcnd8edy.cloudfront.net/auctionimages/900/0819/w5d5af2de543281_t.jpg'
                        }
                        size={'small'}
                        className={'m-auto'}
                      />
                    </Grid.Column>
                    <Grid.Column className={'m-3'} width={2}>
                      <Modal
                        trigger={
                          <Button className={'m-auto'} basic size={'tiny'}>
                            <Icon name={'barcode'} />
                            {lotNumber}
                          </Button>
                        }
                        content={
                          <ItemReport
                            inventoryNumber={internalId}
                            naItemUrl={`${config.auctionMethodApi.base}/auction/${auctionId}/item/${id}`}
                          />
                        }
                        size={'mini'}
                      />
                    </Grid.Column>

                    <Grid.Column width={3}>
                      <Button
                        active={statuses[id]}
                        basic
                        size={'tiny'}
                        onClick={() => onStatusChange(id, !statuses[id])}
                      >
                        <Icon name={'dollar'} />
                        {statuses[id] ? 'No Re-Listing Fee' : ' Add Re-Listing Fee'}
                      </Button>
                    </Grid.Column>
                    <Grid.Column className={'m-3'} width={5}>
                      {[
                        ['CMD', 'I', 'CRF', 'CRL'],
                        ['MD', 'U2L', 'DMD'],
                      ].map((group, key1) => (
                        <Button.Group
                          key={key1}
                          className={'mb-1'}
                          size={'mini'}
                          fluid
                          color={'teal'}
                        >
                          {group.map((code, key2) => (
                            <Button
                              key={key2}
                              content={code}
                              as={Label}
                              onClick={event =>
                                onDescriptionChange(event, { name: id, value: code })
                              }
                            />
                          ))}
                        </Button.Group>
                      ))}
                      <Form>
                        <TextArea
                          name={id}
                          onChange={onDescriptionChange}
                          value={descriptions[id]}
                          placeholder={'Required: Reason for refund ...'}
                          className={'red-placeholder'}
                        />
                      </Form>
                    </Grid.Column>
                    <Grid.Column width={1}>
                      <Button
                        basic
                        icon={'arrow down'}
                        onClick={() => onSelect(id)}
                        size={'small'}
                        loading={id === selected}
                        active={id === selected}
                        disabled={!descriptions[id]}
                      />
                    </Grid.Column>
                  </Segment>
                </Grid>
              </Responsive>
              <Responsive minWidth={'768'}>
                <Segment className={'m-3'}>
                  <Grid centered columns={'equal'} stackable verticalAlign={'middle'} key={key}>
                    <Grid.Column width={3}>
                      <Image
                        src={
                          thumbUrl ||
                          'https://d3d4g4mcnd8edy.cloudfront.net/auctionimages/900/0819/w5d5af2de543281_t.jpg'
                        }
                        size={'small'}
                      />
                    </Grid.Column>
                    <Grid.Column width={3}>
                      <div className={'flex fd-row'}>
                        <Modal
                          trigger={
                            <Button className={'mb-1'} basic size={'large'}>
                              <Icon name={'barcode'} />
                              {lotNumber}
                            </Button>
                          }
                          content={
                            <ItemReport
                              inventoryNumber={internalId}
                              naItemUrl={`${config.auctionMethodApi.base}/auction/${auctionId}/item/${id}`}
                            />
                          }
                          size={'small'}
                        />
                      </div>
                      <div className={'flex jc-baseline'}>{description}</div>
                    </Grid.Column>
                    <Grid.Column width={3}>
                      <Button
                        active={statuses[id]}
                        basic
                        size={'tiny'}
                        onClick={() => onStatusChange(id, !statuses[id])}
                      >
                        <Icon name={'dollar'} />
                        {statuses[id] ? 'No Re-Listing Fee' : ' Add Re-Listing Fee'}
                      </Button>
                    </Grid.Column>
                    <Grid.Column width={5}>
                      {[
                        ['CMD', 'I', 'CRF', 'CRL'],
                        ['MD', 'U2L', 'DMD'],
                      ].map((group, key1) => (
                        <Button.Group
                          key={key1}
                          className={'mb-1'}
                          size={'mini'}
                          fluid
                          color={'teal'}
                        >
                          {group.map((code, key2) => (
                            <Button
                              key={key2}
                              content={code}
                              as={Label}
                              onClick={event =>
                                onDescriptionChange(event, { name: id, value: code })
                              }
                            />
                          ))}
                        </Button.Group>
                      ))}
                      <Form>
                        <TextArea
                          name={id}
                          onChange={onDescriptionChange}
                          value={descriptions[id]}
                          placeholder={'Required: Reason for refund ...'}
                          className={'red-placeholder'}
                        />
                      </Form>
                    </Grid.Column>
                    <Grid.Column width={1}>
                      <Button
                        basic
                        icon={'arrow down'}
                        onClick={() => onSelect(id)}
                        size={'small'}
                        loading={id === selected}
                        active={id === selected}
                        disabled={!descriptions[id]}
                      />
                    </Grid.Column>
                  </Grid>
                </Segment>
              </Responsive>
            </Fragment>
          );
        })}
      </div>
    );
  }
}

ProcessQueue.propTypes = {
  items: PropTypes.array.isRequired,
  onSelect: PropTypes.func.isRequired,
  onStatusChange: PropTypes.func.isRequired,
  statuses: PropTypes.object.isRequired,
  selected: PropTypes.number,
  onDescriptionChange: PropTypes.func.isRequired,
  descriptions: PropTypes.object.isRequired,
  auctionId: PropTypes.any.isRequired,
};

class RefundGovernor extends Component {
  constructor(props) {
    super(props);

    this.state = {
      id: null,
      processed: [],
      itemStatuses: props.invoice.line_items.reduce(
        (acc, item) => ({ ...acc, [item.id]: false }),
        {}
      ),
      descriptions: {},
    };
  }

  handleItemSelect = id => this.setState({ id });

  handleConfirmation = (id = null) => {
    this.setState(prevState => ({ processed: [...prevState.processed, id], id: null }));
  };

  handleItemStatusAdjustment = (id, status) =>
    this.setState(prevState => ({ itemStatuses: { ...prevState.itemStatuses, [id]: status } }));

  // if this form shows up again, rewrite => handleChange(event, {name, value}) => state({[name]:value})
  handleDescriptionChange = (event, { name, value }) =>
    this.setState(prevState => ({ descriptions: { ...prevState.descriptions, [name]: value } }));

  handleRemoveItem = id => {
    this.setState(prevState => ({ processed: prevState.processed.filter(item => item !== id) }));
  };

  render() {
    const {
      state: { id, processed, itemStatuses, descriptions },
      props: { invoice, children },
      handleItemSelect,
      handleConfirmation,
      handleItemStatusAdjustment,
      handleDescriptionChange,
      handleRemoveItem,
    } = this;

    const { line_items: lineItems } = this.props.invoice;

    const processedItems = lineItems.filter(item => processed.includes(item.id));

    const moneyTable = processedItems.map(item => {
      const relistFee = itemStatuses[item.id]
        ? 0
        : ((Number(item.final_bid) + Number(item.buyers_premium)) * 0.35).toFixed(2);

      return {
        itemId: item.id,
        lotNumber: item.lot_number,
        itemTotal: item.item_total,
        relistFee,
        refund: (item.item_total - relistFee).toFixed(2),
      };
    });

    const refundTotal = moneyTable.reduce((acc, item) => acc + Number(item.refund), 0).toFixed(2);

    const feeTotal = moneyTable.reduce((acc, item) => acc + Number(item.relistFee), 0).toFixed(2);

    /**
     * Bundle the payments so that when we dynamically create the inputs to apply refunds,
     * we do not get multiple payment inputs.
     */
    const payments = invoice.payments.reduce(
      (acc, { payment_type: paymentType, amount }) => ({
        ...acc,
        [paymentType]: acc[paymentType] + amount,
      }),
      invoice.payments.reduce(
        (acc, { payment_type: paymentType }) => ({ ...acc, [paymentType]: 0 }),
        { 'Store Credit': 0 }
      )
    );

    const reqs = processedItems
      .map(item =>
        itemStatuses[item.id]
          ? [
              requests.itemStatus('7')(item),
              requests.refundItem(item.internal_id)(item.final_bid)(item.invoice_id)(
                descriptions[item.id]
              ),
            ]
          : [
              requests.itemStatus('8')(item),
              requests.refundItem(item.internal_id)(item.final_bid)(item.invoice_id)(
                descriptions[item.id]
              ),
              requests.relistFee((Number(item.final_bid) + Number(item.buyers_premium)) * 0.35)(
                item
              ),
            ]
      )
      .flat();

    return children({
      id,
      processed,
      itemStatuses,
      processedItems,
      moneyTable,
      refundTotal,
      feeTotal,
      payments,
      reqs,
      descriptions,
      handleItemSelect,
      handleConfirmation,
      handleItemStatusAdjustment,
      handleDescriptionChange,
      handleRemoveItem,
    });
  }
}

RefundGovernor.propTypes = {
  invoice: PropTypes.object.isRequired,
  children: PropTypes.func.isRequired,
};

class RelotRefund extends Component {
  render() {
    const { authUser, customer, auctionMethodApi, invoice, refreshPage } = this.props;

    return (
      <RefundGovernor invoice={invoice}>
        {({
          id,
          processed,
          itemStatuses,
          processedItems,
          moneyTable,
          refundTotal,
          feeTotal,
          payments,
          reqs,
          descriptions,
          handleItemSelect,
          handleConfirmation,
          handleItemStatusAdjustment,
          handleDescriptionChange,
          handleRemoveItem,
        }) => {
          return (
            <Modal
              trigger={
                <Label as={Button}>
                  <Icon name={'space shuttle'} />
                  Refund
                </Label>
              }
              size={'large'}
            >
              <Modal.Header>
                <Header as="h2">
                  <Icon name={'space shuttle'} />
                  <Header.Content>
                    Refund Processing
                    <Header.Subheader>Invoice - {invoice.id}</Header.Subheader>
                  </Header.Content>
                </Header>
              </Modal.Header>
              <Modal.Content>
                <Grid stackable centered divided verticalAlign={'middle'}>
                  <Grid.Row>
                    <Grid.Column width={16} textAlign={'center'}>
                      <ProcessQueue
                        items={invoice.line_items.filter(
                          item => !processed.includes(item.id) && canRefund(item)
                        )}
                        onSelect={handleItemSelect}
                        onStatusChange={handleItemStatusAdjustment}
                        statuses={itemStatuses}
                        selected={id}
                        descriptions={descriptions}
                        onDescriptionChange={handleDescriptionChange}
                        auctionId={invoice.auction_id}
                      />
                    </Grid.Column>
                  </Grid.Row>
                  <Grid.Row>
                    <Grid.Column width={8} textAlign={'center'}>
                      {id !== null ? (
                        <div className={'flex fd-column as-center p-2'}>
                          <QuickReturn
                            inventoryNumber={
                              invoice.line_items.find(item => item.id === id).internal_id
                            }
                          />
                          <Button className={'m-2'} onClick={() => handleConfirmation(id)}>
                            Confirm
                            <Icon name={'arrow right'} />
                          </Button>
                        </div>
                      ) : (
                        <Message>NO ITEM SELECTED</Message>
                      )}
                    </Grid.Column>
                    <Grid.Column width={8} textAlign={'center'}>
                      {processed.length > 0 ? (
                        <API.Crud route={'refunds'} api={new CargoApi()}>
                          {({ post }) => {
                            return (
                              <API.Query
                                api={new CargoApi()}
                                queries={processedItems.map(item => ({
                                  route: 'itemByInventoryNumber',
                                  id: item.internal_id,
                                }))}
                                control={true}
                              >
                                {({ data = [] }) => {
                                  const z = data.flat().reduce((acc, val) => {
                                    return { ...acc, [val.inventoryNumber]: val };
                                  }, {});

                                  return (
                                    <RefundForm
                                      auctionMethodApi={auctionMethodApi}
                                      authUser={authUser}
                                      customer={customer}
                                      refreshPage={refreshPage}
                                      refundTotal={refundTotal}
                                      reqs={reqs}
                                      feeTotal={feeTotal}
                                      inventoryNumbersProcessed={processedItems.map(
                                        i => i.internal_id
                                      )}
                                      invoice={invoice}
                                      moneyTable={moneyTable}
                                      payments={payments}
                                      onRemoveItem={handleRemoveItem}
                                      onSuccessfulSubmission={async () => {
                                        const cargoReqs = processedItems.map(
                                          item =>
                                            itemStatuses[item.id] && {
                                              amount: item.final_bid,
                                              description: descriptions[item.id],
                                              inventoryNumber: Number(item.internal_id),
                                              refundType: 1,
                                              refundingUserId: authUser.id,
                                              userId: !isEmpty(z[item.internal_id])
                                                ? z[item.internal_id].userId
                                                : 99999,
                                            }
                                        );

                                        await Promise.all(
                                          cargoReqs.map(cargoData => post(cargoData))
                                        );

                                        return null;
                                      }}
                                    />
                                  );
                                }}
                              </API.Query>
                            );
                          }}
                        </API.Crud>
                      ) : (
                        <Message>No Items Processed</Message>
                      )}
                    </Grid.Column>
                  </Grid.Row>
                </Grid>
              </Modal.Content>
            </Modal>
          );
        }}
      </RefundGovernor>
    );
  }
}

RelotRefund.propTypes = {
  authUser: PropTypes.object.isRequired,
  customer: PropTypes.object.isRequired,
  auctionMethodApi: PropTypes.object.isRequired,
  invoice: PropTypes.object.isRequired,
  refreshPage: PropTypes.func.isRequired,
};

class QuarantineRefund extends Component {
  render() {
    const { authUser, customer, auctionMethodApi, invoice, refreshPage } = this.props;

    return (
      <RefundGovernor invoice={invoice}>
        {({
          id,
          processed,
          itemStatuses,
          processedItems,
          moneyTable,
          refundTotal,
          feeTotal,
          payments,
          reqs,
          descriptions,
          handleItemSelect,
          handleConfirmation,
          handleItemStatusAdjustment,
          handleDescriptionChange,
          handleRemoveItem,
        }) => {
          return (
            <Modal
              closeIcon
              closeOnDimmerClick={false}
              trigger={
                <Label className={'m-1'} as={Button}>
                  <Icon name={'space shuttle'} />
                  Refund
                </Label>
              }
            >
              <Modal.Header>
                <Header as="h2">
                  <Icon name={'space shuttle'} />
                  <Header.Content>
                    Refund Processing
                    <Header.Subheader>Invoice - {invoice.id}</Header.Subheader>
                  </Header.Content>
                </Header>
              </Modal.Header>
              <Modal.Content>
                <Grid celled={'internally'} verticalAlign={'middle'}>
                  <Grid.Row>
                    <Grid.Column width={16} textAlign={'center'}>
                      <ProcessQueue
                        items={invoice.line_items.filter(
                          item => !processed.includes(item.id) && canRefund(item)
                        )}
                        onSelect={itemId => {
                          handleItemSelect(itemId);
                          handleConfirmation(itemId);
                        }}
                        onStatusChange={handleItemStatusAdjustment}
                        statuses={itemStatuses}
                        selected={id}
                        descriptions={descriptions}
                        onDescriptionChange={handleDescriptionChange}
                        auctionId={invoice.auction_id}
                      />
                    </Grid.Column>
                  </Grid.Row>
                  <Grid.Row>
                    <Grid.Column width={16} textAlign={'center'}>
                      {processed.length > 0 ? (
                        <API.Query
                          api={new CargoApi()}
                          queries={processedItems.map(item => ({
                            route: 'itemByInventoryNumber',
                            id: item.internal_id,
                          }))}
                          control={true}
                        >
                          {({ data = [] }) => {
                            const i = data.flat();

                            return (
                              <API.Crud api={new CargoApi()}>
                                {({ batch: batchCargo }) => {
                                  return (
                                    <NotificationContext.Consumer>
                                      {({ addNotifications }) => {
                                        return (
                                          <RefundForm
                                            auctionMethodApi={auctionMethodApi}
                                            authUser={authUser}
                                            customer={customer}
                                            refreshPage={refreshPage}
                                            refundTotal={refundTotal}
                                            reqs={reqs}
                                            feeTotal={feeTotal}
                                            inventoryNumbersProcessed={processedItems.map(
                                              it => it.internal_id
                                            )}
                                            invoice={invoice}
                                            onRemoveItem={handleRemoveItem}
                                            moneyTable={moneyTable}
                                            payments={payments}
                                            onSuccessfulSubmission={async () => {
                                              const cargoResults = await batchCargo(
                                                i.map(item => ({
                                                  method: 'post',
                                                  route: 'quarantined',
                                                  data: {
                                                    itemId: item.id,
                                                  },
                                                }))
                                              );

                                              if (cargoResults) {
                                                addNotifications([
                                                  {
                                                    ...SUCCESS,
                                                    content: `Successfully moved item to quarantine!`,
                                                  },
                                                ]);
                                              }

                                              if (!cargoResults) {
                                                addNotifications([
                                                  {
                                                    ...FAILED,
                                                    content: `Failed to send item to Quarantine! Please contact a Mike Pavese with item details to manually transfer item to quarantine!`,
                                                  },
                                                ]);
                                              }
                                            }}
                                          />
                                        );
                                      }}
                                    </NotificationContext.Consumer>
                                  );
                                }}
                              </API.Crud>
                            );
                          }}
                        </API.Query>
                      ) : (
                        <Message>No Items Processed</Message>
                      )}
                    </Grid.Column>
                  </Grid.Row>
                </Grid>
              </Modal.Content>
            </Modal>
          );
        }}
      </RefundGovernor>
    );
  }
}

QuarantineRefund.propTypes = {
  authUser: PropTypes.object.isRequired,
  customer: PropTypes.object.isRequired,
  auctionMethodApi: PropTypes.object.isRequired,
  invoice: PropTypes.object.isRequired,
  refreshPage: PropTypes.func.isRequired,
};

class QuarantinedUnpaid extends Component {
  render() {
    const { auctionMethodApi, invoice, refreshPage } = this.props;

    return (
      <RefundGovernor invoice={invoice}>
        {({
          id,
          processed,
          itemStatuses,
          processedItems,
          moneyTable,
          feeTotal,
          reqs,
          descriptions,
          handleItemSelect,
          handleConfirmation,
          handleItemStatusAdjustment,
          handleDescriptionChange,
          handleRefundClose,
          handleRemoveItem,
        }) => {
          return (
            <Modal
              closeIcon
              closeOnDimmerClick={false}
              onClose={() => handleRefundClose}
              trigger={
                <Label as={Button}>
                  <Icon name={'space shuttle'} />
                  Refund
                </Label>
              }
            >
              <Modal.Header>
                <Header as="h2">
                  <Icon name={'space shuttle'} />
                  <Header.Content>
                    Refund Processing
                    <Header.Subheader>Invoice - {invoice.id}</Header.Subheader>
                  </Header.Content>
                </Header>
              </Modal.Header>
              <Modal.Content>
                <Grid celled={'internally'} verticalAlign={'middle'}>
                  <Grid.Row>
                    <Grid.Column width={16} textAlign={'center'}>
                      <ProcessQueue
                        items={invoice.line_items.filter(
                          item => !processed.includes(item.id) && canRefund(item)
                        )}
                        onSelect={itemId => {
                          handleItemSelect(itemId);
                          handleConfirmation(itemId);
                        }}
                        onStatusChange={handleItemStatusAdjustment}
                        statuses={itemStatuses}
                        selected={id}
                        descriptions={descriptions}
                        onDescriptionChange={handleDescriptionChange}
                        auctionId={invoice.auction_id}
                      />
                    </Grid.Column>
                  </Grid.Row>
                  <Grid.Row>
                    <Grid.Column width={16} textAlign={'center'}>
                      {processed.length > 0 ? (
                        <API.Query
                          api={new CargoApi()}
                          queries={processedItems.map(item => ({
                            route: 'itemByInventoryNumber',
                            id: item.internal_id,
                          }))}
                        >
                          {({ data }) => {
                            const i = data.flat();

                            return (
                              <API.Crud api={new CargoApi()}>
                                {({ batch: batchCargo }) => {
                                  return (
                                    <API.Crud api={auctionMethodApi}>
                                      {({ batch: batchAM, loading }) => {
                                        return (
                                          <NotificationContext.Consumer>
                                            {({ addNotifications }) => {
                                              return (
                                                <>
                                                  <Table compact basic={'very'}>
                                                    <Table.Header>
                                                      <Table.Row>
                                                        <Table.HeaderCell>
                                                          Lot Number
                                                        </Table.HeaderCell>
                                                        <Table.HeaderCell>
                                                          Relist Fee
                                                        </Table.HeaderCell>
                                                      </Table.Row>
                                                    </Table.Header>
                                                    <Table.Body>
                                                      {moneyTable.map((item, key) => {
                                                        return (
                                                          <Table.Row key={key}>
                                                            <Table.Cell>
                                                              {item.lotNumber}
                                                            </Table.Cell>
                                                            <Table.Cell>
                                                              {item.relistFee}
                                                            </Table.Cell>
                                                          </Table.Row>
                                                        );
                                                      })}
                                                    </Table.Body>
                                                    <Table.Footer>
                                                      <Table.Row>
                                                        <Table.HeaderCell />
                                                        <Table.HeaderCell>
                                                          <Statistic color="red" size={'small'}>
                                                            <Statistic.Value>
                                                              <Icon name={'dollar'} />
                                                              {feeTotal}
                                                            </Statistic.Value>
                                                            <Statistic.Label>
                                                              Fees to Customer
                                                            </Statistic.Label>
                                                          </Statistic>
                                                        </Table.HeaderCell>
                                                      </Table.Row>
                                                    </Table.Footer>
                                                  </Table>
                                                  <Button
                                                    content={
                                                      'Apply fees and send item to quarantine!'
                                                    }
                                                    loading={loading}
                                                    onClick={async () => {
                                                      const amResults = await batchAM(reqs);

                                                      const cargoResults = await batchCargo(
                                                        // @TODO need to loop to get proper ids
                                                        i.map(item => ({
                                                          method: 'post',
                                                          route: 'quarantined',
                                                          data: {
                                                            itemId: item.id,
                                                          },
                                                        }))
                                                      );

                                                      if (amResults) {
                                                        addNotifications([
                                                          {
                                                            ...SUCCESS,
                                                            content: `Successfully updated invoice!`,
                                                          },
                                                        ]);
                                                      }

                                                      if (!amResults) {
                                                        addNotifications([
                                                          {
                                                            ...FAILED,
                                                            content: `Failed to update invoice! Please contact a manager!`,
                                                          },
                                                        ]);
                                                      }

                                                      if (cargoResults) {
                                                        addNotifications([
                                                          {
                                                            ...SUCCESS,
                                                            content: `Successfully moved item to quarantine!`,
                                                          },
                                                        ]);
                                                      }

                                                      if (!cargoResults) {
                                                        addNotifications([
                                                          {
                                                            ...FAILED,
                                                            content: `Failed to send item to Quarantine! Please contact a Mike Pavese with item details to manually transfer item to quarantine!`,
                                                          },
                                                        ]);
                                                      }

                                                      refreshPage();
                                                    }}
                                                  />
                                                </>
                                              );
                                            }}
                                          </NotificationContext.Consumer>
                                        );
                                      }}
                                    </API.Crud>
                                  );
                                }}
                              </API.Crud>
                            );
                          }}
                        </API.Query>
                      ) : (
                        <Message>No Items Processed</Message>
                      )}
                    </Grid.Column>
                  </Grid.Row>
                </Grid>
              </Modal.Content>
            </Modal>
          );
        }}
      </RefundGovernor>
    );
  }
}

QuarantinedUnpaid.propTypes = {
  authUser: PropTypes.object.isRequired,
  customer: PropTypes.object.isRequired,
  auctionMethodApi: PropTypes.object.isRequired,
  invoice: PropTypes.object.isRequired,
  refreshPage: PropTypes.func.isRequired,
};

export const Refund = {
  Quarantine: withAuthorization(QuarantineRefund),
  Relot: withAuthorization(RelotRefund),
  Unpaid: withAuthorization(QuarantinedUnpaid),
};
