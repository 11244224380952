import { useSet, useAsyncFn } from 'react-use';
import CargoApi from '../shared-api-adapters/cargo-api';
import NellisAuctionApi from '../shared-api-adapters/nellis-auction-api';
// import { NotificationContext } from './notification-context';
import { useCallback, useContext, useEffect, useMemo } from 'react';
import { AuthContext } from './firebase/authentication';
import AuctionConcoction from '../shared-api-adapters/auction-concoction';

export const useMultiToggle = (initialState = []) => {
  const [set, utils] = useSet(new Set(initialState));

  const toggle = key => (utils.has(key) ? utils.remove(key) : utils.add(key));

  const toggleAll = (arr, by) =>
    arr.length === set.size ? utils.reset() : arr.forEach((x, key) => utils.add(x[by] || key));

  return [set, utils, toggle, toggleAll];
};

const useGetRoute = (api, endpoint, deps = [], id = null, filters = {}) =>
  useAsyncFn(async () => await api.get(endpoint, id, filters), deps);

const useLocations = (api, deps) => useGetRoute(api, '/locations', deps);

const useLocationsFromCargo = deps => useLocations(new CargoApi(), deps);
const useLocationsFromNellis = deps => useLocations(new NellisAuctionApi(), deps);

/**
 * Async Reducer with set state cleanup
 *
 * @param asyncFunction - The request
 * @param successActionName - The name of the action to dispatch
 * @param dispatch - from reacts useReducer
 */
const useAsyncReducerEffect = (asyncFunction, successActionName, dispatch) => {
  // const { addNotifications } = useContext(NotificationContext);

  useEffect(
    () => {
      let didCancel = false;

      const fetchData = async () => {
        dispatch({ actionName: 'loading', loading: true });

        try {
          const results = await asyncFunction();

          if (!didCancel) {
            dispatch({ actionName: successActionName, results });
            // addNotifications([{ ...SUCCESS, content: 'Successful request' }]);
            dispatch({ actionName: 'loading', loading: false });
          }
        } catch (error) {
          // addNotifications([{ ...FAILED, content: error.message }]);
        }
      };

      fetchData();

      return () => {
        didCancel = true;
      };
    },
    [asyncFunction, successActionName, dispatch]
  );
};

/**
 * Async Reducer with set state cleanup
 *
 * @param asyncFunction - The request
 * @param onSuccess - The name of the action to dispatch
 * @param onFailure - from reacts useReducer
 */
export const useAsyncReducerEffectCustom = (asyncFunction, onSuccess, onFailure) => {
  useEffect(
    () => {
      let didCancel = false;

      const fetchData = async () => {
        try {
          const results = await asyncFunction();

          if (!didCancel) onSuccess(results);
        } catch (error) {
          if (!didCancel) onFailure(error);
        }
      };

      fetchData();

      return () => {
        didCancel = true;
      };
    },
    [asyncFunction, onSuccess, onFailure]
  );
};

/**
 * Memoized instance of api class
 * @returns {AuctionConcoction}
 */
const useAuctionConcoctionApi = () => useMemo(() => new AuctionConcoction(), []);
const useCargoApi = () => useMemo(() => new CargoApi(), []);

/**
 * Get invoice from Auction Method by invoiceId
 *
 * @param invoiceId
 * @returns {function(): any}
 */
const useAMInvoiceFetchByInvoiceIdCallback = invoiceId => {
  const { auctionMethodApi } = useContext(AuthContext);

  return useCallback(
    () => {
      return auctionMethodApi.get(`${auctionMethodApi.routes.invoice}?invoice_id[]=${invoiceId}`);
    },
    [invoiceId, auctionMethodApi]
  );
};

/**
 * Get Auction Concoction payments by invoice id
 *
 * @param invoiceId
 * @returns {function(): Promise<*|boolean>}
 */
const useACPaymentFetchByInvoiceIdCallback = invoiceId => {
  const api = useAuctionConcoctionApi();

  return useCallback(
    () => {
      return api.get(api.routes.payments, invoiceId);
    },
    [invoiceId, api]
  );
};

/**
 * Abstract reducer function
 *
 * @param actions - Array of functions in the form [actionName] = (state, action) => ({...state, [propToUpdate]: action })
 *   => @param state - reducer state
 *   => @param payload - dispatch request => ({ actionName, ...payload })
 *      => @param actionName - name of action
 * @returns {function(*=, *=): *}
 */
const reducer = actions => (state, payload) => {
  return actions[payload.actionName](state, payload) || state;
};

export {
  useGetRoute,
  useLocations,
  useLocationsFromCargo,
  useLocationsFromNellis,
  useAuctionConcoctionApi,
  useAMInvoiceFetchByInvoiceIdCallback,
  useACPaymentFetchByInvoiceIdCallback,
  useAsyncReducerEffect,
  useCargoApi,
  reducer,
};
