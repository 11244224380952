import React from 'react';
import AuthUserContext from './auth-user-context';
import { firebase } from '../firebase';
import * as auth from '../firebase/auth';
import NellisAuctionApi from '../shared-api-adapters/nellis-auction-api';
import AuctionMethodApi from '../shared-api-adapters/auction-method-api';

const withAuthentication = Component => {
  class WithAuthentication extends React.Component {
    constructor(props) {
      super(props);

      this.state = {
        authUser: null,
        complete: false,
      };
    }

    componentDidMount() {
      firebase.auth.onAuthStateChanged(this.authenticateUser);
    }

    authenticateUser = authUser => {
      if (!authUser) {
        return this.setState({ authUser: null, complete: true });
      }

      auth.getIdToken().then(token => {
        const api = new NellisAuctionApi(token);

        api
          .put(`${api.routes.userLoggedIn}/${authUser.email}`, { email: authUser.email })
          .then(() => true);

        Promise.all([
          api.get(api.routes.authenticate),
          api.get(api.routes.users),
          api.get(api.routes.locations),
        ]).then(([[loggedInUser], users, locations]) => {
          const userDetails = users.find(user => user.id === loggedInUser.userId);

          // const auctionMethodApi = new AuctionMethodApi(
          //   'https://dev-api.cargo.helex.io:8080/https://www.nellisauction.com',
          //   '9a7ad014613cb0896260feeea5785bc46f23e185'
          // );

          const auctionMethodApi = new AuctionMethodApi(
            null,
            process.env.NODE_ENV === 'development'
              ? '32838b54df255fa13b19dfb770ed516cca1f1a97'
              : loggedInUser.apiKey
          );

          this.setState({
            authUser: {
              ...loggedInUser,
              ...userDetails,
              location: locations.find(loca => loca.id === loggedInUser.locationId),
              token,
            },
            api,
            auctionMethodApi,
            complete: true,
          });
        });
      });

      return null;
    };

    render() {
      return (
        <AuthUserContext.Provider value={{ ...this.state }}>
          <Component {...this.props} />
        </AuthUserContext.Provider>
      );
    }
  }

  return WithAuthentication;
};

export default withAuthentication;
