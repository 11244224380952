import React, { Component } from 'react';
import PropTypes from 'prop-types';
import AuthUserContext from './auth-user-context';
import { NotificationContext } from './notification-context';
import NotificationsCenter from './notifications-center';

class LoginStatus extends Component {
  render() {
    return (
      <AuthUserContext.Consumer>
        {({ authUser }) => {
          return authUser ? (
            <>
              <NotificationContext.Consumer>
                {({ notifications }) => {
                  return <NotificationsCenter notifications={notifications} />;
                }}
              </NotificationContext.Consumer>
            </>
          ) : null;
        }}
      </AuthUserContext.Consumer>
    );
  }
}

LoginStatus.propTypes = {
  email: PropTypes.string,
  authUser: PropTypes.object,
};

export default LoginStatus;
