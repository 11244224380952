import React from 'react';
import PropTypes from 'prop-types';
import { Container } from 'semantic-ui-react';

export const MainRefContext = React.createContext();

const Main = ({ children }) => {
  const ref = React.createRef();

  return (
    <MainRefContext.Provider value={ref}>
      <div id={'main'} className={'bg-whitesmoke t-5r l-0 b-0 r-0 py-3 fixed ofy-auto'} ref={ref}>
        <Container>{children}</Container>
      </div>
    </MainRefContext.Provider>
  );
};

Main.propTypes = {
  children: PropTypes.any,
};

export default Main;
