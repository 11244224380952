import React, { Component } from 'react';
import API from './api';
import CargoApi from '../shared-api-adapters/cargo-api';
import Customer from './customer';
import PropTypes from 'prop-types';
import { isEmpty } from 'lodash/fp';
import { DAYS_BACK } from '../constants';
import withAuthorization from './with-authorization';
import { NotificationContext } from './notification-context';
import GenerateInvoices from './dev/generate-invoices';

class CustomerPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      daysBack: DAYS_BACK,
      refreshTotal: 0,
    };
  }

  fetchInvoices = daysBack =>
    this.setState(prevState => ({ daysBack: prevState.daysBack + daysBack }));

  refreshPage = () => this.setState(prevState => ({ refreshTotal: prevState.refreshTotal + 1 }));
  /**
   * We group the invoices based only on data stored in our database
   * table itemTimers
   *
   * @returns {Array}
   */
  organizeByPriorityAndAssignTimers = (timers = []) => (invoices = []) => {
    return invoices.reduce(
      (acc, invoice) => {
        if (
          invoice.paid === '0' ||
          invoice.line_items.some(item => item.item_status.id === 0)
          // ||
          // invoice.line_items.some(item => item.cargoItem.itemStatusId === 1)
        ) {
          return {
            ...acc,
            invoicesNeedingAction: [
              ...acc.invoicesNeedingAction,
              {
                ...invoice,
                line_items: invoice.line_items.map(item => ({
                  ...item,
                  ...(timers.find(t => t.inventoryNumber === item.internal_id) || {}),
                })),
              },
            ],
          };
        }
        return { ...acc, completedInvoices: [...acc.completedInvoices, invoice] };
      },
      { invoicesNeedingAction: [], completedInvoices: [] }
    );
  };

  assignAuthorsToCustomerNotes = (users = []) => (customer = {}) => {
    if (isEmpty(users) || isEmpty(customer)) return customer;

    return {
      ...customer,
      notes: customer?.notes?.map(note => ({
        ...note,
        author: users.find(user => user.id === Number(note.author)),
      })),
    };
  };

  render() {
    const {
      state: { daysBack, refreshTotal },
      props: {
        match: {
          params: { id },
        },
        user,
        auctionMethodApi,
        mainRef,
      },
      fetchInvoices,
      refreshPage,
    } = this;

    return (
      <>
        <GenerateInvoices refreshPage={refreshPage} />
        <API.Query
          key={[daysBack, refreshTotal]}
          api={auctionMethodApi}
          queries={[
            { route: 'customers', filter: { id } },
            { route: 'invoice', filter: { user_id: id, days_back: daysBack } },
          ]}
          control={true}
        >
          {({ data: [[customer = {}], invoices = []] }) => {
            // query string filter for bulk inv num request
            // const inventoryNumbers = !isEmpty(i)
            //   ? JSON.stringify(
            //       i
            //         .map(inv => inv.line_items)
            //         .flat()
            //         .map(item => Number(item.internal_id))
            //     )
            //   : null;

            return (
              <API.Query
                api={new CargoApi()}
                queries={[
                  // ...[
                  //   inventoryNumbers
                  //     ? {
                  //         route: 'items',
                  //         filter: { inventoryNumbers },
                  //       }
                  //     : {},
                  // ],
                  ...invoices.map(inv => ({ route: 'itemTimersByInvoice', id: inv.id })),
                ]}
                control={true}
              >
                {({ data: tmrs }) => {
                  // map cargo item into line items to leverage cargo's item status
                  // const invoices = i.map(inv => {
                  //   return {
                  //     ...inv,
                  //     line_items: inv.line_items.map(item => {
                  //       const cItem = cargoItems.find(
                  //         cargItem => Number(cargItem.inventoryNumber) === Number(item.internal_id)
                  //       );
                  //
                  //       return {
                  //         ...item,
                  //         cargoItem: cItem ? cItem : { itemStatusId: null },
                  //       };
                  //     }),
                  //   };
                  // });

                  const timers = tmrs.flat().filter(t => t.pickedUp === 0);

                  return (
                    <API.Query
                      queries={[{ route: 'locations' }, { route: 'users' }]}
                      control={true}
                    >
                      {({ data: [locations = [], users = []] }) => {
                        return (
                          <>
                            <NotificationContext.Consumer>
                              {({ addNotifications }) => {
                                return (
                                  <Customer
                                    id={id}
                                    locations={locations}
                                    customer={this.assignAuthorsToCustomerNotes(users)(customer)}
                                    invoices={this.organizeByPriorityAndAssignTimers(timers)(
                                      invoices
                                    )}
                                    user={user}
                                    users={users}
                                    fetchInvoices={fetchInvoices}
                                    refreshPage={refreshPage}
                                    addNotifications={addNotifications}
                                    mainRef={mainRef}
                                  />
                                );
                              }}
                            </NotificationContext.Consumer>
                          </>
                        );
                      }}
                    </API.Query>
                  );
                }}
              </API.Query>
            );
          }}
        </API.Query>
      </>
    );
  }
}

CustomerPage.propTypes = {
  user: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  auctionMethodApi: PropTypes.object.isRequired,
};

export default withAuthorization(CustomerPage);
