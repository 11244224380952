import { Field } from 'react-final-form';
import { Checkbox, Form as SemForm, Radio } from 'semantic-ui-react';
import React from 'react';
import { DateInput } from 'semantic-ui-calendar-react';
import { startCase } from 'lodash/fp';
import PropTypes from 'prop-types';

const required = value => (value ? null : 'Required');
const mustBeNumber = value => (!value || !isNaN(value) ? null : 'Must be a number');
const dateFormat = date =>
  !date || /^\d{4}-\d{2}-\d{2}$/.test(date) ? null : 'Date is incorrectly formatted';
const digitLength = length => value =>
  !value || value.toString().length === length ? null : `Number must be ${length} digits`;
const emailMustBeUnique = emails => value =>
  emails.includes(value) ? 'Email must be unique' : null;
const composeValidators = (...validators) => value =>
  validators.reduce((error, validator) => error || validator(value), null);

const BasicField = ({ name, validate, type = 'text', label = '', ...rest }) => (
  <Field name={name} validate={validate}>
    {({ input, meta }) => (
      <SemForm.Input
        {...rest}
        {...input}
        label={label || startCase(name)}
        type={type}
        placeholder={meta.error && meta.touched ? meta.error : label || startCase(name)}
        error={meta.error && meta.touched}
      />
    )}
  </Field>
);

/**
 * These adapters are just helper functions to make
 * React Final Form work with React Semantic UI components
 * @SEE
 *  https://github.com/final-form/react-final-form#examples
 *
 * @param input - provided by react final form
 * @param label - user provided prop
 * @param meta - provided by react final form
 */
const RadioAdapter = ({ input, label, meta, ...rest }) => (
  <React.Fragment>
    <SemForm.Field
      {...rest}
      label={startCase(label)}
      value={label}
      control={Radio}
      onChange={(event, { value }) => input.onChange(value)}
      checked={input.value === label}
    />
    {meta.error && meta.touched && <span className={'mr-2 c-red'}>{meta.error}</span>}
  </React.Fragment>
);

const DropdownAdapter = ({ input, meta, label, options, initialValue = '', ...rest }) => (
  <SemForm.Select
    {...rest}
    fluid
    label={label}
    value={input.value || initialValue}
    options={options}
    placeholder={meta.error && meta.touched ? meta.error : rest.placeholder || label}
    onChange={(event, { value }) => input.onChange(value)}
    error={meta.error && meta.touched}
  />
);

const CalendarAdapter = ({ input: { value = '', onChange, name }, meta, ...rest }) => (
  <React.Fragment>
    <DateInput
      {...rest}
      name={name}
      closable
      dateFormat={'YYYY-MM-DD'}
      value={value}
      iconPosition="left"
      onChange={(event, inputData) => onChange(inputData.value)}
    />
    {meta.error && meta.touched && <span className={'mr-2 c-red'}>{meta.error}</span>}
  </React.Fragment>
);

const ToggleAdapter = ({ input, ...rest }) => (
  <Checkbox
    {...rest}
    label={input.value === 1 ? 'Active' : 'Inactive'}
    toggle
    checked={input.value === 1}
    onClick={() => {
      input.onChange(input.value === 1 ? 2 : 1);
    }}
  />
);

BasicField.propTypes = {
  name: PropTypes.string,
  validate: PropTypes.func,
  type: PropTypes.string,
  label: PropTypes.string,
};

CalendarAdapter.propTypes = {
  input: PropTypes.object,
  meta: PropTypes.object,
  placeholder: PropTypes.string,
};

DropdownAdapter.propTypes = {
  input: PropTypes.object,
  meta: PropTypes.object,
  label: PropTypes.string,
  options: PropTypes.array,
  initialValue: PropTypes.oneOfType([PropTypes.array, PropTypes.number]),
};

RadioAdapter.propTypes = {
  input: PropTypes.object,
  label: PropTypes.string,
  meta: PropTypes.object,
};

ToggleAdapter.propTypes = {
  input: PropTypes.object,
};

export const adapters = {
  RadioAdapter,
  DropdownAdapter,
  CalendarAdapter,
  ToggleAdapter,
  BasicField,
};

export const validators = {
  mustBeNumber,
  dateFormat,
  digitLength,
  emailMustBeUnique,
  composeValidators,
  required,
};
