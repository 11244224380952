import React, { Component } from 'react';
import { Dropdown, Label } from 'semantic-ui-react';
import API from './api';
import Input from 'semantic-ui-react/dist/commonjs/elements/Input';
import NellisAuctionApi from '../shared-api-adapters/nellis-auction-api';
import PropTypes from 'prop-types';
import withAuthorization from './with-authorization';

class PettyCash extends Component {
  constructor(props) {
    super(props);
    const { authUser } = this.props;

    this.nellisAuctionApi = new NellisAuctionApi(authUser.token);

    this.state = {
      amount: 0.0,
      userId: null,
    };
  }

  setClerkId = (event, { value }) => this.setState({ userId: value });

  setAmount = (event, { value }) => this.setState({ amount: value });

  handleSubmit = async () => {
    const { amount, userId } = this.state;
    const { addNotifications } = this.props;

    const response = await this.nellisAuctionApi.post(this.nellisAuctionApi.routes.pettyCash, {
      userId,
      amount,
    });

    let message = [{ content: 'Added petty cash entry', header: 'Success', color: 'green' }];

    if (!response) {
      message = [{ content: 'Failed to add petty cash entry', header: 'Error', color: 'red' }];
    }

    addNotifications(message);

    this.setState({ userId: null, amount: 0.0 });
  };

  render() {
    const { userId, amount } = this.state;

    return (
      <API.Query queries={[{ route: 'users' }]}>
        {({ data: [users] }) => {
          const filteredUsers = users.filter(({ auctionMethodId }) => auctionMethodId !== null);

          return (
            <div className={'t-center'}>
              <Dropdown
                className={'icon mr-2'}
                labeled
                name={'clerks'}
                clearable
                search
                options={filteredUsers.map(user => ({
                  text: `${user.firstName} ${user.lastName}`,
                  value: user.id,
                }))}
                placeholder={'Please select a clerk'}
                onChange={this.setClerkId}
                selection
                value={userId ? userId : ''}
              />
              <Input
                labelPosition="left"
                type="number"
                step={0.01}
                placeholder="Amount"
                className={'mr-2'}
                onChange={this.setAmount}
                value={amount}
              >
                <Label basic>$</Label>
                <input />
              </Input>
              <button className={'ui button'} onClick={this.handleSubmit}>
                Submit
              </button>
            </div>
          );
        }}
      </API.Query>
    );
  }
}

PettyCash.propTypes = {
  addNotifications: PropTypes.func.isRequired,
  authUser: PropTypes.object.isRequired,
};

export default withAuthorization(PettyCash);
