import React from 'react';
import PropTypes from 'prop-types';

class Closeable extends React.Component {
  state = {
    visible: true,
  };

  componentDidMount() {
    document.addEventListener('mousedown', this.handleClickOutside);
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside);
  }

  ref = React.createRef();

  handleClickOutside = e => {
    if (!this.ref.current.contains(e.target)) {
      this.setState({ visible: false });
    }
  };

  render() {
    const { children } = this.props;

    return (
      <div
        ref={this.ref}
        className={`${this.state.visible ? 'd-block ' : 'd-none '} mt-1 absolute`}
      >
        {children}
      </div>
    );
  }
}

Closeable.propTypes = {
  children: PropTypes.any.isRequired,
};

export default Closeable;
