const MISDESCRIBED_REFUND = {
  id: 7,
  description: 'Mis-Described Refunded',
  feePercent: 0.00001,
};

const COURTESY_REFUND = { id: 8, description: 'Courtesy Refund', feePercent: 0.35 };

const COURTESY_RELIST = { id: 6, description: 'Courtesy Re-List', feePercent: 0.35 };

export { MISDESCRIBED_REFUND, COURTESY_REFUND, COURTESY_RELIST };
