import React, { Component } from 'react';
import { Button, Icon } from 'semantic-ui-react';
import PropTypes from 'prop-types';

class MultiToggleButton extends Component {
  resolveIconDisplay = (l1, l2) => {
    if (l1 === 0) {
      return 'square outline';
    }
    if (l1 === l2) {
      return 'check square outline';
    }
    if (l1 !== l2) {
      return 'minus square outline';
    }
    return 'square outline';
  };

  render() {
    const { selected, options, onClick } = this.props;

    return (
      <Button type={'button'} basic icon onClick={onClick}>
        <Icon name={this.resolveIconDisplay(selected.length, options.length)} />
        <Icon name={'caret down'} />
      </Button>
    );
  }
}

MultiToggleButton.propTypes = {
  selected: PropTypes.array.isRequired,
  options: PropTypes.array.isRequired,
  onClick: PropTypes.func.isRequired,
};

export default MultiToggleButton;
