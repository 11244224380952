import React from 'react';
import { Button } from 'semantic-ui-react';
import { auth } from '../firebase';
import PropTypes from 'prop-types';

const LogoutButton = ({ children, ...rest }) => (
  <Button {...rest} onClick={auth.doSignOut}>
    {children}
  </Button>
);

LogoutButton.propTypes = {
  children: PropTypes.any,
};

export default LogoutButton;
