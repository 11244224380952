import React, { Component } from 'react';
import API from './api';
import AuctionMethodApi from '../shared-api-adapters/auction-method-api';
import PropTypes from 'prop-types';
import SearchBy from './search-by';
import ClosableList from './closeable-list';

class Search extends Component {
  render() {
    const { options, initialOptionValue, route, itemRenderer, label } = this.props;

    return (
      <SearchBy initialOptionValue={initialOptionValue} options={options} label={label}>
        {({ by, searchValue, setLoading }) => {
          if (!by || !searchValue) return null;

          return (
            <API.Query
              queries={[{ route, filter: { [by]: searchValue, limit: 50 } }]}
              api={new AuctionMethodApi()}
              wait={300}
              control={true}
              externalLoadWatcher={setLoading}
            >
              {({ data: [items], loading }) => (
                <ClosableList
                  loading={loading}
                  items={items ? items.map(itemRenderer(searchValue)) : []}
                />
              )}
            </API.Query>
          );
        }}
      </SearchBy>
    );
  }
}

Search.defaultProp = {
  label: '',
};

Search.propTypes = {
  options: PropTypes.array.isRequired,
  initialOptionValue: PropTypes.string,
  route: PropTypes.string.isRequired,
  itemRenderer: PropTypes.func.isRequired,
  label: PropTypes.string,
};

export default Search;
