import React, { Component } from 'react';
import { Button, Container, Divider, Input, Message, Segment } from 'semantic-ui-react';
import { NotificationContext } from './notification-context';
import API from './api';
import withAuthorization from './with-authorization';
import { isEmpty } from 'lodash/fp';
import { FAILED, SUCCESS } from '../constants/notifications';
import Toggle from '../shared-components/toggle';

class QuickCirc extends Component {
  constructor(props) {
    super(props);

    this.state = {
      value: '',
    };
  }

  inputRef = React.createRef();

  handleChange = (event, { value }) => {
    this.setState({ value });
  };

  handleRelist = async () => {
    const {
      props: { post, addNotifications },
      state: { value },
    } = this;

    const result = await post({
      InternalID: value,
      AuctionID: '900',
      ItemStatusID: '0',
      RelistingAmount: 0,
      ModifyInvoice: 'Do nothing',
    });

    if (result) {
      addNotifications([{ ...SUCCESS, content: `Successfully re-listed item ${value}` }]);
    }

    if (!result) {
      addNotifications([{ ...FAILED, content: `Failed to re-list item ${value}` }]);
    }

    this.setState({ value: '' });

    this.inputRef.current.focus();
  };

  componentDidMount() {
    this.inputRef.current.focus();
  }

  render() {
    const {
      props: { auctionMethodApi, loading },
      state: { value },
      inputRef,
      handleChange,
      handleRelist,
    } = this;

    return (
      <Container>
        <Toggle active={true}>
          {({ toggle, active }) => (
            <>
              <div className={'flex fd-row ai-end'}>
                <Button.Group>
                  <Button active={active} onClick={toggle} content={'Manual'} />
                  <Button active={!active} onClick={toggle} content={'Automatic'} />
                </Button.Group>
                <div className={'ml-2'}>
                  {active
                    ? 'User must manually confirm re-listing.'
                    : 'Re-list will occur automatically.'}
                </div>
              </div>
              <Input
                ref={inputRef}
                transparent
                fluid
                placeholder={'Scan item...'}
                onChange={handleChange}
                as={Segment}
                value={value}
              />
              <Divider />
              {value.length === 10 ? (
                <API.Query
                  queries={[{ route: 'inventoryNumber', filter: { internal_id: value } }]}
                  api={auctionMethodApi}
                >
                  {({ data: [item = {}] }) => {
                    return isEmpty(item) ? (
                      <Message color={'red'} content={`No data found for item ${value}`} />
                    ) : (
                      <>
                        <AutoButton
                          automatic={!active}
                          content={'Relist'}
                          onClick={handleRelist}
                          floated={'right'}
                          loading={loading}
                        />
                        <pre>{JSON.stringify(item, 0, 2)}</pre>
                      </>
                    );
                  }}
                </API.Query>
              ) : (
                <Message content={'Nothing Scanned'} />
              )}
            </>
          )}
        </Toggle>
      </Container>
    );
  }
}

class AutoButton extends Component {
  componentDidMount() {
    const { automatic, onClick } = this.props;

    if (automatic) {
      setTimeout(onClick, 1000);
    }
  }

  render() {
    const { automatic, ...buttonProps } = this.props;

    return <Button {...buttonProps} />;
  }
}

const QuickCircCrud = ({ ...props }) => (
  <NotificationContext.Consumer>
    {({ ...notificationCenter }) => (
      <API.Crud api={props.auctionMethodApi} route={'relistItem'}>
        {({ ...crud }) => <QuickCirc {...props} {...crud} {...notificationCenter} />}
      </API.Crud>
    )}
  </NotificationContext.Consumer>
);

export default withAuthorization(QuickCircCrud);
