import React, { Component } from 'react';
import PropTypes from 'prop-types';
import API from './api';
import CargoApi from '../shared-api-adapters/cargo-api';
import { isEmpty } from 'lodash/fp';
import { Card, Divider, Grid, Header, Image, Label, List, Message } from 'semantic-ui-react';

const DB_DESCRIPTOR_LABELS = {
  conditionType: 'Condition',
  damageType: 'Damage',
  missingPartsType: 'Missing Parts',
  packageType: 'Package',
  assemblyType: 'Assembly',
  functionalType: 'Functional',
  salePrice: 'Sale Price',
  retailPrice: 'Retail Price',
};

class PhotoSelect extends Component {
  state = {
    selectedImage: 0,
  };

  handleImageChange = index => {
    this.setState({ selectedImage: index });
  };

  render() {
    const {
      props: {
        item,
        item: { inventoryNumber, leadDescription, notes },
        photos,
        url,
      },
      state: { selectedImage },
      handleImageChange,
    } = this;

    return (
      <Grid container columns={2} divided>
        <Grid.Column width={12}>
          <Image src={photos[selectedImage].url} size={'huge'} />
          <Divider />
          <div>
            <div>
              <Header className={'m-2 centered'} size={'large'}>
                {leadDescription}
              </Header>
            </div>
          </div>
          <div>
            <Header className={'mt-2'} size={'medium'}>
              Notes:
            </Header>
            {notes}
          </div>
          <List className={'mt-4'}>
            <List.Item>
              <Label color={'blue'} horizontal>
                Lot Number
              </Label>
              <a href={url} target={'_blank'} rel={'noopener noreferrer'}>
                {inventoryNumber.slice(-6)}
              </a>
            </List.Item>
            {[
              'conditionType',
              'damageType',
              'packageType',
              'functionalType',
              'missingPartsType',
              'assemblyType',
            ].map((type, key) => (
              <List.Item key={key}>
                <Label color={'blue'} horizontal>
                  {DB_DESCRIPTOR_LABELS[type]}
                </Label>
                {item[type].description}
              </List.Item>
            ))}
            {['retailPrice', 'salePrice'].map((type, key) => (
              <List.Item key={key}>
                <Label color={'blue'} horizontal>
                  {DB_DESCRIPTOR_LABELS[type]}
                </Label>
                ${item[type] || '_'}
              </List.Item>
            ))}
          </List>
        </Grid.Column>
        <Grid.Column width={4} className={'flex fd-column jc-center ai-center'}>
          {photos.map((photo, key) => (
            <Image
              key={key}
              size={'small'}
              src={photo.url}
              onClick={() => handleImageChange(key)}
            />
          ))}
        </Grid.Column>
      </Grid>
    );
  }
}

PhotoSelect.propTypes = {
  item: PropTypes.object.isRequired,
  photos: PropTypes.array.isRequired,
  url: PropTypes.string.isRequired,
};

class LabelDetail extends Component {
  render() {
    const { label, detail, ...rest } = this.props;

    return (
      <Label {...rest}>
        {label}
        <Label.Detail>{detail}</Label.Detail>
      </Label>
    );
  }
}

LabelDetail.defaultProps = {
  color: null,
  size: 'medium',
};

LabelDetail.propTypes = {
  label: PropTypes.string.isRequired,
  detail: PropTypes.string.isRequired,
};

class ItemReport extends Component {
  resolveDescriptorSize = type => {
    switch (type) {
      case 'usageType':
        return 'massive';
      case 'damageType':
      case 'functional':
        return 'big';
      default:
        return 'medium';
    }
  };

  render() {
    const {
      props: { inventoryNumber, naItemUrl },
    } = this;

    return (
      <API.Query
        api={new CargoApi()}
        queries={[{ route: 'itemByInventoryNumber', id: inventoryNumber }]}
      >
        {({ data: [[item]] }) => {
          return isEmpty(item) ? (
            <Message>
              <Message.Header>ITEM DOES NOT EXIST IN CARGO</Message.Header>
              <Message.Content>
                <div className={'mb-1'}>
                  <a
                    href={naItemUrl}
                    className={'ui medium label'}
                    target={'_blank'}
                    rel="noopener noreferrer"
                  >
                    Lot Number: <div className={'detail'}>{inventoryNumber.slice(-6)}</div>
                  </a>
                </div>
              </Message.Content>
            </Message>
          ) : (
            <Card fluid color="blue" className={'p-4'}>
              <PhotoSelect photos={item.photos} item={item} url={naItemUrl} />
            </Card>
          );
        }}
      </API.Query>
    );
  }
}

ItemReport.propTypes = {
  inventoryNumber: PropTypes.string.isRequired,
  naItemUrl: PropTypes.string.isRequired,
};

export default ItemReport;
