import React from 'react';
import { Table, Header, Loader, Button } from 'semantic-ui-react';
import { useAsync, useAsyncFn } from 'react-use';
import CargoApi from '../shared-api-adapters/cargo-api';
import { Link } from 'react-router-dom';
import * as routes from '../constants/routes';

const useFraudData = () => {
  const cargoApi = new CargoApi();

  const [
    { loading: fetchingAccountsForReview, value: accountsForReview = [] },
    fetchAccountsForReview,
  ] = useAsyncFn(async () => {
    return await cargoApi.get(cargoApi.routes.accountsForReview);
  });

  useAsync(async () => {
    await fetchAccountsForReview();
  }, []);

  return { fetchingAccountsForReview, accountsForReview, fetchAccountsForReview };
};

const FraudDashboard = () => {
  const { accountsForReview, fetchingAccountsForReview } = useFraudData();

  if (fetchingAccountsForReview) return <Loader active />;

  return (
    <>
      <Header>Accounts Pending Review</Header>
      <Table basic={'very'}>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>Date</Table.HeaderCell>
            <Table.HeaderCell>Name</Table.HeaderCell>
            <Table.HeaderCell>Bid Count</Table.HeaderCell>
            <Table.HeaderCell>Reason</Table.HeaderCell>
            <Table.HeaderCell>Customer Profile Id</Table.HeaderCell>
            <Table.HeaderCell />
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {accountsForReview.map((account, key) => {
            return (
              <Table.Row key={key}>
                <Table.Cell>{new Date(account.createdAt).toLocaleDateString()}</Table.Cell>
                <Table.Cell>{`${account.firstName} ${account.lastName}`}</Table.Cell>
                <Table.Cell>{account.bidCount}</Table.Cell>
                <Table.Cell>{account.fraudReasonType.description}</Table.Cell>
                <Table.Cell>{account.authNetId}</Table.Cell>
                <Table.Cell>
                  <Button
                    as={Link}
                    content={'Details'}
                    to={`${routes.CUSTOMER}/${account.customerId}`}
                  />
                </Table.Cell>
              </Table.Row>
            );
          })}
        </Table.Body>
      </Table>
    </>
  );
};

export default FraudDashboard;
