import React from 'react';
import PropTypes from 'prop-types';
import * as routes from '../constants/routes';
import { TESTERS } from '../constants/access-levels';

class Access extends React.Component {
  inTestGroup = userId => TESTERS.includes(userId);

  isStaging = () => {
    const env = process.env.REACT_APP_NODE_ENV || '';

    return env.toUpperCase() === 'STAGING';
  };

  componentDidMount() {
    const { accessLevel, authUser, history, checkApiKey } = this.props;

    if (
      !authUser ||
      !accessLevel.includes(authUser.roleId) ||
      !authUser.applicationIds.includes(3) ||
      (this.isStaging() && !this.inTestGroup(authUser.id))
    )
      history.push(routes.HOME, {
        message: {
          error: true,
          header: 'You do not have permission to view that content',
          content: 'If this is a mistake please contact your administrator',
        },
      });

    if (checkApiKey && !authUser.apiKey)
      history.push(routes.HOME, {
        message: {
          error: true,
          header: 'You do not have a valid API key to view that content',
          content: 'If this is a mistake please contact your administrator',
        },
      });
  }

  render() {
    const { render } = this.props;

    return render(this.props);
  }
}

Access.propTypes = {
  render: PropTypes.any.isRequired,
  accessLevel: PropTypes.array.isRequired,
  authUser: PropTypes.object,
  history: PropTypes.object.isRequired,
  checkApiKey: PropTypes.bool,
};

export default Access;
