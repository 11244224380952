import React, { useState, useRef } from 'react';
import { useAsync, useAsyncFn } from 'react-use';
import { hrFirestore } from '../shared-components/firebase/firestore';
import { Icon, Label, Table, Button, Input } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import * as routes from '../constants/routes';
import DataFilter from './data-filter';
import Paginater from './paginater';
import moment from 'moment';
import { DateInput } from 'semantic-ui-calendar-react';
import { CSVLink } from 'react-csv';

export const useLostFeed = affiliateId => {
  const [lostFeed, setLostFeed] = useState([]);

  useAsync(async () => {
    hrFirestore
      .collection('lost')
      .where('affiliateId', '==', affiliateId)
      .onSnapshot(snapShot => {
        const data = snapShot.docs.map(doc => {
          return { docId: doc.id, ...doc.data() };
        });

        setLostFeed(data);
      });
  });

  const [remove] = useAsyncFn(async docId => {
    return hrFirestore
      .collection('lost')
      .doc(docId)
      .delete();
  });

  return [lostFeed, remove];
};

export const LostItemFeed = ({ formatUser, affiliateId }) => {
  const [lostFeed] = useLostFeed(affiliateId);
  const [startDate, setStartDate] = useState(moment(0, 'HH'));
  const [endDate, setEndDate] = useState(moment({ hour: 23, minute: 59, seconds: 59 }));
  const csvLink = useRef();

  return (
    <>
      <DataFilter
        filterBy={'name'}
        data={lostFeed
          .map(item => ({ ...item, name: formatUser(item.userId) }))
          .filter(
            ({ createdAt: { seconds } }) => seconds > startDate.unix() && seconds < endDate.unix()
          )
          .sort(({ createdAt: a }, { createdAt: b }) => b - a)}
        conditionalOverride={(item, reg, value) => {
          return (
            reg.test(item.inventoryNumber) ||
            String(item.userId).startsWith(value) ||
            String(item.customerId).startsWith(value) ||
            String(item.invoiceId).startsWith(value) ||
            String(item.createdAt.toDate().toLocaleDateString()).startsWith(value)
          );
        }}
      >
        {({ filteredData, handleSearchValueChange, handleSearchValueClear, value }) => {
          return (
            <Paginater maxItemsPerPage={10} items={filteredData} domain={filteredData.length}>
              {({ renderPaginater, itemsOnThisPage }) => {
                return (
                  <>
                    <div className={'flex fd-column'}>
                      <div className={filteredData.length > 10 ? 'mb-3' : null}>
                        {renderPaginater()}
                      </div>

                      <div className={'flex jc-between mb-3'}>
                        <div>
                          <Label className={'as-center'} color={'teal'} size={'large'}>
                            Count: <Label.Detail>{filteredData.length}</Label.Detail>
                          </Label>
                          <Button
                            size={'small'}
                            icon={'download'}
                            onClick={() => csvLink.current.link.click()}
                          />
                          <CSVLink
                            data={filteredData}
                            filename={`Lost_Items_${startDate.format('MM-DD-YYYY')}_${endDate.format('MM-DD-YYYY')}.csv`}
                            className={'hidden'}
                            ref={csvLink}
                            target={'_blank'}
                          />
                        </div>
                        <Input
                          icon={{
                            name: value ? 'close' : 'search',
                            link: true,
                            onClick: () => {
                              handleSearchValueClear();
                            },
                          }}
                          placeholder={'Search'}
                          onChange={handleSearchValueChange}
                          value={value}
                        />
                      </div>
                    </div>

                    <div className={'flex jc-between'}>
                      <DateInput
                        closable
                        dateFormat={'MM-DD-YYYY'}
                        iconPosition="left"
                        onChange={(event, { value }) => setStartDate(moment(value, 'MM-DD-YYYY'))}
                        value={startDate}
                        placeholder={'Enter start date...'}
                        label={'From: '}
                      />
                      <DateInput
                        closable
                        dateFormat={'MM-DD-YYYY'}
                        iconPosition="left"
                        onChange={(event, { value }) => {
                          const end = moment(value, 'MM-DD-YYYY');

                          setEndDate(
                            end
                              .hour(23)
                              .minute(59)
                              .second(59)
                          );
                        }}
                        value={endDate}
                        placeholder={'Enter end date...'}
                        label={'To: '}
                      />
                    </div>

                    <Table basic="very" collapsing striped>
                      <Table.Header>
                        <Table.Row>
                          <Table.HeaderCell>Customer ID</Table.HeaderCell>
                          <Table.HeaderCell>Inventory Number</Table.HeaderCell>
                          <Table.HeaderCell>Invoice ID</Table.HeaderCell>
                          <Table.HeaderCell>Location</Table.HeaderCell>
                          <Table.HeaderCell>Employee</Table.HeaderCell>
                          <Table.HeaderCell>Date</Table.HeaderCell>
                        </Table.Row>
                      </Table.Header>

                      <Table.Body>
                        {itemsOnThisPage.map((item, key) => {
                          return (
                            <Table.Row key={key}>
                              <Table.Cell>
                                <Link
                                  to={`${routes.CUSTOMER}/${item.customerId}?search=${item.invoiceId}`}
                                >
                                  {item.customerId}
                                </Link>
                              </Table.Cell>
                              <Table.Cell>{item.inventoryNumber}</Table.Cell>
                              <Table.Cell>{item.invoiceId}</Table.Cell>
                              <Table.Cell>{item.warehousePosition || 'Unknown'}</Table.Cell>
                              <Table.Cell>{formatUser(item.userId)}</Table.Cell>
                              <Table.Cell>
                                {item.createdAt.toDate().toLocaleDateString()}
                              </Table.Cell>
                            </Table.Row>
                          );
                        })}
                      </Table.Body>
                    </Table>
                  </>
                );
              }}
            </Paginater>
          );
        }}
      </DataFilter>
    </>
  );
};

export const LostItemFeedToggle = ({ affiliateId, ...buttonProps }) => {
  const [lostFeed] = useLostFeed(affiliateId);

  return (
    <Button color={'blue'} icon labelPosition={'left'} {...buttonProps}>
      <Icon name={'blind'} className={'m-0'} />
      {`${
        lostFeed.filter(({ createdAt: { seconds } }) => seconds > moment(0, 'HH').unix()).length
      }`}
    </Button>
  );
};
