import React, { useState } from 'react';
import { Header, Input, Button, Segment, Message } from 'semantic-ui-react';
import { doPasswordReset } from '../shared-components/firebase/auth-helpers';
import { isEmpty } from 'lodash/fp';

const EmployeePasswordReset = () => {
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState({});

  const passwordReset = () => {
    const res = doPasswordReset(email);

    res
      .then(() => {
        setMessage({
          color: 'green',
          header: 'Success',
          message: `Email has been sent to ${email} to reset password`,
          icon: 'check circle',
        });
      })
      .catch(error => {
        setMessage({
          color: 'red',
          header: 'Error',
          message: error.message,
          icon: 'remove circle',
        });
      });
  };

  return (
    <>
      <div className={'flex ai-center jc-center mt-4'}>
        <Segment className={'w-75'}>
          <Header content={'Password Reset'} icon={'asterisk'} />
          <Input
            type={'email'}
            fluid
            onChange={(event, { value }) => setEmail(value)}
            actionPosition={'right'}
            action={
              <Button content={'Send'} icon={'paper plane'} onClick={() => passwordReset()} />
            }
            placeholder={'Enter employee email to reset...'}
          />
          {isEmpty(message) ? null : (
            <div className={'mt-3'}>
              <Message
                icon={message.icon}
                header={message.header}
                content={message.message}
                color={message.color}
              />
            </div>
          )}
        </Segment>
      </div>
    </>
  );
};

export default EmployeePasswordReset;
