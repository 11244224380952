export const REGISTRATION = '/registration';
export const LOGIN = '/login';
export const HOME = '/';
export const ACCOUNT = '/account';
export const PASSWORD_FORGET = '/password-forget';
export const TEAM = '/teams';
export const USERS = '/users';
export const USERS_CREATE = '/users/create';
export const INACTIVE_INVENTORY = '/inactiveInventory';
export const ITEM = '/item';
export const INVOICES = '/invoices';
export const RETURNS = '/returns';
export const CHANGE_LOG = '/changeLog';
export const ADMIN = '/admin';
export const CUSTOMER = '/customer';
export const PICK_LIST = '/pickList';
export const QUICK_CIRC = '/quickCirc';
export const BATCH_MOVE = '/batchMove';
export const BATCH_STORE_CREDIT = '/batchStoreCredit';
export const PICKUP_QUEUE = '/pickupQueue';
export const PICKUP_QUEUE_EDIT = '/pickupQueueEdit';
export const STATS_DASHBOARD = '/stats';
export const LOT_DETAILS = '/lotDetails';
export const LOT_DETAILS_SETTINGS = '/lotDetails/settings';
export const LOT_QUEUE = '/lotQueue';
export const FRAUD_DASHBOARD = '/fraudDashboard';
export const EMPLOYEE_RESET_PASSWORD = '/employeeResetPassword';
