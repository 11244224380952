import React, { Component } from 'react';
import { Button, Icon, Label, Message, Popup, Responsive, Transition } from 'semantic-ui-react';
import PropTypes from 'prop-types';
import { render } from 'react-dom';

class AnimatedMessage extends Component {
  componentDidMount() {
    const { node } = this.props;

    setTimeout(() => {
      node.remove();
    }, 10000);
  }

  render() {
    const { duration, message, header, color, animation, size } = this.props;

    return (
      <Transition
        visible={true}
        animation={animation || 'fade up'}
        duration={duration || 500}
        unmountOnHide
      >
        <Message color={color || 'black'} compact size={size || 'huge'} className={'mb-1'}>
          <Message.Header>{header}</Message.Header>
          <Message.Content>{message}</Message.Content>
        </Message>
      </Transition>
    );
  }
}

AnimatedMessage.propTypes = {
  message: PropTypes.string.isRequired,
  animation: PropTypes.string,
  duration: PropTypes.number,
  color: PropTypes.string,
  node: PropTypes.any.isRequired,
  header: PropTypes.string.isRequired,
  size: PropTypes.string,
};

const snackbarPortal = (message, header, color, size, animation = 'fade up') => {
  const snackbarRoot = document.querySelector('#snackbar');

  const div = document.createElement('div');

  snackbarRoot.append(div);

  return render(
    <AnimatedMessage
      message={message}
      header={header}
      color={color}
      animation={animation}
      node={div}
      size={size}
    />,
    div
  );
};

export { snackbarPortal };

class NotificationsCenter extends Component {
  state = {
    loggedNotifications: [],
    transition: false,
    open: false,
  };

  handleNotificationOpen = () => {
    this.setState({
      open: true,
      loggedNotifications: [...this.props.notifications],
    });
  };

  handleNotificationClose = () => {
    this.setState({ open: false });
  };

  componentDidUpdate(prevProps) {
    if (prevProps.notifications.length !== this.props.notifications.length) {
      this.setState(prevState => ({ transition: !prevState.transition }));

      this.props.notifications
        .slice(prevProps.notifications.length)
        .forEach(notification =>
          snackbarPortal(
            notification.content,
            notification.header,
            notification.color,
            notification.size
          )
        );
    }
  }

  renderNotifications = () => {
    const {
      props: { notifications },
    } = this;

    return (
      <>
        {notifications.length > 0 ? (
          notifications.map(
            (
              {
                color = null,
                header = 'Notification',
                timestamp = new Date().toLocaleTimeString(),
                content = 'No message available',
                size = 'huge',
              },
              key
            ) => (
              <Message key={key} color={color} size={size}>
                <Message.Header>{header} </Message.Header>
                <Message.Content>{timestamp}</Message.Content>
                <Message.Content>{content}</Message.Content>
              </Message>
            )
          )
        ) : (
          <div>No notifications</div>
        )}
      </>
    );
  };

  render() {
    const {
      props: { notifications },
      state: { transition, open, loggedNotifications },
      handleNotificationOpen,
      handleNotificationClose,
      renderNotifications,
    } = this;

    const diff = notifications.slice(loggedNotifications.length);
    const isErrorNotification = notifications
      .filter((val, key) => !loggedNotifications[key])
      .some(notification => notification.color === 'red');

    return (
      <>
        <Responsive maxWidth={'767'}>
          <Popup
            open={open}
            basic
            trigger={
              <Transition
                animation={'small'}
                duration={500}
                visible={transition}
                mountOnShow={false}
              >
                <Button className={'m-2'} icon onClick={handleNotificationOpen}>
                  <Button.Content className={'inline-flex ai-center'}>
                    <Icon name={'bell'} />
                    {diff.length > 0 && (
                      <Label color={isErrorNotification ? 'red' : 'green'} floating>
                        {diff.length}
                      </Label>
                    )}
                  </Button.Content>
                </Button>
              </Transition>
            }
            content={renderNotifications()}
            position={'bottom right'}
            onClose={handleNotificationClose}
          />
        </Responsive>
        <Responsive minWidth={'768'}>
          <Popup
            open={open}
            basic
            trigger={
              <Transition
                animation={'small'}
                duration={500}
                visible={transition}
                mountOnShow={false}
              >
                <Button size={'medium'} icon className={'m-2'} onClick={handleNotificationOpen}>
                  <Button.Content>
                    <Icon size={'large'} name={'bell'} />
                    {diff.length > 0 && (
                      <Label color={isErrorNotification ? 'red' : 'green'} floating>
                        {diff.length}
                      </Label>
                    )}
                  </Button.Content>
                </Button>
              </Transition>
            }
            content={renderNotifications()}
            position={'bottom right'}
            onClose={handleNotificationClose}
          />
        </Responsive>
      </>
    );
  }
}

NotificationsCenter.propTypes = {
  notifications: PropTypes.array.isRequired,
};

export default NotificationsCenter;
