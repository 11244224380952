const SUPERVISOR = [1, 2, 5];
const AUTHENTICATED_USER = [3];

const TESTERS = [
  1, // Mike
  2, // Zach
  3, // Spencer
  4, // Damien
  223, // Aaron
  579, // Dustin
  763,
  31,
  57,
  58,
  89,
  107,
  116,
  198,
  256,
  512,
  575,
  586,
  589,
  605,
  615,
  643,
  672,
  703,
  711,
  738,
];

export { SUPERVISOR, AUTHENTICATED_USER, TESTERS };
