import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Container,
  Divider,
  Header,
  Icon,
  Label,
  List,
  Modal,
  Portal,
  Segment,
} from 'semantic-ui-react';
import {
  firebaseArrayUnion,
  getLotQueueCollection,
  pickupQueueCollection,
  useCollectionSnapshot,
  useCustomerAppointmentsByAffiliate,
  useLotQueueSnapshot,
} from '../shared-components/firebase/firestore';
import { sortByArrivalTime } from '../shared-components/helpers';
import { useAsyncFn } from 'react-use';
import AuthUserContext from './auth-user-context';
import { QuickCustomerLookup } from './pickup-queue-search';
import { startCase, isEmpty } from 'lodash/fp';
import { CurbSide } from './curbside-city';
import { PickUpQueuePayment } from './pickup-queue';
import { differenceInMinutes } from 'date-fns';
import DataFilter from './data-filter';
import FocusInput from './focus-input';
import { useLocation, useHistory } from 'react-router-dom';
import { urlSearchParams } from '../helpers/url-utils';

export const LOT_QUEUE_STATUSES = {
  IN_QUEUE: 'IN_QUEUE',
  PROCESSING: 'PROCESSING',
  CHECKED_IN: 'CHECKED_IN',
  COMPLETE: 'COMPLETED',
};

const useParkingLotPick = () => {
  const {
    authUser: { userId, location },
  } = useContext(AuthUserContext);

  return useCollectionSnapshot(
    getLotQueueCollection(location.affiliateId)
      .where('userId', '==', userId)
      .where('status', '==', LOT_QUEUE_STATUSES.PROCESSING)
  );
};

const GrabNextParkingSpot = ({ docId, setUserToPick, loading }) => {
  return (
    <Button
      loading={loading}
      className={'mt-3'}
      fluid
      icon={'dolly'}
      color={docId ? 'green' : 'grey'}
      disabled={!docId}
      content={docId ? 'SERVICE NEXT CUSTOMER' : 'NO MORE CUSTOMERS PARKED'}
      onClick={setUserToPick}
      size={'massive'}
    />
  );
};

GrabNextParkingSpot.propTypes = {
  docId: PropTypes.string,
  setUserToPick: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
};

const CurrentParkingSpotCustomerLookUp = ({ setCustomer }) => {
  const [searching, setSearching] = useState(false);

  return (
    <div>
      <Button
        onClick={() => setSearching(true)}
        icon={'search'}
        size={'mini'}
        content={'Search Customer Database'}
        fluid
        color={'yellow'}
      />
      <Modal open={searching} size={'mini'} centered={false} onClose={() => setSearching(false)}>
        <div className={'bg-white'}>
          <div className={'bg-material-blue c-white p-3 fs-x-large'}>Customers</div>
          <div className={'p-3 border-light'}>
            <QuickCustomerLookup
              inputSize={'mini'}
              searching={searching}
              itemRenderer={() => ({ id, firstname: firstName, lastname: lastName, email }) => ({
                key: id,
                header: `${startCase(firstName)} ${startCase(lastName)}`,
                description: email,
                onClick: () => {
                  setCustomer({ id, firstName, lastName, email });
                  setSearching(false);
                },
              })}
            />
          </div>
        </div>
      </Modal>
    </div>
  );
};

CurrentParkingSpotCustomerLookUp.propTypes = {
  setCustomer: PropTypes.func.isRequired,
};

const PickUpQueueLookUp = ({ affiliateId, setCustomer, pick }) => {
  const pickUpQueueData = useCollectionSnapshot(
    pickupQueueCollection.where('status', '<', 4).where('affiliateId', '==', affiliateId)
  ).map(data => {
    // for data filter to work properly we need to remove the reference prop
    // eslint-disable-next-line no-unused-vars
    const { ref, ...rest } = data;

    return rest;
  });

  // for when a user scans their drivers license we autofill data filter
  const initialFilterValue =
    !isEmpty(pick) && !isEmpty(pick.customerUpdates) ? pick.customerUpdates.initialFilter : '';

  return (
    <DataFilter
      filterBy={'id'}
      data={pickUpQueueData}
      initialValue={initialFilterValue}
      conditionalOverride={(queueItem, test) => {
        return (
          test.test(queueItem.customer.firstName) ||
          test.test(queueItem.customer.lastName) ||
          test.test(JSON.stringify(queueItem.invoiceIds)) ||
          test.test(queueItem.customer.phoneNumber) ||
          test.test(queueItem.customer.id)
        );
      }}
    >
      {({ filteredData, handleSearchValueChange, handleSearchValueClear, value }) => {
        return (
          <>
            <FocusInput
              fluid
              size={'mini'}
              className={'py-2 my-2 relative'}
              value={value}
              icon={{
                name: value ? 'close' : 'search',
                link: true,
                onClick: () => {
                  handleSearchValueClear();
                },
              }}
              placeholder={'Search Pickup Queue By Name, Phone, Invoice, Customer Code'}
              onChange={handleSearchValueChange}
            />

            <Portal open={!!value}>
              <Container>
                <Segment style={{ top: '14rem' }}>
                  <List celled selection>
                    {filteredData.map((item, key) => {
                      return (
                        <List.Item
                          key={key}
                          className={'py-2 flex fd-row ai-center jc-start'}
                          onClick={() => {
                            handleSearchValueClear();
                            setCustomer({ ...item.customer });
                          }}
                        >
                          <Label className={'ellipsis mw-8r'}>
                            {`${item.customer.lastName}, ${item.customer.firstName}`}
                          </Label>
                          <Label>
                            ETA:
                            <Label.Detail>
                              {item.approxPickupTime.toDate().toLocaleString()}
                            </Label.Detail>
                          </Label>
                        </List.Item>
                      );
                    })}
                  </List>

                  <Button
                    content={'Close'}
                    negative
                    size={'small'}
                    onClick={handleSearchValueClear}
                  />
                </Segment>
              </Container>
            </Portal>
          </>
        );
      }}
    </DataFilter>
  );
};

PickUpQueueLookUp.propTypes = {
  affiliateId: PropTypes.number.isRequired,
  setCustomer: PropTypes.func.isRequired,
  pick: PropTypes.object.isRequired,
};

const CurrentParkingSpot = ({ pick: { customers = [], ...pick }, affiliateId }) => {
  const routerLocation = useLocation();
  const { push } = useHistory();

  // @TODO clean this up. I haven't come up with a smooth way to get out
  // of the box async cleanup. This is hacky, but gets us there
  const [, editPick] = useAsyncFn(async fn => {
    await fn();
  });

  const [, updateFbPick] = useAsyncFn(
    async c => {
      return await pick.ref.update({ customers: firebaseArrayUnion(c) });
    },
    [pick]
  );

  const [, dropParkingSpot] = useAsyncFn(async () => {
    return await pick.ref.update({ userId: null, status: LOT_QUEUE_STATUSES.IN_QUEUE });
  }, [pick]);

  const waitTimeInMinutes = differenceInMinutes(new Date(), pick.arrivalTime.toDate());
  const up = urlSearchParams(routerLocation, push);

  return (
    <>
      <Button className={'mb-1'} onClick={() => dropParkingSpot()}>
        <Icon name={'cart arrow down'} />
        Drop
      </Button>
      <CurrentParkingSpotCustomerLookUp setCustomer={updateFbPick} />
      <PickUpQueueLookUp pick={pick} affiliateId={affiliateId} setCustomer={updateFbPick} />
      <div className={'flex fd-row jc-between ai-center'}>
        <Header>
          Arrival Time:
          <Header.Subheader className={'fd-column jc-start'}>
            {pick.arrivalTime.toDate().toLocaleString()}
            <Label color={waitTimeInMinutes >= 5 ? 'red' : null}>
              Waiting <Label.Detail>{waitTimeInMinutes} min</Label.Detail>
            </Label>
          </Header.Subheader>
        </Header>
        <Label size={'massive'} color={pick.color || null} className={'flex pointer'}>
          <Icon name={'car'} />
          {pick.lot}
        </Label>
      </div>
      {customers.map((customer, key) => (
        <CurrentParkingSpotDetail key={key} customer={customer || {}} pick={pick} />
      ))}
      <Divider />
      <Button
        fluid
        content={'Complete Check In'}
        size={'tiny'}
        color={'teal'}
        disabled={!pick.appointments}
        onClick={() => {
          editPick(() =>
            pick.ref.update({
              userId: null,
              checkedInBy: pick.userId,
              status: LOT_QUEUE_STATUSES.COMPLETE,
            })
          );

          if (up.urlParams.has('from'))
            push(`${up.urlParams.get('from')}?tab=${up.urlParams.get('tab')}`);
        }}
      />
    </>
  );
};

CurrentParkingSpot.propTypes = {
  pick: PropTypes.object.isRequired,
  affiliateId: PropTypes.number.isRequired,
};

export const resolveStatus = status => {
  switch (status) {
    case -2:
      return { color: 'pink', content: 'NR' };
    case -1:
      return { color: 'grey', content: 'S' };
    case 0:
      return { color: 'red', content: 'AC' };
    case 1:
      return { color: 'blue', content: 'IP' };
    case 2:
      return { color: 'yellow', content: 'C' };
    case 3:
      return { color: 'orange', content: 'LO' };
    case 4:
      return { color: 'green', content: 'PU' };
    default:
      return { content: 'N/A' };
  }
};

const CurrentParkingSpotDetail = ({ customer, pick }) => {
  const {
    authUser: { location, userId },
  } = useContext(AuthUserContext);

  const [paymentModalOpen, setPaymentModal] = useState({});

  const lotQueueCollection = getLotQueueCollection(location.affiliateId);

  const appointments = useCustomerAppointmentsByAffiliate(
    customer.auctionMethodId || customer.id,
    location.affiliateId
  );

  const [, editFB] = useAsyncFn(async fn => {
    return await fn();
  });

  const [, assignSelectedAppointmentsToFirebase] = useAsyncFn(
    async appointment => {
      return await lotQueueCollection.doc(pick.docId).set(
        {
          appointments: firebaseArrayUnion(appointment.ref),
        },
        { merge: true }
      );
    },
    [pick]
  );

  return (
    <>
      {!isEmpty(customer) && (
        <>
          <Divider />
          <Header as="h2">
            <Icon name="user" />
            <Header.Content>
              {`${customer.lastName}, ${customer.firstName}`}
              <Header.Subheader>ID: {customer.auctionMethodId || customer.id}</Header.Subheader>
              <Header.Subheader>Email: {customer.email}</Header.Subheader>
            </Header.Content>
          </Header>
        </>
      )}
      {!isEmpty(customer) && isEmpty(appointments) && (
        <>
          <Header color={'red'}>
            There are no appointments scheduled for the current customer
          </Header>
          <Modal
            trigger={
              <Button
                size={'mini'}
                content={'Schedule Appointment'}
                fluid
                icon={'pencil'}
                color={'grey'}
              />
            }
          >
            <Modal.Content>
              <CurbSide customerId={customer.id} userId={userId} />
            </Modal.Content>
          </Modal>
        </>
      )}
      {!isEmpty(customer) && !isEmpty(appointments) && (
        <>
          <Header content={'Appointments'} />
          {appointments.map((appointment, key) => {
            return (
              <div key={key} className={'my-2'}>
                <div className={'flex fd-row jc-between'}>
                  <div className={'flex fd-column'}>
                    <div>
                      <strong>Schedule Time: </strong>
                      {appointment.approxPickupTime.toDate().toLocaleString()}
                    </div>
                    <div>Invoices: [{appointment.invoiceIds.join(', ')}]</div>
                    <div>Number Of Items: {appointment.itemIds.length}</div>
                    <div>
                      Status: <Label size={'large'} {...resolveStatus(appointment.status)} />
                      {appointment.isHere && (
                        <Button size={'large'} color={'green'} icon compact>
                          <Icon name={'thumbs up'} />
                        </Button>
                      )}
                    </div>
                  </div>

                  <div className={'flex fd-column jc-end'}>
                    <Modal
                      open={paymentModalOpen[key]}
                      onClose={() => setPaymentModal(prevState => ({ ...prevState, [key]: false }))}
                      size={'mini'}
                      trigger={
                        <Button
                          onClick={() =>
                            setPaymentModal(prevState => ({ ...prevState, [key]: true }))
                          }
                          content={appointment.paid && appointment.isHere ? 'Paid' : 'Check In'}
                          size={'mini'}
                          color={appointment.paid && appointment.isHere ? 'green' : null}
                        />
                      }
                    >
                      <Modal.Content>
                        <PickUpQueuePayment
                          queueId={appointment.docId}
                          customerId={Number(customer.id)}
                          invoiceIds={appointment.invoiceIds}
                          buttonContent={'Check In'}
                          onPaymentComplete={async () => {
                            // pull the latest data to update status properly if necessary
                            const doc = await appointment.ref.get();

                            const { status } = doc.data();

                            editFB(() =>
                              appointment.ref.update({
                                isHere: true,
                                paid: true,
                                arrivalTime: pick.arrivalTime,
                                parkingSpace: pick.lot,
                                status: status === -1 || status === 2 ? status + 1 : status,
                                checkedInBy: pick.userId,
                                parkingAssignedBy: pick.parkingAssignedBy,
                              })
                            );

                            assignSelectedAppointmentsToFirebase(appointment);

                            setPaymentModal(prevState => ({ ...prevState, [key]: false }));
                          }}
                          showButton={true}
                        />
                      </Modal.Content>
                    </Modal>
                  </div>
                </div>
              </div>
            );
          })}
        </>
      )}
    </>
  );
};

CurrentParkingSpotDetail.propTypes = {
  customer: PropTypes.object.isRequired,
  pick: PropTypes.object.isRequired,
};

const LotQueue = () => {
  const {
    authUser: { location, userId },
  } = useContext(AuthUserContext);
  const lotQueueSnapshot = sortByArrivalTime(
    useLotQueueSnapshot(location.affiliateId, [LOT_QUEUE_STATUSES.IN_QUEUE])
  );
  const [firstPriority = {}] = lotQueueSnapshot;
  const [pick] = useParkingLotPick();

  const lotQueueCollection = getLotQueueCollection(location.affiliateId);

  const [{ loading }, setUserToPick] = useAsyncFn(
    async docId => {
      if (!userId) return;

      lotQueueCollection.doc(docId).update({ userId, status: LOT_QUEUE_STATUSES.PROCESSING });
    },
    [userId]
  );

  return pick ? (
    <CurrentParkingSpot pick={pick} affiliateId={location.affiliateId} />
  ) : (
    <>
      <GrabNextParkingSpot
        docId={firstPriority.docId}
        setUserToPick={() => setUserToPick(firstPriority.docId)}
        loading={loading}
      />
      <Divider />
      {lotQueueSnapshot.map((lot, key) => {
        return (
          <Button
            key={key}
            className={'mb-1'}
            content={lot.lot}
            color={lot.color}
            fluid
            onClick={() => setUserToPick(lot.docId)}
          />
        );
      })}
    </>
  );
};

// @TODO clean this up
const WithAuthUser = () => {
  const { authUser } = useContext(AuthUserContext);

  if (!authUser) return '';

  return <LotQueue />;
};

export default WithAuthUser;

/**
 * ======================================================================
 * THIS IS ALL THE LICENSE PLATE PICTURE AND DRIVERS LICENSE CAPTURE CODE
 * ======================================================================
 */
// const CustomerRegistration = ({ pick, setCustomer, onComplete }) => {
//   const cargoApi = new CargoApi();
//
//   const [[{ results = [] } = {}] = [], , camera, , lpLoading] = useLicensePlateReader();
//   const [selectedLicensePlate, setLicensePlate] = useState();
//   const [selectedCustomer, setSelectedCustomer] = useState({});
//
//   const {
//     driversLicenseInput,
//     driversLicenseData,
//     status: dlScanStatus,
//   } = useDriversLicenseReader();
//
//   const [
//     { loading: checkingIfPlateExists, value: [{ customers = [], licensePlate } = {}] = [] },
//     checkIfLicensePlateExists,
//   ] = useAsyncFn(async () => {
//     return await cargoApi.get(`/licensePlate/${selectedLicensePlate}`);
//   }, [selectedLicensePlate]);
//
//   const [, updateFirebasePickReference] = useAsyncFn(
//     async data => {
//       return await pick.ref.set(data, { merge: true });
//     },
//     [pick]
//   );
//
//   useAsync(async () => {
//     if (isEmpty(selectedCustomer)) return;
//
//     await updateFirebasePickReference({
//       verifiedCustomerInfo: { customer: selectedCustomer, licensePlate },
//     });
//     we have verified plate, remove update request
// await updateFirebasePickReference({ customerUpdates: { licensePlate: deleteField() } });
// }, [selectedCustomer, updateFirebasePickReference]);
//
// useAsync(async () => {
//   if (!selectedLicensePlate) return;
//
//   await updateFirebasePickReference({ customerUpdates: { licensePlate: selectedLicensePlate } });
//   await checkIfLicensePlateExists();
// }, [selectedLicensePlate, checkIfLicensePlateExists]);
//
// useAsync(async () => {
//   if (dlScanStatus !== 'SUCCESS') return;
//
//   updateFirebasePickReference({
//     customerUpdates: { driversLicenseData, initialFilter: driversLicenseData.lastName },
//   });
// }, [dlScanStatus, updateFirebasePickReference, driversLicenseData]);
//
// const loading = lpLoading || checkingIfPlateExists;
//
// return (
//   <>
//     {/* <Button*/}
//     {/*  fluid*/}
//     {/*  color={'red'}*/}
//     {/*  content={'Skip Customer Registration!'}*/}
//     {/*  onClick={() => {*/}
//     {/*    onComplete();*/}
//     {/*  }}*/}
//     {/* />*/}
//     {/* <Divider />*/}
//     <Header>License Plate Picture</Header>
//     {camera}
//     {loading && (
//       <Segment className={'p-4'}>
//         <Dimmer active>
//           <Loader />
//         </Dimmer>
//       </Segment>
//     )}
//     <div className={'flex fd-column my-2'}>
//       {results.length > 0 && 'Please select correct plate!'}
//       {results.map(({ plate }, key) => {
//         return (
//           <Button
//             key={key}
//             content={plate.toUpperCase()}
//             fluid
//             onClick={() => setLicensePlate(plate)}
//             disabled={!!selectedLicensePlate}
//           />
//         );
//       })}
//     </div>
//     {selectedLicensePlate && isEmpty(customers) && (
//       <Message>
//         <Message.Content>No Associated Customers To Given License Plate</Message.Content>
//       </Message>
//     )}
//     {customers.length > 0 && (
//       <Header>
//         Customers Accounts <Header.Subheader>Please select account!</Header.Subheader>
//       </Header>
//     )}
//     <List celled selection>
//       {customers.map((customer, key) => {
//         return (
//           <List.Item
//             key={key}
//             onClick={() => setSelectedCustomer(customer)}
//             active={selectedCustomer.id === customer.id}
//           >
//             <Label size={'tiny'} className={'mb-1'} color={'blue'}>
//               Name:
//               <Label.Detail>{`${customer.firstName} ${customer.lastName}`}</Label.Detail>
//             </Label>
//             <Label size={'tiny'} color={'pink'}>
//               Email:
//               <Label.Detail>{customer.email}</Label.Detail>
//             </Label>
//           </List.Item>
//         );
//       })}
//       {selectedLicensePlate && (
//         <List.Item
//           active={isEmpty(selectedCustomer)}
//           onClick={async () => {
//             setSelectedCustomer({});
//
//             await updateFirebasePickReference({
//               customerUpdates: { licensePlate: selectedLicensePlate },
//             });
//           }}
//         >
//           <Label color={'black'}>Associate new customer to license plate!</Label>
//         </List.Item>
//       )}
//     </List>
//     {!isEmpty(selectedCustomer) && (
//       <>
//         <Header>
//           Associated Drivers Licenses{' '}
//           <Header.Subheader>Verify, or add new drivers license</Header.Subheader>
//         </Header>
//         {selectedCustomer.driversLicenses.length === 0 && (
//           <Message>
//             <Message.Content>
//               No drivers licenses associated to selected customers account!
//             </Message.Content>
//           </Message>
//         )}
//         <List celled selection>
//           {selectedCustomer.driversLicenses.map((dl, key) => {
//             return (
//               <List.Item key={key}>
//                 <Label color={'blue'} className={'mb-1'} size={'tiny'}>
//                   Name:
//                   <Label.Detail>{`${dl.firstName} ${dl.lastName}`}</Label.Detail>
//                 </Label>
//                 <Label color={'teal'} size={'tiny'}>
//                   DL Number:
//                   <Label.Detail>{dl.number}</Label.Detail>
//                 </Label>
//               </List.Item>
//             );
//           })}
//         </List>
//         {driversLicenseInput}
//       </>
//     )}
//
//     {isEmpty(selectedCustomer) && (
//       <>
//         <Header>Associate Drivers License For New Customer</Header>
//         {driversLicenseInput}
//       </>
//     )}
//     {dlScanStatus === 'FAILURE' && (
//       <Message content={'Failed to parse drivers license data!'} color={'red'} />
//     )}
//     {dlScanStatus === 'SUCCESS' && (
//       <Message content={'Successfully parsed drivers license data!'} color={'green'} />
//     )}
//     <Divider />
//     <Button
//       fluid
//       content={'Complete Customer Registration'}
//       onClick={() => {
//         setCustomer(selectedCustomer);
//         onComplete();
//       }}
//     />
//   </>
// );
// };
//
// CustomerRegistration.propTypes = {
//   pick: PropTypes.object.isRequired,
//   setCustomer: PropTypes.func.isRequired,
//   onComplete: PropTypes.func.isRequired,
// };

// cargoCustomerRecord being undefined lets you know this routine hasn't run yet. Important
// for when to create a new customer. It will be empty
// array if we tried and failed, thus create new record
// const [{ value: cargoCustomerRecords }, getCustomerFromCargo] = useAsyncFn(async () => {
//   return await cargoApi.get('/customers/auctionMethodId', customer.id);
// }, [customer.id]);
//
// const [{ value: newCargoCustomerRecords }, createCargoCustomerRecord] = useAsyncFn(async () => {
//   return await cargoApi.post('/customers', {
//     auctionMethodId: customer.id,
//     firstName: customer.firstName,
//     lastName: customer.lastName,
//     email: customer.email,
//     verified: 0,
//   });
// }, [customer.id, customer.firstName, customer.lastName, customer.email]);

// const [cargoCustomer = {}] = [
//   ...(cargoCustomerRecords || []),
//   ...(newCargoCustomerRecords || []),
// ];
//
// useAsync(async () => {
//   if (!customer.id) return;
//
//   await getCustomerFromCargo();
// }, [customer.id]);

// const dontPostNewCustomerRecord = typeof cargoCustomerRecords === 'undefined' || cargoCustomer.id;
//
// useAsync(async () => {
//   if (dontPostNewCustomerRecord) return;
//
//   await createCargoCustomerRecord();
// }, [dontPostNewCustomerRecord]);

// const licensePlate = pick && pick.customerUpdates ? pick.customerUpdates.licensePlate : '';
// const { state, firstName, lastName, givenName, licenseNumber, postalCode } =
//   pick && pick.customerUpdates && pick.customerUpdates.driversLicenseData
//     ? pick.customerUpdates.driversLicenseData
//     : {};

// const [{ value: [newVehicleRecord = {}] = [] }, createCustomerVehicle] = useAsyncFn(async () => {
//   return await cargoApi.post('/vehicles', {
//     licensePlate,
//   });
// }, [licensePlate]);

// const [, createCustomerToVehicleAssociation] = useAsyncFn(async () => {
//   return cargoApi.post('/customerVehicles', {
//     customerId: cargoCustomer.id,
//     vehicleId: newVehicleRecord.id,
//   });
// }, [newVehicleRecord.id, cargoCustomer.id]);

// useAsync(async () => {
//   if (!newVehicleRecord.id) return;
//
//   await createCustomerToVehicleAssociation();
// }, [newVehicleRecord.id]);

// const [
//   { value: [newDriversLicenseRecord = {}] = [] },
//   createCustomerDriversLicense,
// ] = useAsyncFn(async () => {
//   return await cargoApi.post('/driversLicense', {
//     number: licenseNumber,
//     state,
//     zipCode: postalCode,
//     firstName: givenName || firstName,
//     lastName,
//   });
// }, [state, licenseNumber, postalCode, givenName, firstName, lastName]);

// const [, createCustomerToDriversLicenseAssociation] = useAsyncFn(async () => {
//   return cargoApi.post('/customerDriversLicenses', {
//     customerId: cargoCustomer.id,
//     driversLicenseId: newDriversLicenseRecord.id,
//   });
// }, [newDriversLicenseRecord.id, cargoCustomer.id]);
//
// useAsync(async () => {
//   if (!newDriversLicenseRecord.id) return;
//
//   await createCustomerToDriversLicenseAssociation();
// }, [newDriversLicenseRecord.id]);
