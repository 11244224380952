import React, { Component, Fragment } from 'react';
import { Link } from 'react-router-dom';
import { auth } from '../firebase';
import * as routes from '../constants/routes';
import { Button, Form, Segment } from 'semantic-ui-react';
import MessageBlock from '../shared-components/message-block';

const byPropKey = (propertyName, value) => () => ({
  [propertyName]: value,
});

const INITIAL_STATE = {
  email: '',
  error: null,
  messages: [],
  messageType: 'success',
};

class PasswordForgetPage extends Component {
  constructor(props) {
    super(props);

    this.state = { ...INITIAL_STATE };
  }

  onSubmit = event => {
    const { email } = this.state;

    auth
      .doPasswordReset(email)
      .then(() => {
        this.setState({ ...INITIAL_STATE, messages: [`An email has been sent to ${email}`] });
      })
      .catch(error => {
        this.setState({
          error,
          messages: [
            `An error has occurred. Please contact your system administrator`,
            error.message,
          ],
          messageType: 'error',
        });
      });

    event.preventDefault();
  };

  clearMessages = () => this.setState({ messages: [] });

  render() {
    const { email, messages, messageType } = this.state;

    const isInvalid = email === '';

    return (
      <Fragment>
        <div className={'flex ai-center jc-center mb-3'}>
          <MessageBlock
            messages={messages}
            type={messageType}
            onDismiss={this.clearMessages}
            timeout={5000}
          />
        </div>
        <div className={'flex ai-center jc-center'}>
          <Segment className={'flex fd-column w-30'}>
            <h3 className={'ff-jura t-center'}>Forgot Password</h3>

            <Form onSubmit={this.onSubmit}>
              <Form.Input
                value={this.state.email}
                onChange={event => this.setState(byPropKey('email', event.target.value))}
                type="text"
                placeholder="Email Address"
              />
              <Button disabled={isInvalid} type="submit" style={{ float: 'right' }}>
                Reset My Password
              </Button>
            </Form>
          </Segment>
        </div>
      </Fragment>
    );
  }
}

const PasswordForgetLink = () => (
  <p>
    <Link to={routes.PASSWORD_FORGET}>Forgot Password?</Link>
  </p>
);

export default PasswordForgetPage;

export { PasswordForgetLink };
