import React, { Component } from 'react';
import { DateInput } from 'semantic-ui-calendar-react';
import moment from 'moment';
import PropTypes from 'prop-types';
import { Button, Divider, Header, Label } from 'semantic-ui-react';
import { times } from 'lodash/fp';

class ItemTimers extends Component {
  constructor(props) {
    super(props);

    this.sundays = this.getListOfSundays();

    this.state = {
      date: '',
      additions: [],
    };
  }

  getListOfSundays = () => {
    const m = moment().weekday(0);

    return times(() => m.add(7, 'days').format('MM-DD-YYYY'), 3);
  };

  handleChange = (event, { name, value }) => this.setState({ [name]: value });

  handleItemAddition = index => {
    this.setState(prevState => ({ additions: [...prevState.additions, index] }));
  };

  updateTimers = async () => {
    const {
      state: { additions, date },
      props: {
        invoices,
        timerUpdates,
        cargoCrud: { put: update },
        extraItems,
      },
    } = this;

    // let the people know
    if (!date) return;

    const targetDateHours = moment(new Date(date)).diff(moment().startOf('day'), 'hours');

    const requests = invoices
      .map(inv =>
        [...inv.line_items, ...extraItems.filter(item => additions.includes(item.id))].map(
          item => ({
            timeRemaining: targetDateHours,
            id: item.itemId,
          })
        )
      )
      .flat();

    // @TODO add error handling message via snackbar
    // and revert invoice update if fail
    await Promise.all(
      requests.map(req => {
        return update(req);
      })
    );

    // assuming results pass, update invoice array
    const updatedInvoices = invoices.map(inv => ({
      ...inv,
      line_items: [
        ...inv.line_items,
        ...extraItems.filter(item => additions.includes(item.id)),
      ].map(item => ({ ...item, timeRemaining: targetDateHours })),
    }));

    timerUpdates(updatedInvoices);
  };

  render() {
    const {
      state: { date, additions },
      props: { invoices, closeModal, extraItems },
      handleChange,
      handleItemAddition,
      updateTimers,
    } = this;

    let longestTimer = 0;
    let shortestTimer = 500;

    return (
      <>
        {invoices.map((inv, key) => {
          return (
            <div key={key}>
              <Header as={'h4'}>Invoice: {inv.id}</Header>
              {extraItems.length > 0 &&
                extraItems
                  .filter(item => !additions.includes(item.id))
                  .map((item, key3) => {
                    return (
                      <div key={key3} className={'flex fd-row jc-between ai-center mb-1'}>
                        <div>
                          <Button
                            as={Label}
                            icon={'arrow down'}
                            compact
                            onClick={() => handleItemAddition(item.id)}
                          />
                          <Label>{item.lot_number}</Label>
                        </div>
                        <Label>
                          {item.timeRemaining
                            ? `${item.timeRemaining / 24} days`
                            : 'No timer available.'}
                          days
                        </Label>
                      </div>
                    );
                  })}
              <Divider />
              <div className={'mb-4'}>
                {[...inv.line_items, ...extraItems.filter(item => additions.includes(item.id))].map(
                  (item, key1) => {
                    longestTimer =
                      item.timeRemaining > longestTimer ? item.timeRemaining : longestTimer;
                    shortestTimer =
                      item.timeRemaining < shortestTimer ? item.timeRemaining : shortestTimer;

                    return (
                      <div key={key1} className={'flex fd-row jc-between mb-1'}>
                        <Label>{item.lot_number}</Label>
                        <Label>
                          {item.timeRemaining
                            ? `${item.timeRemaining / 24} days`
                            : 'No timer available.'}
                        </Label>
                      </div>
                    );
                  }
                )}
              </div>
            </div>
          );
        })}
        <DateInput
          closable
          fluid
          dateFormat={'MM-DD-YYYY'}
          iconPosition={'left'}
          className={'mb-4'}
          disable={[...this.sundays]}
          minDate={moment().add(longestTimer, 'hours')}
          maxDate={moment().add(shortestTimer / 24 + 7, 'days')}
          value={
            date ||
            moment()
              .add(longestTimer, 'hours')
              .format('MM-DD-YYYY')
          }
          name={'date'}
          onChange={handleChange}
          initialDate={moment()
            .add(longestTimer, 'hours')
            .format('MM-DD-YYYY')}
        />
        <div className={'flex fd-row jc-end'}>
          <Button content={'Cancel'} color={'red'} onClick={closeModal} />
          <Button content={'Update Timers'} color={'blue'} onClick={updateTimers} />
        </div>
      </>
    );
  }
}

ItemTimers.defaultProps = {
  closeModal: () => {},
  extraItems: [],
};

ItemTimers.propTypes = {
  invoices: PropTypes.array.isRequired,
  timerUpdates: PropTypes.func.isRequired,
  cargoCrud: PropTypes.object.isRequired,
  closeModal: PropTypes.func,
  extraItems: PropTypes.array,
};

export default ItemTimers;
