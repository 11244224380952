import React, { Component } from 'react';
import { Accordion, Dropdown, Icon, Table } from 'semantic-ui-react';
import API from './api';
import NellisAuctionApi from '../shared-api-adapters/nellis-auction-api';
import withAuthorization from './with-authorization';
import PropTypes from 'prop-types';

class ClerkDropdown extends Component {
  constructor(props) {
    super(props);

    const { authUser } = this.props;

    this.nellisAuctionApi = new NellisAuctionApi(authUser.token);

    this.state = {
      accordionActive: false,
      clerkId: 1,
      pettyCash: [],
    };
  }

  setClerkId = async (event, { value }) => {
    const users = await this.nellisAuctionApi.get(this.nellisAuctionApi.routes.users);

    const { id } = users.find(user => user.auctionMethodId === value);

    const pettyCash = await this.nellisAuctionApi.get(
      `${this.nellisAuctionApi.routes.pettyCash}/user/${id}`
    );

    this.setState({ clerkId: value, pettyCash });
  };

  sumTransactionType = (transactions, transactionType = null) => {
    return transactions.reduce((acc, value) => {
      if (value.mode === transactionType || transactionType === null) {
        return acc + value.amount;
      }
      return acc;
    }, 0.0);
  };

  formatDate = transactions => {
    return transactions.map(transaction => {
      const { createdAt } = transaction;
      const date = new Date(createdAt);

      return {
        ...transaction,
        createdAt: `${date.toISOString().slice(0, 10)} ${date.toLocaleTimeString().slice(0, 8)}`,
      };
    });
  };

  handleAccordionClick = () => this.setState({ accordionActive: !this.state.accordionActive });

  render() {
    const { accordionActive, clerkId, pettyCash } = this.state;

    return (
      <API.Query queries={[{ route: 'users' }]}>
        {({ data: [users] }) => {
          const filteredUsers = users.filter(user => user.auctionMethodId !== null);

          return (
            <>
              <Dropdown
                className={'icon'}
                labeled
                name={'clerks'}
                clearable
                search
                options={filteredUsers.map(user => ({
                  text: `${user.firstName} ${user.lastName}`,
                  value: user.auctionMethodId ? user.auctionMethodId : user.id,
                }))}
                placeholder={'Please select a clerk'}
                onChange={this.setClerkId}
                selection
              />
              <API.Query
                queries={[{ route: 'auctionMethodPayments', id: clerkId ? clerkId : 1 }]}
                control={true}
              >
                {({ data: [transactions] }) => {
                  if (clerkId === 1) return null;

                  if (transactions.length === 0 && pettyCash.length === 0) {
                    return (
                      <div className={'m-3'}>
                        <p>No transactions found for this user</p>
                      </div>
                    );
                  }

                  const cash = this.sumTransactionType(transactions, 'Cash');
                  const creditCard = this.sumTransactionType(transactions, 'Credit Card');
                  const swipedCreditCard = this.sumTransactionType(
                    transactions,
                    'Swiped Credit Card'
                  );
                  const check = this.sumTransactionType(transactions, 'Check');
                  const wireTransfer = this.sumTransactionType(transactions, 'Wire Transfer');
                  const storeCredit = this.sumTransactionType(transactions, 'Store Credit');
                  const pettyCashTotal = this.sumTransactionType(pettyCash);

                  const allTransactions = [...transactions, ...this.formatDate(pettyCash)];

                  return (
                    <>
                      <Table basic={'very'} striped celled className={'stats-table'}>
                        <Table.Header>
                          <Table.Row>
                            <Table.HeaderCell>Transaction Type</Table.HeaderCell>
                            <Table.HeaderCell>Total</Table.HeaderCell>
                          </Table.Row>
                        </Table.Header>
                        <Table.Body>
                          <Table.Row>
                            <Table.Cell>Cash</Table.Cell>
                            <Table.Cell>${cash.toFixed(2)}</Table.Cell>
                          </Table.Row>
                          <Table.Row>
                            <Table.Cell>Credit Card</Table.Cell>
                            <Table.Cell>${creditCard.toFixed(2)}</Table.Cell>
                          </Table.Row>
                          <Table.Row>
                            <Table.Cell>Swiped Credit Card</Table.Cell>
                            <Table.Cell>${swipedCreditCard.toFixed(2)}</Table.Cell>
                          </Table.Row>
                          <Table.Row>
                            <Table.Cell>Check</Table.Cell>
                            <Table.Cell>${check.toFixed(2)}</Table.Cell>
                          </Table.Row>
                          <Table.Row>
                            <Table.Cell>Wire Transfer</Table.Cell>
                            <Table.Cell>${wireTransfer.toFixed(2)}</Table.Cell>
                          </Table.Row>
                          <Table.Row>
                            <Table.Cell>Store Credit</Table.Cell>
                            <Table.Cell>${storeCredit.toFixed(2)}</Table.Cell>
                          </Table.Row>
                          <Table.Row>
                            <Table.Cell>Petty Cash</Table.Cell>
                            <Table.Cell>${pettyCashTotal.toFixed(2)}</Table.Cell>
                          </Table.Row>
                        </Table.Body>
                      </Table>

                      <Accordion fluid styled>
                        <Accordion.Title
                          active={accordionActive}
                          index={1}
                          onClick={this.handleAccordionClick}
                        >
                          <Icon name="dropdown" />
                          Transaction Details
                        </Accordion.Title>
                        <Accordion.Content active={accordionActive}>
                          <Table basic={'very'} striped celled className={'stats-table'}>
                            <Table.Header>
                              <Table.Row>
                                <Table.HeaderCell>Date / Time</Table.HeaderCell>
                                <Table.HeaderCell>Invoice ID</Table.HeaderCell>
                                <Table.HeaderCell>Payment Type</Table.HeaderCell>
                                <Table.HeaderCell>Amount</Table.HeaderCell>
                              </Table.Row>
                            </Table.Header>
                            <Table.Body>
                              {allTransactions.map((transaction, key) => (
                                <Table.Row key={key}>
                                  <Table.Cell>{transaction.createdAt}</Table.Cell>
                                  <Table.Cell>{transaction.invoice_id}</Table.Cell>
                                  <Table.Cell>{transaction.mode || 'Petty Cash'}</Table.Cell>
                                  <Table.Cell>${transaction.amount.toFixed(2)}</Table.Cell>
                                </Table.Row>
                              ))}
                            </Table.Body>
                          </Table>
                        </Accordion.Content>
                      </Accordion>
                    </>
                  );
                }}
              </API.Query>
            </>
          );
        }}
      </API.Query>
    );
  }
}

ClerkDropdown.propTypes = {
  authUser: PropTypes.object.isRequired,
};

export default withAuthorization(ClerkDropdown);
