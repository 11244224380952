import React, { Component } from 'react';
import { Dropdown, Input } from 'semantic-ui-react';
import PropTypes from 'prop-types';

class SearchBy extends Component {
  state = {
    searchValue: '',
    by: this.props.initialOptionValue || '',
    loading: false,
  };

  handleChange = (event, { value, name }) => this.setState({ [name]: value });

  setLoading = loading => this.setState({ loading });

  render() {
    const {
      props: { children, options, label },
      state: { searchValue, by, loading },
      handleChange,
      setLoading,
    } = this;

    return (
      <div>
        <Input
          fluid
          loading={loading}
          onSubmit={handleChange}
          onChange={handleChange}
          name={'searchValue'}
          icon={'search'}
          value={searchValue}
          label={
            options.length > 1 ? (
              <Dropdown
                placeholder={'Search by'}
                name={'by'}
                onChange={handleChange}
                options={options}
                value={by}
              />
            ) : (
              label
            )
          }
          className={'my-1'}
        />
        {children({ by, searchValue, setLoading })}
      </div>
    );
  }
}

SearchBy.defaultProps = {
  label: '',
};

SearchBy.propsTypes = {
  children: PropTypes.func.isRequired,
  options: PropTypes.array.isRequired,
  initialOptionValue: PropTypes.string,
  label: PropTypes.string,
};

export default SearchBy;
